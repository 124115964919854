import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  AppConfigService,
  ATableComponent,
  CellChangeEvent,
  CollectionEstimatedCostDto,
  CollectionEstimatedCostsService,
  CollectionExternalProviderRelationsService,
  CollectionVersionService,
  CurrencyExchangeRateService,
  DictionaryBaseDto,
  DictionaryBaseService,
  ExternalProviderRelationDto,
  GrowlService,
  LoggedUserService,
  RouterService,
  TemplateService,
} from '../../bonding_shared';
import {CollectionCostsAbstractComponent} from './collection-costs-abstract.component';
import * as _ from 'lodash';
import {
  CollectionCostType,
  CollectionElementaryRight,
  CollectionRecoveryType,
  CollectionType,
  Currency,
} from '../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'collection-estimated-costs',
  templateUrl: 'collection-costs.component.pug',
})
export class CollectionEstimatedCostsComponent extends CollectionCostsAbstractComponent {
  @ViewChild('estimatedCosts', {static: true}) estimatedCostsTable: ATableComponent<CollectionEstimatedCostDto>;
  equivalentCurrency: DictionaryBaseDto;

  constructor(
    route: ActivatedRoute,
    versionService: CollectionVersionService,
    protected parentService: CollectionEstimatedCostsService,
    private currencyExchangeRateService: CurrencyExchangeRateService,
    private dictBaseService: DictionaryBaseService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    loggedUserService: LoggedUserService,
    collectionExternalProviderRelationsService: CollectionExternalProviderRelationsService,
    templateService: TemplateService,
    appConfig: AppConfigService
  ) {
    super(
      route,
      versionService,
      parentService,
      router,
      translateService,
      growlService,
      loggedUserService,
      collectionExternalProviderRelationsService,
      templateService,
      appConfig
    );
    this.titleLabelKey = 'collectionCosts.estimatedCostsTitle';
    this.dictBaseService
      .getDictionaryEntry('Currency', Currency.PLN)
      .subscribe((cur) => (this.equivalentCurrency = cur));
  }

  accountedCostsEditable(): boolean {
    return false;
  }

  sendToAcceptance() {
    this.parentService.sendToAcceptance(this.parent.id, this.acceptanceUser.id).subscribe({
      next: (res) => {
        this.current.acceptanceTask = res;
        this.acceptanceUser = undefined;
        this.current.warnings = undefined;
        this.showSavedMsg();
      },
      error: (error) => this.handleServerError(error),
    });
  }

  public get pickUser(): boolean {
    return (
      !!(this.current.warnings && this.current.warnings.length) &&
      this.loggedUserService.hasRight(CollectionElementaryRight.COLLECTION_COSTS_CREATE_UPDATE)
    );
  }

  decideAcceptanceTask(): boolean {
    return (
      this.current.acceptanceTask &&
      this.current.acceptanceTask.id &&
      this.current.acceptanceTask.user &&
      this.loggedUserService.isThisUser(this.current.acceptanceTask.user.id)
    );
  }

  accept() {
    this.onSave();
  }

  showRejectModal() {
    const confirmationPromise: Promise<boolean> = this.rejectCostsConfirmDialog.open(
      'collectionCosts.rejectCostsTitle',
      'collectionCosts.rejectCostsMessage'
    );

    confirmationPromise.then((result) => {
      if (result) {
        this.reject();
      }
    });
  }

  reject() {
    this.parentService.reject(this.parent.id, this.current).subscribe({
      next: (res) => {
        this.current = res;
        this.showSavedMsg();
      },
      error: (error) => this.handleServerError(error),
    });
  }

  onSupplierRelationClick(supplierRelation: ExternalProviderRelationDto) {
    if (this.getRelationFromList(supplierRelation).provider) {
      this.router.toExternalProviderDetailsByParentId(supplierRelation.provider.id);
    } else {
      this.router.toCompanyPreview(supplierRelation.company.id);
    }
  }

  onAddEstimatedCost() {
    if (!this.current.estimatedCosts) {
      this.current.estimatedCosts = [];
    }
    let balance = 0;
    let balanceCurrency;
    if (this.parent.type.id === CollectionType.RECOVERY_BONDING) {
      balance = this.version.balance;
      balanceCurrency = this.equivalentCurrency;
      this.estimatedCostsTable.onAddItem(<CollectionEstimatedCostDto>{
        balance: balance,
        balanceCurrency: balanceCurrency,
        waitingForAcceptance: true,
      });
    } else {
      const oneCurrency = _.uniqBy(this.version.collectionInvoices, (inv) => inv.currency.code).length === 1;
      if (oneCurrency) {
        balance =
          this.version &&
          this.version.recoveryAssignmentType &&
          this.version.recoveryAssignmentType.id === CollectionRecoveryType.INSURER
            ? this.version.collectionInvoices.map((inv) => inv.insurersBalance).reduce((a, b) => a + b, 0)
            : this.version.collectionInvoices.map((inv) => inv.totalBalance).reduce((a, b) => a + b, 0);
        balanceCurrency = this.version.collectionInvoices[0].currency;
        this.estimatedCostsTable.onAddItem(<CollectionEstimatedCostDto>{
          balance: balance,
          balanceCurrency: balanceCurrency,
          waitingForAcceptance: true,
        });
      } else {
        this.estimatedCostsTable.onAddItem(<CollectionEstimatedCostDto>{waitingForAcceptance: true});
      }
    }
  }

  onTypeChange(event: CellChangeEvent<CollectionEstimatedCostDto>) {
    this.recalculateBalanceIfApplicable(event);
    this.recalculateCommissionIfApplicable(event);
  }

  onProposedAmountChange(event: CellChangeEvent<CollectionEstimatedCostDto>) {
    event.item.waitingForAcceptance = event.item.proposedAmount !== null && event.item.proposedAmount > 0;
  }

  proposedAmountRequired(item: CollectionEstimatedCostDto): boolean {
    return !item.acceptedAmount;
  }

  recalculateBalanceIfApplicable(event: CellChangeEvent<CollectionEstimatedCostDto>) {
    if (event.item.type && event.item.type.id === CollectionCostType.COURT_EXECUTION_PROCEDURE) {
      event.item.balanceCurrency = <DictionaryBaseDto>{id: Currency.PLN};
      event.item.balance = _(this.version.collectionInvoices)
        .map((i) => this.currencyExchangeRateService.calculate(i.totalBalance, i.currency.id, Currency.PLN))
        .reduce((a, b) => a + b, 0);
    }
  }

  recalculateCommissionIfApplicable(event: CellChangeEvent<CollectionEstimatedCostDto>) {
    if (event.item.supplierRelation && event.item.type && event.item.type.id === CollectionCostType.COMMISSION) {
      const supplierRelation =
        event.item.supplierRelation && this.availableSuppliers.find((sup) => sup.id === event.item.supplierRelation.id);
      const commission = supplierRelation && (supplierRelation.commission || supplierRelation.commissionFromProvider);
      event.item.commissionAmount =
        commission &&
        event.item.balance &&
        event.item.balance * (commission / 100) * ((100 + supplierRelation.vatRate) / 100);
      event.item.currency = event.item.balanceCurrency;
      event.item.proposedAmount = event.item.commissionAmount;
      event.item.waitingForAcceptance = true;
    } else {
      delete event.item.commissionAmount;
    }
  }
}
