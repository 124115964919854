import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView, FrontendError} from '../../../../bonding_shared/components/details-view/details-view';
import {
  CountryRatingService,
  GrowlService,
  IndustryTariffsDto,
  InquiryService,
  OfferCalculationEnvelopeDto,
  PolicyInquiryOfferEnvelopeDto,
  PremiumCalculationCriteriaDto,
  PremiumCalculationResultDto,
  RouterService,
} from '../../../../bonding_shared';
import {DictionaryProfile, RatingScale} from '../../../../bonding_shared/model/dictionary-ids';
import {NgForm} from '@angular/forms';
import {forkJoin} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ecg-calculator-details',
  templateUrl: 'ecg-calculator-details.component.pug',
  styles: ['.bon-card-group {margin-top: 15px; margin-bottom: 15px}', 'table td {border-top: none}'],
})
export class EcgCalculatorDetailsComponent extends DetailsView implements OnInit {
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  readonly CHECK_STYLES = {
    NOT_CHECKED: '',
    NOK: 'color: white; background-color: red !important',
    OK: 'color: white; background-color: green !important',
  };

  readonly INITIAL_ADMINISTRATION_COST_MULTIPLICATOR = 1.3;

  readonly RatingScale = RatingScale;

  readonly DictionaryProfile = DictionaryProfile;

  insurableTurnoverMultiplicator: number;

  premiumCalculationCriteria = <PremiumCalculationCriteriaDto>{};
  premiumCalculationResult = <PremiumCalculationResultDto>{};

  lRaverage: number;
  lRmultiplicator: number;

  brokerCommissionMultiplicator: number;
  discretionaryCoverMultiplier: number;
  insuranceCoverMultiplier: number;
  calculatedExpectedPremium: number;
  discount: number;

  insuredAmountGraterThanHio: 'NOT_CHECKED' | 'OK' | 'NOK';
  insuredAmountLessEquals30xPremium: 'NOT_CHECKED' | 'OK' | 'NOK';
  multiplicity: number;

  offerId: number;
  inquiryId: number;
  offerEnvelope: PolicyInquiryOfferEnvelopeDto;

  premiumRateDisabled = true;

  public industryTariffs = <IndustryTariffsDto>{};

  constructor(
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    private route: ActivatedRoute,
    protected countryRatingService: CountryRatingService,
    public routerService: RouterService,
    private inquiryService: InquiryService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.saveButton.disabled = true;
    this.cancelButton.hidden = false;
  }

  ngOnInit() {
    this.readOnlyMode = true;
    this.handleButtons();
    this.form = this.ngForm.form;
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.offerId = +params['offerId'];
    this.inquiryId = +params['inquiryId'];

    this.inProgress = true;
    forkJoin([this.inquiryService.getOffer(this.offerId), this.inquiryService.getCalculate(this.offerId)]).subscribe({
      next: ([offerEnvelope, calc]) => {
        this.setOffer(offerEnvelope);
        this.setCalculation(calc);
      },
      error: (error) => this.handleServerError(error),
      complete: () => (this.inProgress = false),
    });
    this.insuredAmountGraterThanHio = 'NOT_CHECKED';
    this.insuredAmountLessEquals30xPremium = 'NOT_CHECKED';
  }

  private setCalculation(res: OfferCalculationEnvelopeDto) {
    if (res && res.premiumCalculationCriteria) {
      this.premiumCalculationCriteria = res.premiumCalculationCriteria;
      this.recalculateIndustryTariffs();
      this.recalculateCalculatedExpectedPremium();
      this.recalculateInsurableTurnoverMultiplicator();
      this.recalculateBrokerCommissionMultiplicator();
      this.recalculateDiscretionaryCoverMultiplier();
      this.recalculateInsuranceCoverMultiplier();
      this.calculateChecks();
    } else {
      this.initCalculationCriteria();
    }
  }

  private setOffer(offerEnvelope: PolicyInquiryOfferEnvelopeDto) {
    this.offerEnvelope = offerEnvelope;
    if (offerEnvelope.offer.premiumCalculationResult) {
      this.premiumCalculationResult = offerEnvelope.offer.premiumCalculationResult;
      this.saveButton.disabled = true;
    } else {
      this.resetPremiumCalculationResults();
    }
  }

  initCalculationCriteria() {
    this.premiumCalculationCriteria = <PremiumCalculationCriteriaDto>{};
    this.premiumCalculationCriteria.naceCode = this.offerEnvelope.offer.policyInquiryVersion.naceCode;
    this.premiumCalculationCriteria.insurableTurnover = this.offerEnvelope.offer.policyInquiryVersion.turnoverInsurable;
    this.premiumCalculationCriteria.administrationCostMultiplicator = this.INITIAL_ADMINISTRATION_COST_MULTIPLICATOR;
    this.premiumCalculationCriteria.minimumPremiumRatio = 0.5;
    const brokerCommission = this.offerEnvelope.offer.policyInquiryVersion.brokerCommission;
    this.premiumCalculationCriteria.brokerCommission = brokerCommission != null ? brokerCommission : 0;
    this.recalculateIndustryTariffs();
    this.recalculateCalculatedExpectedPremium();
    this.recalculateInsurableTurnoverMultiplicator();
    this.recalculateBrokerCommissionMultiplicator();
    this.recalculateDiscretionaryCoverMultiplier();
  }

  // todo
  onSave(): void {
    if (!this.formValidates()) {
      return;
    }
    this.inProgress = true;
    this.serverErrors = null;
    this.inquiryService.savePremiumCalculation(this.offerId, this.premiumCalculationCriteria).subscribe({
      next: () => {
        this.showSavedMsg('inquiry.calculator.premiumCalculationSaved');
        this.inProgress = false;
      },
      error: (error) => this.handleServerError(error),
    });

    this.readOnlyMode = true;
    this.premiumRateDisabled = true;
    this.handleButtons();
  }

  goToOffer() {
    this.routerService.toInquiryOfferDetails(this.inquiryId, this.offerId);
  }

  // todo
  onCalculate() {
    if (this.recalculationDisabled()) {
      return;
    }
    this.serverErrors = null;
    this.frontendErrors = null;
    const errors = this.calculateCalculatedPremiumRateCriteriaComplete();
    if (errors.length > 0) {
      this.frontendErrors = errors;
      return;
    }
    this.inProgress = true;
    this.serverErrors = null;
    this.inquiryService.calculateCalculatedPremiumRate(this.premiumCalculationCriteria).subscribe({
      next: (result) => {
        this.premiumCalculationResult = result;
        this.growlService.notice('inquiry.calculator.succeeded');
        this.showErrors = false;
        this.inProgress = false;
        this.saveButton.disabled = false;
      },
      error: (error) => {
        this.handleServerError(error);
        this.saveButton.disabled = true;
      },
      complete: () => {
        this.recalculateDiscount();
        this.readOnlyMode = true;
      },
    });
  }

  resetPremiumCalculationResults() {
    this.saveButton.disabled = true;
    this.premiumCalculationResult = <PremiumCalculationResultDto>{};
  }

  recalculationDisabled() {
    // return !(this.offerEnvelope?.offer?.votingStatus?.id === PolicyInquiryOfferVotingStatus.NOT_SENT);
    return false;
  }

  // todo
  onEdit(): void {
    this.readOnlyMode = false;
    this.premiumRateDisabled = false;
    this.handleButtons();
    this.saveButton.disabled = true;
  }

  // todo
  onCancel(): void {
    this.readOnlyMode = true;
    this.premiumRateDisabled = true;
    this.handleButtons();
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  // todo
  public handleButtons(): void {
    this.editButton.hidden = !this.readOnlyMode;
    this.editButton.disabled = !this.readOnlyMode;
    this.saveButton.hidden = this.readOnlyMode;
    this.cancelButton.hidden = this.readOnlyMode;
  }

  onInsurableTurnoverChange() {
    this.recalculateInsurableTurnoverMultiplicator();
    this.recalculateCalculatedExpectedPremium();
    this.recalculateDiscount();
    this.recalculateExpectedPremium();
  }

  recalculateInsurableTurnoverMultiplicator() {
    if (this.premiumCalculationCriteria.insurableTurnover == null) {
      this.insurableTurnoverMultiplicator = null;
      this.recalculateCalculatedPremiumRate();
      return;
    }
    this.inProgress = true;
    this.inquiryService.calculateInsurableTurnoverMultiplicator(this.premiumCalculationCriteria).subscribe({
      next: (res) => {
        this.insurableTurnoverMultiplicator = res;
        this.inProgress = false;
        this.recalculateCalculatedExpectedPremium();
        this.recalculateCalculatedPremiumRate();
      },
      error: (error) => {
        console.log('insurableTurnoverMultiplicator', error);
        this.growlService.error('Error calculating Insurable turnover multiplicator!');
        this.insurableTurnoverMultiplicator = null;
        this.inProgress = false;
        this.recalculateCalculatedExpectedPremium();
        this.recalculateCalculatedPremiumRate();
      },
    });
  }

  recalculateLossRatioMultiplicator() {
    if (!this.lRaverage) {
      this.lRmultiplicator = null;
      this.recalculateCalculatedPremiumRate();
      return;
    }
    this.inProgress = true;
    this.inquiryService.calculateLossRatioMultiplicator(this.premiumCalculationCriteria).subscribe({
      next: (res) => {
        this.lRmultiplicator = res;
        this.inProgress = false;
        this.recalculateCalculatedPremiumRate();
      },
      error: (error) => {
        console.log('insurableTurnoverMultiplicator', error);
        this.growlService.error('Error calculating Loss ratio multiplicator!');
        this.lRmultiplicator = null;
        this.inProgress = false;
        this.recalculateCalculatedPremiumRate();
      },
    });
  }

  recalculateIndustryTariffs() {
    if (!this.premiumCalculationCriteria.naceCode.id) {
      this.industryTariffs = <IndustryTariffsDto>{};
      this.recalculateCalculatedPremiumRate();
      return;
    }
    this.inquiryService.getIndustryTariffs(this.premiumCalculationCriteria.naceCode.id).subscribe({
      next: (res) => {
        this.industryTariffs = res;
        this.recalculateCalculatedPremiumRate();
      },
      error: (error) => {
        console.log('recalculateIndustryTariffs', error);
        this.growlService.error('Error calculatoing industry tariffs!');
        this.industryTariffs = <IndustryTariffsDto>{};
        this.recalculateCalculatedPremiumRate();
      },
    });
  }

  recalculateLrAverage() {
    this.lRaverage =
      (this.premiumCalculationCriteria.lossRatioCurrentYear +
        this.premiumCalculationCriteria.lossRatioYearMinusOne +
        this.premiumCalculationCriteria.lossRatioYearMinusTwo) /
      3;
    this.recalculateLossRatioMultiplicator();
  }

  recalculateBrokerCommissionMultiplicator() {
    if (this.premiumCalculationCriteria.brokerCommission !== 1) {
      this.brokerCommissionMultiplicator = 1 / (1 - this.premiumCalculationCriteria.brokerCommission / 100);
    } else {
      this.brokerCommissionMultiplicator = null;
    }
    this.recalculateCalculatedPremiumRate();
  }

  recalculateCalculatedPremiumRate() {
    //   if (!this.calculateCalculatedPremiumRateCriteriaComplete()) {
    //     return;
    //   }
    //   // todo: business review: calculate on sufficient data entry or on Recalculate button click
    //   //    this.recalculateCalculatedExpectedPremium();
    //   //    this.recalculateDiscount();
  }

  calculateCalculatedPremiumRateCriteriaComplete(): FrontendError[] {
    return this.getRequiredErrors([
      {value: this.insurableTurnoverMultiplicator, labelKey: 'inquiry.calculator.insurableTurnoverMultiplicator'},
      {value: this.industryTariffs.riskMultiplicator, labelKey: 'inquiry.calculator.riskMultiplicator'},
      {value: this.industryTariffs.tariffRatePercent, labelKey: 'inquiry.calculator.tariffRatePercent'},
      {
        value: this.premiumCalculationCriteria.brokerCommission,
        labelKey: 'bondingContractOffer.details.brokerCommission',
      },
      {
        value: this.premiumCalculationCriteria.administrationCostMultiplicator,
        labelKey: 'inquiry.calculator.intermediary.administrationCostMultiplicator',
      },
      {value: this.premiumCalculationCriteria.insuranceCover, labelKey: 'inquiry.calculator.conditions.insuranceCover'},
      {value: this.premiumCalculationCriteria.lossRatioCurrentYear, labelKey: 'inquiry.calculator.lossRatio.lRcY'},
      {
        value: this.premiumCalculationCriteria.lossRatioYearMinusOne,
        labelKey: 'inquiry.calculator.lossRatio.lRyMinusOne',
      },
      {
        value: this.premiumCalculationCriteria.lossRatioYearMinusTwo,
        labelKey: 'inquiry.calculator.lossRatio.lRyMinusTwo',
      },
    ]);
  }

  private getRequiredErrors(fields: {value: any; labelKey: string}[]): FrontendError[] {
    const errors: FrontendError[] = [];
    const msgPrefix = 'Field "';
    const msgSuffix = '" is required to calculate.';
    for (const field of fields) {
      if (field.value == null) {
        const msgInfix = this.translateService.instant(field.labelKey);
        errors.push(<FrontendError>{msgKey: msgPrefix + msgInfix + msgSuffix, warning: false});
      }
    }
    return errors;
  }

  recalculateDiscretionaryCoverMultiplier() {
    if (this.premiumCalculationCriteria.discretionaryCover == null) {
      this.discretionaryCoverMultiplier = null;
      this.recalculateCalculatedPremiumRate();
      return;
    }
    this.inProgress = true;
    this.inquiryService.calculateDiscretionaryCoverMultiplier(this.premiumCalculationCriteria).subscribe({
      next: (res) => {
        this.discretionaryCoverMultiplier = res;
        this.inProgress = false;
        this.recalculateCalculatedPremiumRate();
      },
      error: (error) => {
        console.log('recalculateDiscretionaryCoverMultiplier', error);
        this.growlService.error('Error calculating Discretionary Cover Multiplier!');
        this.discretionaryCoverMultiplier = null;
        this.inProgress = false;
        this.recalculateCalculatedPremiumRate();
      },
    });
  }

  recalculateInsuranceCoverMultiplier() {
    if (!this.premiumCalculationCriteria.insuranceCover) {
      this.insuranceCoverMultiplier = null;
      this.recalculateCalculatedPremiumRate();
      return;
    }
    this.inProgress = true;
    this.inquiryService.calculateInsuranceCoverMultiplier(this.premiumCalculationCriteria).subscribe({
      next: (res) => {
        this.insuranceCoverMultiplier = res;
        this.inProgress = false;
        this.recalculateCalculatedPremiumRate();
      },
      error: (error) => {
        console.log('recalculateInsuranceCoverMultiplier', error);
        this.growlService.error('Error calculating Insurance Cover Multiplier!');
        this.insuranceCoverMultiplier = null;
        this.inProgress = false;
        this.recalculateCalculatedPremiumRate();
      },
    });
  }

  onPremiumRatechange() {
    this.recalculateDiscount();
    this.recalculateExpectedPremium();
  }

  recalculateDiscount() {
    if (this.premiumCalculationCriteria.premiumRate && this.premiumCalculationResult.premiumRate !== 0) {
      this.discount = 1 - this.premiumCalculationCriteria.premiumRate / this.premiumCalculationResult.premiumRate;
    } else {
      this.discount = null;
    }
  }

  recalculateCalculatedExpectedPremium() {
    // todo: calculate on backend together with calculatedPremiumRate
    // todo: null checks
    // this.calculatedExpectedPremium = this.calculatedPremiumRate * this.premiumCalculationCriteria.insurableTurnover;
  }

  recalculateExpectedPremium() {
    if (
      this.premiumCalculationCriteria.insurableTurnover == null ||
      this.premiumCalculationCriteria.premiumRate == null
    ) {
      this.premiumCalculationCriteria.expectedPremium = null;
      return;
    }
    this.premiumCalculationCriteria.expectedPremium =
      (this.premiumCalculationCriteria.insurableTurnover * this.premiumCalculationCriteria.premiumRate) / 100;
    this.recalculateMinimumPremium();
    this.calculateChecks();
  }

  recalculateMinimumPremium() {
    if (
      this.premiumCalculationCriteria.expectedPremium == null ||
      this.premiumCalculationCriteria.minimumPremiumRatio == null
    ) {
      this.premiumCalculationCriteria.minimumPremium = null;
      return;
    }
    const minimumPremiumPrecise: number =
      this.premiumCalculationCriteria.expectedPremium * this.premiumCalculationCriteria.minimumPremiumRatio;
    this.premiumCalculationCriteria.minimumPremium = Number((minimumPremiumPrecise / 1000).toFixed()) * 1000;
  }

  recalculateMinimumPremiumRatio() {
    if (
      this.premiumCalculationCriteria.expectedPremium == null ||
      this.premiumCalculationCriteria.expectedPremium === 0 ||
      this.premiumCalculationCriteria.minimumPremium == null
    ) {
      this.premiumCalculationCriteria.minimumPremiumRatio = null;
      return;
    }
    this.premiumCalculationCriteria.minimumPremiumRatio =
      this.premiumCalculationCriteria.minimumPremium / this.premiumCalculationCriteria.expectedPremium;
  }

  calculateChecks() {
    if (!this.premiumCalculationCriteria.insuredAmount || !this.premiumCalculationCriteria.hio) {
      this.insuredAmountGraterThanHio = 'NOT_CHECKED';
    } else if (this.premiumCalculationCriteria.insuredAmount > this.premiumCalculationCriteria.hio) {
      this.insuredAmountGraterThanHio = 'OK';
    } else {
      this.insuredAmountGraterThanHio = 'NOK';
    }
    if (!this.premiumCalculationCriteria.insuredAmount || !this.premiumCalculationCriteria.expectedPremium) {
      this.insuredAmountLessEquals30xPremium = 'NOT_CHECKED';
    } else if (this.premiumCalculationCriteria.insuredAmount <= this.premiumCalculationCriteria.expectedPremium * 30) {
      this.insuredAmountLessEquals30xPremium = 'OK';
    } else {
      this.insuredAmountLessEquals30xPremium = 'NOK';
    }
    this.recalculateMultiplicity();
  }

  recalculateMultiplicity() {
    if (
      this.premiumCalculationCriteria.insuredAmount == null ||
      this.premiumCalculationCriteria.expectedPremium == null ||
      this.premiumCalculationCriteria.expectedPremium === 0
    ) {
      this.multiplicity = null;
    } else {
      this.multiplicity =
        this.premiumCalculationCriteria.insuredAmount / this.premiumCalculationCriteria.expectedPremium;
    }
  }
}
