import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AbstractService, UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {
  BusinessUnitIdDto,
  ChangePasswordDto,
  ProfileCategoryAccessLimitation,
  StateTransitionDto,
  UserAvailabilityDto,
  UserDto,
  UserSimpleDto,
  UserWithProfileDto,
} from '../model/dtos';
import {Language} from '../model/dictionary-ids';
import {UserGroupBaseDto, UserRoleQuotaDto} from '../model';
import {HttpClient} from '@angular/common/http';
import {Cacheable} from 'ts-cacheable';
import {BinaryDownloaderService} from './binary-downloader.service';

export const employeesCacheBuster$ = new Subject<void>();

@Injectable()
export class UserService extends AbstractService {
  protected url = this.urlPrefix + 'user';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    public binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'user';
  }

  changePassword(hashedPassword: ChangePasswordDto) {
    return this.post1<ChangePasswordDto, ChangePasswordDto>(hashedPassword, this.url + '/changePassword');
  }

  saveUser(user: UserDto) {
    if (user.id > 0) {
      return this.updateUser(user);
    }
    return this.createUser(user);
  }

  getUser(id: number): Observable<UserDto> {
    console.log('User details: id = ' + id);
    return this.get<UserDto>(this.url + '/' + id);
  }

  oauth2Login(login: string): Observable<UserDto> {
    console.log('User details: login = ' + login);
    return this.get<UserDto>(this.url + '/oauth2/' + login);
  }

  getUserGroups(): Observable<UserGroupBaseDto[]> {
    return this.get<UserGroupBaseDto[]>(this.url + '/group');
  }

  changeLanguage(langIsoCode: string): Observable<UserDto> {
    console.log('change logged user lang = ' + langIsoCode);
    return this.get<UserDto>(this.url + '/changeLanguage/' + langIsoCode);
  }

  setToPresent(): Observable<UserDto> {
    console.log('change logged user availability to present');
    return this.get<UserDto>(this.url + '/changeToPresent');
  }

  changeAvailability(dto: UserAvailabilityDto): Observable<UserDto> {
    return this.post1<UserAvailabilityDto, UserDto>(dto, this.url + '/changeAvailability/');
  }

  @Cacheable({maxAge: 300000}) // 5 minutes cache
  getIntranetUsers(): Observable<UserSimpleDto[]> {
    return this.get<UserSimpleDto[]>(this.url + '/intranetUsers');
  }

  @Cacheable({maxAge: 300000, cacheBusterObserver: employeesCacheBuster$}) // 5 minutes cache
  getEmployees(): Observable<UserSimpleDto[]> {
    return this.get<UserSimpleDto[]>(this.url + '/employees');
  }

  getLawyers(): Observable<UserSimpleDto[]> {
    return this.get<UserSimpleDto[]>(this.url + '/lawyers');
  }

  getCountryManagers(): Observable<UserSimpleDto[]> {
    return this.get<UserSimpleDto[]>(this.url + '/countryManagers');
  }

  getHR(): Observable<UserSimpleDto[]> {
    return this.get<UserSimpleDto[]>(this.url + '/hr');
  }

  getClaimAnalysts(): Observable<UserSimpleDto[]> {
    return this.get<UserSimpleDto[]>(this.url + '/claimAnalysts');
  }

  getCollectors(): Observable<UserSimpleDto[]> {
    return this.get<UserSimpleDto[]>(this.url + '/collectors');
  }

  getUsers(range: UserRange = 'intranet', businessUnit?: BusinessUnitIdDto): Observable<UserSimpleDto[]> {
    switch (range) {
      case 'intranet':
        return this.getIntranetUsers();
      case 'sales':
        return this.getSalesUsers(businessUnit);
      case 'lawyer':
        return this.getLawyers();
      case 'claim':
        return this.getClaimAnalysts();
      case 'collection':
        return this.getCollectors();
      case 'representativeOffice':
        return this.getRepresentativeOfficeUsers();
      case 'hr':
        return this.getHR();
      case 'countryManagers':
        return this.getCountryManagers();
      case 'employees':
        return this.getEmployees();
      default:
        const exhaustiveCheck: never = range;
        throw new Error(exhaustiveCheck);
    }
  }

  @Cacheable()
  getSalesUsers(businessUnit?: BusinessUnitIdDto): Observable<UserSimpleDto[]> {
    let serverUrl = this.url + '/salesUsers';
    if (businessUnit) {
      serverUrl += '?businessUnitId=' + businessUnit.id;
    }
    return this.get<UserSimpleDto[]>(serverUrl);
  }

  getLeadAnalysts(): Observable<UserSimpleDto[]> {
    return this.get<UserSimpleDto[]>(this.url + '/leadAnalysts');
  }

  getPolicySupport(): Observable<UserSimpleDto[]> {
    return this.get<UserSimpleDto[]>(this.url + '/policySupport');
  }

  getRepresentativeOfficeUsers(): Observable<UserSimpleDto[]> {
    return this.get<UserSimpleDto[]>(this.url + '/representativeOffice');
  }

  getBondingSupport(businessUnitId?: number): Observable<UserSimpleDto[]> {
    let queryUrl = this.url + '/bondingSupport';
    if (businessUnitId) {
      queryUrl += '?businessUnitId=' + businessUnitId;
    }
    return this.get<UserSimpleDto[]>(queryUrl);
  }

  protected createUser(user: UserDto): Observable<UserDto> {
    console.log('create User:', user);
    return this.put<UserDto>(user, this.url);
  }

  createExternalUser(user: UserDto, type: string, businessUnit: number): Observable<UserDto> {
    console.log('create External User:', user);
    return this.put<UserDto>(user, this.url + '/predefined/' + type + '/' + businessUnit);
  }

  protected updateUser(user: UserDto): Observable<UserDto> {
    console.log('update User:', user);
    return this.post<UserDto>(user, this.url);
  }

  getTransitions(userDto: UserDto): Observable<Array<StateTransitionDto>> {
    return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + userDto.status.id);
  }

  getActiveDirectoryTransitions(userDto: UserDto): Observable<Array<StateTransitionDto>> {
    return this.get<Array<StateTransitionDto>>(this.url + '/activeDirectoryTransition/' + userDto.status.id);
  }

  getTransitionsMap(): Observable<Map<number, Array<StateTransitionDto>>> {
    return this.get<Map<number, Array<StateTransitionDto>>>(this.url + '/transition');
  }

  getUserRoleQuotas(userRoleId: number, currencyId: number, dictionaryId?: number): Observable<UserRoleQuotaDto[]> {
    return this.get(
      this.url +
        '/userRoleQuotas?userRoleId=' +
        userRoleId +
        (currencyId ? '&currencyId=' + currencyId : '') +
        (dictionaryId ? '&dictionaryId=' + dictionaryId : '')
    );
  }

  getUserRoleQuotasSystemCurrency(userRoleId: number, dictionaryId?: number): Observable<UserRoleQuotaDto[]> {
    return this.get(
      this.url +
        '/userRoleQuotasSystemCurrency?userRoleId=' +
        userRoleId +
        (dictionaryId ? '&dictionaryId=' + dictionaryId : '')
    );
  }

  putUserRoleQuotas(userRoleQuotas: UserRoleQuotaDto[]): Observable<UserRoleQuotaDto[]> {
    return this.put(userRoleQuotas, this.url + '/userRoleQuotas');
  }

  getUsersForProfileCategory(
    profileCategoryId: number,
    rightId: number,
    excludeLoggedUser: boolean
  ): Observable<UserWithProfileDto[]> {
    const params = UrlParams.new()
      .add('profileCategoryId', profileCategoryId)
      .add('rightId', rightId)
      .add('excludeLoggedUser', excludeLoggedUser);
    return this.get<Array<UserWithProfileDto>>(this.buildUrl(this.url, 'usersForProfileCategory', params));
  }

  salesRepUserChanges(user: UserDto): Observable<boolean> {
    return this.post1(user, this.url + '/salesRepUserChanges');
  }

  getUsersForCompanyManagers(profileCategoryId: number, excludeLoggedUser: boolean): Observable<UserWithProfileDto[]> {
    return this.get<UserWithProfileDto[]>(
      this.url +
        '/usersForCompanyManagers?profileCategoryId=' +
        profileCategoryId +
        '&exceptLoggedUser=' +
        excludeLoggedUser
    );
  }

  getUserHistoryReport(userId: number, login: string) {
    this.binaryDownloader.download(
      'POST',
      this.url + '/generateHistoryReport',
      'application/json',
      'application/octet-stream',
      this.loggedUserService.getLoggedUserData().language.id === Language.POLISH
        ? login + ' - raport zmian.xlsx'
        : login + ' - history changes.xlsx',
      userId
    );
  }

  updateUserFromAD(id: number) {
    return this.get<UserDto>(this.url + '/updateFromAD/' + id);
  }

  getAvailableProfileCategories(accessType: ProfileCategoryAccessLimitation): Observable<number[]> {
    return this.get<number[]>(this.url + '/availableProfileCategories/' + accessType);
  }

  getAvailableModules(): Observable<number[]> {
    return this.get<number[]>(this.url + '/availableModules');
  }

  sendActivationLink(id: number) {
    return this.get<UserDto>(this.url + '/sendActivationLink/' + id);
  }

  updateAllFromAd() {
    return this.get<void>(this.url + '/updateAllFromAD');
  }

  passwordExpiring(): Observable<number> {
    return this.get<number>(this.url + '/passwordExpiring');
  }
}

export type UserRange =
  | 'intranet'
  | 'sales'
  | 'lawyer'
  | 'claim'
  | 'collection'
  | 'representativeOffice'
  | 'hr'
  | 'countryManagers'
  | 'employees';
