import {BondDetailsComponent} from './bond-details.component';
import {BondVersionSearchComponent} from './bond-version-search.component';
import {BondLinkedClaimsComponent} from './bond-linked-claims.component';
import {BondDetailsGuard, BondSearchGuard} from './_guards';

export const BondRoutes = [
  {
    path: 'bond-details/:id',
    component: BondDetailsComponent,
    canActivate: [BondDetailsGuard],
  },
  {
    path: 'bond-details/:id/:clientId/:contractId/:typeId',
    component: BondDetailsComponent,
    canActivate: [BondDetailsGuard],
  },
  {
    path: 'bond-version-search',
    component: BondVersionSearchComponent,
    canActivate: [BondSearchGuard],
  },
  {
    path: 'bond-linked-claims/:bondVersionId',
    component: BondLinkedClaimsComponent,
    canActivate: [BondDetailsGuard],
  },
];
