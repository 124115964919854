import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AppConfigService,
  ConfirmDialogComponent,
  ContactPersonDto,
  ContactPersonGroupDto,
  ContactPersonService,
  GrowlService,
  LoggedUserService,
  RouterService,
} from '../../bonding_shared';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormGroup} from '@angular/forms';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  BusinessObjectType,
  ContactPersonType,
  ContactPersonTypes,
  ElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import {InquiryAbstractService} from '../../bonding_shared/services/inquiry-abstract.service';

@Component({
  selector: 'service-contacts',
  templateUrl: './service-contacts.component.pug',
})
export class ServiceContactsComponent extends DetailsView implements OnInit {
  @ViewChild('deleteConfirm', {static: true}) confirmDialog: ConfirmDialogComponent;
  groups: ContactPersonGroupDto[] = [];

  viewParams: ServiceContactParams;

  hiddenTypes: Set<number>;
  isPolicyInquiry: boolean;

  constructor(
    protected growlService: GrowlService,
    private _route: ActivatedRoute,
    public router: RouterService,
    public contactPersonService: ContactPersonService,
    private loggedUserService: LoggedUserService,
    public appService: AppConfigService,
    private inquiryService: InquiryAbstractService
  ) {
    super(growlService);
    this.form = new UntypedFormGroup({});
    this.objectNotFound = false;
    this.saveButton.hidden = true;
    this.cancelButton.hidden = false;
  }

  ngOnInit() {
    this._route.queryParams.subscribe((params: ServiceContactParams) => this.initializeView(params));
  }

  initializeView(params: ServiceContactParams) {
    this.saveButton.hidden = true;
    this.viewParams = params;
    if (this.portal && this.kuke) {
      this.hiddenTypes = ContactPersonTypes.HIDDEN_IN_PORTAL;
      if (this.getReturnTypeId() === BusinessObjectType.POLICY_INQUIRY) {
        this.inquiryService.isPolicyInquiry(this.getReturnBoId()).subscribe({
          next: (result) => {
            this.isPolicyInquiry = result;
            if (this.isPolicyInquiry) {
              this.hiddenTypes = new Set<number>([ContactPersonType.PORTAL_ADMIN]);
            }
            // in the kuke portal, if the business object is an inquiry, you should be sure that the huddenIds will be updated before downloading the contacts so that the "Main" contact type is not hidden when it is supposed to be visible
            this.getContacts();
          },
          error: (error) => this.handleServerError(error),
        });
      } else {
        this.getContacts();
      }
    } else {
      this.getContacts();
    }
  }

  getContacts() {
    this.contactPersonService.getContactPersonGroups(+this.viewParams.boId, +this.viewParams.boTypeId).subscribe({
      next: (groups) => {
        this.groups = groups;
        this.saveButton.hidden = !this.canEdit && this.groups.length > 0;
      },
      error: (error) => this.handleServerError(error),
    });
  }

  onSave() {
    this.clearErrors();
    if (this.formValidates()) {
      this.contactPersonService
        .updateContactPersons(this.groups, +this.viewParams.boId, +this.viewParams.boTypeId)
        .subscribe({
          next: (persons) => {
            this.groups = persons;
            this.showSavedMsg('serviceContacts.saved');
          },
          error: (error) => this.handleServerError(error),
        });
    }
  }

  onCancel() {
    super.onCancel();
    this.initializeView(<ServiceContactParams>this._route.snapshot.queryParams);
  }

  deleteContactPerson(cp: ContactPersonDto) {
    this.confirmDialog.openAndExecuteOnConfirm('common.confirmDelete', 'serviceContacts.confirmDeleteMessage', () => {
      this.groups.forEach((item, index) => {
        const cpindex = item.persons.findIndex((d) => d === cp); // find index in your array
        if (cpindex !== -1) {
          item.persons.splice(cpindex, 1); // remove element from array
        }
      });
    });
  }

  kuke(): boolean {
    return this.appService.kuke;
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  rowEditable(cp: ContactPersonDto): boolean {
    return !(
      this.kuke() &&
      this.portal &&
      (this.contactPersonService.isPortalAdmin(cp) ||
        (ContactPersonService.isMain(cp) && (!this.isPolicyInquiry || cp.id)))
    );
  }

  getReturnTypeId(): number {
    return this.viewParams.returnBoTypeId ? +this.viewParams.returnBoTypeId : +this.viewParams.boTypeId;
  }

  getReturnBoId(): number {
    return this.viewParams.returnBoId ? +this.viewParams.returnBoId : +this.viewParams.boId;
  }

  get canEdit(): boolean {
    return this.loggedUserService.hasRight(ElementaryRight.CONTACTPERSONENDPOINT_UPDATE);
  }
}

export class ServiceContactParams {
  boId: number | string;
  boTypeId: number | string;
  returnBoId?: number | string;
  returnBoTypeId?: number | string;
}
