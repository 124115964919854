import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {
  ContractBaseDto,
  ContractDto,
  ContractVersionCriteriaDto,
  Page,
  SearchCriteria,
  SearchResult,
  StatisticsDto,
  UserDto,
} from '../model/dtos';
import {ContractVersionDto, ContractVersionSimpleDto} from '../model';
import {HttpClient} from '@angular/common/http';
import {ContractStatus} from '../model/dictionary-ids';
import {SearchDataProvider} from './search-data-provider';
import {filter, map, mergeMap} from 'rxjs/operators';

@Injectable()
export class ContractVersionService extends AbstractService {
  private clientContractCache: ReplaySubject<SearchResult<ContractVersionSimpleDto>> = null;

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'contractVersion';

    if (this.loggedUserService.portal) {
      loggedUserService.getUser().subscribe((user: UserDto) => {
        this.clearClientContractCache();
      });
    }
  }

  getContractVersion(id: number): Observable<ContractVersionDto> {
    console.log('contract version details: id = ' + id);
    return this.get<ContractVersionDto>(this.url + '/' + id);
  }

  getContractVersionsByContractId(contractId: number): Observable<ContractVersionDto> {
    const query = this.url + '/findByParent/' + contractId + '?lastIssued=true';
    return this.get<ContractVersionDto>(query);
  }

  findLastAllowedVersionForNewBond(contractId: number): Observable<ContractVersionDto> {
    const query = this.url + '/findLastAllowedVersionForNewBond/' + contractId;
    return this.get<ContractVersionDto>(query);
  }

  getLastContractVersion(contractId: number): Observable<ContractVersionDto> {
    const query = this.url + '/last/' + contractId;
    return this.get<ContractVersionDto>(query);
  }

  getAllVersions(
    page: Page,
    sortBy: string,
    desc: boolean,
    active: boolean
  ): Observable<SearchResult<ContractVersionSimpleDto>> {
    const query: string =
      this.url +
      '?start=' +
      page.start +
      '&count=' +
      page.count +
      '&sortBy=' +
      sortBy +
      '&desc=' +
      desc +
      '&active=' +
      active;
    return this.get<SearchResult<ContractVersionSimpleDto>>(query);
  }

  deleteContractVersion(id: number): Observable<ContractVersionDto> {
    console.log('delete contract version: id = ' + id);
    return this.delete<ContractVersionDto>(this.url + '/' + id);
  }

  unsuspend(contractVersionId: number): Observable<ContractVersionDto> {
    console.log('unsuspend contract: id = ' + contractVersionId);
    return this.postEmpty<ContractVersionDto>(`${this.url}/${contractVersionId}/unsuspend`);
  }

  createFromOffer(offerId: number): Observable<ContractVersionDto> {
    return this.postEmpty<ContractVersionDto>(this.url + '/createFromOffer/' + offerId);
  }

  getClientContractsCriteria(runOffContracts = true): ContractVersionCriteriaDto {
    const contractCriteria = <ContractVersionCriteriaDto>{contract: <ContractBaseDto>{}};
    contractCriteria.statusIds = [
      ContractStatus.CONTRACT_ACTIVATED,
      ContractStatus.CONTRACT_TO_BE_CANCELLED,
      ContractStatus.SUSPENDED,
    ];
    if (runOffContracts) {
      contractCriteria.statusIds.push(ContractStatus.RUN_OFF);
    }
    return contractCriteria;
  }

  getClientContractsFull(): Observable<ContractVersionSimpleDto> {
    if (this.clientContractCache === null) {
      const lClientContractCache = (this.clientContractCache = new ReplaySubject<
        SearchResult<ContractVersionSimpleDto>
      >(1));

      const dataProvider: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionDto> = new SearchDataProvider(
        this
      );
      dataProvider.searchCriteria = <SearchCriteria<ContractVersionCriteriaDto>>{};
      dataProvider.searchCriteria.criteria = this.getClientContractsCriteria();
      dataProvider.additionalQueryParams = [{key: 'single', val: 'true'}];
      dataProvider.searchAllInLoop().subscribe({
        next: (data) => {
          lClientContractCache.next(data);
          lClientContractCache.complete();
        },
        error: (error) => {
          lClientContractCache.error(error);
          this.clearClientContractCache();
        },
      });
    }

    return this.clientContractCache.pipe(
      mergeMap((contracts) => {
        return contracts.result;
      })
    );
  }

  getClientContracts(): Observable<IdAndNumber> {
    return this.getClientContractsFull().pipe(
      map((c: ContractVersionSimpleDto) => {
        return <IdAndNumber>{
          id: c.id,
          cvNumber: (c.contract.number ? c.contract.number : c.contract.requestNumber) + '/' + c.versionNumber,
        };
      })
    );
  }

  getClientContract(id: number): Observable<ContractVersionSimpleDto> {
    return this.getClientContractsFull().pipe(filter((cv) => cv.id === id));
  }

  getContractStatistics(contractVersionId: number): Observable<StatisticsDto> {
    return this.get<StatisticsDto>(this.url + '/' + contractVersionId + '/statistics');
  }

  getContract(contractId: number): Observable<ContractDto> {
    console.log('contract details: id = ' + contractId);
    return this.get<ContractDto>(this.url + '/contract/' + contractId);
  }

  private clearClientContractCache() {
    this.clientContractCache = null;
  }
}

export interface IdAndNumber {
  id: number;
  cvNumber: string;
}

export interface GroupedIdAndNumber {
  groupName: string;
  elements: IdAndNumber[];
}
