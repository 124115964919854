<search-view [labelKey]="captionKey" [objectName]="getInvoiceCategoryLabel()" [dataProvider]="dataProvider"
             [newHandled]="false" (newExecuted)="newExecuted($event)" [newObjectLabelKey]="newObjectLabelKey"
             [newButtonDisabled] = "newDisabled">
  <div class="search-criteria bon-card-group">
    <div class="bon-card-inner">
      <ng-container *ngIf="!businessObjectTypeId">
        <input-row labelKey="invoice.search.number" [(ngModel)]="dataProvider.searchCriteria.criteria.number" name="invoiceNumber">
        </input-row>
        <dict-row [disabled]="productLineDisabled" labelKey="invoice.search.productLine" [(ngModel)]="productLine" (changeItem)="onProductLineChange($event)"
                  dictionary="ProductLine" [hiddenIds]="productLineHiddenIds" name="productLine" [nullLabel]="'invoice.search.all' | translate"></dict-row>

        <dict-row labelKey="invoice.search.relatedTo" [(ngModel)]="dataProvider.searchCriteria.criteria.businessObject.relatedTo"
                  dictionary="BusinessObjectType" name="relatedTo" [nullLabel]="'invoice.search.all' | translate"></dict-row>
        <input-row labelKey="invoice.search.businessObjectNumber" [(ngModel)]="dataProvider.searchCriteria.criteria.businessObjectNumber" name="businessObjectNumber" >
        </input-row>
        <date-row labelKey="invoice.search.dateFrom" [(ngModel)]="dataProvider.searchCriteria.criteria.dateFromRange.dateFrom"
                  type="date" name="dateFrom"> </date-row>
        <date-row labelKey="invoice.search.dateTo" [(ngModel)]="dataProvider.searchCriteria.criteria.dateToRange.dateTo"
                  type="date" name="dateTo"> </date-row>
        <dict-row labelKey="invoice.search.invoiceType" [(ngModel)]="dataProvider.searchCriteria.criteria.invoiceType"
                  dictionary="InvoiceType" name="invoiceType" [nullLabel]="'invoice.search.all' | translate"></dict-row>
        <form-row labelKey="invoice.search.businessUnit">
          <business-unit-id-selector class="bon-input-size" [(ngModel)]="dataProvider.searchCriteria.criteria.businessUnit"
                                     name="businessUnit" [nullLabel]="'invoice.search.all' | translate"></business-unit-id-selector>
        </form-row>
        <dict-row labelKey="invoice.search.status" [(ngModel)]="dataProvider.searchCriteria.criteria.status"
                  dictionary="InvoiceStatus" name="status" [nullLabel]="'invoice.search.all' | translate"
                  [codeRegexp]="invoiceStatusRegexp" [hiddenIds]="hiddenStatuses"></dict-row>
      </ng-container>
      <ng-container *ngIf="policyContractId">
        <form-row labelKey="invoice.search.policyYear">
          <string-combo class="bon-input-size" nullLabelKey="invoice.search.all" [items]="policyYearComboItems" [(ngModel)]="dataProvider.searchCriteria.criteria.contractLink.policyContract.id"
                        (changeItem)="onSelectContractByPolicyYear($event)" [preSelectedValue]="policyContractId"></string-combo>
        </form-row>
      </ng-container>
      <ng-container *ngIf="bondId">
        <form-row labelKey="invoice.search.bondVersions">
          <item-id-combo class="bon-input-size" nullLabelKey="invoice.search.all" [items]="bondVersions" [(ngModel)]="dataProvider.searchCriteria.criteria.businessObject.relatedToId"
                        (changeItem)="onSelectBondVersion($event)">
            <ng-template let-item="item">{{item.bond.number + '/' + item.versionNumber}}</ng-template>
          </item-id-combo>
        </form-row>
      </ng-container>
    </div>

    <div class="bon-card-inner bon-label-150" style="width:48%;">
      <ng-container *ngIf="!businessObjectTypeId">
        <input-row labelKey="invoice.search.id" [(ngModel)]="dataProvider.searchCriteria.criteria.id" name="id"
                   type="number">
        </input-row>
        <input-row [label]="'invoice.search.clientName' | cattranslate: category"
                   [(ngModel)]="dataProvider.searchCriteria.criteria.clientName" name="clientName">
        </input-row>
        <input-row [label]="'invoice.search.clientId' | cattranslate: category"
                   [(ngModel)]="dataProvider.searchCriteria.criteria.clientId" name="clientId" type="number">
        </input-row>
        <input-row labelKey="invoice.search.contractNumber"
                   [(ngModel)]="dataProvider.searchCriteria.criteria.contractNumber" name="contractNumber">
        </input-row>
        <date-range-row *ngIf="categoryId !== InvoiceCategory.BROKER" labelKey="invoice.search.dueDateRange"
                        [value]="dataProvider.searchCriteria.criteria.dueDateRange"></date-range-row>
        <date-range-row labelKey="invoice.search.issueDateRange"
                        [value]="dataProvider.searchCriteria.criteria.issueDateRange"></date-range-row>
        <date-range-row labelKey="invoice.search.creationDateRange"
                        [value]="dataProvider.searchCriteria.criteria.creationDateRange"></date-range-row>
      </ng-container>
    </div>
    <div class="bon-card-inner"></div>

  </div>

  <div class="search-results">
      <a-table [pagination]="true" [pageSize]="20" [selection]="false" [sortable]='!dataProvider.textSearch'>
        <column labelKey="invoice.search.result.type" property="invoiceType" sort="invoiceType" dictionary="InvoiceType"></column>
        <column labelKey="invoice.search.result.number" sort="number" [link]="true" (linkClick)="router.toInvoiceDetails(categoryId, $event.id)">
          <ng-template let-item="item" let-edition="false">{{item.number ? item.number : 'invoice.search.result.empty' | translate}}</ng-template>
        </column>

        <column labelKey="invoice.search.result.status" property="businessStatus" sort="status" dictionary="InvoiceStatus"></column>
        <column [title]= "'invoice.search.result.clientId' | cattranslate: category" property="client.id" sort="client.id" [link]="true"
                (linkClick)="router.toCompanyPreview($event.client.id)"></column>
        <column [title]= "'invoice.search.result.clientName' | cattranslate: category" [link]="true"
                (linkClick)="router.toCompanyPreview($event.client.id)">
          <ng-template let-item="item" let-edition="false">{{item?.client?.shortName && showShortNameIfExists ? item?.client?.shortName : item?.client?.registrationName}}</ng-template>
        </column>
        <column labelKey="invoice.search.result.contractNumber" property="contractNumber" sort="contractNumber"></column>
        <column labelKey="invoice.search.result.subtype" sort="subtype, types">
          <ng-template let-item="item" let-edition="false">{{getSubtypeOrType(item)}}</ng-template>
        </column>
        <column labelKey="invoice.search.result.netAmount" property="netAmount" sort="netAmount" type="number"></column>
        <column labelKey="invoice.search.result.currency" property="currency" sort="currency" dictionary="Currency" dictLabel="code"></column>
        <column labelKey="invoice.search.result.creationDate" property="creationDate" sort="creationDate" type="date"  [initialSort]="initialSort()"></column>
        <column labelKey="invoice.search.result.issueDate" property="issueDate" sort="issueDate" type="date"></column>
        <column *ngIf="categoryId !== InvoiceCategory.BROKER" labelKey="invoice.search.result.dueDate"
                property="dueDate" sort="dueDate" type="date"></column>
        <column labelKey="invoice.search.result.dateFrom" property="dateFrom" sort="dateFrom" type="date"></column>
        <column labelKey="invoice.search.result.dateTo" property="dateTo" sort="dateTo" type="date"></column>
        <column labelKey="invoice.search.result.businessUnit" property="businessUnit.name"></column>
      </a-table>
  </div>
  <div class="additional-buttons">
    <button [btnIcon]="'fa-download'" (click)="downloadZip()" [disabled]="massExportDisabled"
            type="button" class="bon-btn-info" translate>invoice.search.downloadAsZip</button>
    <button *ngIf="policyContractId" (click)="router.toPolicyContractPreviewByPolicyContractId(policyContractId)"
            type="button" class="bon-btn-info" translate>policy.details.title</button>
    <button *ngIf="bondId" (click)="router.toBondDetailsByBondId(bondId)"
            type="button" class="bon-btn-info" translate>clientbond.details.title</button>
    <button *ngIf="writeAllOffVisible" [disabled]="writeAllOffDisabled" [btnIcon]="'fa-download'"
            (click)="openWriteAllOffConfirmDialog()" type="button" class="bon-btn-info" translate>invoice.search.writeAllOff</button>
    <button class="bon-btn-info" [btnIcon]="'fa-file-text-o'"
            (click)="exportClicked()" type="button" [disabled]="exportInProgress || massExportDisabled"
            translate>invoice.search.export</button>
    <button (click)="router.toBatchAllExecutions()"
            type="button" class="bon-btn-info" translate>invoice.search.batchExecutionSearch</button>
    <img *ngIf="exportInProgress" src="assets/images/loading2.gif"/>
  </div>

  <confirm-dialog #confirmDialog></confirm-dialog>
</search-view>

