import {UntypedFormControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {StringUtils} from '../../utils/index';
import {TableComponent} from './table.component';
import {ColumnComponent} from './column.component';
import {FormatService} from '../../services';

// import {Http} from '@angular/http';

export class TableValidators {
  static unique = function (table: any, column: any, item: any, formatService: FormatService): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (!table || !control.value || !column || !item || !table.items) {
        return null;
      }

      for (const it of table.items) {
        const value = table.getProperty(it, column);
        if (item !== it) {
          if (this.areColumnValuesEqual(column, value, control.value, formatService)) {
            return {unique: control.value};
          }
        }
      }
      return null;
    };
  };

  static uniqueColumns = function (
    table: TableComponent<any>,
    columns: string[],
    formatService: FormatService
  ): ValidatorFn {
    if (!columns || !table) {
      return undefined;
    }

    return (control: UntypedFormControl) => {
      if (!table.items) {
        return undefined;
      }
      const checkedColumns = table.columns.filter((column) => columns.includes(column.property));
      const anyInvalid = table.items.some((item) => {
        return table.items
          .filter((item2) => item !== item2)
          .some((item2) =>
            checkedColumns.every((column) => {
              return this.areColumnValuesEqual(
                column,
                table.getProperty(item, column),
                table.getProperty(item2, column),
                formatService
              );
            })
          );
      });
      return anyInvalid ? {uniqueColumns: {columnNames: checkedColumns.map((col) => col.title).join(', ')}} : null;
    };
  };

  static areColumnValuesEqual(
    column: ColumnComponent<any>,
    value1: any,
    value2: any,
    formatService: FormatService
  ): boolean {
    if (column.isType('date')) {
      value1 = formatService.formatDate(value1);
      value2 = formatService.formatDate(value2);
    } else if (column.isDictionary() || column.type === 'userAutocomplete') {
      value1 = value1 ? value1.id : value1;
      value2 = value2 ? value2.id : value2;
    }

    return value1 === value2;
  }

  static minLength(min: number): ValidatorFn {
    return (control: UntypedFormGroup) =>
      control && Object.keys(control.controls).length < min ? {tableMin: {min: min}} : null;
  }
}
