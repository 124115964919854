/**
 * Created by siminski on 16.08.2016.
 * Updated by szkrabko on 02.08.2023.
 */

import {Component, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CKEditor4, CKEditorComponent} from 'ckeditor4-angular';

const HTML_EDITOR_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => HtmlEditorComponent),
  multi: true,
};

@Component({
  selector: 'html-editor',
  template: `
    <ckeditor
      [(data)]="value"
      [readOnly]="readonly"
      [config]="config"
      editorUrl="./assets/ckeditor4/ckeditor.js"
      (ready)="onReady()"
      (blur)="onTouchedListeners()"
      (dataChange)="onChangeListeners($event)"
    ></ckeditor>
  `,
  providers: [HTML_EDITOR_CONTROL_VALUE_ACCESSOR],
})
export class HtmlEditorComponent implements ControlValueAccessor {
  static readonly DEFAULT_CONFIG: CKEditor4.Config = {
    toolbarGroups: [
      {name: 'clipboard', groups: ['clipboard', 'undo']},
      {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
      // { name: 'forms', groups: [ 'forms' ] },
      // { name: 'links', groups: [ 'links' ] },
      {name: 'insert', groups: ['insert']},
      {name: 'colors', groups: ['colors']},
      {name: 'tools', groups: ['tools']},
      {name: 'document', groups: ['mode', 'document', 'doctools']},
      {name: 'about', groups: ['about']},
      '/',
      {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
      {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
      {name: 'styles', groups: ['styles']},
      '/',
      {name: 'others', groups: ['others']},
    ],
    language_list: ['en:English', 'de:German', 'nl:Dutch', 'fr:French', 'pl:Polish'],
    removeButtons: 'Save,NewPage,SelectAll,Flash,Smiley,Iframe,CreateDiv,BidiLtr,BidiRtl,Language',
    format_tags: 'p;h1;h2;h3;pre',
    removeDialogTabs: '',
    protectedSource: [/\n?[\t ]*<#[^\>]*>[\t ]*\n?/g, /\n?[\t ]*<\/#[^\>]*>[\t ]*\n?/g],
    extraPlugins:
      'tableresize,colorbutton,font,indentblock,justify,liststyle,' +
      'preview,print,newpage,pagebreak,dialogadvtab,colordialog,showblocks',
    removePlugins: 'magicline,showborders',
    skin: 'kama',
    allowedContent: true,
    entities: false,
    versionCheck: false,
  };

  @ViewChild(CKEditorComponent) ckEditor: CKEditorComponent;

  @Input() readonly = false;

  @Input() set config(config: CKEditor4.Config) {
    this._config = this._height ? {...config, height: this._height} : this._config;
  }

  @Input() set height(height: number) {
    this._height = height;
    this._config = {...this._config, height: height};
  }

  @Output() modeChange = new EventEmitter<String>();

  onChangeListeners: Function;
  onTouchedListeners: Function;

  get config(): CKEditor4.Config {
    return this._config;
  }

  get height(): number {
    return this._height;
  }

  _config = HtmlEditorComponent.DEFAULT_CONFIG;
  _height: number;

  value = '';

  onReady() {
    this.ckEditor.instance.on('mode', (event: any) => {
      this.modeChange.emit(this.ckEditor.instance.mode);
    });
    this.modeChange.emit(this.ckEditor.instance.mode);
  }

  // From ControlValueAccessor interface
  writeValue(value: string): void {
    if (!value) {
      value = '';
    }
    this.value = value;
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }
}
