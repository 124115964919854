import {Component, OnInit, ViewChild} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {
  BondDto,
  BondVersionCriteriaDto,
  ErrorReason,
  LocalDateRange,
  SearchCriteria,
  TrainingBaseDto,
  TrainingCriteriaDto,
  TrainingDto,
  UserDto,
  UserIdDto,
  UserSimpleDto,
} from '../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {TrainingService} from '../../bonding_shared/services/training-service.service';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {LoggedUserService, UserNamePipe, UserRange} from '../../bonding_shared';
import {SearchViewComponent} from '../../bonding_shared/components/search';

@Component({
  selector: 'training-search',
  templateUrl: './training-search.component.html',
})
export class TrainingSearchComponent extends SearchView implements OnInit {
  dataProvider: SearchDataProvider<TrainingCriteriaDto, TrainingBaseDto>;
  readonly employeeUserRange: UserRange = 'employees';
  showNewButton = this.loggedUserService.isHRRole() || this.loggedUserService.isEmployee();
  disableChangeParticipant = !this.loggedUserService.isHRRole();

  @ViewChild(SearchViewComponent) searchView: SearchViewComponent<TrainingCriteriaDto, TrainingDto>;

  constructor(
    public router: RouterService,
    public trainingService: TrainingService,
    private loggedUserService: LoggedUserService
  ) {
    super();
  }

  ngOnInit() {
    this.dataProvider = new SearchDataProvider<TrainingCriteriaDto, TrainingBaseDto>(this.trainingService);
    this.dataProvider.searchCriteria = <SearchCriteria<TrainingCriteriaDto>>{criteria: <TrainingCriteriaDto>{}};
    this.dataProvider.searchCriteria.criteria = <TrainingCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.dateFromInRange = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.dateToInRange = <LocalDateRange>{};
    if (!this.loggedUserService.isHRRole()) {
      this.dataProvider.searchCriteria.criteria.participant = this.getLoggedUser();
    } else {
      this.dataProvider.searchCriteria.criteria.participant = <UserSimpleDto>{};
    }
  }

  onUserCreatedByChange(item: UserIdDto) {
    this.dataProvider.searchCriteria.criteria.createdBy = item;
  }

  onUserParticipantChange(item: UserIdDto) {
    this.dataProvider.searchCriteria.criteria.participant = item;
  }

  userFullName = (u: UserDto): string => {
    return new UserNamePipe().transform(u);
  };

  private getLoggedUser(): UserDto {
    return this.loggedUserService.getLoggedUserData();
  }

  public export() {
    this.dataProvider.inProgress = true;
    this.trainingService.export(
      this.dataProvider.searchCriteria,
      'trainings.xlsx',
      (errorMsg) => this.downloadErrorCallback(errorMsg),
      () => {
        this.searchView.serverErrors = null;
        this.dataProvider.inProgress = false;
        this.exportCompleteCallback();
      }
    );
  }

  private downloadErrorCallback(err: string): void {
    const error = <ErrorReason[]>JSON.parse(err);
    console.log('Training export error', error);
    this.searchView.serverErrors = error;
    this.dataProvider.inProgress = false;
  }

  private exportCompleteCallback(): void {
    console.log('exportCompleteCallback');
  }
}
