import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
  AbstractService,
  AppConfigService,
  GrowlService,
  PolicyContractService,
  PolicyContractVersionService,
  PolicyLimitListService,
  RouterService,
} from '../../../../bonding_shared/services';
import {
  CompanySimpleDto,
  DeclarationBuyerCriteriaDto,
  PolicyContractDto,
  PolicyContractSimpleIdDataDto,
  LimitListVersionInDeclarationDto,
  ReportingPeriodDto,
  CompanyInDeclarationDto,
} from '../../../../bonding_shared/model';
import * as moment from 'moment';
import {PolicyContractType} from '../../../../bonding_shared/model/dictionary-ids';
import {DeclarationService} from '../../../../bonding_shared/services/declaration.service';
import {DeclarationCriteria, DeclarationParams} from '../declaration-details-view';
import {DeclarationWithoutEntriesDto} from '../../../../bonding_shared/model';

@Component({
  selector: 'declaration-criteria',
  templateUrl: './declaration-criteria.component.pug',
})
export class DeclarationCriteriaComponent {
  // only confirmed declarations (effectiveDate in past)
  // only one final declaration for triple (rp.id, llv.id, decOn.id) either declaration or amendment
  singleFinalConfirmedDeclarationsForPolicy: DeclarationWithoutEntriesDto[] = [];
  rpsForConfirmedDeclarations: Map<number, ReportingPeriodDto>;
  allReportingPeriods: ReportingPeriodDto[];
  allReportingPeriodsMap: Map<number, ReportingPeriodDto[]>;
  amendmentBlocked_flag: boolean;

  constructor(
    private route: ActivatedRoute,
    private routerService: RouterService,
    private appService: AppConfigService,
    private policyContractVersionService: PolicyContractVersionService,
    private policyContractService: PolicyContractService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    private router: RouterService,
    private policyLimitListService: PolicyLimitListService,
    public declarationService: DeclarationService
  ) {}

  @Input() policyService: AbstractService;

  @Input() dc: DeclarationCriteria;

  @Input() withBuyerFilter = false;

  searchWithFilter = false;

  intranet: boolean;
  outstandingsSP: boolean;
  // (used to distinguish whether to show or not reportingTo date) AND (to filter on portal amendment view declared on list)
  pureAmendmentView: boolean;
  amendmentDeclaration: boolean;
  nnPolicy: boolean;
  declarationIsEffective = false;
  @Input() criteriaDisabled: boolean;

  @Output() policyChanged = new EventEmitter();
  @Output() criteriaTouched = new EventEmitter();
  @Output() criteriaChanged = new EventEmitter<boolean>();
  @Output() filteringChanged = new EventEmitter();

  showSelectPolicyMessage = false;
  showSelectRpMessage = false;
  showK7message = false;
  showK9message = false;
  showNoConfirmedDeclarationsForSelectedLimitListMessage = false;

  switchSearchMode() {
    this.searchWithFilter = !this.searchWithFilter;
    if (!this.searchWithFilter) {
      this.dc.buyerCriteria = <DeclarationBuyerCriteriaDto>{};
      this.filteringChanged.emit();
    }
  }

  turnOffFiltering() {
    this.searchWithFilter = false;
  }

  filterByBuyerCriteria() {
    this.filteringChanged.emit();
  }

  selectedPolicyIsOutstanding(): boolean {
    return (
      this.dc.selectedPolicy &&
      this.dc.selectedPolicy.productType &&
      this.dc.selectedPolicy.productType.id === PolicyContractType.KUKE_KOM_SP
    );
  }

  public portal() {
    return !this.intranet;
  }

  public get ecgPortal() {
    return this.appService.ecg && this.portal();
  }

  public goToPolicy() {
    if (this.portal()) {
      return this.routerService.toClientPolicyPreviewKuke(this.dc.lastPolicyContractVersionId);
    } else {
      return this.routerService.toPolicyContractPreview(this.dc.lastPolicyContractVersionId);
    }
  }

  public initDeclarationImporterByPortal(amendment: boolean, dcParams?: DeclarationParams) {
    this.intranet = false;
    this.outstandingsSP = false;
    this.pureAmendmentView = amendment;
    // czy wymagane?
    this.criteriaTouched.emit();

    this.declarationService.findCurrentReportingPeriodsForDeclarations().subscribe(
      (rpsForCurrentDeclarations) => {
        if (amendment) {
          this.declarationService.findAllClientRPsForDeclarationAmendments().subscribe(
            (rpsFromAmendments) =>
              this.initByRpsListOrPolicy(
                this.mergeRpListsWithoutDuplicates(rpsForCurrentDeclarations, rpsFromAmendments),
                dcParams,
                undefined
              ),
            () => this.growlService.error('Error while getting reporting periods list')
          );
        } else {
          this.initByRpsListOrPolicy(rpsForCurrentDeclarations, dcParams, undefined);
        }
      },
      () => this.growlService.error('Error while getting reporting periods list')
    );
  }

  // merging two arrays - with removing reportingPeriod duplicates
  private mergeRpListsWithoutDuplicates(
    rpsFromAmendments: ReportingPeriodDto[],
    rpsForCurrentPeriod: ReportingPeriodDto[]
  ) {
    const mapById = function (m, obj) {
      m.set(obj.id, obj);
      return m;
    };
    const rpsConcatenation = rpsFromAmendments.reduce(
      mapById,
      rpsForCurrentPeriod.reduce(mapById, new Map<number, ReportingPeriodDto>())
    );
    return Array.from<ReportingPeriodDto>(rpsConcatenation.values());
  }

  public initAmendmentByPortalByDcParams(dcParams?: DeclarationParams) {
    this.initAmendmentByPortal(dcParams, undefined);
  }

  public initAmendmentByPortalWithoutSelection() {
    this.initAmendmentByPortal(undefined, undefined);
  }

  public initAmendmentByPortalByPolicy(policyContractId: number) {
    this.intranet = false;
    this.outstandingsSP = false;
    this.pureAmendmentView = true;

    this.policyContractService.getById<PolicyContractDto>(policyContractId).subscribe((pc) => {
      this.initAmendmentByPortal(undefined, PolicyContractService.toPolicyContractSimpleIdDto(pc));
    });
  }

  private initAmendmentByPortal(dcParams?: DeclarationParams, policy?: PolicyContractSimpleIdDataDto) {
    this.intranet = false;
    this.outstandingsSP = false;
    this.pureAmendmentView = true;

    this.criteriaTouched.emit();

    this.declarationService.findAllClientRPsForDeclarationAmendments().subscribe(
      (rpsFromAmendments) => this.initByRpsListOrPolicy(rpsFromAmendments, dcParams, policy),
      () => this.growlService.error('Error while getting reporting periods list')
    );
  }

  public initByPortal(dcParams?: DeclarationParams, outstandings = false) {
    this.intranet = false;
    this.outstandingsSP = outstandings;
    this.pureAmendmentView = false;
    // czy wymagane?
    this.criteriaTouched.emit();

    if (this.outstandingsSP) {
      this.declarationService.findCurrentReportingPeriodsForOutstandings().subscribe(
        (rps) => this.initByRpsListOrPolicy(rps, dcParams, undefined),
        () => this.growlService.error('Error while getting reporting periods list')
      );
    } else {
      this.declarationService.findCurrentReportingPeriodsForDeclarations().subscribe(
        (rps) => this.initByRpsListOrPolicy(rps, dcParams, undefined),
        () => this.growlService.error('Error while getting reporting periods list')
      );
    }
  }

  public onInitIntranetByPolicy(policy: PolicyContractSimpleIdDataDto, dcParams: DeclarationParams) {
    this.intranet = true;
    this.outstandingsSP = undefined;
    this.onSelectedPolicy(policy, dcParams);
  }

  private initByRpsListOrPolicy(
    rps: ReportingPeriodDto[],
    dcParams: DeclarationParams,
    policy: PolicyContractSimpleIdDataDto
  ) {
    this.allReportingPeriods = rps;
    this.allReportingPeriodsMap = new Map<number, ReportingPeriodDto[]>(); // <policy.id, [rp, rp, ...]>
    const policiesReduced: PolicyContractSimpleIdDataDto[] = []; // distinct policy

    rps.forEach((rp) => {
      if (!this.allReportingPeriodsMap.has(rp.policyContractData.id)) {
        this.allReportingPeriodsMap.set(rp.policyContractData.id, []);
        policiesReduced.push(rp.policyContractData);
      }
      this.allReportingPeriodsMap.get(rp.policyContractData.id).push(rp);
    });
    this.setPoliciesList(policiesReduced, dcParams, policy);
  }

  clearBuyerFilter() {
    this.filteringChanged.emit();
  }

  public clearSelections() {
    this.dc.selectedDecOn = undefined;
    this.dc.selectedLlv = undefined;
    this.dc.selectedRp = undefined;
  }

  onSelectedPolicyByPolicy(policy: PolicyContractSimpleIdDataDto) {
    this.onSelectedPolicy(policy, undefined);
  }

  onSelectedPolicyByParams(dcParams: DeclarationParams) {
    this.onSelectedPolicy(undefined, dcParams);
  }

  private onSelectedPolicy(policy: PolicyContractSimpleIdDataDto, dcParams: DeclarationParams) {
    this.criteriaTouched.emit();
    this.showNoConfirmedDeclarationsForSelectedLimitListMessage = false;
    this.showK7message = false;
    // set policy from params
    if (!policy && dcParams && dcParams.paramRpId && this.allReportingPeriods) {
      const currentRpByParam = this.allReportingPeriods.find((rp) => rp.id === dcParams.paramRpId);
      if (currentRpByParam) {
        policy = currentRpByParam.policyContractData;
      }
    }

    this.showSelectPolicyMessage = policy === undefined;

    this.clearSelections();
    this.clearRpLLvDecOnLists();
    this.dc.selectedPolicy = policy;
    if (!this.portal()) {
      this.outstandingsSP = this.selectedPolicyIsOutstanding();
    }

    if (policy) {
      this.policyService.getById<PolicyContractDto>(policy.id).subscribe((pc) => {
        this.onPolicyChanged();
        // set last policy contract version (linking purpose)
        const lastPcv = pc.versions[pc.versions.length - 1];
        this.dc.lastPolicyContractVersionId = lastPcv.id;

        const now = Date.now();
        if (this.intranet) {
          this.setRpList(
            pc.reportingPeriods.filter((rp) => moment(rp.periodTo).endOf('day').isBefore(now)),
            dcParams
          );
          this.declarationService.findFinalConfirmedDeclarationsForPolicy(policy.id).subscribe((declarations) => {
            this.singleFinalConfirmedDeclarationsForPolicy = declarations;
            this.calculateLateAcceptanceReportingPeriodsForSelectedPolicy();
            this.criteriaChanged.emit(true);
          });
        } else {
          this.buildPolicyRpMap(policy.id, dcParams);
        }
      });
    } else {
      this.criteriaChanged.emit(true);
      this.onPolicyChanged();
    }
  }

  private onPolicyChanged() {
    if (this.dc.selectedPolicy) {
      this.declarationService.isProductDependentOnPremiumRate(this.dc.selectedPolicy.productType.id).subscribe({
        next: (dependent) => {
          this.dc.productDependentOnPremiumRate = dependent;
          this.policyChanged.emit();
        },
      });
    } else {
      this.dc.productDependentOnPremiumRate = false;
      this.policyChanged.emit();
    }
  }

  private calculateNNPolicy(pcId: number, rpId: number) {
    if (this.appService && this.appService.kuke && pcId && rpId) {
      this.policyContractService.isNN(pcId, rpId).subscribe((nn) => {
        this.nnPolicy = nn;
      });
    } else {
      this.nnPolicy = false;
    }
  }

  private buildPolicyRpMap(policyId: number, dcParams: DeclarationParams) {
    this.declarationService.findFinalConfirmedDeclarationsForPolicy(policyId).subscribe((declarations) => {
      this.singleFinalConfirmedDeclarationsForPolicy = declarations;
      this.calculateLateAcceptancePolicies();

      this.rpsForConfirmedDeclarations = new Map<number, ReportingPeriodDto>();
      this.singleFinalConfirmedDeclarationsForPolicy.forEach((t) => {
        if (!this.rpsForConfirmedDeclarations.has(t.reportingPeriod.id)) {
          this.rpsForConfirmedDeclarations.set(t.reportingPeriod.id, t.reportingPeriod);
        }
      });

      this.setRpList(this.getProperReportingPeriods(policyId), dcParams);
    });
  }

  // late activation case
  // add only those for which there are confirmed declarations + 1 next rp
  private getProperReportingPeriods(policyId: number) {
    let sortedRpsForPolicy = [];
    const rpsOnlyForPolicy = this.allReportingPeriodsMap.get(policyId);
    if (rpsOnlyForPolicy) {
      sortedRpsForPolicy = rpsOnlyForPolicy.sort((a, b) => (a.periodTo < b.periodTo ? -1 : 1));
    }

    const properRpsForPolicy = [];
    let add = true;
    let i = 0;

    while (add) {
      const currentRp = sortedRpsForPolicy[i];
      if (currentRp) {
        properRpsForPolicy.push(currentRp);
        add = this.rpsForConfirmedDeclarations.has(currentRp.id);
        i++;
      } else {
        add = false;
      }
    }

    return properRpsForPolicy;
  }

  private calculateLateAcceptancePolicies() {
    this.dc.lateActivationPolicies = [];
    let datesString: string;
    this.dc.policiesList.forEach((p) => {
      datesString = undefined;
      this.allReportingPeriodsMap
        .get(p.id)
        .sort((a, b) => (a.periodTo < b.periodTo ? -1 : 1))
        .forEach((rp) => {
          if (this.dc.selectedPolicy.id !== p.id && rp.lateActivation) {
            // empty dates for not picked policy due this.singleFinalConfirmedDeclarationsForPolicy
            // contains only declarations for picked policy
            datesString = '';
          } else if (
            rp.lateActivation &&
            this.singleFinalConfirmedDeclarationsForPolicy.filter((t) => t.reportingPeriod.id === rp.id).length === 0
          ) {
            datesString = datesString
              ? datesString.concat(', ').concat(new Date(rp.periodTo).toLocaleDateString())
              : ' ' + new Date(rp.periodTo).toLocaleDateString();
          }
        });
      if (datesString !== undefined) {
        this.dc.lateActivationPolicies.push({policyNumber: p.number, dates: datesString});
      }
    });
  }

  private calculateLateAcceptanceReportingPeriodsForSelectedPolicy() {
    this.dc.lateActivationPolicies = [];
    let datesString: string;
    this.dc.rpList
      .sort((a, b) => (a.periodTo < b.periodTo ? -1 : 1))
      .forEach((rp) => {
        if (
          rp.lateActivation &&
          this.singleFinalConfirmedDeclarationsForPolicy.filter((t) => t.reportingPeriod.id === rp.id).length === 0
        ) {
          datesString = datesString
            ? datesString.concat(', ').concat(new Date(rp.periodTo).toLocaleDateString())
            : ' ' + new Date(rp.periodTo).toLocaleDateString();
        }
      });
    if (datesString !== undefined) {
      this.dc.lateActivationPolicies.push({policyNumber: this.dc.selectedPolicy.number, dates: datesString});
    }
  }

  onSelectedReportingPeriodByPeriod(rp: ReportingPeriodDto) {
    this.onSelectedReportingPeriod(rp, undefined);
  }

  onSelectedReportingPeriodByParams(dcParams: DeclarationParams) {
    this.onSelectedReportingPeriod(undefined, dcParams);
  }

  private onSelectedReportingPeriod(rp: ReportingPeriodDto, dcParams: DeclarationParams) {
    // this.amendment = undefined;
    this.showK7message = false;
    this.showNoConfirmedDeclarationsForSelectedLimitListMessage = false;
    this.criteriaTouched.emit();
    if (!rp && dcParams && dcParams.paramRpId && this.dc.rpList) {
      rp = this.dc.rpList.find((rpElem) => rpElem.id === dcParams.paramRpId);
    }
    this.dc.selectedRp = rp;
    if (rp === undefined) {
      this.showK7message = false;
    } else {
      this.declarationService.checkK7Message(rp.id).subscribe((b) => {
        this.showK7message = b;
      });
    }
    this.showSelectRpMessage = rp === undefined;

    this.reloadLlVAndDecOnListsAndSetDefaultOrParamsSelections(dcParams);

    if (this.dc && this.dc.selectedPolicy && rp) {
      this.calculateNNPolicy(this.dc.selectedPolicy.id, rp.id);
    }
  }

  onSelectedLimitListVersion(llv: LimitListVersionInDeclarationDto) {
    this.clearDecOnList();
    this.showNoConfirmedDeclarationsForSelectedLimitListMessage = false;
    this.criteriaTouched.emit();
    this.dc.selectedLlv = llv;

    if (this.dc.selectedLlv) {
      this.declarationService.findAllCompaniesToBeDeclaredOn(this.dc.selectedLlv.id).subscribe((companies) => {
        this.dc.selectedDecOn = undefined;

        if (this.portal() && this.pureAmendmentView) {
          const confirmedDeclarationsForDeclaredOdIds = this.singleFinalConfirmedDeclarationsForPolicy
            .filter(
              (acd) =>
                acd.reportingPeriod.id === this.dc.selectedRp.id && acd.limitListVersion.id === this.dc.selectedLlv.id
            )
            .map((rpcd) => rpcd.declaredOn.id);
          this.setDecOnList(companies.filter((c) => confirmedDeclarationsForDeclaredOdIds.includes(c.id)));
        } else {
          this.setDecOnList(companies);
        }

        if (this.dc.decOnList.length === 1) {
          this.dc.selectedDecOn = this.dc.decOnList[0];
        } else if (this.dc.decOnList.length === 0) {
          this.showNoConfirmedDeclarationsForSelectedLimitListMessage = true;
        }
        this.criteriaChanged.emit(true);
      });
    } else {
      // undefined llv param should not happen!
      this.setDecOnList([]);
      this.dc.selectedDecOn = undefined;
      this.criteriaChanged.emit(true);
    }
  }

  onSelectedDeclaredOn(decOn: CompanyInDeclarationDto) {
    this.criteriaTouched.emit();
    this.dc.selectedDecOn = decOn;
    this.criteriaChanged.emit(true);
  }

  private reloadLlVAndDecOnListsAndSetDefaultOrParamsSelections(dcParams: DeclarationParams) {
    this.reloadLlVAndDecOnListsAndSetParamsSelections(dcParams, true);
  }

  public reloadLlVAndDecOnListsAndSetParamsSelections(dcParams: DeclarationParams, reloadDeclaration = false) {
    this.clearLLvDecOnLists();
    this.dc.selectedLlv = undefined;
    if (dcParams && this.dc.rpList) {
      this.dc.selectedRp = this.dc.rpList.find((rp) => rp.id === dcParams.paramRpId);
      this.showSelectRpMessage = this.dc.selectedRp === undefined;
    }

    if (this.dc.selectedPolicy && this.dc.selectedRp) {
      if (this.pureAmendmentView && this.portal()) {
        // portal have 2 separate components for declaration and amendment
        this.policyLimitListService
          .listsForAmendments(this.dc.selectedPolicy.number, this.dc.selectedRp.periodTo)
          .subscribe(
            (sr) => {
              this.reloadLLv(sr, dcParams, reloadDeclaration);
            },
            () => this.growlService.error('Error while getting limit list')
          );
      } else {
        // intranet have only one component for declaration and amendment
        this.policyLimitListService
          .listsForDeclarations(this.dc.selectedPolicy.number, this.dc.selectedRp.periodTo)
          .subscribe(
            (sr) => {
              this.reloadLLv(sr, dcParams, reloadDeclaration);
            },
            () => this.growlService.error('Error while getting limit list')
          );
      }
    } else {
      this.criteriaChanged.emit(reloadDeclaration);
    }
  }

  private reloadLLv(list: LimitListVersionInDeclarationDto[], dcParams: DeclarationParams, reloadDeclaration: boolean) {
    // set policy limit list version lists
    this.setLlvList(list.map((r) => <LimitListVersionInDeclarationDto>r));
    // and main or first limit list version or from parameter as selected
    if (this.dc && this.dc.llvList) {
      if (dcParams && dcParams.paramLlvId) {
        this.dc.selectedLlv = this.dc.llvList.find((f) => f.id === dcParams.paramLlvId);
      } else if (this.dc.llvList.length > 0) {
        this.dc.selectedLlv = this.dc.llvList.find((f) => f.mainLimitList);
        if (!this.dc.selectedLlv) {
          this.dc.selectedLlv = this.dc.llvList[0];
        }
      }
    }
    this.reloadDecOnList(dcParams, reloadDeclaration);
  }

  private reloadDecOnList(dcParams: DeclarationParams, reloadDeclaration = false) {
    this.showNoConfirmedDeclarationsForSelectedLimitListMessage = false;
    this.clearDecOnList();
    this.dc.selectedDecOn = undefined;
    if (this.dc.selectedLlv) {
      this.declarationService.findAllCompaniesToBeDeclaredOn(this.dc.selectedLlv.id).subscribe((companies) => {
        this.dc.selectedDecOn = undefined;

        if (this.portal() && this.pureAmendmentView) {
          const confirmedDeclarationsForDeclaredOdIds = this.singleFinalConfirmedDeclarationsForPolicy
            .filter(
              (acd) =>
                acd.reportingPeriod.id === this.dc.selectedRp.id && acd.limitListVersion.id === this.dc.selectedLlv.id
            )
            .map((rpcd) => rpcd.declaredOn.id);
          this.setDecOnList(companies.filter((c) => confirmedDeclarationsForDeclaredOdIds.includes(c.id)));
        } else {
          this.setDecOnList(companies);
        }

        if (this.dc.decOnList.length > 1) {
          if (dcParams && dcParams.paramDecOnId) {
            this.dc.selectedDecOn = this.dc.decOnList.find((decOn) => decOn.id === dcParams.paramDecOnId);
          } else {
            this.dc.selectedDecOn = undefined;
          }
        } else {
          if (this.dc.decOnList.length === 1) {
            this.dc.selectedDecOn = this.dc.decOnList[0];
          } else {
            this.dc.selectedDecOn = undefined;
          }
        }
        if (this.dc.decOnList.length === 0) {
          this.showNoConfirmedDeclarationsForSelectedLimitListMessage = true;
        }
        this.criteriaChanged.emit(reloadDeclaration);
      });
    } else {
      this.dc.selectedDecOn = undefined;
      this.criteriaChanged.emit(reloadDeclaration);
    }
  }

  // ############## SETTERS ##############
  public setPoliciesList(
    policies: PolicyContractSimpleIdDataDto[],
    dcParams: DeclarationParams,
    policy: PolicyContractSimpleIdDataDto
  ) {
    if (policies) {
      this.showSelectPolicyMessage = policies.length > 1 && !policy;
      if ((policies.length === 1 || policy) && !dcParams) {
        this.dc.policiesList = policies;
        if (policy) {
          this.onSelectedPolicyByPolicy(policy);
        } else {
          this.onSelectedPolicyByPolicy(policies[0]);
        }
      } else {
        this.dc.policiesList = policies.sort((a, b) => (a.validTo < b.validTo ? 1 : -1));
        if (policies.length > 0 && dcParams) {
          this.onSelectedPolicyByParams(dcParams);
        } else {
          this.criteriaChanged.emit(true);
        }
      }
    } else {
      this.dc.policiesList = [];
    }
  }

  private setRpList(rps: ReportingPeriodDto[], dcParams: DeclarationParams) {
    if (rps) {
      this.showSelectRpMessage = rps.length > 1 && (!dcParams || !dcParams.paramRpId);
      if (rps.length === 1 && !dcParams) {
        this.dc.rpList = rps;
        this.onSelectedReportingPeriodByPeriod(rps[0]);
      } else {
        this.dc.rpList = rps.sort((a, b) => (a.periodTo < b.periodTo ? 1 : -1));
        if (dcParams && rps.length > 0) {
          this.onSelectedReportingPeriodByParams(dcParams);
        } else {
          this.criteriaChanged.emit(true);
        }
      }
    } else {
      this.dc.rpList = undefined;
      this.criteriaChanged.emit(true);
    }
  }

  private setLlvList(llvs: LimitListVersionInDeclarationDto[]) {
    this.dc.llvList = llvs;
  }

  private setDecOnList(decOnList: CompanyInDeclarationDto[]) {
    this.dc.decOnList = decOnList;
  }

  private clearRpLLvDecOnLists() {
    this.dc.rpList = undefined;
    this.dc.selectedRp = undefined;
    this.clearLLvDecOnLists();
  }

  private clearLLvDecOnLists() {
    this.dc.llvList = undefined;
    this.dc.selectedLlv = undefined;
    this.clearDecOnList();
  }

  private clearDecOnList() {
    this.dc.decOnList = undefined;
    this.dc.selectedDecOn = undefined;
  }

  recalculateK7Message() {
    this.declarationService.checkK7Message(this.dc.selectedRp.id).subscribe((b) => {
      this.showK7message = b;
    });
  }

  public get mehib(): boolean {
    return this.appService.mehib;
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }
}
