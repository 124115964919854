import {Component, Input} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {BondType} from '../../../../bonding_shared/model/dictionary-ids';
import {BondVersionDto, BondVersionService, ClaimVersionBondInfoDto} from '../../../../bonding_shared';

@Component({
  selector: 'claim-bond-information',
  templateUrl: 'claim-bond-information.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class ClaimBondInformationComponent {
  readonly bondTypeWithSupplementalValue = BondType.NALEZYTE_WYKONANIE_KONTRAKTU_I_USUNIECIE_WAD_I_USTEREK;

  @Input()
  bondInfo: ClaimVersionBondInfoDto = <ClaimVersionBondInfoDto>{};
  @Input()
  presentationMode: boolean;
  @Input()
  formEditable: boolean;

  bondVersion: BondVersionDto;
  private onDate: Date;

  constructor(private bondService: BondVersionService) {}

  @Input()
  set notificationDate(onDate: Date) {
    this.onDate = onDate ? onDate : new Date();
    this.refreshValue();
  }

  @Input()
  set bondVersionId(id: number) {
    if (id) {
      this.bondService.getBondVersion(id).subscribe((bondVersion) => {
        this.bondVersion = bondVersion;
        this.refreshValue();
      });
    }
  }

  refreshValue() {
    if (!this.bondVersion || !this.formEditable) {
      return;
    }
    const valuesToLookup = this.bondInfo?.supplementaryValue
      ? this.bondVersion.supplementaryValues
      : this.bondVersion.values;
    const bondValue = valuesToLookup.find(
      (value) => value.validFrom <= this.onDate && (!value.validTo || value.validTo >= this.onDate)
    );
    this.bondInfo.bondType = this.bondVersion.type;
    this.bondInfo.currency = this.bondVersion.currency;
    this.bondInfo.value = bondValue?.value;
    this.bondInfo.validFrom = bondValue?.validFrom;
    this.bondInfo.validTo = bondValue?.validTo;
  }
}
