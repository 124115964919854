import {Component, Input} from '@angular/core';
import {
  AppConfigService,
  CompanyBaseDto,
  CompanyRatingService,
  CompanyRatingVersionPreviewDto,
  DictionaryBaseDto,
  RouterService,
} from '../../../../bonding_shared';
import {RatingType} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'company-ratings',
  templateUrl: './company-ratings.component.pug',
})
export class CompanyRatingsComponent {
  private _company: CompanyBaseDto;
  @Input() public showGroupLiability = false;
  public companyRatings: CompanyRatingVersionPreviewDto[] = [];
  private companyId: number;
  public ratingCategories: DictionaryBaseDto[] = [];

  constructor(
    public routerService: RouterService,
    private companyRatingService: CompanyRatingService,
    public appConfigService: AppConfigService
  ) {}

  @Input()
  public set company(value: CompanyBaseDto) {
    this._company = value;
    this.reloadRatings();
  }

  public get company(): CompanyBaseDto {
    return this._company;
  }

  public get kuke(): boolean {
    return this.appConfigService.kuke;
  }

  public get ecg(): boolean {
    return this.appConfigService.ecg;
  }

  public get mehib(): boolean {
    return this.appConfigService.mehib;
  }

  public get credendo(): boolean {
    return this.appConfigService.credendo;
  }

  public reloadRatings(): void {
    if (this.company && this.companyId !== this.company.id) {
      this.companyRatingService
        .getRatingCategories(this.company.id)
        .subscribe((categories) => (this.ratingCategories = categories));

      this.companyRatingService.getActiveCompanyRatingVersionPreviews(this.company.id).subscribe((companyRatings) => {
        this.companyRatings = companyRatings;
        this.companyRatings?.forEach((r) => {
          if (r.ratingType.id === RatingType.CC_CATEGORY) {
            r.liability = null;
          }
        });
      });
    }
  }
  isInsuranceRating(r: CompanyRatingVersionPreviewDto): boolean {
    return r.ratingType?.id === RatingType.ST_INSURANCE;
  }
}
