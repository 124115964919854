import {Component, Input} from '@angular/core';
import {BondVersionDto, ContractVersionDto} from '../../../../bonding_shared/model';
import {ControlContainer, NgForm} from '@angular/forms';
import {BondDifferentPriceType, BondInvoicingSchema} from '../../../../bonding_shared/model/dictionary-ids';
import {DictionaryUtils} from '../../../../bonding_shared/utils/dictionary-utils';

@Component({
  selector: 'different-price',
  templateUrl: 'different-price.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: NgForm,
    },
  ],
})
export class DifferentPriceComponent {
  readonly BondDifferentPriceType = BondDifferentPriceType;
  @Input() showErrors = false;
  @Input() bond: BondVersionDto;
  @Input() contractVersion: ContractVersionDto;
  @Input() disabled = false;

  isDifferentPriceType(differentPriceTypeIds: number[]): boolean {
    if (!this.bond) {
      return false;
    }
    return DictionaryUtils.inArr(this.bond.differentPriceType, differentPriceTypeIds);
  }

  get differentPriceTypeRegExp(): RegExp {
    return this.contractVersion &&
      DictionaryUtils.inArr(this.contractVersion.invoicingSchema, BondInvoicingSchema.FLAT_FEES)
      ? /^(?!.*PREMIUM_PERIOD).*$/
      : undefined;
  }
}
