export enum ReportDefinition {
  COUNTRY_RATINGS = 51,
  KUKE_CLAIM_CALCULATION = 1021,
  COMPANY_NETTO_EXPOSITION = 1004,
  DECLARATION_REPORT = 1201,
  CLIENT_OFFERS = 1047,
  LIMITS_ON_POLICY_INQUIRY = 1065,
  CONTRACT_PARAMETERS_GSP = 1067,
  CONTRACT_PARAMETERS_KOM = 1068,
  ECG_CLAIM_CALCULATION = 2012,
}

export enum ReportOptions {
  ERROR_ON_EMPTY_TAB = 'errorOnEmptyTab',
}
