import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {NgForm} from '@angular/forms';
import {
  AppConfigService,
  BondVersionDto,
  BondVersionService,
  CellChangeEvent,
  CessionVersionDto,
  ClaimFullDto,
  ClaimInvoiceDto,
  ClaimProductionCostDto,
  ClaimService,
  ClaimVersionBaseDto,
  ClaimVersionDto,
  CompanySimpleDto,
  ContactPersonDto,
  DictionaryBaseDto,
  DictionaryBaseService,
  DocumentService,
  FormDialogComponent,
  GrowlService,
  LimitRequestSimpleDto,
  LimitService,
  LoggedUserService,
  PolicyCessionVersionService,
  PolicyContractCoInsurerCompanyDto,
  PolicyContractIdDto,
  PolicyContractVersionDto,
  PolicyContractVersionService,
  PolicyDrawdownBaseDto,
  RecoveryDto,
  RouterService,
  TemplateService,
  TemplateSimpleDto,
  UserDto,
  UserIdDto,
  UserRange,
} from '../../bonding_shared';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {ClaimGuiService} from './services/claim-gui.service';
import {Button} from '../../bonding_shared/components/details-view/button';
import {DateUtils} from '../../bonding_shared/utils/date-utils';
import {
  BusinessObjectType,
  ClaimCauseOfLoss,
  ClaimElementaryRight,
  ClaimRiskType,
  ClaimStatus,
  ClaimVersionType,
  ContactNoteElementaryRight,
  ContactPersonGroupType,
  DictionaryProfile,
  DocumentType,
  ElementaryRight,
  KukeRepoOperation,
  LimitCategory,
  PolicyContractCompanyRole,
  PolicyContractType,
  PolicyContractVersionStatus,
  ReinsuranceMode,
  RepoDocumentElementaryRight,
  ServiceContactElementaryRight,
  ServiceNoteElementaryRight,
  Template,
  UnderwritingType,
} from '../../bonding_shared/model/dictionary-ids';
import {ClaimSelectorsComponent} from './components/claim-selectors.component';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {flatMap, map, shareReplay, tap, toArray} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Cacheable} from 'ts-cacheable';
import * as moment from 'moment';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';
import {Location} from '@angular/common';
import {BusinessUnit} from '../../bonding_shared/model/business-unit';
import {PolicyDrawdownVersionService} from '../../bonding_shared/services/policy-drawdown-version.service';
import {MehibClaimCalculationComponent} from './components/mehib-claim-calculation.component';
import {ClaimRecoveriesService} from '../../bonding_shared/services/claim-recoveries.service';
import {
  IPasteFromExcelColumnConfig,
  IPasteFromExcelConfig,
} from '../../bonding_shared/components/aku-table/services/paste-from-excel.service';
import {GroupedItem} from '../../bonding_shared/components/item-dropdown/item-grouped-dropdown.component';
import {ClaimUtilsService} from './services/claim-utils.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'claim-details',
  templateUrl: './claim-details.component.pug',
})
export class ClaimDetailsComponent extends DetailsView implements AfterViewInit {
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  @ViewChild(ClaimSelectorsComponent, {static: true}) selectors: ClaimSelectorsComponent;
  @ViewChild('changeClaimOfficer') changeClaimOfficerDialog: FormDialogComponent;
  @ViewChild('mehibClaimCalculation') mehibClaimCalculationComponent: MehibClaimCalculationComponent;

  dictionaryProfile = DictionaryProfile;
  claimVersion = ClaimGuiService.emptyVersion();
  limitRequest: LimitRequestSimpleDto;
  templatesGrouped: GroupedItem[] = [];
  recoveryTemplate: TemplateSimpleDto;
  possibleBeneficiaries: PossibleBeneficiary[] = [];
  recovery: RecoveryDto = <RecoveryDto>{};
  bond: BondVersionDto;
  policyContractVersion: PolicyContractVersionDto;
  contactPersonGroups: Map<number, ContactPersonDto[]>;
  readonly DateUtils = DateUtils;
  readonly BusinessObjectType = BusinessObjectType;
  readonly ClaimElementaryRight = ClaimElementaryRight;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  readonly ServiceNoteElementaryRight = ServiceNoteElementaryRight;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  readonly ContactPersonGroupType = ContactPersonGroupType;
  versions: ClaimVersionBaseDto[];
  readonly BusinessObjectTypeCLAIM = BusinessObjectType.CLAIM;
  allowEditSelectedFields = false;
  readonly ClaimVersionType = ClaimVersionType;
  drawdowns: PolicyDrawdownBaseDto[];
  pasteDataFromExcelConfig: IPasteFromExcelConfig[];

  readonly canCreateNewVersion: boolean;
  private _currentClaimOfficer = this.claimVersion.claimOfficer;
  private _newClaimOfficer: UserDto;
  readonly claimUserRange: UserRange = 'claim';
  readonly isClaimInvoiceCauseOfLossRequired = (invoice: ClaimInvoiceDto) => !!invoice.claimAmount;

  get claim(): ClaimFullDto {
    return this.claimVersion.claim;
  }

  set claim(value: ClaimFullDto) {
    this.claimVersion.claim = value;
  }

  createRightFunc(claimVersion: ClaimVersionDto): string {
    if (claimVersion) {
      return claimVersion?.claim?.id
        ? ClaimElementaryRight.CLAIM_CREATE_NEW_VERSION
        : ClaimElementaryRight.CLAIM_CREATE_INITIAL;
    }
    return undefined;
  }

  constructor(
    private _route: ActivatedRoute,
    public claimService: ClaimService,
    public guiService: ClaimGuiService,
    private templateService: TemplateService,
    public router: RouterService,
    private loggedUserService: LoggedUserService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    public appService: AppConfigService,
    private dictService: DictionaryBaseService,
    private cessionService: PolicyCessionVersionService,
    private policyVersionService: PolicyContractVersionService,
    private policyDrawdownVersionService: PolicyDrawdownVersionService,
    public documentService: DocumentService,
    private location: Location,
    private bondService: BondVersionService,
    private limitService: LimitService,
    private claimRecoveriesService: ClaimRecoveriesService
  ) {
    super(growlService);
    this.newVersionButton = new Button(
      'claim.newVersionButton',
      this.onCreateNewVersion.bind(this),
      true,
      this.newVersionButtonDisabled
    );
    this.deleteButton = undefined;
    this.canCreateNewVersion =
      loggedUserService.hasRight(ClaimElementaryRight.CLAIM_CREATE_NEW_VERSION) &&
      !(appService.ecg && loggedUserService.portal);
    this.initializeSelectors();
  }

  ngAfterViewInit() {
    this.form = this.ngForm.form;
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.clearErrors();
    if (params['id']) {
      this.loadClaimVersion(+params['id']);
    } else {
      this.claimVersion = ClaimGuiService.emptyVersion();
      if (params['claimRiskTypeId']) {
        this.claimVersion.claim.riskType = <DictionaryBaseDto>{id: +params['claimRiskTypeId']};
      }
      this.hideButtons(true);
      setTimeout(() => this.selectors.newClaim());
    }
  }

  initializeSelectors() {
    this.selectorNameList = ['ExternalServiceProvider'];
    this.initializeSelectorEmitters(true);
  }

  onSave() {
    this.clearErrors();
    if (this.formValidates()) {
      this.inProgress = true;
      this.saveClaimVersion();
    }
  }

  loadVersions(claimId: number) {
    this.claimService.getVersions(claimId, this.kukePortal ? ClaimStatus.USER_ERROR : null, false).subscribe({
      next: (versions) => {
        this.versions = versions;
        this.loadTemplates();
        this.hideButtons(false);
        this.handleButtons();
      },
      error: (error) => this.handleServerError(error),
    });
  }

  handleButtons() {
    this.newVersionButton.hidden = !this.claim?.id || this.kukePortal;
    this.newVersionButton.disabled = !this.claimVersion || !this.claimVersion.id || !this.claimVersion.last;
    this.saveButton.hidden = !this.claim || (!this.isInitialVersionCreation && this.kukePortal);
    this.saveButton.disabled = !this.claimVersion || (this.claimVersion.id && !this.claimVersion.last);
    this.cancelButton.hidden = !this.claim || (!this.isInitialVersionCreation && this.kukePortal);
  }

  saveClaimVersion() {
    if (this.ecg) {
      this.mergeContactPersonGroups();
    }
    this.claimService.save(this.claimVersion).subscribe(
      (claimVersion) => {
        this.setClaimVersion(claimVersion);
        this.inProgress = false;
        this.allowEditSelectedFields = false;
        this.showSavedMsg();
        if (this.claimVersion.occurenceDateOutsideOfPolicy) {
          this.addFrontendWarning('Occurence date not contained in selected policy year');
        }
        if (this.claimVersion.firstInvoiceDateOutsideOfPolicy) {
          this.addFrontendWarning('First invoice date not contained in selected policy year');
        }
        this.router.toClaimDetails(claimVersion.id);
      },
      (error) => this.handleServerError(error)
    );
  }

  calculateClaimVersion() {
    if (this.policyContractVersion?.claimSpecialConditions) {
      this.addFrontendWarning('Some non-standard condition exists in the policy. Calculation must be checked manually');
    }
    this.serverErrors = undefined;
    if (this.formValidates()) {
      this.claimService.calculateClaimVersion(this.claimVersion).subscribe(
        (claimVersion) => {
          this.claimVersion = claimVersion;
          this.inProgress = false;
          this.claimVersion.reserveAmount = this.claimVersion.calculatedReserveAmount;
        },
        (error) => this.handleServerError(error)
      );
    }
  }

  onCreateNewVersion() {
    this.showErrors = false;
    this.claimService.getNewClaimVersion(this.claim.id).subscribe((claimVersion) => {
      this.setClaimVersion(claimVersion);
    });
  }

  onSelectClaimVersion(claimVersion: ClaimVersionDto) {
    this.router.toClaimDetails(claimVersion.id);
  }

  onCancel() {
    if (!this.claim.id) {
      this.location.back();
    } else {
      super.onCancel(this._route);
    }
  }

  onPrintSelected(template: GroupedItem): void {
    const repoOperationId =
      template.id === Template.APPLICATION_TO_THE_MANAGMENT_BOARD_CLAIM ||
      template.id === Template.APPLICATION_TO_THE_KRU_CLAIM
        ? KukeRepoOperation.PROPOSALS
        : KukeRepoOperation.WORDINGS;
    const businessObjectId = this.credendo ? this.claimVersion.id : this.claim.id;
    this.router.toDocumentDetailsNewOperations(repoOperationId, template.id, businessObjectId, template.typeId);
  }

  onClaimRiskTypeChanged(claimRiskType: DictionaryBaseDto) {
    this.claimVersion = ClaimGuiService.emptyVersion();
    this.claimVersion.claim.riskType = claimRiskType;
    this.hideButtons(true);
  }

  showHideSpecialRiskAlert() {
    if (!!this.claim.risk && this.policyContractVersion?.risks.some((risk) => risk.company.id === this.claim.risk.id)) {
      this.addFrontendWarning('claim.details.riskAlert');
    }
  }

  loadClaimVersion(versionId: number) {
    this.claimService.getClaimVersion(versionId).subscribe(
      (claimVersion) => {
        this.setClaimVersion(claimVersion);
        this.loadRecovery(claimVersion.claim.id);
      },
      (error) => {
        this.objectNotFound = true;
      }
    );
  }

  loadRecovery(claimId: number) {
    if (!this.credendo) {
      return;
    }
    this.claimRecoveriesService.getFromRevision(claimId).subscribe((recovery) => {
      this.recovery = recovery;
    });
  }

  setClaimVersion(claimVersion: ClaimVersionDto) {
    this.claimVersion = claimVersion;
    this._currentClaimOfficer = this.claimVersion.claimOfficer;
    if (!!claimVersion.claim.policyContractVersion) {
      this.policyVersionService
        .getPolicyContractVersion(claimVersion.claim.policyContractVersion.id)
        .subscribe((pcv) => {
          this.policyContractVersion = pcv;
          this.setupPossibleBeneficiaries(pcv);
        });
    }
    if (this.claim.id) {
      this.loadVersions(this.claim.id);
    }
    this.objectNotFound = false;
    this.handleButtons();

    this.serverErrors = claimVersion.warnings;
    this.showErrors = true;

    this.showHideSpecialRiskAlert();
    if (this.kuke || this.ecg) {
      if (this.isInitialVersionCreation || (this.isNewVersion && !this.claimVersion.additionalInformation)) {
        this.claimVersion.additionalInformation = ClaimGuiService.emptyAdditionalInformation();
      }
    }
    if (this.creditLimitLinkVisible) {
      this.loadLimitRequest();
    }
    const bv = claimVersion.claim.bondVersion;
    if (!!bv) {
      this.getBondVersion(bv.id);
    }
    if (this.isInvestmentInsuranceType() || this.isMLTCreditInsuranceType()) {
      this.loadPolicyDrawdowns();
    }
    if (this.ecg) {
      this.buildContactPersonGroups();
    }
  }

  private loadLimitRequest() {
    const riskCompany = this.claimVersion.claim?.thirdParty
      ? BusinessUtils.getThirdPartyCompany(this.claim?.thirdParty)?.company
      : this.claim?.risk;
    if (!riskCompany) {
      return;
    }
    const criteria = BusinessUtils.createLimitRequestSearchCriteria();
    criteria.criteria.buyerCompanyId = riskCompany.id;
    if (this.kuke) {
      criteria.criteria.policyLimitListId = this.claimVersion.claim?.policyLimitList?.id;
    } else {
      criteria.criteria.masterPolicyContractId = this.claimVersion.claim?.policyContract?.masterPolicyId;
    }
    criteria.criteria.policyClientOrSubinsuredId = this.claimVersion.claim?.mainBeneficiary?.id;
    criteria.criteria.limit.category = <DictionaryBaseDto>{id: LimitCategory.POLICY};
    this.limitService.searchByCriteria(criteria).subscribe({
      next: (response) => (this.limitRequest = <LimitRequestSimpleDto>response.result[0]),
      error: (error) => this.handleServerError(error),
    });
  }

  private loadPolicyDrawdowns() {
    this.policyDrawdownVersionService
      .getApprovedDrawdownsByMasterPolicyId(this.claim.policyContractVersion.policyContract.masterPolicyContract.id)
      .subscribe({
        next: (drawdownVersions) => {
          this.drawdowns = drawdownVersions.map((dv) => dv.parent);
          this.initLoansExcelConfig(this.drawdowns);
        },
        error: (error) => this.handleServerError(error),
      });
  }

  private loadTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    dictionarySelectors.push(this.claim.riskType);
    if (this.credendo && this.claim.policyContractVersion !== undefined) {
      dictionarySelectors.push(this.claim.policyContractVersion.contractType);
    }

    this.templatesGrouped = [];
    this.templateService
      .findByType(
        undefined,
        this.credendo ? BusinessObjectType.CLAIM_VERSION : BusinessObjectType.CLAIM,
        undefined,
        dictionarySelectors,
        undefined,
        undefined,
        this.claim.businessUnit.id,
        true
      )
      .subscribe((result) => {
        if (result !== undefined && result !== null) {
          result = result.filter((t) => t.type.id !== DocumentType.CLAIM_CONFIRMATION_REGISTRATION_BALANCE);
          result.forEach((r) => {
            const templateGrouped = this.templatesGrouped.find((e) => e.name === r.type.name);
            if (templateGrouped) {
              if (!templateGrouped.children) {
                templateGrouped.children = [];
              }
              templateGrouped.children.push(<GroupedItem>{name: r.name, id: r.id, typeId: r.type.id});
            } else {
              this.templatesGrouped.push(<GroupedItem>{
                name: r.type.name,
                typeId: r.type.id,
                children: [<GroupedItem>{name: r.name, id: r.id, typeId: r.type.id}],
              });
            }
          });
        }
      });

    this.templateService
      .findByType(
        DocumentType.CLAIM_CONFIRMATION_REGISTRATION_BALANCE,
        BusinessObjectType.CLAIM,
        null,
        dictionarySelectors
      )
      .subscribe((result) => {
        if (result && result.length > 0) {
          this.recoveryTemplate = result[0];
        }
      });
  }

  isThreat() {
    if (this.claimVersion && this.claimVersion.claimCauseOfLoss) {
      return this.claimVersion.claimCauseOfLoss.id === ClaimCauseOfLoss.LOSS_THREAT;
    }
    return false;
  }

  setupPossibleBeneficiaries(pcv: PolicyContractVersionDto) {
    if (pcv) {
      this.dictService
        .getDictionaryEntry('PolicyContractCompanyRole', PolicyContractCompanyRole.CLIENT)
        .subscribe((dict) => {
          const client = <PossibleBeneficiary>pcv.client;
          client.role = dict.name;
          client.iban = pcv.insuredIban;
          client.bic = pcv.insuredBic;

          this.possibleBeneficiaries = [
            client,
            ...pcv.subinsured
              .filter((s) => !s.silentInsurance)
              .map((c) => {
                const res = <PossibleBeneficiary>c.company;
                res.role = c.role.name;
                res.iban = c.insuredIban;
                res.bic = c.insuredBic;
                return res;
              }),
          ];
          const masterPolicyContractId = pcv.policyContract.masterPolicyContract.id;
          this.getCessionBeneficiaries(masterPolicyContractId).subscribe((cessionBeneficiaries) => {
            if (masterPolicyContractId === pcv.policyContract.masterPolicyContract.id) {
              this.possibleBeneficiaries = this.possibleBeneficiaries.concat(cessionBeneficiaries);
            }
          });
        });
    } else {
      this.possibleBeneficiaries = [];
    }
  }

  @Cacheable()
  private getCessionBeneficiaries(masterPolicyContractId: number): Observable<PossibleBeneficiary[]> {
    const dataProvider = BusinessUtils.createPolicyCessionDataProvider(this.cessionService);
    dataProvider.searchCriteria.criteria.cession.masterPolicyContract.id = masterPolicyContractId;
    const translateBeneficiary = (cv: CessionVersionDto): Observable<PossibleBeneficiary> =>
      this.translateService
        .get('claim.cessionBeneficiary')
        .pipe(map((t) => <PossibleBeneficiary>{...cv.cession.assignee, role: t, iban: cv.iban, bic: cv.bic}));
    return dataProvider.search(null, null).pipe(
      tap((list) =>
        console.log('search all master policy cession versions', {criteria: dataProvider.searchCriteria, result: list})
      ),
      flatMap((list) => list.result),
      flatMap((c) => translateBeneficiary(c)),
      toArray(),
      shareReplay()
    );
  }

  bankInfoReadOnly(): boolean {
    return this.isCreditInsuranceType() || this.claimVersion.bankAccountReadonly;
  }

  isDisabled(): boolean {
    return (
      this.claimVersion && this.claimVersion.status.id !== ClaimStatus.UNDER_ASSESSMENT && !this.hasFieldsUpdateRight
    );
  }

  isDecisionDisabled(): boolean {
    return (
      this.claimVersion && this.claimVersion.status.id === ClaimStatus.UNDER_ASSESSMENT && !this.hasFieldsUpdateRight
    );
  }

  onAddClaimInvoice(ci: ClaimInvoiceDto) {
    ci.currency = this.policyContractVersion?.currency;
  }

  isSuretyType(): boolean {
    return this.isExpectedRiskType(ClaimRiskType.SURETY);
  }

  isCreditInsuranceType(): boolean {
    return this.isExpectedRiskType(ClaimRiskType.CREDIT_INSURANCE);
  }

  isProductionRiskType(): boolean {
    return (
      this.isExpectedRiskType(ClaimRiskType.PROD_RISK) || this.isExpectedRiskType(ClaimRiskType.MLT_PRODUCTION_RISK)
    );
  }

  isInvestmentInsuranceType(): boolean {
    return this.isExpectedRiskType(ClaimRiskType.MLT_INVESTMENT_INSURANCE);
  }

  isMLTCreditInsuranceType(): boolean {
    return this.isExpectedRiskType(ClaimRiskType.MLT_CREDIT_INSURANCE);
  }

  private isExpectedRiskType(...expectedRiskTypes: number[]): boolean {
    return this.claim.riskType && DictionaryUtils.in(this.claim.riskType, ...expectedRiskTypes);
  }

  isBusinessUnit(expectedBusinessUnitId: number): boolean {
    return this.claim?.businessUnit?.id === expectedBusinessUnitId;
  }

  get isMehibMLTBusinessUnit(): boolean {
    return this.isBusinessUnit(BusinessUnit.MEHIB_MLT);
  }

  get creditLimitLinkVisible() {
    return (
      (this.ecg && this.isCreditInsuranceType() && this.intranet) ||
      (this.appService.credendo && this.isSingleRiskOrTurnover()) ||
      (this.kuke && this.isCreditInsuranceType() && this.intranet)
    );
  }

  isNotificationDateWarning() {
    return this.claim.bondVersion && this.claimVersion.notificationDate > this.claim.bondVersion.releaseDate;
  }

  onNotificationDateChanged(event: any) {
    if (this.claimVersion && this.claimVersion.notificationDate) {
      if (this.mehib && this.policyContractVersion?.claimWaitingPeriod) {
        this.claimVersion.endOfClaimWaitingPeriodDate = DateUtils.addDay(
          this.claimVersion.notificationDate,
          this.policyContractVersion?.claimWaitingPeriod
        );
      }
      if (this.claim.riskType.id === ClaimRiskType.SURETY) {
        this.claimVersion.lossOccurrence = this.claimVersion.notificationDate;
      }
    }
  }

  invoiceNotFromCollection(i: ClaimInvoiceDto): boolean {
    return !i.collectionInvoice;
  }

  get credendo(): boolean {
    return this.appService.credendo;
  }
  get kuke(): boolean {
    return this.appService.kuke;
  }

  get mehib(): boolean {
    return this.appService.mehib;
  }

  get ecg(): boolean {
    return this.appService.ecg;
  }
  invoiceIssueDateChanged(event: CellChangeEvent<ClaimInvoiceDto>) {
    if (!event.item.deliveryDispatchDate) {
      event.item.deliveryDispatchDate = event.value;
    }
    event.item.customExchangeRateDate = moment(event.value).subtract(1, 'd').toDate();
  }

  isInitialized() {
    return this.claimVersion.status;
  }

  get readonly(): boolean {
    const isNewVersionCreationOrLastVersionSelected = this.isNewVersion || this.claimVersion.last;
    if (this.claimVersion.readonly) {
      return true;
    } else if (this.isCreditInsuranceOrProdRiskTypeAndKukeAndIntranet) {
      return !isNewVersionCreationOrLastVersionSelected;
    } else if (this.ecg) {
      return !isNewVersionCreationOrLastVersionSelected || (!this.isNewVersion && this.ecgPortal);
    }
    return false;
  }

  get saveCancelButtonsHidden(): boolean {
    if (this.credendo) {
      return false;
    }
    return this.readonly;
  }

  get isNewVersion(): boolean {
    return !(this.claimVersion && this.claimVersion.id);
  }

  get isInitialVersionCreation(): boolean {
    return this.isNewVersion && this.isFirstClaimVersion;
  }

  get isNewFullCorrectionVersion(): boolean {
    return (
      this.isNewVersion && DictionaryUtils.in(this.claimVersion.versionType, ClaimVersionType.ENTIRE_CLAIM_CORRECTION)
    );
  }

  get commentsReadonly(): boolean {
    return this.claimVersion.commentsReadonly;
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  get intranet(): boolean {
    return !this.loggedUserService.portal;
  }

  get newVersionButtonDisabled(): boolean {
    return this.kuke && !this.intranet;
  }

  isCreditInsuranceOrProdRiskTypeAndKukeAndIntranet(): boolean {
    return this.isCreditInsuranceOrProdRiskTypeAndKuke() && this.intranet;
  }

  isCreditInsuranceOrProdRiskTypeAndKuke(): boolean {
    return this.isExpectedRiskType(ClaimRiskType.CREDIT_INSURANCE, ClaimRiskType.PROD_RISK) && this.kuke;
  }

  reloadBankAccount() {
    if (this.claimVersion.beneficiary) {
      this.onSelectBeneficiary(this.possibleBeneficiaries.find((b) => b.id === this.claimVersion.beneficiary.id));
    }
  }

  onSelectBeneficiary(beneficiary: PossibleBeneficiary) {
    if (this.claim.policyContractVersion.status.id !== PolicyContractVersionStatus.ACTIVATED_LOCKED) {
      this.growlService.error('claim.policyNotLocked');
    } else {
      this.claimVersion.iban = beneficiary.iban;
      this.claimVersion.bic = beneficiary.bic;
    }
  }

  get isClaimSurveyVisible() {
    return this.kuke && !this.isSuretyType() && (this.isInitialVersionCreation || this.claim.surveyAnswers?.length > 0);
  }

  toServiceContacts() {
    this.router.toServiceContacts({boTypeId: BusinessObjectType.CLAIM, boId: this.claimVersion.claim.id});
  }

  get hasFieldsUpdateRight(): boolean {
    return this.loggedUserService.hasRight(ElementaryRight.CLAIM_FIELDS_EDITION);
  }

  onAllowEdit() {
    this.allowEditSelectedFields = !this.allowEditSelectedFields;
  }

  onEditPolicy(c: PolicyContractVersionDto) {
    this.policyVersionService.getPolicyContractVersion(c.id).subscribe((pcv) => {
      const policyContract = <PolicyContractIdDto>{
        id: pcv.policyContract.id,
        policyYear: pcv.policyContract.policyYear,
        masterPolicyId: pcv.policyContract.masterPolicyId,
        number: pcv.policyContract.number,
        policyYearFormatted: pcv.policyContract.policyYearFormatted,
      };
      this.claimVersion.claim.policyContractVersion = pcv;
      this.claimVersion.claim.policyContract = policyContract;
      this.setupPossibleBeneficiaries(pcv);
    });
  }

  changeStatus(status: DictionaryBaseDto) {
    this.claimVersion.status = status;
  }

  private getBondVersion(versionId: number) {
    this.bondService.getBondVersion(versionId).subscribe((bond) => (this.bond = bond));
  }

  get isFirstClaimVersion(): boolean {
    return !this.claim.id || this.claimVersion.versionNumber === 1;
  }

  get isChangeDisabledForPortalUser(): boolean {
    return this.ecgPortal && !this.isInitialVersionCreation;
  }

  isClaimVersionType(): boolean {
    return this.claimVersion.versionType.id === ClaimVersionType.CLAIM;
  }

  isEntireClaimCorrectionVersionType(): boolean {
    return this.claimVersion.versionType.id === ClaimVersionType.ENTIRE_CLAIM_CORRECTION;
  }

  isClaimWithdrawVersionType(): boolean {
    return this.claimVersion.versionType.id === ClaimVersionType.CLAIM_WITHDRAW;
  }

  get isMehibBeyondAssessment() {
    return this.mehib && !DictionaryUtils.equalsDictAndId(this.claimVersion.status, ClaimStatus.UNDER_ASSESSMENT);
  }

  get isBondClaimAmountEditable(): boolean {
    return (
      this.isNewVersion &&
      (this.isInitialVersionCreation ||
        DictionaryUtils.in(
          this.claimVersion.versionType,
          ClaimVersionType.PARTIAL_CLAIM_CORRECTION,
          ClaimVersionType.ENTIRE_CLAIM_CORRECTION,
          ClaimVersionType.APPEAL_FROM_DECISION
        ))
    );
  }

  get areDataEditable(): boolean {
    return !((this.kuke && !this.isNewVersion) || this.readonly);
  }

  get isAdditionalInformationDisabled(): boolean {
    return !this.areDataEditable;
  }

  isInvoicesAddButtonVisible(): boolean {
    return !(this.kuke || this.readonly) && !this.isMehibBeyondAssessment && !this.isChangeDisabledForPortalUser;
  }

  isInvoicesDeleteButtonVisible(): boolean {
    return (
      !((this.kuke && !this.isInitialVersionCreation) || this.readonly) &&
      !this.isMehibBeyondAssessment &&
      !this.isChangeDisabledForPortalUser
    );
  }

  isEcgInvoicesAddButtonVisible(): boolean {
    return this.isInvoicesAddButtonVisible() && this.isNewVersion;
  }

  isEcgInvoicesDeleteButtonVisible(): boolean {
    return this.isInvoicesDeleteButtonVisible() && this.isNewVersion;
  }

  isLifecycleDropdownVisible(): boolean {
    return this.claimVersion.last && !this.kukePortal;
  }

  openChangeClaimOfficer() {
    this.changeClaimOfficerDialog.openAndExecuteOnConfirm(() => this.saveChangeClaimOfficer());
  }

  saveChangeClaimOfficer() {
    this.claimVersion.claimOfficer = this.newClaimOfficer;
    this.saveClaimVersion();
  }

  get currentClaimOfficer(): UserIdDto {
    return this._currentClaimOfficer;
  }

  get newClaimOfficer(): UserDto {
    return this._newClaimOfficer;
  }

  set newClaimOfficer(user: UserDto) {
    this._newClaimOfficer = user;
  }

  get kukePortal(): boolean {
    return this.kuke && this.portal;
  }

  get ecgPortal(): boolean {
    return this.ecg && this.portal;
  }

  get isGeneralInformationVisible(): boolean {
    return !(this.kukePortal && this.isNewVersion);
  }

  isSingleRiskOrTurnover() {
    return this.isCreditInsuranceAndProductType(PolicyContractType.SINGLE_RISK, PolicyContractType.TURNOVER);
  }

  isFactoringPolicy() {
    return this.isCreditInsuranceAndProductType(PolicyContractType.FACTORING_CF);
  }

  private isCreditInsuranceAndProductType(...expectedProductIds: number[]) {
    return (
      this.isCreditInsuranceType() &&
      this.claim.policyContractVersion.policyContract.masterPolicyContract &&
      expectedProductIds.includes(this.claim.policyContractVersion.policyContract.masterPolicyContract.productType.id)
    );
  }

  get showReinsuranceFacultativeTreaty(): boolean {
    return this.policyContractVersion?.reinsuranceMode?.id === ReinsuranceMode.FACULTATIVE;
  }

  paymentTerm(invoice: ClaimInvoiceDto) {
    const calculationStartDate = invoice.deliveryDispatchDate ? invoice.deliveryDispatchDate : invoice.issueDate;
    if (!!invoice.dueDate && !!calculationStartDate) {
      return DateUtils.daysBetween(calculationStartDate, invoice.dueDate);
    }
    return '';
  }

  recalculateInvoiceMehib(event: CellChangeEvent<ClaimInvoiceDto>) {
    if (event.item.lossAmount != null) {
      event.item.openAmountGross = event.item.lossAmount - (event.item.recoveredAmount ?? 0);
    }
  }

  recalculateProductionCostMehib(event: CellChangeEvent<ClaimProductionCostDto>) {
    if (event.item.amount != null) {
      event.item.openAmountGross = event.item.amount - (event.item.recoveredAmount ?? 0);
    }
  }

  public generateRecoveryDocument(): void {
    this.documentService.generatePdf(
      this.recoveryTemplate.id,
      this.claim.id,
      this.recoveryTemplate.name + '.' + this.claim.id + '.pdf',
      true
    );
  }
  updateMehibTotals() {
    const sameCurrencyInvoices =
      this.claimVersion.claimInvoices.filter((inv) => inv.currency?.id !== this.claimVersion.currency?.id).length === 0;
    if (sameCurrencyInvoices) {
      this.claimVersion.lossAmount = this.claimVersion.claimInvoices
        .map((inv) => inv.lossAmount)
        .reduce((acc, value) => acc + value);
    }
    const invoicesSortedByDueDate = this.claimVersion.claimInvoices.sort(DateUtils.sorter('dueDate'));
    const eldestDueDate = invoicesSortedByDueDate[0].dueDate;
    this.claimVersion.lossOccurrence = eldestDueDate ? DateUtils.addDay(eldestDueDate, 60) : null;
    if (DictionaryUtils.in(this.claimVersion.claim.riskType, ClaimRiskType.CREDIT_INSURANCE)) {
      const earliestDueDate = invoicesSortedByDueDate[invoicesSortedByDueDate.length - 1].dueDate;
      this.claimVersion.endOfClaimWaitingPeriodDate = earliestDueDate
        ? DateUtils.addDay(earliestDueDate, this.claimVersion.claimWaitingPeriodInDays)
        : null;
    }
    this.mehibClaimCalculationComponent.recalculate();
  }

  createClaimNotification() {
    this.showErrors = false;
    this.claimService.getNewClaimVersion(this.claim.id).subscribe((claimVersion) => {
      this.setClaimVersion({
        ...claimVersion,
        notificationDate: DateUtils.today(),
        claimCauseOfLoss: null,
        versionType: <DictionaryBaseDto>{id: ClaimVersionType.ECG_CLAIM_NOTIFICATION},
      });
    });
  }

  get minInvoiceNumber(): number {
    return this.isSingleRiskOrTurnover() ? 0 : 1;
  }

  get policyCoInsurers(): PolicyContractCoInsurerCompanyDto[] {
    return this.policyContractVersion?.coInsurers;
  }

  get policyCoInsurersVisible(): boolean {
    const coInsurers = this.policyCoInsurers;
    return !this.kuke && coInsurers && coInsurers.length > 0;
  }

  initLoansExcelConfig(drawdowns: PolicyDrawdownBaseDto[]) {
    const typeDictName = this.isMLTCreditInsuranceType() ? 'ClaimRepaymentScheduleInstalmentType' : 'InvestmentForm';
    this.pasteDataFromExcelConfig = [
      {
        columns: [
          <IPasteFromExcelColumnConfig>{
            type: 'combo',
            property: 'drawdown',
            comboItems: drawdowns,
          },
          <IPasteFromExcelColumnConfig>{
            type: 'dictionary',
            property: 'type',
            dictionary: typeDictName,
          },
          <IPasteFromExcelColumnConfig>{
            type: 'number',
            property: 'amount',
          },
          <IPasteFromExcelColumnConfig>{
            type: 'dictionary',
            property: 'currency',
            dictionary: 'Currency',
          },
          <IPasteFromExcelColumnConfig>{
            type: 'date',
            property: 'dueDate',
          },
        ],
      },
    ];
  }

  get isMehibClaimAdvice(): boolean {
    return DictionaryUtils.in(this.claimVersion.versionType, ClaimVersionType.MEHIB_CLAIM_ADVICE);
  }

  get mehibInvoiceMinLength(): number {
    return this.isMehibClaimAdvice ? 0 : 1;
  }

  get vatInsured(): boolean {
    return !!this.policyContractVersion?.vatInsured;
  }

  get isBondSubTypeEditable(): boolean {
    return this.isInitialVersionCreation;
  }

  private buildContactPersonGroups() {
    const creditors =
      this.claimVersion.contactPersons.filter((cp) =>
        DictionaryUtils.in(cp.groupType, ContactPersonGroupType.CREDITOR)
      ) ?? [];
    const debtors =
      this.claimVersion.contactPersons.filter((cp) =>
        DictionaryUtils.in(cp.groupType, ContactPersonGroupType.DEBTOR)
      ) ?? [];
    this.contactPersonGroups = new Map<number, ContactPersonDto[]>();
    this.contactPersonGroups.set(ContactPersonGroupType.CREDITOR, creditors);
    this.contactPersonGroups.set(ContactPersonGroupType.DEBTOR, debtors);
  }

  getContactPersons(groupType: ContactPersonGroupType): ContactPersonDto[] {
    return this.contactPersonGroups?.get(groupType);
  }

  private mergeContactPersonGroups() {
    const creditors = this.contactPersonGroups.get(ContactPersonGroupType.CREDITOR);
    const debtors = this.contactPersonGroups.get(ContactPersonGroupType.DEBTOR);
    creditors.forEach((c) => (c.groupType = <DictionaryBaseDto>{id: ContactPersonGroupType.CREDITOR}));
    debtors.forEach((c) => (c.groupType = <DictionaryBaseDto>{id: ContactPersonGroupType.DEBTOR}));
    this.claimVersion.contactPersons = [...creditors, ...debtors];
  }

  calcInvoiceMaxLossAmount(invoiceDto: ClaimInvoiceDto): number {
    return invoiceDto?.originalAmount ?? Number.MAX_VALUE;
  }
}

type PossibleBeneficiary = CompanySimpleDto & {role: string; iban: string; bic: string};
