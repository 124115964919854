<search-view [objectName]="'Training'" customTitle="searchView.trainingsSearch" [dataProvider]="dataProvider"
             [searchModeSwitchable]="false"
             [newButton]="showNewButton">
  <div class="additional-buttons">
    <button class="bon-btn-success" type="button"  (click)="export()" btnIcon="fa-file-text-o" translate>searchView.export</button>
  </div>
  <div class="search-criteria bon-card-group">
    <div class="bon-card-inner">
      <dict-row labelKey="common.type"
                [(ngModel)]="dataProvider.searchCriteria.criteria.type"
                dictionary="TrainingType" name="type" nullLabelKey="user.search.all.type"></dict-row>
      <date-range-row labelKey="common.dateFrom" [value]="dataProvider.searchCriteria.criteria.dateFromInRange"
                      name="dateFromInRange"
      ></date-range-row>
      <date-range-row labelKey="common.dateTo" [value]="dataProvider.searchCriteria.criteria.dateToInRange"
                      name="dateToInRange"></date-range-row>
      <div class="bon-row">
        <label class="bon-label" translate>common.createdBy</label>
        <div style="display: inline-block">
          <user-auto-complete
            [ngModel] = "dataProvider.searchCriteria.criteria.createdBy"
            [ngModelOptions]="{standalone: true}"
            (changeItem) = "onUserCreatedByChange($event)"
            [range]="employeeUserRange"
            [valueFormatter]="userFullName"
            [listFormatter]="userFullName"
            [matchFormatted] = "true"></user-auto-complete>
        </div>
      </div>
    </div>
    <div class="bon-card-inner">
      <div class="bon-row">
        <label class="bon-label" translate>training.data.participant</label>
        <div style="display: inline-block">
          <user-auto-complete
            [ngModel] = "dataProvider.searchCriteria.criteria.participant"
            [ngModelOptions]="{standalone: true}"
            [defaultValue]="dataProvider.searchCriteria.criteria.participant"
            (changeItem) = "onUserParticipantChange($event)"
            [range]="employeeUserRange"
            [disabled] = "disableChangeParticipant"
            [valueFormatter]="userFullName"
            [listFormatter]="userFullName"
            [matchFormatted] = "true"></user-auto-complete>
        </div>
      </div>
      <input-row labelKey="training.data.topic"
                 [(ngModel)]="dataProvider.searchCriteria.criteria.topic"
                 name="topic"></input-row>
      <input-row labelKey="common.description"
                 [(ngModel)]="dataProvider.searchCriteria.criteria.description"
                 name="description"></input-row>

    </div>
    <div class="bon-card-inner">
      <boolean-combo-row labelKey="training.data.idd"
                    [(ngModel)]="dataProvider.searchCriteria.criteria.checkIdd"
                    name="idd"></boolean-combo-row>
      <boolean-combo-row labelKey="training.data.certificate"
                    [(ngModel)]="dataProvider.searchCriteria.criteria.checkCertificate"
                    name="certificate"></boolean-combo-row>
      <boolean-combo-row labelKey="training.data.relevantTechnology"
                         [(ngModel)]="dataProvider.searchCriteria.criteria.checkRelevantTechnology"
                         name="relevantTechnology"></boolean-combo-row>

    </div>
  </div>

  <div class="search-results">
    <a-table [pagination]="true" [pageSize]="20" [selection]="false" [dataProvider]="dataProvider"
             [sortable]='!dataProvider.textSearch'>
      <column labelKey="common.id" property="id" sort="id" [link]="true"
              (linkClick)="router.toTrainingDetails($event.id)" sort="id" initialSort="UP"></column>
      <column labelKey="training.data.topic" property="topic" sort="topic"></column>
      <column labelKey="common.type" dictionary="TrainingType" property="type" sort="type"></column>
      <column labelKey="training.data.idd" property="idd" type="checkbox" sort="idd"></column>
      <column labelKey="training.data.relevantTechnology"  property="relevantTechnology" type="checkbox" sort="relevantTechnology"></column>
      <column labelKey="training.data.numberOfHours" property="numberOfHours" sort="numberOfHours"></column>
      <column labelKey="common.dateFrom" property="dateFrom" type="date" sort="dateFrom"></column>
      <column labelKey="common.dateTo" property="dateTo" type="date" sort="dateTo"></column>

    </a-table>
  </div>

</search-view>
