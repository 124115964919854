import {Injectable} from '@angular/core';
import {
  GlobalConditionsOfInsurance,
  InquiryProductType,
  PolicyContractType,
  PolicyContractVersionStatus,
} from '../../../bonding_shared/model/dictionary-ids';
import {DictionaryBaseDto, PolicyContractVersionDto} from '../../../bonding_shared';

// tslint:disable:max-line-length
export enum PolicyContractViewSection {
  BASE_INSURERS,
  POLICY_CONTRACT_DATA__MULTI_FACTORS, // policy contract data section inner fields
  POLICY_CONTRACT_DATA__IN_ACC_WITH_FL_CONTACT,
  POLICY_CONTRACT_DATA__OPTION,
  POLICY_CONTRACT_DATA__DOMESTIC_TURNOVER,
  POLICY_CONTRACT_DATA__DOMESTIC_TURNOVER_REQUIRED,
  POLICY_CONTRACT_DATA__EXPORT_TURNOVER,
  POLICY_CONTRACT_DATA__EXPORT_TURNOVER_REQUIRED,
  POLICY_CONTRACT_DATA__KUKE_EXTERNAL_NUMBER,
  POLICY_CONTRACT_DATA__COVERABLE_DESCRIPTION,
  POLICY_CONTRACT_DATA__NACE_CODE,
  POLICY_CONTRACT_DATA__PROMISE,
  POLICY_CONTRACT_DATA__GBC,
  POLICY_CONTRACT_DATA__CESSION,
  POLICY_CONTRACT_DATA__COMMITMENT,
  POLICY_CONTRACT_DATA__SR_DETAILS,
  POLICY_CONTRACT_DATA__CURRENCIES_RESTRICTED_MEHIB_DEFAULT,
  POLICY_CONTRACT_DATA__CLIENT_READONLY,
  POLICY_CONTRACT_DATA__MONITORING,
  POLICY_CONTRACT_DATA__GILDA_NUMBER,
  POLICY_CONTRACT_DATA__DOMESTIC_CONTENT,

  TURNOVER_REPORTING,
  TURNOVER_REPORTING__KUKE_AMENDMENTS_NON_STANDARD,

  SCOPE_OF_COVER__RISK_GSP,
  SCOPE_OF_COVER__POLITICAL_RISK_ONLY,
  SCOPE_OF_COVER__POLITICAL_AND_COMMERCIAL_RISK,
  SCOPE_OF_COVER__PRODUCTION_RISK,
  SCOPE_OF_COVER__POLITICAL_RISK,
  SCOPE_OF_COVER__PROTRACTED_DEFAULT,
  SCOPE_OF_COVER__PROTRACTED_DEFAULT_GSP,
  SCOPE_OF_COVER__NON_PAYMENT_RISK_DISABLED_GSP,
  SCOPE_OF_COVER__PRODUCTION_RISK_DISABLED_GSP,
  SCOPE_OF_COVER__FL,

  PREMIUM__MIN_PREMIUM_FIXED,
  PREMIUM__PREMIUM_ON_SUBINSURED_LEVEL_DISABLED,
  PREMIUM__PREMIUM_RATE,
  PREMIUM__ADMINISTRATION_FEE,
  PREMIUM__CUSTOM_RISKS,
  PREMIUM__PREMIUM_FIXED,
  PREMIUM__KUKE_PREMIUM_INSTALMENTS,
  PREMIUM__PREMIUM_INSTALMENTS_DISABLED, // disabling premium instalments
  PREMIUM__KUKE_SUBINSURED_LEVEL,
  PREMIUM__KUKE_FIXED_MIN_PREMIUMS,
  PREMIUM__KUKE_TARIFF_RATE,
  PREMIUM__KUKE_NN_RATE,
  PREMIUM__KUKE_COVER_PLUS,
  PREMIUM__KUKE_PAYMENT_METHOD,
  PREMIUM__KUKE_PAYMENT_DAYS,
  PREMIUM__FIRST_INSTALLMENT_DATE,
  PREMIUM__NO_OF_INSTALLMENTS,
  PREMIUM__KUKE_PRODUCTION_RISK,
  PREMIUM__PDR,
  PREMIUM__NO_LOSS_REFUND,
  PREMIUM__ACQUISITION_DISCOUNT,
  PREMIUM__SR_DETAILS,
  PREMIUM__WHOLE_TURNOVER_REFUND,
  PREMIUM__LIMITED_LIABILITY_DISCOUNT,
  PREMIUM__AUTOMATIC_INVOICING,

  INDEMNIFICATION__LOL_X,
  INDEMNIFICATION__LOL_X_REQUIRED,
  INDEMNIFICATION__NQL,
  INDEMNIFICATION__EE,
  INDEMNIFICATION__AAD,
  INDEMNIFICATION__LOSS_OCCURING,
  INDEMNIFICATION__CHANGE_IN_RISK,
  INDEMNIFICATION__GLOBAL_LOL,
  INDEMNIFICATION__KUKE_GLOBAL_LOLS,
  INDEMNIFICATION__KUKE_GLOBAL_LOL_REQUIRED,
  INDEMNIFICATION__MIN_RETENTION,
  INDEMNIFICATION__LIMITED_LIABILITY,
  INDEMNIFICATION__POLITICAL_RISK_COVER,
  INDEMNIFICATION__COMMERCIAL_RISK_COVER,
  INDEMNIFICATION__MEHIB_INSURANCE_COVER,
  INDEMNIFICATION__INSURED_AMOUNT_CURRENCY,

  LIMIT_FEES__BIG_FARMER,
  LIMIT_FEES__LUMP_SUM,
  LIMIT_FEES__KUKE_LUMP_SUM_REQUIRED,
  LIMIT_FEES__NO_INVOICE_FOR_REF,
  LIMIT_FEES__KUKE_COUNTRY_INVOICING_TYPE_REQUIRED,
  LIMIT_FEES__KUKE_FALCON_FEE,
  LIMIT_FEES__IVOICE_TYPE,
  LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES,
  LIMIT_FEES__KUKE_LIMIT_AND_MONITORING,
  LIMIT_FEES__CURRENCY,

  COVERED_TRANSACTION__A,
  COVERED_TRANSACTION__B,
  COVERED_TRANSACTION__C,
  COVERED_TRANSACTION__D,
  COVERED_TRANSACTION__GSP_KN,
  COVERED_TRANSACTION__GSP_KD,
  COVERED_TRANSACTION__GSP_IBZ,
  COVERED_TRANSACTION__GSP_PI,
  COVERED_TRANSACTION__GSP_WN,
  COVERED_TRANSACTION__GSP_LF,

  INSUREDS__SUBINSURED,
  INSUREDS__SUBINSURED_SHARE,
  INSUREDS__FRONTING_TYPE_DISABLED,

  // TODO: delete me
  CREDIT_LIMITS__NEG_LIMIT_DEC_VAL_DAYS,
  CREDIT_LIMITS__REST,

  OTHER_CONDITIONS__ADVERSE_INFORMATION_DAYS,
  OTHER_CONDITIONS__INSURANCE_THRESHOLD, // KUKE_ONLY
  OTHER_CONDITIONS__REST,
  OTHER_CONDITIONS__PROTRACTED_DEFAULT,
  OTHER_CONDITIONS__POLITICAL_RISK,
  OTHER_CONDITIONS__POLITICAL_RISK_VALUE,

  CONTRACT_TERMINATION,
  CONTRACT_TERMINATION_TACIT_RENEWAL_EDITABLE,

  COUNTRIES__MARKETABLE,
  COUNTRIES__NON_MARKETABLE,
  COUNTRIES__KUKE_EDITABLE,

  TREATY, // CR captive product

  SPECIAL_CLAUSES,

  BONUS_MALUS__REST,

  COLLECTION_COMPANIES_SECTION,

  RISK_ASSESSMENT, // risk-assessment-section inner section
  RISK_ASSESSMENT__TYPE_BLOCKED, // risk-assessment-section inner field

  TECHNICAL__2ND_COLUMN, // section technical inner fields
  TECHNICAL__HIGHEST_DIFF, // section technical inner fields
  TECHNICAL_DETAILS__CESSION,
  TECHNICAL__GSP,
  TECHNICAL_DETAILS__KUKE_CROSSBORDER,
  TECHNICAL__FORCE_CONDITION,
  TECHNICAL_DETAILS__REINSURANCE_PREMIUMS,

  // Policy tabs visibility
  CLAUSES_TAB,
  INDIVIDUAL_CLAUSES_TAB,
  STATEMENTS_TAB,
  LIMITLIST_TAB,
  OFFERS_TAB,
  INQUIRIES_TAB,
  PREVIEW_INSUREDS_TAB,
  COVERABLE_DESCRIPTION,
  COVERED_TRANSACTIONS_TAB,

  // HIDE IN KUKE
  BONUS_MALUS__FIXED_AMOUNT,
  BONUS_MALUS__BONUSES,
  OTHER_CONDITIONS__CLAIM_SPECIAL_CONDITIONS,
  CONTRACT_TERMINATION__EXIT_PENALTY, // Credendo true, Kuke false
  POLICY_CONTRACT_DATA__INSURED_IBAN,
  POLICY_CONTRACT_DATA__INSURED_BIC,
  BONUS_MALUS__MALUS_FIXED_AMOUNT,
  BONUS_MALUS__MALUSES,
  BONUS_MALUS__PROFIT_SHARES,
  OTHER_CONDITIONS__RUN_IN,
  OTHER_CONDITIONS__RUN_OFF,

  PORTAL_PREMIUM__KUKE_MIN_PREMIUM_FIXED,
  PORTAL_PREMIUM__KUKE_PREMIUM_RATE,
  PORTAL_PREMIUM__KUKE_COVER_PLUS,
  PORTAL_PREMIUM__KUKE_PREMIUM_FIXED,
  PORTAL_PREMIUM__KUKE_FIXED_MIN_PREMIUMS,

  PORTAL_PREMIUM_INSTALEMENT_CALENDAR__KUKE,

  PORTAL_INDEMNIFICATION__KUKE_NQL,

  PORTAL_REPORTING_PERIODS__KUKE,
  PORTAL_SUBINSUREDS__KUKE,

  OFFER__BLOG_OFFER,
  OFFER__GBC,
  OFFER__NO_LOSS_REFUND__ACQUISITION_DISCOUNT__PRODUCTION_RISK,
  OFFER__WHOLE_TURNOVER_REFUND,
  OFFER__LIMITED_LIABILITY,
  OFFER__AAD,
  OFFER__PD,
  OFFER__PROTRACTED_DEFAULT,
  OFFER__MIN_RETENTION,
  OFFER__EE,
  OFFER__RISK_ASSESSMENT,
  OFFER__NQL,
  OFFER__ROL,
  OFFER__MEHIB_CALCULATOR,
  OFFER__ECG_CALCULATOR,
  OFFER__GLOBAL_LOL,
  OFFER__GLOBAL_LOL_TYPE,
  OFFER__GLOBAL_LOL_DISABLED,
  OFFER__INVOICED_PREMIUM_DISABLED,
  OFFER__MANUFACTURING_PERIOD,
  OFFER__VSKV_TA,
  OFFER__COMMERCIAL_RISK_COVER,
  OFFER__REPAYMENT_PERIOD,
  OFFER__BUYER_RISK,
  OFFER__MLT,
  OFFER__INSURANCE_COVER_MEHIB,
  OFFER__INSURANCE_COVER,
  OFFER__DISCRETIONARY_COVER_BONUS_MALUS,
  OFFER__DISCRETIONARY_COVER,
  OFFER__FOCUS_PREMIUM,
  OFFER__MAX_PAYMENT_DATE,
  OFFER__DECLARED_TURNOVER,
  BONUS_MALUS__ADDITIONAL_PREMIUM_BUTTON,
  BONUS_MALUS__PREMIUM_RETURN_REQUEST_BUTTON,
  NEW_LIMIT_BUTTON,
  NEW_POLICY_YEAR_BUTTON,

  SALESFORCE_ID,
}

@Injectable()
export class PolicyContractViewService {
  readonly = false;
  sectionsVisibility: boolean[][] = [];

  reportingPeriodsEditable = false;

  static policyAtLeastActivated(businessStatus: DictionaryBaseDto) {
    return (
      businessStatus &&
      [
        PolicyContractVersionStatus.ACTIVATED,
        PolicyContractVersionStatus.BS_ACTIVE,
        PolicyContractVersionStatus.TERMINATION_ACTIVATED,
        PolicyContractVersionStatus.BS_TERMINATED_CLOSED,
        PolicyContractVersionStatus.BS_OBSOLETE,
        PolicyContractVersionStatus.BS_EXPIRED,
      ].includes(businessStatus.id)
    );
  }

  static policyAtLeastActivatedOrLocked(businessStatus: DictionaryBaseDto) {
    return (
      businessStatus &&
      [
        PolicyContractVersionStatus.ACTIVATED,
        PolicyContractVersionStatus.BS_ACTIVE,
        PolicyContractVersionStatus.TERMINATION_ACTIVATED,
        PolicyContractVersionStatus.BS_TERMINATED_CLOSED,
        PolicyContractVersionStatus.BS_OBSOLETE,
        PolicyContractVersionStatus.BS_EXPIRED,
        PolicyContractVersionStatus.ACTIVATED_LOCKED,
        PolicyContractVersionStatus.BS_ACTIVE_LOCKED,
        PolicyContractVersionStatus.BS_EXPIRED_LOCKED,
      ].includes(businessStatus.id)
    );
  }

  // prettier-ignore
  constructor() {

    // CREDENDO products
    this.sectionsVisibility[PolicyContractType.TOP_UP] = [];
    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS] = [];
    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS_PARTNER] = [];
    this.sectionsVisibility[PolicyContractType.CAPTIVE] = [];
    this.sectionsVisibility[PolicyContractType.SINGLE_RISK] = [];
    this.sectionsVisibility[PolicyContractType.TURNOVER] = [];

    this.sectionsVisibility[InquiryProductType.TOP_UP] = [];
    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS] = [];
    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS_PARTNER] = [];
    this.sectionsVisibility[InquiryProductType.CAPTIVE] = [];
    this.sectionsVisibility[InquiryProductType.SINGLE_RISK] = [];
    this.sectionsVisibility[InquiryProductType.TURNOVER] = [];

    // Mehib ST policy products
    this.sectionsVisibility[PolicyContractType.EXPORT_RECEIVABLES_C] = [];
    this.sectionsVisibility[PolicyContractType.FACTORING_CF] = [];

    // Mehib MLT policy products
    this.sectionsVisibility[PolicyContractType.BUYER_CREDIT_V] = [];
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_S] = [];
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_DISCOUNTING_KV] = [];
    this.sectionsVisibility[PolicyContractType.FOREIGN_INVESTMENT_B] = [];
    this.sectionsVisibility[PolicyContractType.TIED_AID_TAI] = [];
    this.sectionsVisibility[PolicyContractType.PRESHIPMENT_RISK_G] = [];

    // Mehib ST inquiry products
    this.sectionsVisibility[InquiryProductType.EXPORT_RECEIVABLES_C] = [];
    this.sectionsVisibility[InquiryProductType.FACTORING_CF] = [];

    // Mehib MLT inquiry products
    this.sectionsVisibility[InquiryProductType.BUYER_CREDIT_V] = [];
    this.sectionsVisibility[InquiryProductType.SUPPLIER_CREDIT_S] = [];
    this.sectionsVisibility[InquiryProductType.SUPPLIER_CREDIT_DISCOUNTING_KV] = [];
    this.sectionsVisibility[InquiryProductType.FOREIGN_INVESTMENT_B] = [];
    this.sectionsVisibility[InquiryProductType.TIED_AID_TAI] = [];
    this.sectionsVisibility[InquiryProductType.PRESHIPMENT_RISK_G] = [];

    // KUKE KOM products
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP] = [];
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP] = [];
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE] = [];
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK] = [];
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP] = [];

    // KUKE GSP products
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG] = [];

    // ECG
    this.sectionsVisibility[PolicyContractType.GLOBAL_RISK] = [];
    this.sectionsVisibility[PolicyContractType.SELECTIVE_RISK] = [];
    this.sectionsVisibility[PolicyContractType.ECG_TOP_UP] = [];

    this.sectionsVisibility[InquiryProductType.GLOBAL_RISK] = [];
    this.sectionsVisibility[InquiryProductType.SELECTIVE_RISK] = [];
    this.sectionsVisibility[InquiryProductType.ECG_TOP_UP] = [];


    // -------------------------------------------------------------------------------------------------------------------------------------
    // ---- CREDENDO -- CREDENDO -- CREDENDO -- CREDENDO -- CREDENDO -- CREDENDO -- CREDENDO -- CREDENDO -- CREDENDO -- CREDENDO -----------
    // -------------------------------------------------------------------------------------------------------------------------------------

    const setForCredendoSingleRiskPolicy = (sectionId: PolicyContractViewSection, visibility = true) => {
      this.sectionsVisibility[PolicyContractType.SINGLE_RISK][sectionId] = visibility;
      this.sectionsVisibility[PolicyContractType.TURNOVER][sectionId] = visibility;
    };

    const setForCredendoMultiRiskPolicy = (sectionId: PolicyContractViewSection, visibility = true) => {
      this.sectionsVisibility[PolicyContractType.TOP_UP][sectionId] = visibility;
      this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS][sectionId] = visibility;
      this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS_PARTNER][sectionId] = visibility;
      this.sectionsVisibility[PolicyContractType.CAPTIVE][sectionId] = visibility;
    };

    const setForCredendoPolicy = (sectionId: PolicyContractViewSection, visibility = true) => {
      setForCredendoSingleRiskPolicy(sectionId, visibility);
      setForCredendoMultiRiskPolicy(sectionId, visibility);
    };

    const setForCredendoSingleRiskInquiry = (sectionId: PolicyContractViewSection, visibility = true) => {
      this.sectionsVisibility[InquiryProductType.SINGLE_RISK][sectionId] = visibility;
      this.sectionsVisibility[InquiryProductType.TURNOVER][sectionId] = visibility;
    };

    const setForCredendoMultiRiskInquiry = (sectionId: PolicyContractViewSection, visibility = true) => {
      this.sectionsVisibility[InquiryProductType.TOP_UP][sectionId] = visibility;
      this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS][sectionId] = visibility;
      this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS_PARTNER][sectionId] = visibility;
      this.sectionsVisibility[InquiryProductType.CAPTIVE][sectionId] = visibility;
    };

    const setForCredendoInquiry = (sectionId: PolicyContractViewSection, visibility = true) => {
      setForCredendoSingleRiskInquiry(sectionId, visibility);
      setForCredendoMultiRiskInquiry(sectionId, visibility);
    };

    setForCredendoPolicy(PolicyContractViewSection.POLICY_CONTRACT_DATA__INSURED_IBAN);
    setForCredendoPolicy(PolicyContractViewSection.POLICY_CONTRACT_DATA__INSURED_BIC);
    setForCredendoPolicy(PolicyContractViewSection.OTHER_CONDITIONS__CLAIM_SPECIAL_CONDITIONS);
    setForCredendoPolicy(PolicyContractViewSection.OTHER_CONDITIONS__RUN_IN);
    setForCredendoPolicy(PolicyContractViewSection.CONTRACT_TERMINATION__EXIT_PENALTY);
    setForCredendoPolicy(PolicyContractViewSection.BONUS_MALUS__FIXED_AMOUNT);
    setForCredendoPolicy(PolicyContractViewSection.BONUS_MALUS__BONUSES);
    setForCredendoPolicy(PolicyContractViewSection.BONUS_MALUS__MALUS_FIXED_AMOUNT);
    setForCredendoPolicy(PolicyContractViewSection.BONUS_MALUS__MALUSES);
    setForCredendoPolicy(PolicyContractViewSection.BONUS_MALUS__PROFIT_SHARES);
    setForCredendoPolicy(PolicyContractViewSection.COUNTRIES__MARKETABLE, false);
    setForCredendoPolicy(PolicyContractViewSection.COUNTRIES__NON_MARKETABLE, false);
    setForCredendoPolicy(PolicyContractViewSection.OTHER_CONDITIONS__ADVERSE_INFORMATION_DAYS, false);
    setForCredendoPolicy(PolicyContractViewSection.INDEMNIFICATION__GLOBAL_LOL);
    setForCredendoPolicy(PolicyContractViewSection.PREMIUM__AUTOMATIC_INVOICING);
    setForCredendoPolicy(PolicyContractViewSection.NEW_LIMIT_BUTTON, true);
    setForCredendoPolicy(PolicyContractViewSection.OTHER_CONDITIONS__POLITICAL_RISK_VALUE, false);

    setForCredendoMultiRiskPolicy(PolicyContractViewSection.INDEMNIFICATION__MIN_RETENTION, true);
    setForCredendoMultiRiskPolicy(PolicyContractViewSection.INDEMNIFICATION__NQL, true);
    setForCredendoMultiRiskPolicy(PolicyContractViewSection.INDEMNIFICATION__EE, true);
    setForCredendoMultiRiskPolicy(PolicyContractViewSection.INDEMNIFICATION__AAD, true);
    setForCredendoMultiRiskPolicy(PolicyContractViewSection.INDEMNIFICATION__LOSS_OCCURING, true);
    setForCredendoMultiRiskPolicy(PolicyContractViewSection.INDEMNIFICATION__CHANGE_IN_RISK, true);

    setForCredendoSingleRiskPolicy(PolicyContractViewSection.COLLECTION_COMPANIES_SECTION, true);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.RISK_ASSESSMENT, false);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.OTHER_CONDITIONS__REST, false);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.POLICY_CONTRACT_DATA__DOMESTIC_TURNOVER, false);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.POLICY_CONTRACT_DATA__EXPORT_TURNOVER, false);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.TECHNICAL__2ND_COLUMN, false);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.PREMIUM__SR_DETAILS, true);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.POLICY_CONTRACT_DATA__SR_DETAILS, true);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.POLICY_CONTRACT_DATA__MONITORING, true);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.POLICY_CONTRACT_DATA__GILDA_NUMBER, true);

    setForCredendoSingleRiskInquiry(PolicyContractViewSection.PREMIUM__SR_DETAILS, true);
    setForCredendoSingleRiskInquiry(PolicyContractViewSection.OFFER__PROTRACTED_DEFAULT, true);
    setForCredendoSingleRiskInquiry(PolicyContractViewSection.OFFER__AAD, false);
    setForCredendoSingleRiskInquiry(PolicyContractViewSection.OFFER__MIN_RETENTION, false);
    setForCredendoSingleRiskInquiry(PolicyContractViewSection.OFFER__EE, false);
    setForCredendoSingleRiskInquiry(PolicyContractViewSection.OFFER__NQL, false);
    setForCredendoSingleRiskInquiry(PolicyContractViewSection.OFFER__ROL, false);

    setForCredendoMultiRiskInquiry(PolicyContractViewSection.OFFER__AAD, true);
    setForCredendoMultiRiskInquiry(PolicyContractViewSection.OFFER__MIN_RETENTION, true);
    setForCredendoMultiRiskInquiry(PolicyContractViewSection.OFFER__EE, true);
    setForCredendoMultiRiskInquiry(PolicyContractViewSection.OFFER__NQL, true);
    setForCredendoMultiRiskInquiry(PolicyContractViewSection.OFFER__ROL, true);

    setForCredendoInquiry(PolicyContractViewSection.OFFER__FOCUS_PREMIUM, true);
    setForCredendoInquiry(PolicyContractViewSection.OFFER__INSURANCE_COVER, true);

    this.sectionsVisibility[PolicyContractType.TOP_UP][PolicyContractViewSection.BASE_INSURERS] = true;
    this.sectionsVisibility[PolicyContractType.TOP_UP][PolicyContractViewSection.POLICY_CONTRACT_DATA__MULTI_FACTORS] = true;
    this.sectionsVisibility[PolicyContractType.TOP_UP][PolicyContractViewSection.TECHNICAL__HIGHEST_DIFF] = true;

    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS][PolicyContractViewSection.COLLECTION_COMPANIES_SECTION] = true;
    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS][PolicyContractViewSection.RISK_ASSESSMENT] = false;
    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS][PolicyContractViewSection.OTHER_CONDITIONS__REST] = false;

    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS_PARTNER][PolicyContractViewSection.COLLECTION_COMPANIES_SECTION] = true;
    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS_PARTNER][PolicyContractViewSection.RISK_ASSESSMENT] = false;
    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS_PARTNER][PolicyContractViewSection.RISK_ASSESSMENT__TYPE_BLOCKED] = true;
    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS_PARTNER][PolicyContractViewSection.OTHER_CONDITIONS__REST] = false;

    this.sectionsVisibility[PolicyContractType.CAPTIVE][PolicyContractViewSection.TREATY] = true;

    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS][PolicyContractViewSection.OFFER__PD] = true;
    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS_PARTNER][PolicyContractViewSection.OFFER__PD] = true;
    this.sectionsVisibility[InquiryProductType.CAPTIVE][PolicyContractViewSection.OFFER__PD] = true;

    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS][PolicyContractViewSection.OFFER__PROTRACTED_DEFAULT] = true;
    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS_PARTNER][PolicyContractViewSection.OFFER__PROTRACTED_DEFAULT] = true;
    this.sectionsVisibility[InquiryProductType.CAPTIVE][PolicyContractViewSection.OFFER__PROTRACTED_DEFAULT] = true;

    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS][PolicyContractViewSection.OFFER__RISK_ASSESSMENT] = true;
    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS_PARTNER][PolicyContractViewSection.OFFER__RISK_ASSESSMENT] = true;
    this.sectionsVisibility[InquiryProductType.CAPTIVE][PolicyContractViewSection.OFFER__RISK_ASSESSMENT] = true;


    // -------------------------------------------------------------------------------------------------------------------------------------
    // ---- MEHIB -- MEHIB -- MEHIB -- MEHIB -- MEHIB -- MEHIB -- MEHIB -- MEHIB -- MEHIB -- MEHIB -- MEHIB -- MEHIB -- MEHIB -- MEHIB -----
    // -------------------------------------------------------------------------------------------------------------------------------------

    const setVisibilityForMehibST = (sectionId: PolicyContractViewSection, visible) => {
      this.sectionsVisibility[PolicyContractType.EXPORT_RECEIVABLES_C][sectionId] = visible;
      this.sectionsVisibility[PolicyContractType.FACTORING_CF][sectionId] = visible;
    };

    const setVisibilityForMehibMLT = (sectionId: PolicyContractViewSection, visible) => {
      this.sectionsVisibility[PolicyContractType.BUYER_CREDIT_V][sectionId] = visible;
      this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_S][sectionId] = visible;
      this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_DISCOUNTING_KV][sectionId] = visible;
      this.sectionsVisibility[PolicyContractType.FOREIGN_INVESTMENT_B][sectionId] = visible;
      this.sectionsVisibility[PolicyContractType.TIED_AID_TAI][sectionId] = visible;
      this.sectionsVisibility[PolicyContractType.PRESHIPMENT_RISK_G][sectionId] = visible;
    };

    const setVisibilityForAllMehib = (sectionId: PolicyContractViewSection, visible) => {
      setVisibilityForMehibST(sectionId, visible);
      setVisibilityForMehibMLT(sectionId, visible);
    };

    setVisibilityForMehibST(PolicyContractViewSection.PREMIUM__PREMIUM_FIXED, false);
    setVisibilityForMehibST(PolicyContractViewSection.PREMIUM__PREMIUM_RATE, false);
    setVisibilityForMehibST(PolicyContractViewSection.TURNOVER_REPORTING__KUKE_AMENDMENTS_NON_STANDARD, false);
    setVisibilityForMehibST(PolicyContractViewSection.INSUREDS__FRONTING_TYPE_DISABLED, true);
    setVisibilityForMehibST(PolicyContractViewSection.LIMIT_FEES__LUMP_SUM, false);
    setVisibilityForMehibST(PolicyContractViewSection.LIMIT_FEES__BIG_FARMER, false);
    setVisibilityForMehibST(PolicyContractViewSection.LIMIT_FEES__NO_INVOICE_FOR_REF, true);
    setVisibilityForMehibST(PolicyContractViewSection.LIMIT_FEES__IVOICE_TYPE, true);
    setVisibilityForMehibST(PolicyContractViewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES, true);
    setVisibilityForMehibST(PolicyContractViewSection.SCOPE_OF_COVER__PRODUCTION_RISK, true);
    setVisibilityForMehibST(PolicyContractViewSection.SCOPE_OF_COVER__PROTRACTED_DEFAULT, true);
    setVisibilityForMehibST(PolicyContractViewSection.INDEMNIFICATION__LIMITED_LIABILITY, true);
    setVisibilityForMehibST(PolicyContractViewSection.INDEMNIFICATION__MEHIB_INSURANCE_COVER, true);
    setVisibilityForMehibST(PolicyContractViewSection.BONUS_MALUS__ADDITIONAL_PREMIUM_BUTTON, false);
    setVisibilityForMehibST(PolicyContractViewSection.PREMIUM__PREMIUM_INSTALMENTS_DISABLED, true);
    setVisibilityForMehibST(PolicyContractViewSection.LIMIT_FEES__CURRENCY, true);
    setVisibilityForMehibST(PolicyContractViewSection.NEW_LIMIT_BUTTON, true);
    setVisibilityForMehibST(PolicyContractViewSection.TURNOVER_REPORTING, true);

    setVisibilityForMehibMLT(PolicyContractViewSection.POLICY_CONTRACT_DATA__PROMISE, false);
    setVisibilityForMehibMLT(PolicyContractViewSection.OFFERS_TAB, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.COVERED_TRANSACTIONS_TAB, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.POLICY_CONTRACT_DATA__COMMITMENT, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.PREMIUM__PREMIUM_FIXED, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.PREMIUM__PREMIUM_RATE, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.PREMIUM__ADMINISTRATION_FEE, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.PREMIUM__PDR, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.TECHNICAL__FORCE_CONDITION, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.COVERED_TRANSACTION__A, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.COVERED_TRANSACTION__B, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.COVERED_TRANSACTION__C, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.COVERED_TRANSACTION__D, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.COVERED_TRANSACTION__GSP_KN, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.COVERED_TRANSACTION__GSP_KD, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.COVERED_TRANSACTION__GSP_IBZ, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.COVERED_TRANSACTION__GSP_PI, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.COVERED_TRANSACTION__GSP_WN, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.COVERED_TRANSACTION__GSP_LF, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.SCOPE_OF_COVER__POLITICAL_RISK, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.INDEMNIFICATION__GLOBAL_LOL, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.INDEMNIFICATION__POLITICAL_RISK_COVER, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.BONUS_MALUS__PREMIUM_RETURN_REQUEST_BUTTON, false);
    setVisibilityForMehibMLT(PolicyContractViewSection.PREMIUM__PREMIUM_INSTALMENTS_DISABLED, false);
    setVisibilityForMehibMLT(PolicyContractViewSection.INDEMNIFICATION__INSURED_AMOUNT_CURRENCY, true);
    setVisibilityForMehibMLT(PolicyContractViewSection.TECHNICAL_DETAILS__REINSURANCE_PREMIUMS, true);

    setVisibilityForAllMehib(PolicyContractViewSection.POLICY_CONTRACT_DATA__CURRENCIES_RESTRICTED_MEHIB_DEFAULT, true);
    setVisibilityForAllMehib(PolicyContractViewSection.POLICY_CONTRACT_DATA__DOMESTIC_TURNOVER, false);
    setVisibilityForAllMehib(PolicyContractViewSection.POLICY_CONTRACT_DATA__EXPORT_TURNOVER, false);
    setVisibilityForAllMehib(PolicyContractViewSection.POLICY_CONTRACT_DATA__IN_ACC_WITH_FL_CONTACT, false);
    setVisibilityForAllMehib(PolicyContractViewSection.POLICY_CONTRACT_DATA__CLIENT_READONLY, true);
    setVisibilityForAllMehib(PolicyContractViewSection.OTHER_CONDITIONS__RUN_OFF, false);
    setVisibilityForAllMehib(PolicyContractViewSection.OTHER_CONDITIONS__POLITICAL_RISK, false);
    setVisibilityForAllMehib(PolicyContractViewSection.TECHNICAL__2ND_COLUMN, false);
    setVisibilityForAllMehib(PolicyContractViewSection.PREMIUM__PREMIUM_ON_SUBINSURED_LEVEL_DISABLED, true);
    setVisibilityForAllMehib(PolicyContractViewSection.CLAUSES_TAB, true);
    setVisibilityForAllMehib(PolicyContractViewSection.POLICY_CONTRACT_DATA__MONITORING, true);
    setVisibilityForAllMehib(PolicyContractViewSection.POLICY_CONTRACT_DATA__NACE_CODE, true);

    this.sectionsVisibility[PolicyContractType.FOREIGN_INVESTMENT_B][PolicyContractViewSection.SCOPE_OF_COVER__POLITICAL_RISK_ONLY] = true;
    this.sectionsVisibility[PolicyContractType.FOREIGN_INVESTMENT_B][PolicyContractViewSection.SCOPE_OF_COVER__POLITICAL_AND_COMMERCIAL_RISK] = false;
    this.sectionsVisibility[PolicyContractType.PRESHIPMENT_RISK_G][PolicyContractViewSection.SCOPE_OF_COVER__PRODUCTION_RISK] = true;
    this.sectionsVisibility[PolicyContractType.PRESHIPMENT_RISK_G][PolicyContractViewSection.SCOPE_OF_COVER__PRODUCTION_RISK] = true;
    this.sectionsVisibility[PolicyContractType.FACTORING_CF][PolicyContractViewSection.SCOPE_OF_COVER__PRODUCTION_RISK] = false;

    this.sectionsVisibility[PolicyContractType.EXPORT_RECEIVABLES_C][PolicyContractViewSection.POLICY_CONTRACT_DATA__GBC] = true;
    this.sectionsVisibility[PolicyContractType.EXPORT_RECEIVABLES_C][PolicyContractViewSection.PREMIUM__NO_LOSS_REFUND] = true;
    this.sectionsVisibility[PolicyContractType.EXPORT_RECEIVABLES_C][PolicyContractViewSection.PREMIUM__ACQUISITION_DISCOUNT] = true;
    this.sectionsVisibility[PolicyContractType.EXPORT_RECEIVABLES_C][PolicyContractViewSection.PREMIUM__WHOLE_TURNOVER_REFUND] = true;
    this.sectionsVisibility[PolicyContractType.EXPORT_RECEIVABLES_C][PolicyContractViewSection.PREMIUM__LIMITED_LIABILITY_DISCOUNT] = true;
    this.sectionsVisibility[PolicyContractType.EXPORT_RECEIVABLES_C][PolicyContractViewSection.BONUS_MALUS__PREMIUM_RETURN_REQUEST_BUTTON] = true;
    this.sectionsVisibility[PolicyContractType.FACTORING_CF][PolicyContractViewSection.BONUS_MALUS__PREMIUM_RETURN_REQUEST_BUTTON] = false;

    // Cessions, POLICY CONTRACT PREVIEW
    this.sectionsVisibility[PolicyContractType.FACTORING_CF][PolicyContractViewSection.POLICY_CONTRACT_DATA__CESSION] = false;
    this.sectionsVisibility[PolicyContractType.BUYER_CREDIT_V][PolicyContractViewSection.POLICY_CONTRACT_DATA__CESSION] = false;
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_DISCOUNTING_KV][PolicyContractViewSection.POLICY_CONTRACT_DATA__CESSION] = false;
    this.sectionsVisibility[PolicyContractType.TIED_AID_TAI][PolicyContractViewSection.POLICY_CONTRACT_DATA__CESSION] = false;

    //  Cessions, Technical Details
    this.sectionsVisibility[PolicyContractType.FACTORING_CF][PolicyContractViewSection.TECHNICAL_DETAILS__CESSION] = false;
    this.sectionsVisibility[PolicyContractType.BUYER_CREDIT_V][PolicyContractViewSection.TECHNICAL_DETAILS__CESSION] = false;
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_DISCOUNTING_KV][PolicyContractViewSection.TECHNICAL_DETAILS__CESSION] = false;
    this.sectionsVisibility[PolicyContractType.TIED_AID_TAI][PolicyContractViewSection.TECHNICAL_DETAILS__CESSION] = false;

    this.sectionsVisibility[PolicyContractType.BUYER_CREDIT_V][PolicyContractViewSection.INSUREDS__SUBINSURED_SHARE] = true;
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_DISCOUNTING_KV][PolicyContractViewSection.INSUREDS__SUBINSURED_SHARE] = true;
    this.sectionsVisibility[PolicyContractType.TIED_AID_TAI][PolicyContractViewSection.INSUREDS__SUBINSURED_SHARE] = true;

    this.sectionsVisibility[PolicyContractType.BUYER_CREDIT_V][PolicyContractViewSection.CONTRACT_TERMINATION_TACIT_RENEWAL_EDITABLE] = false;
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_S][PolicyContractViewSection.CONTRACT_TERMINATION_TACIT_RENEWAL_EDITABLE] = false;
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_DISCOUNTING_KV][PolicyContractViewSection.CONTRACT_TERMINATION_TACIT_RENEWAL_EDITABLE] = false;
    this.sectionsVisibility[PolicyContractType.TIED_AID_TAI][PolicyContractViewSection.CONTRACT_TERMINATION_TACIT_RENEWAL_EDITABLE] = false;
    this.sectionsVisibility[PolicyContractType.PRESHIPMENT_RISK_G][PolicyContractViewSection.CONTRACT_TERMINATION_TACIT_RENEWAL_EDITABLE] = false;

    this.sectionsVisibility[PolicyContractType.BUYER_CREDIT_V][PolicyContractViewSection.BONUS_MALUS__ADDITIONAL_PREMIUM_BUTTON] = false;
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_S][PolicyContractViewSection.BONUS_MALUS__ADDITIONAL_PREMIUM_BUTTON] = false;
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_DISCOUNTING_KV][PolicyContractViewSection.BONUS_MALUS__ADDITIONAL_PREMIUM_BUTTON] = false;
    this.sectionsVisibility[PolicyContractType.FOREIGN_INVESTMENT_B][PolicyContractViewSection.BONUS_MALUS__ADDITIONAL_PREMIUM_BUTTON] = false;
    this.sectionsVisibility[PolicyContractType.PRESHIPMENT_RISK_G][PolicyContractViewSection.BONUS_MALUS__ADDITIONAL_PREMIUM_BUTTON] = false;

    this.sectionsVisibility[PolicyContractType.BUYER_CREDIT_V][PolicyContractViewSection.INDEMNIFICATION__COMMERCIAL_RISK_COVER] = true;
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_S][PolicyContractViewSection.INDEMNIFICATION__COMMERCIAL_RISK_COVER] = true;
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_DISCOUNTING_KV][PolicyContractViewSection.INDEMNIFICATION__COMMERCIAL_RISK_COVER] = true;
    this.sectionsVisibility[PolicyContractType.TIED_AID_TAI][PolicyContractViewSection.INDEMNIFICATION__COMMERCIAL_RISK_COVER] = true;
    this.sectionsVisibility[PolicyContractType.PRESHIPMENT_RISK_G][PolicyContractViewSection.INDEMNIFICATION__COMMERCIAL_RISK_COVER] = true;
    this.sectionsVisibility[PolicyContractType.FACTORING_CF][PolicyContractViewSection.INDEMNIFICATION__LIMITED_LIABILITY] = false;

    this.sectionsVisibility[PolicyContractType.BUYER_CREDIT_V][PolicyContractViewSection.POLICY_CONTRACT_DATA__DOMESTIC_CONTENT] = true;
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_S][PolicyContractViewSection.POLICY_CONTRACT_DATA__DOMESTIC_CONTENT] = true;
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_DISCOUNTING_KV][PolicyContractViewSection.POLICY_CONTRACT_DATA__DOMESTIC_CONTENT] = true;
    this.sectionsVisibility[PolicyContractType.TIED_AID_TAI][PolicyContractViewSection.POLICY_CONTRACT_DATA__DOMESTIC_CONTENT] = true;
    this.sectionsVisibility[PolicyContractType.PRESHIPMENT_RISK_G][PolicyContractViewSection.POLICY_CONTRACT_DATA__DOMESTIC_CONTENT] = true;

    this.sectionsVisibility[PolicyContractType.BUYER_CREDIT_V][PolicyContractViewSection.NEW_POLICY_YEAR_BUTTON] = false;
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_S][PolicyContractViewSection.NEW_POLICY_YEAR_BUTTON] = false;
    this.sectionsVisibility[PolicyContractType.SUPPLIER_CREDIT_DISCOUNTING_KV][PolicyContractViewSection.NEW_POLICY_YEAR_BUTTON] = false;
    this.sectionsVisibility[PolicyContractType.TIED_AID_TAI][PolicyContractViewSection.NEW_POLICY_YEAR_BUTTON] = false;
    this.sectionsVisibility[PolicyContractType.PRESHIPMENT_RISK_G][PolicyContractViewSection.NEW_POLICY_YEAR_BUTTON] = false;

    const setVisibilityForMehibStInquiry = (sectionId: PolicyContractViewSection, visible) => {
      this.sectionsVisibility[InquiryProductType.EXPORT_RECEIVABLES_C][sectionId] = visible;
      this.sectionsVisibility[InquiryProductType.FACTORING_CF][sectionId] = visible;
    };

    const setVisibilityForMehibMltInquiry = (sectionId: PolicyContractViewSection, visible) => {
      this.sectionsVisibility[InquiryProductType.BUYER_CREDIT_V][sectionId] = visible;
      this.sectionsVisibility[InquiryProductType.SUPPLIER_CREDIT_S][sectionId] = visible;
      this.sectionsVisibility[InquiryProductType.SUPPLIER_CREDIT_DISCOUNTING_KV][sectionId] = visible;
      this.sectionsVisibility[InquiryProductType.FOREIGN_INVESTMENT_B][sectionId] = visible;
      this.sectionsVisibility[InquiryProductType.TIED_AID_TAI][sectionId] = visible;
      this.sectionsVisibility[InquiryProductType.PRESHIPMENT_RISK_G][sectionId] = visible;
    };

    const setVisibilityForAllMehibInquiry = (sectionId: PolicyContractViewSection, visible) => {
      setVisibilityForMehibStInquiry(sectionId, visible);
      setVisibilityForMehibMltInquiry(sectionId, visible);
    };

    setVisibilityForMehibStInquiry(PolicyContractViewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES, true);
    setVisibilityForMehibStInquiry(PolicyContractViewSection.LIMIT_FEES__LUMP_SUM, false);
    setVisibilityForMehibStInquiry(PolicyContractViewSection.LIMIT_FEES__CURRENCY, true);
    setVisibilityForMehibStInquiry(PolicyContractViewSection.OFFER__INSURANCE_COVER_MEHIB, true);

    setVisibilityForMehibMltInquiry(PolicyContractViewSection.OFFER__GBC, true);
    setVisibilityForMehibMltInquiry(PolicyContractViewSection.OFFER__MEHIB_CALCULATOR, true);
    setVisibilityForMehibMltInquiry(PolicyContractViewSection.OFFER__GLOBAL_LOL_DISABLED, true);
    setVisibilityForMehibMltInquiry(PolicyContractViewSection.OFFER__INVOICED_PREMIUM_DISABLED, true);
    setVisibilityForMehibMltInquiry(PolicyContractViewSection.OFFER__MLT, true);
    setVisibilityForMehibMltInquiry(PolicyContractViewSection.OFFER__INSURANCE_COVER_MEHIB, false);

    setVisibilityForAllMehibInquiry(PolicyContractViewSection.OFFER__GLOBAL_LOL, false);
    setVisibilityForAllMehibInquiry(PolicyContractViewSection.OFFER__BLOG_OFFER, false);
    setVisibilityForAllMehibInquiry(PolicyContractViewSection.OFFER__AAD, false);
    setVisibilityForAllMehibInquiry(PolicyContractViewSection.LIMIT_FEES__BIG_FARMER, false);

    this.sectionsVisibility[InquiryProductType.EXPORT_RECEIVABLES_C][PolicyContractViewSection.OFFER__NO_LOSS_REFUND__ACQUISITION_DISCOUNT__PRODUCTION_RISK] = true;
    this.sectionsVisibility[InquiryProductType.EXPORT_RECEIVABLES_C][PolicyContractViewSection.OFFER__WHOLE_TURNOVER_REFUND] = true;
    this.sectionsVisibility[InquiryProductType.EXPORT_RECEIVABLES_C][PolicyContractViewSection.OFFER__LIMITED_LIABILITY] = true;
    this.sectionsVisibility[InquiryProductType.EXPORT_RECEIVABLES_C][PolicyContractViewSection.OFFER__NO_LOSS_REFUND__ACQUISITION_DISCOUNT__PRODUCTION_RISK] = true;

    this.sectionsVisibility[InquiryProductType.FOREIGN_INVESTMENT_B][PolicyContractViewSection.OFFER__COMMERCIAL_RISK_COVER] = false;
    this.sectionsVisibility[InquiryProductType.FOREIGN_INVESTMENT_B][PolicyContractViewSection.OFFER__REPAYMENT_PERIOD] = false;
    this.sectionsVisibility[InquiryProductType.FOREIGN_INVESTMENT_B][PolicyContractViewSection.OFFER__BUYER_RISK] = false;

    this.sectionsVisibility[InquiryProductType.PRESHIPMENT_RISK_G][PolicyContractViewSection.OFFER__REPAYMENT_PERIOD] = false;
    this.sectionsVisibility[InquiryProductType.PRESHIPMENT_RISK_G][PolicyContractViewSection.OFFER__MANUFACTURING_PERIOD] = true;

    this.sectionsVisibility[InquiryProductType.BUYER_CREDIT_V][PolicyContractViewSection.OFFER__VSKV_TA] = true;
    this.sectionsVisibility[InquiryProductType.SUPPLIER_CREDIT_S][PolicyContractViewSection.OFFER__VSKV_TA] = true;
    this.sectionsVisibility[InquiryProductType.SUPPLIER_CREDIT_DISCOUNTING_KV][PolicyContractViewSection.OFFER__VSKV_TA] = true;
    this.sectionsVisibility[InquiryProductType.TIED_AID_TAI][PolicyContractViewSection.OFFER__VSKV_TA] = true;


    // -------------------------------------------------------------------------------------------------------------------------------------
    // ---- ECG ----------------------------------------------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------------------------------------------------------

    const setVisibilityForEcg = (sectionId: PolicyContractViewSection, visible) => {
      this.sectionsVisibility[PolicyContractType.GLOBAL_RISK][sectionId] = visible;
      this.sectionsVisibility[PolicyContractType.SELECTIVE_RISK][sectionId] = visible;
      this.sectionsVisibility[PolicyContractType.ECG_TOP_UP][sectionId] = visible;
    };
    const setVisibilityForEcgInquiry = (sectionId: PolicyContractViewSection, visible) => {
      this.sectionsVisibility[InquiryProductType.GLOBAL_RISK][sectionId] = visible;
      this.sectionsVisibility[InquiryProductType.SELECTIVE_RISK][sectionId] = visible;
      this.sectionsVisibility[InquiryProductType.ECG_TOP_UP][sectionId] = visible;
    };

    setVisibilityForEcg(PolicyContractViewSection.LIMIT_FEES__BIG_FARMER, false);
    setVisibilityForEcg(PolicyContractViewSection.LIMIT_FEES__NO_INVOICE_FOR_REF, true);
    setVisibilityForEcg(PolicyContractViewSection.TURNOVER_REPORTING__KUKE_AMENDMENTS_NON_STANDARD, false);
    setVisibilityForEcg(PolicyContractViewSection.INDEMNIFICATION__GLOBAL_LOL, false);
    setVisibilityForEcg(PolicyContractViewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES, true);
    setVisibilityForEcg(PolicyContractViewSection.INDEMNIFICATION__NQL, true);
    setVisibilityForEcg(PolicyContractViewSection.INDEMNIFICATION__EE, true);
    setVisibilityForEcg(PolicyContractViewSection.TECHNICAL__2ND_COLUMN, false);
    setVisibilityForEcg(PolicyContractViewSection.CLAUSES_TAB, true);
    setVisibilityForEcg(PolicyContractViewSection.SALESFORCE_ID, true);
    setVisibilityForEcg(PolicyContractViewSection.NEW_LIMIT_BUTTON, true);
    setVisibilityForEcg(PolicyContractViewSection.INQUIRIES_TAB, true);

    this.sectionsVisibility[PolicyContractType.ECG_TOP_UP][PolicyContractViewSection.INDEMNIFICATION__AAD] = true;
    this.sectionsVisibility[PolicyContractType.GLOBAL_RISK][PolicyContractViewSection.TURNOVER_REPORTING] = true;
    this.sectionsVisibility[PolicyContractType.SELECTIVE_RISK][PolicyContractViewSection.TURNOVER_REPORTING] = true;
    this.sectionsVisibility[PolicyContractType.ECG_TOP_UP][PolicyContractViewSection.SCOPE_OF_COVER__FL] = true;

    setVisibilityForEcgInquiry(PolicyContractViewSection.OFFER__DISCRETIONARY_COVER_BONUS_MALUS, true);
    setVisibilityForEcgInquiry(PolicyContractViewSection.OFFER__GLOBAL_LOL_TYPE, true);
    setVisibilityForEcgInquiry(PolicyContractViewSection.OFFER__INSURANCE_COVER, true);
    setVisibilityForEcgInquiry(PolicyContractViewSection.OFFER__ECG_CALCULATOR, true);
    setVisibilityForEcgInquiry(PolicyContractViewSection.OFFER__MAX_PAYMENT_DATE, true);
    setVisibilityForEcgInquiry(PolicyContractViewSection.OFFER__DECLARED_TURNOVER, true);
    setVisibilityForEcgInquiry(PolicyContractViewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES, true);
    setVisibilityForEcgInquiry(PolicyContractViewSection.LIMIT_FEES__BIG_FARMER, false);

    this.sectionsVisibility[InquiryProductType.GLOBAL_RISK][PolicyContractViewSection.OFFER__AAD] = false;
    this.sectionsVisibility[InquiryProductType.SELECTIVE_RISK][PolicyContractViewSection.OFFER__AAD] = false;
    this.sectionsVisibility[InquiryProductType.GLOBAL_RISK][PolicyContractViewSection.OFFER__DISCRETIONARY_COVER] = true;
    this.sectionsVisibility[InquiryProductType.SELECTIVE_RISK][PolicyContractViewSection.OFFER__DISCRETIONARY_COVER] = true;
    this.sectionsVisibility[InquiryProductType.GLOBAL_RISK][PolicyContractViewSection.POLICY_CONTRACT_DATA__IN_ACC_WITH_FL_CONTACT] = false;
    this.sectionsVisibility[InquiryProductType.SELECTIVE_RISK][PolicyContractViewSection.POLICY_CONTRACT_DATA__IN_ACC_WITH_FL_CONTACT] = false;

    // -------------------------------------------------------------------------------------------------------------------------------------
    // ---- KUKE -- KUKE -- KUKE -- KUKE -- KUKE -- KUKE -- KUKE -- KUKE -- KUKE -- KUKE -- KUKE -- KUKE -- KUKE -- KUKE -- KUKE -- TABS ---
    // -------------------------------------------------------------------------------------------------------------------------------------

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.POLICY_CONTRACT_DATA__IN_ACC_WITH_FL_CONTACT] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.POLICY_CONTRACT_DATA__OPTION] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.CREDIT_LIMITS__NEG_LIMIT_DEC_VAL_DAYS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.CREDIT_LIMITS__REST] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.OTHER_CONDITIONS__REST] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.OTHER_CONDITIONS__INSURANCE_THRESHOLD] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.BONUS_MALUS__REST] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.RISK_ASSESSMENT] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.TECHNICAL__2ND_COLUMN] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.TECHNICAL_DETAILS__KUKE_CROSSBORDER] = true;


    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.CONTRACT_TERMINATION] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.CREDIT_LIMITS__NEG_LIMIT_DEC_VAL_DAYS] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.CREDIT_LIMITS__REST] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.OTHER_CONDITIONS__INSURANCE_THRESHOLD] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.OTHER_CONDITIONS__REST] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.POLICY_CONTRACT_DATA__OPTION] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.POLICY_CONTRACT_DATA__IN_ACC_WITH_FL_CONTACT] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.PREMIUM__PREMIUM_INSTALMENTS_DISABLED] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.RISK_ASSESSMENT] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.SPECIAL_CLAUSES] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.INSUREDS__SUBINSURED] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.TECHNICAL__2ND_COLUMN] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.BONUS_MALUS__REST] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.OTHER_CONDITIONS__POLITICAL_RISK] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.BONUS_MALUS__ADDITIONAL_PREMIUM_BUTTON] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.BONUS_MALUS__PREMIUM_RETURN_REQUEST_BUTTON] = false;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.POLICY_CONTRACT_DATA__IN_ACC_WITH_FL_CONTACT] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.POLICY_CONTRACT_DATA__OPTION] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.CREDIT_LIMITS__NEG_LIMIT_DEC_VAL_DAYS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.CREDIT_LIMITS__REST] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.OTHER_CONDITIONS__REST] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.OTHER_CONDITIONS__INSURANCE_THRESHOLD] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.BONUS_MALUS__REST] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.RISK_ASSESSMENT] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.TECHNICAL__2ND_COLUMN] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.INSUREDS__SUBINSURED] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.SPECIAL_CLAUSES] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.TECHNICAL_DETAILS__CESSION] = true;


    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.POLICY_CONTRACT_DATA__IN_ACC_WITH_FL_CONTACT] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.POLICY_CONTRACT_DATA__OPTION] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.CREDIT_LIMITS__NEG_LIMIT_DEC_VAL_DAYS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.CREDIT_LIMITS__REST] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.OTHER_CONDITIONS__INSURANCE_THRESHOLD] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.OTHER_CONDITIONS__REST] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.BONUS_MALUS__REST] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.TECHNICAL__2ND_COLUMN] = false;


    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.POLICY_CONTRACT_DATA__IN_ACC_WITH_FL_CONTACT] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.POLICY_CONTRACT_DATA__OPTION] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.CREDIT_LIMITS__NEG_LIMIT_DEC_VAL_DAYS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.CREDIT_LIMITS__REST] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.OTHER_CONDITIONS__INSURANCE_THRESHOLD] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.OTHER_CONDITIONS__REST] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.BONUS_MALUS__REST] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.TECHNICAL__2ND_COLUMN] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.TECHNICAL_DETAILS__KUKE_CROSSBORDER] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.POLICY_CONTRACT_DATA__IN_ACC_WITH_FL_CONTACT] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.POLICY_CONTRACT_DATA__OPTION] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.CREDIT_LIMITS__NEG_LIMIT_DEC_VAL_DAYS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.CREDIT_LIMITS__REST] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.OTHER_CONDITIONS__INSURANCE_THRESHOLD] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.OTHER_CONDITIONS__REST] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.BONUS_MALUS__ADDITIONAL_PREMIUM_BUTTON] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.BONUS_MALUS__PREMIUM_RETURN_REQUEST_BUTTON] = false;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.POLICY_CONTRACT_DATA__KUKE_EXTERNAL_NUMBER] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.POLICY_CONTRACT_DATA__KUKE_EXTERNAL_NUMBER] = true;
    // this.sectionsVisibility[PolicyContractType.KUKE_KOM_PB][PolicyContractViewSection.POLICY_CONTRACT_DATA__KUKE_EXTERNAL_NUMBER] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.POLICY_CONTRACT_DATA__KUKE_EXTERNAL_NUMBER] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.POLICY_CONTRACT_DATA__KUKE_EXTERNAL_NUMBER] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.POLICY_CONTRACT_DATA__KUKE_EXTERNAL_NUMBER] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.PREMIUM__KUKE_TARIFF_RATE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.PREMIUM__KUKE_TARIFF_RATE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.PREMIUM__KUKE_TARIFF_RATE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.PREMIUM__KUKE_TARIFF_RATE] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.PREMIUM__KUKE_NN_RATE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.PREMIUM__KUKE_NN_RATE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.PREMIUM__KUKE_NN_RATE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.PREMIUM__KUKE_NN_RATE] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.PREMIUM__KUKE_COVER_PLUS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.PREMIUM__KUKE_COVER_PLUS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.PREMIUM__KUKE_COVER_PLUS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.PREMIUM__KUKE_COVER_PLUS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.PREMIUM__KUKE_COVER_PLUS] = true;


    // -------------------------------------------------------------------------------------------------------------------------------------
    // ---- TABS -- TABS -- TABS -- TABS -- TABS -- TABS -- TABS -- TABS -- TABS -- TABS -- TABS -- TABS -- TABS -- TABS -- TABS -- TABS ---
    // -------------------------------------------------------------------------------------------------------------------------------------

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.CLAUSES_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.CLAUSES_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.CLAUSES_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.CLAUSES_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.CLAUSES_TAB] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.PREVIEW_INSUREDS_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.PREVIEW_INSUREDS_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.PREVIEW_INSUREDS_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.PREVIEW_INSUREDS_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.PREVIEW_INSUREDS_TAB] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.COVERABLE_DESCRIPTION] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.COVERABLE_DESCRIPTION] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.COVERABLE_DESCRIPTION] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.INDIVIDUAL_CLAUSES_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.INDIVIDUAL_CLAUSES_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.INDIVIDUAL_CLAUSES_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.INDIVIDUAL_CLAUSES_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.INDIVIDUAL_CLAUSES_TAB] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.STATEMENTS_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.STATEMENTS_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.STATEMENTS_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.STATEMENTS_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.STATEMENTS_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.STATEMENTS_TAB] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.LIMITLIST_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.LIMITLIST_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.LIMITLIST_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.LIMITLIST_TAB] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.LIMITLIST_TAB] = true;


    // -------------------------------------------------------------------------------------------------------------------------------------
    // ---- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -----
    // -------------------------------------------------------------------------------------------------------------------------------------

    // common data
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.POLICY_CONTRACT_DATA__DOMESTIC_TURNOVER] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.POLICY_CONTRACT_DATA__EXPORT_TURNOVER] = false;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.POLICY_CONTRACT_DATA__DOMESTIC_TURNOVER_REQUIRED] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.POLICY_CONTRACT_DATA__EXPORT_TURNOVER_REQUIRED] = false;

    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.POLICY_CONTRACT_DATA__DOMESTIC_TURNOVER_REQUIRED] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.POLICY_CONTRACT_DATA__EXPORT_TURNOVER_REQUIRED] = false;


    // turnover reporting
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.TURNOVER_REPORTING__KUKE_AMENDMENTS_NON_STANDARD] = false;

    // premium
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.PREMIUM__PREMIUM_RATE] = false;


    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.PREMIUM__KUKE_PAYMENT_METHOD] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.PREMIUM__KUKE_PAYMENT_METHOD] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.PREMIUM__KUKE_PAYMENT_METHOD] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.PREMIUM__KUKE_PAYMENT_METHOD] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.PREMIUM__KUKE_PAYMENT_METHOD] = true;


    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.POLICY_CONTRACT_DATA__COVERABLE_DESCRIPTION] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.POLICY_CONTRACT_DATA__COVERABLE_DESCRIPTION] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.POLICY_CONTRACT_DATA__COVERABLE_DESCRIPTION] = false;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.PREMIUM__KUKE_SUBINSURED_LEVEL] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.PREMIUM__KUKE_SUBINSURED_LEVEL] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.PREMIUM__KUKE_SUBINSURED_LEVEL] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.PREMIUM__KUKE_SUBINSURED_LEVEL] = true;

    // indemnification
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.INDEMNIFICATION__LOL_X] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.INDEMNIFICATION__LOL_X] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.INDEMNIFICATION__LOL_X] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.INDEMNIFICATION__LOL_X] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.INDEMNIFICATION__LOL_X_REQUIRED] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.INDEMNIFICATION__LOL_X_REQUIRED] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.INDEMNIFICATION__LOL_X_REQUIRED] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.INDEMNIFICATION__LOL_X_REQUIRED] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.INDEMNIFICATION__KUKE_GLOBAL_LOLS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.INDEMNIFICATION__KUKE_GLOBAL_LOLS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.INDEMNIFICATION__KUKE_GLOBAL_LOLS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.INDEMNIFICATION__KUKE_GLOBAL_LOLS] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.INDEMNIFICATION__KUKE_GLOBAL_LOL_REQUIRED] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.INDEMNIFICATION__KUKE_GLOBAL_LOL_REQUIRED] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.INDEMNIFICATION__KUKE_GLOBAL_LOL_REQUIRED] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.INDEMNIFICATION__KUKE_GLOBAL_LOL_REQUIRED] = false;


    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.PREMIUM__PREMIUM_FIXED] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.PREMIUM__PREMIUM_FIXED] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.PREMIUM__PREMIUM_FIXED] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.PREMIUM__PREMIUM_FIXED] = false;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.INDEMNIFICATION__NQL] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.INDEMNIFICATION__NQL] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.INDEMNIFICATION__NQL] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.INDEMNIFICATION__NQL] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.INDEMNIFICATION__NQL] = true;


    // fees
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.LIMIT_FEES__KUKE_COUNTRY_INVOICING_TYPE_REQUIRED] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.LIMIT_FEES__KUKE_COUNTRY_INVOICING_TYPE_REQUIRED] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.LIMIT_FEES__KUKE_COUNTRY_INVOICING_TYPE_REQUIRED] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.LIMIT_FEES__KUKE_COUNTRY_INVOICING_TYPE_REQUIRED] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.LIMIT_FEES__BIG_FARMER] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.LIMIT_FEES__BIG_FARMER] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.LIMIT_FEES__BIG_FARMER] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.LIMIT_FEES__BIG_FARMER] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.LIMIT_FEES__BIG_FARMER] = false;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.LIMIT_FEES__NO_INVOICE_FOR_REF] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.LIMIT_FEES__NO_INVOICE_FOR_REF] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.LIMIT_FEES__NO_INVOICE_FOR_REF] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.LIMIT_FEES__NO_INVOICE_FOR_REF] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.LIMIT_FEES__KUKE_FALCON_FEE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.LIMIT_FEES__KUKE_FALCON_FEE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.LIMIT_FEES__KUKE_FALCON_FEE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.LIMIT_FEES__KUKE_FALCON_FEE] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.PREMIUM__MIN_PREMIUM_FIXED] = false;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES] = true;

    // countries
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.COUNTRIES__KUKE_EDITABLE] = false;

    // -------------------------------------------------------------------------------------------------------------------------------------
    // ---- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -----
    // -------------------------------------------------------------------------------------------------------------------------------------

    // turnover reporting
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.TURNOVER_REPORTING__KUKE_AMENDMENTS_NON_STANDARD] = false;
    // premium
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.PREMIUM__PREMIUM_FIXED] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.PREMIUM__FIRST_INSTALLMENT_DATE] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.PREMIUM__NO_OF_INSTALLMENTS] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.PREMIUM__KUKE_SUBINSURED_LEVEL] = true;


    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.PREMIUM__MIN_PREMIUM_FIXED] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.LIMIT_FEES__BIG_FARMER] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.PREMIUM__KUKE_FIXED_MIN_PREMIUMS] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.PREMIUM__KUKE_PRODUCTION_RISK] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.POLICY_CONTRACT_DATA__DOMESTIC_TURNOVER] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.POLICY_CONTRACT_DATA__EXPORT_TURNOVER] = false;

    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.PREMIUM__PREMIUM_RATE] = false;

    // -------------------------------------------------------------------------------------------------------------------------------------
    // ----- INQUIRIESS -- INQUIRIESS -- INQUIRIESS -- INQUIRIESS -- INQUIRIESS -- INQUIRIESS -- INQUIRIESS -- INQUIRIESS -- INQUIRIESS ----
    // ----- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM -- KOM  -- KOM -- KOM -- KOM --KOM -- KOM -- KOM --KOM -- KOM -- KOM -----
    // -------------------------------------------------------------------------------------------------------------------------------------

    this.sectionsVisibility[InquiryProductType.KUKE_KOM_OP] = [];
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_FK] = [];
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_FE] = [];
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_SP] = [];

    // fees
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_OP][PolicyContractViewSection.LIMIT_FEES__KUKE_LUMP_SUM_REQUIRED] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_FK][PolicyContractViewSection.LIMIT_FEES__KUKE_LUMP_SUM_REQUIRED] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_FE][PolicyContractViewSection.LIMIT_FEES__KUKE_LUMP_SUM_REQUIRED] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_SP][PolicyContractViewSection.LIMIT_FEES__KUKE_LUMP_SUM_REQUIRED] = true;

    this.sectionsVisibility[InquiryProductType.KUKE_KOM_OP][PolicyContractViewSection.LIMIT_FEES__NO_INVOICE_FOR_REF] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_FK][PolicyContractViewSection.LIMIT_FEES__NO_INVOICE_FOR_REF] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_FE][PolicyContractViewSection.LIMIT_FEES__NO_INVOICE_FOR_REF] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_SP][PolicyContractViewSection.LIMIT_FEES__NO_INVOICE_FOR_REF] = true;

    this.sectionsVisibility[InquiryProductType.KUKE_KOM_OP][PolicyContractViewSection.LIMIT_FEES__KUKE_COUNTRY_INVOICING_TYPE_REQUIRED] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_FK][PolicyContractViewSection.LIMIT_FEES__KUKE_COUNTRY_INVOICING_TYPE_REQUIRED] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_FE][PolicyContractViewSection.LIMIT_FEES__KUKE_COUNTRY_INVOICING_TYPE_REQUIRED] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_SP][PolicyContractViewSection.LIMIT_FEES__KUKE_COUNTRY_INVOICING_TYPE_REQUIRED] = true;

    this.sectionsVisibility[InquiryProductType.KUKE_KOM_OP][PolicyContractViewSection.LIMIT_FEES__KUKE_FALCON_FEE] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_FK][PolicyContractViewSection.LIMIT_FEES__KUKE_FALCON_FEE] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_FE][PolicyContractViewSection.LIMIT_FEES__KUKE_FALCON_FEE] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_SP][PolicyContractViewSection.LIMIT_FEES__KUKE_FALCON_FEE] = true;

    this.sectionsVisibility[InquiryProductType.KUKE_KOM_OP][PolicyContractViewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_FK][PolicyContractViewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_FE][PolicyContractViewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES] = true;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_SP][PolicyContractViewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES] = true;

    this.sectionsVisibility[InquiryProductType.KUKE_KOM_FE][PolicyContractViewSection.LIMIT_FEES__BIG_FARMER] = false;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_FK][PolicyContractViewSection.LIMIT_FEES__BIG_FARMER] = false;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_OP][PolicyContractViewSection.LIMIT_FEES__BIG_FARMER] = false;
    this.sectionsVisibility[InquiryProductType.KUKE_KOM_SP][PolicyContractViewSection.LIMIT_FEES__BIG_FARMER] = false;

    // -------------------------------------------------------------------------------------------------------------------------------------
    // ----- INQUIRIESS -- INQUIRIESS -- INQUIRIESS -- INQUIRIESS -- INQUIRIESS -- INQUIRIESS -- INQUIRIESS -- INQUIRIESS -- INQUIRIESS ----
    // ----- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP -- GSP  -- GSP -- GSP -- GSP --GSP -- GSP -- GSP --GSP -- GSP -- GSP -----
    // -------------------------------------------------------------------------------------------------------------------------------------
    this.sectionsVisibility[InquiryProductType.KUKE_GSP_PBG] = [];

    // -------------------------------------------------------------------------------------------------------------------------------------
    // ----- KUKE_PORTAL -- KUKE_PORTAL -- KUKE_PORTAL -- KUKE_PORTAL -- KUKE_PORTAL -- KUKE_PORTAL -- KUKE_PORTAL -- KUKE_PORTAL ----------
    // -------------------------------------------------------------------------------------------------------------------------------------

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.PORTAL_PREMIUM__KUKE_MIN_PREMIUM_FIXED] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.PORTAL_PREMIUM__KUKE_MIN_PREMIUM_FIXED] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.PORTAL_PREMIUM__KUKE_MIN_PREMIUM_FIXED] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.PORTAL_PREMIUM__KUKE_MIN_PREMIUM_FIXED] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.PORTAL_PREMIUM__KUKE_PREMIUM_RATE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.PORTAL_PREMIUM__KUKE_PREMIUM_RATE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.PORTAL_PREMIUM__KUKE_PREMIUM_RATE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.PORTAL_PREMIUM__KUKE_PREMIUM_RATE] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.PORTAL_PREMIUM__KUKE_COVER_PLUS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.PORTAL_PREMIUM__KUKE_COVER_PLUS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.PORTAL_PREMIUM__KUKE_COVER_PLUS] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.PORTAL_PREMIUM__KUKE_COVER_PLUS] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.PORTAL_PREMIUM__KUKE_PREMIUM_FIXED] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_GSP_PBG][PolicyContractViewSection.PORTAL_PREMIUM__KUKE_FIXED_MIN_PREMIUMS] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.PORTAL_PREMIUM_INSTALEMENT_CALENDAR__KUKE] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.PORTAL_PREMIUM_INSTALEMENT_CALENDAR__KUKE] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.PORTAL_PREMIUM_INSTALEMENT_CALENDAR__KUKE] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.PORTAL_PREMIUM_INSTALEMENT_CALENDAR__KUKE] = false;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.PORTAL_PREMIUM_INSTALEMENT_CALENDAR__KUKE] = false;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.PORTAL_INDEMNIFICATION__KUKE_NQL] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.PORTAL_INDEMNIFICATION__KUKE_NQL] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.PORTAL_INDEMNIFICATION__KUKE_NQL] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.PORTAL_INDEMNIFICATION__KUKE_NQL] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_EP][PolicyContractViewSection.PORTAL_INDEMNIFICATION__KUKE_NQL] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.PORTAL_REPORTING_PERIODS__KUKE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.PORTAL_REPORTING_PERIODS__KUKE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.PORTAL_REPORTING_PERIODS__KUKE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.PORTAL_REPORTING_PERIODS__KUKE] = true;

    this.sectionsVisibility[PolicyContractType.KUKE_KOM_OP][PolicyContractViewSection.PORTAL_SUBINSUREDS__KUKE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_SP][PolicyContractViewSection.PORTAL_SUBINSUREDS__KUKE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FK][PolicyContractViewSection.PORTAL_SUBINSUREDS__KUKE] = true;
    this.sectionsVisibility[PolicyContractType.KUKE_KOM_FE][PolicyContractViewSection.PORTAL_SUBINSUREDS__KUKE] = true;

  }

  public isSectionVisible(
    typeId: number,
    policyContractViewSection: PolicyContractViewSection,
    defaultVisibility: boolean,
    globalConditionsOfInsuranceId = null
  ) {
    if (
      (policyContractViewSection === PolicyContractViewSection.COVERABLE_DESCRIPTION ||
        policyContractViewSection === PolicyContractViewSection.POLICY_CONTRACT_DATA__COVERABLE_DESCRIPTION) &&
      globalConditionsOfInsuranceId &&
      this.isKukeOP2016(typeId, globalConditionsOfInsuranceId)
    ) {
      return true;
    }
    const sv =
      this.sectionsVisibility[typeId] != null ? this.sectionsVisibility[typeId][policyContractViewSection] : null;
    return sv != null ? sv : defaultVisibility;
  }

  isKukeOP2016(typeId: number, globalConditionsOfInsuranceId = null) {
    return (
      typeId === PolicyContractType.KUKE_KOM_OP &&
      globalConditionsOfInsuranceId === GlobalConditionsOfInsurance.KUKE_OP_2016_01
    );
  }

  public kukeUnifiedTextAvailable(pcv: PolicyContractVersionDto): boolean {
    return (
      pcv &&
      pcv.id &&
      (pcv?.status?.id === PolicyContractVersionStatus.ACTIVATED ||
        pcv?.status?.id === PolicyContractVersionStatus.TERMINATION_ACTIVATED) &&
      pcv.contractType &&
      [
        PolicyContractType.KUKE_KOM_OP,
        PolicyContractType.KUKE_KOM_SP,
        PolicyContractType.KUKE_KOM_FK,
        PolicyContractType.KUKE_KOM_FE,
      ].includes(pcv.contractType.id)
    );
  }
}
