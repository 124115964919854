import {Component, Input, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ATableComponent} from './a-table.component';
import {UserRange} from '../../services';

/**
 * The component is intended to present in a table a list of simple objects like dictionary entries, strings, numbers ...
 */

@Component({
  selector: 'a-list',
  templateUrl: 'a-list.component.pug',
})
export class AListComponent<T> {
  @ViewChild(ATableComponent, {static: true}) itemTable: ATableComponent<T>;

  @Input() type: 'dictionary' | 'userCombo' | 'userAutocomplete' = 'dictionary';
  @Input() labelKey = 'common.name';
  @Input() deleteButton = false;
  @Input() editable = false;
  @Input() addButton = false;
  @Input() buttonsCellWidth: string;
  @Input() formModel: UntypedFormGroup;
  @Input() showAllErrors = false;
  @Input() parentDictionaryEntryId: number;
  @Input() parentDictionary: string;
  @Input() dictionary: string;
  @Input() unique = true;
  @Input() tooltipProperty: string;
  @Input() userRange: UserRange = 'intranet';

  @Input() set dictHiddenIds(ids: Set<number>) {
    this._dictHiddenIds = ids;
    setTimeout(() => this.itemTable.updateHiddenDictItems());
  }

  wrappedItems: WrappedItem<T>[];
  _items: T[];
  _dictHiddenIds: Set<number>;

  @Input() set items(items: T[]) {
    this._items = items;
    this.wrappedItems = items.map((i) => <WrappedItem<T>>{item: i});
  }

  get items() {
    return this._items;
  }

  updateItems() {
    this._items.length = 0;
    this.wrappedItems.forEach((wI) => this._items.push(wI.item));
  }

  get tooltip() {
    return this.tooltipProperty ? 'item.' + this.tooltipProperty : undefined;
  }

  get dictHiddenIds() {
    return this._dictHiddenIds;
  }
}

export class WrappedItem<T> {
  item: T;
}
