import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, Params} from '@angular/router';
import {NgForm} from '@angular/forms';
import {
  AppConfigService,
  BusinessReportService,
  ClaimCalculationBaseDto,
  ClaimCalculationDto,
  ClaimCalculationInvoiceDto,
  ClaimCalculationInvoiceIndemnificationDataDto,
  ClaimDto,
  ClaimService,
  ClaimVersionDto,
  DictionaryBaseDto,
  GrowlService,
  IMultiSelectSettings,
  RouterService,
  StringUtils,
} from '../../bonding_shared';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {ClaimCalculationService} from '../../bonding_shared/services/claim-calculation.service';
import {
  BondType,
  BusinessObjectType,
  ClaimCalculationDecision,
  ClaimCalculationStatus,
  ClaimElementaryRight,
  ClaimRiskType,
} from '../../bonding_shared/model/dictionary-ids';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';
import {ReportDefinition} from '../../bonding_shared/model/report-ids';
import * as _ from 'lodash';
import {UserComboComponent} from '../../bonding_shared/components/combos/user-combo.component';

@Component({
  selector: 'claim-calculation',
  templateUrl: 'claim-calculation.component.pug',
})
export class ClaimCalculationComponent extends DetailsView implements OnInit {
  claim = <ClaimDto>{};
  calc = <ClaimCalculationDto>{};
  bomDecisionSelected = false;
  calculations: ClaimCalculationBaseDto[];
  claimVersion: ClaimVersionDto;
  lastClaimVersionId: number;
  editable: boolean;
  last: boolean;
  selectedInvoice: ClaimCalculationInvoiceDto;
  calculationDataEditable: boolean;
  firstApprovalCommentEditable: boolean;
  secondApprovalCommentEditable: boolean;
  selectedInvoiceClaimAmountAfterDeductions: number;
  selectedInvoiceApprovedForPaymentAmountMinusLastGoApproved: number;
  productionCostsSuretyApprovedForPaymentAmountMinusLastGoApproved: number;
  isSelectedInvoiceClaimAmountAfterDeductionsNegative = false;

  readonly claimCalcBusinessObjectTypeId = BusinessObjectType.CLAIM_CALCULATION;
  readonly claimVersionBusinessObjectTypeId = BusinessObjectType.CLAIM_VERSION;
  readonly bondTypeWithSupplementalValue = BondType.NALEZYTE_WYKONANIE_KONTRAKTU_I_USUNIECIE_WAD_I_USTEREK;
  readonly payoutPreparationAvailableStatuses = [
    ClaimCalculationStatus.SENT_FOR_PAYMENT,
    ClaimCalculationStatus.WAITING_FOR_SECOND_APPROVAL,
    ClaimCalculationStatus.REJECTED,
    ClaimCalculationStatus.USER_ERROR,
    ClaimCalculationStatus.GO,
  ];
  readonly ClaimElementaryRight = ClaimElementaryRight;
  readonly ClaimRiskType = ClaimRiskType;

  readonly multiSelectSettings: IMultiSelectSettings = {
    dynamicTitleMaxItems: 99,
    whiteSpace: 'normal',
  };

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  @ViewChild('directedToUserCombo') directedToUserCombo: UserComboComponent;

  public constructor(
    private _route: ActivatedRoute,
    private businessReportService: BusinessReportService,
    protected service: ClaimCalculationService,
    protected claimService: ClaimService,
    public router: RouterService,
    protected growlService: GrowlService,
    private appService: AppConfigService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.newVersionButton.hidden = false;
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
  }

  ngOnInit() {
    this.form = this.ngForm.form;
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  onCreateNewVersion() {
    this.service.getNewVersion(this.calc.claimVersion.id).subscribe({
      next: (x) => this.setCalc(x),
      error: (error) => this.handleServerError(error),
    });
    this.getClaimVersion(this.calc.claimVersion.id);
  }

  initializeView(params: Params) {
    super.initializeView(params);
    this.service.getCalc(+params['calcId']).subscribe({
      next: (x) => {
        this.setCalc(x);
        this.bomDecisionSelected = !!x.bomDecisionNumber;
        this.getCalculations(x.claimVersion.id);
        this.getClaim(x.claim.id);
        this.getClaimVersion(this.calc.claimVersion.id);
        this.refreshIndemnificationSummaryData();
      },
      error: (error) => this.handleServerError(error),
    });
  }

  onSave() {
    this.serverErrors = undefined;
    this.showErrors = false;
    if (!this.bomDecisionSelected) {
      this.calc.bomDecisionNumber = null;
      this.calc.bomDecisionDate = null;
    }
    if (this.formValidates()) {
      this.inProgress = true;
      this.service.saveCalc(this.calc).subscribe({
        next: (saved) => {
          this.afterObjectSaved();
          if (this.calc.id) {
            this.setCalc(saved);
            this.getCalculations(saved.claimVersion.id);
          } else {
            this.router.toClaimCalculation(saved.id);
          }
        },
        error: (error) => this.handleServerError(error),
      });
    }
  }

  onCancel() {
    super.onCancel(this._route);
  }

  getCalculations(claimVersionId: number) {
    this.service.getCalculations(claimVersionId).subscribe((c) => (this.calculations = c));
  }

  getClaimVersion(versionId: number) {
    this.claimService.getClaimVersion(versionId).subscribe((claimVersion) => {
      this.claimVersion = claimVersion;
      this.calculateViewState();
    });
  }

  getClaim(claimId: number) {
    this.claimService.getClaim(claimId).subscribe((c) => {
      this.claim = c;
      this.lastClaimVersionId = _.maxBy(c.versions, (v) => v.versionNumber).id;
      this.calculateViewState();
    });
  }

  setCalc(calc: ClaimCalculationDto) {
    this.calc = calc;
    this.objectNotFound = false;
    this.inProgress = false;
    this.selectedInvoice = undefined;
    this.calculateViewState();
  }

  calculateViewState() {
    this.newVersionButton.hidden = !this.calc.id;
    this.newVersionButton.disabled = !this.isLast();
    this.saveButton.disabled = !this.isEditable();
    this.cancelButton.disabled = !this.isEditable();
    this.editable = this.isEditable();
    // no edition of automatic calc result
    this.calculationDataEditable =
      this.editable && DictionaryUtils.in(this.calc.status, ClaimCalculationStatus.IN_PROGRESS);
    this.secondApprovalCommentEditable =
      this.editable && DictionaryUtils.in(this.calc.status, ClaimCalculationStatus.WAITING_FOR_SECOND_APPROVAL);
    this.firstApprovalCommentEditable =
      this.editable && DictionaryUtils.in(this.calc.status, ClaimCalculationStatus.WAITING_FOR_FIRST_APPROVAL);
    this.recalculateProdCostsSurety();
    if (!!this.directedToUserCombo && this.directedToMustBeSpecified) {
      this.directedToUserCombo.loadUsersUsingCustomFunc();
    }
    this.last = this.isLast();
  }

  isEditable() {
    return !this.calc.id || this.isLast();
  }

  isLast() {
    return this.calc.last && this.calc.claimVersion.id === this.lastClaimVersionId;
  }

  selectInvoiceToEdit(event) {
    this.selectedInvoice = event;
    this.refreshIndemnificationSummaryData();
  }

  refreshIndemnificationSummaryData() {
    if (this.selectedInvoice) {
      const data = this.selectedInvoice.indemnificationData;
      const calculatedClaimAmountAfterDeductions = this.calculateClaimAmountAfterDeductions(data);
      this.selectedInvoiceClaimAmountAfterDeductions = Math.max(calculatedClaimAmountAfterDeductions, 0);
      this.isSelectedInvoiceClaimAmountAfterDeductionsNegative = calculatedClaimAmountAfterDeductions < 0;
      this.selectedInvoiceApprovedForPaymentAmountMinusLastGoApproved =
        data.results.approvedForPaymentAmount - data.lastGoApprovedAmount;
    } else {
      this.selectedInvoiceClaimAmountAfterDeductions = 0;
      this.selectedInvoiceApprovedForPaymentAmountMinusLastGoApproved = 0;
    }
  }

  private calculateClaimAmountAfterDeductions(data: ClaimCalculationInvoiceIndemnificationDataDto): number {
    return (
      data.claimAmount -
      data.userSelection.deductibleDisputeAmount -
      data.userSelection.deductibleDocumentFaultsAmount -
      data.userSelection.deductibleCorrectionAmount -
      data.userSelection.deductibleOtherReasonsAmount -
      data.userSelection.requestedSettlementAmount
    );
  }

  refreshProdCostsCalculationDataSurety() {
    if (this.isProductionRiskClaim || this.isSuretyClaim) {
      this.calc.indemnificationData.settlementCoveredAmount = 0;
      this.calc.indemnificationData.goodwillCoveredAmount = 0;
    }
  }

  private recalculateProdCostsSurety() {
    if (this.isProductionRiskClaim || this.isSuretyClaim) {
      const data = this.calc.indemnificationData;
      this.productionCostsSuretyApprovedForPaymentAmountMinusLastGoApproved =
        data.approvedForPaymentAmount - data.lastGoApprovedAmount;
    }
  }

  get payoutPreparationAvailable(): boolean {
    return this.calc.wasSentForPayment && !DictionaryUtils.in(this.calc.decision, ClaimCalculationDecision.REJECTED);
  }

  get isIndemnificationCreationPossible(): boolean {
    return (
      DictionaryUtils.in(this.calc?.status, ClaimCalculationStatus.GO) &&
      (this.calc.invoices.some((inv) => inv.insured) || !this.isCreditInsuranceClaim) &&
      this.calculations &&
      this.calculations
        .filter((c) => DictionaryUtils.in(c.status, ClaimCalculationStatus.GO))
        .sort((c1, c2) => c2.versionNumber - c1.versionNumber)[0].id === this.calc.id
    );
  }

  get isSuretyClaim(): boolean {
    return this.isGivenRiskType(ClaimRiskType.SURETY);
  }

  get isProductionRiskClaim(): boolean {
    return this.isGivenRiskType(ClaimRiskType.PROD_RISK);
  }

  get isCreditInsuranceClaim(): boolean {
    return this.isGivenRiskType(ClaimRiskType.CREDIT_INSURANCE);
  }

  get directedToMustBeSpecified(): boolean {
    return (
      !!this.calc?.id &&
      DictionaryUtils.in(
        this.calc.status,
        ClaimCalculationStatus.IN_PROGRESS,
        ClaimCalculationStatus.WAITING_FOR_FIRST_APPROVAL
      )
    );
  }

  private isGivenRiskType(expectedRiskTypeId: number): boolean {
    return DictionaryUtils.equalsDictAndId(this.claim.riskType, expectedRiskTypeId);
  }

  get isGoStatus(): boolean {
    return !!this.calc?.id && DictionaryUtils.in(this.calc.status, ClaimCalculationStatus.GO);
  }

  generateReport(calculationId: number) {
    let params = new HttpParams();
    params = params.append('claimCalculationId', calculationId + '');
    this.serverErrors = undefined;
    this.businessReportService.generate(
      this.ecg ? ReportDefinition.ECG_CLAIM_CALCULATION : ReportDefinition.KUKE_CLAIM_CALCULATION,
      `report_${calculationId}.xlsx`,
      params,
      (errorMsg) => this.downloadErrorCallback(errorMsg)
    );
  }

  dictionaryShortCode(d: DictionaryBaseDto): string {
    return d?.code ? StringUtils.shortCode(d.code) : '';
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get ecg(): boolean {
    return this.appService.ecg;
  }
}
