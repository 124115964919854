import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {
  AppConfigService,
  ATableComponent,
  BackendError,
  BondingContractInquiryBaseDto,
  BondingContractInquiryCapitalGroupDto,
  BondingContractInquiryClientBalanceDto,
  BondingContractInquiryClientDutyDto,
  BondingContractInquiryClientFinancialDataDto,
  BondingContractInquiryClientInformationDto,
  BondingContractInquiryCloseReasonDto,
  BondingContractInquiryCompanyDto,
  BondingContractInquiryVersionCriteriaDto,
  BondingContractInquiryVersionDto,
  BondingContractInquiryVersionSimpleDto,
  BusinessPropertiesService,
  CacheService,
  CompanySimpleDto,
  DictionaryBaseDto,
  ErrorCode,
  GrowlService,
  LoggedUserService,
  RelatedObject,
  RouterService,
  SearchDataProvider,
  SettableObjectType,
  StateTransitionDto,
  StringUtils,
  TemplateService,
  TemplateSimpleDto,
  UserService,
  UserSimpleDto,
  View,
} from '../../../bonding_shared';
import {
  BondingContractInquiryRejectionReason,
  BondingContractInquiryStatus,
  BondingElementaryRight,
  BusinessObjectType,
  ContactNoteElementaryRight,
  ContractCompanyRole,
  ContractType,
  DictionaryProfile,
  getContractTypeCodeRegexp,
  RepoDocumentElementaryRight,
  ServiceContactElementaryRight,
  ServiceNoteElementaryRight,
  UserRole,
} from '../../../bonding_shared/model/dictionary-ids';
import {ActivatedRoute} from '@angular/router';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {BondingContractInquiryService} from '../../../bonding_shared/services/bonding-contract-inquiry.service';
import {CompanySelectedEvent} from '../../company/components/shared/extended-selectors/company-selector-extended.component';
import {Button} from '../../../bonding_shared/components/details-view/button';
import {NumberUtils} from '../../../bonding_shared/utils/number-utils';
import {BusinessUnit} from '../../../bonding_shared/model/business-unit';

@Component({
  selector: 'bonding-contract-inquiry-details',
  templateUrl: './bonding-contract-inquiry-details.component.pug',
})
export class BondingContractInquiryDetailsComponent extends DetailsView implements OnInit {
  templates: TemplateSimpleDto[] = [];
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  inquiryVersion = <BondingContractInquiryVersionDto>{inquiry: {}, contractCompanies: []};
  versionsDataProvider: SearchDataProvider<
    BondingContractInquiryVersionCriteriaDto,
    BondingContractInquiryVersionSimpleDto
  >;
  readonly = false;
  showDetails = false;
  readonly maintainerAutoAssignButton: Button;
  possibleMaintainers = [];
  multiProduct = false;

  closeReason: BondingContractInquiryCloseReasonDto;
  rejectionReason: DictionaryBaseDto;
  showCloseErrors = false;

  viewParams: BondingContractInquiryViewParams;

  systemCurrency: DictionaryBaseDto;

  readonly ContractType = ContractType;
  readonly BondingElementaryRight = BondingElementaryRight;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly ServiceNoteElementaryRight = ServiceNoteElementaryRight;
  readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  readonly contractTypeCodeRegexp;
  readonly BondingContractInquiryStatus = BondingContractInquiryStatus;

  @ViewChild('versions', {static: true}) versionsTable: ATableComponent<BondingContractInquiryVersionSimpleDto>;
  readonly BusinessObjectType = BusinessObjectType;

  constructor(
    public bondingContractInquiryService: BondingContractInquiryService,
    public loggedUserService: LoggedUserService,
    private userService: UserService,
    public appService: AppConfigService,
    private cacheService: CacheService,
    private businessPropertiesService: BusinessPropertiesService,
    private route: ActivatedRoute,
    public router: RouterService,
    protected growlService: GrowlService,
    private templateService: TemplateService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.newVersionButton.hidden = false;
    this.versionsDataProvider = BusinessUtils.createBondingContractInquiryVersionDataProvider(
      this.bondingContractInquiryService
    );
    this.initializeSelectorEmittersByNames(true, [RelatedObject.CLIENT]);
    this.maintainerAutoAssignButton = new Button(
      'bondingContractInquiry.details.button.maintainer.autoAssign',
      this.onMaintainerAutoAssign.bind(this),
      false,
      true
    );

    this.contractTypeCodeRegexp = getContractTypeCodeRegexp(this.credendo);
  }

  ngOnInit() {
    this.form = this.ngForm.form;
    this.serverErrors = undefined;
    this.multiProduct = false;
    this.commonInit<BondingContractInquiryViewParams>(this.route);
    this.setSystemCurrency();
  }

  initViewWithId(id: number) {
    this.bondingContractInquiryService.getById<BondingContractInquiryVersionDto>(id).subscribe({
      next: (pll) => this.setInquiryVersion(pll, true, true),
      error: () => {
        this.objectNotFound = true;
        this.growlService.error('error.objectNotFound');
      },
    });
  }

  initViewWithCustomParams(q: BondingContractInquiryViewParams) {
    this.viewParams = q;
    if (q.readFromCache && this.cacheService.bondingContractInquiry) {
      this.setInquiryVersion(this.cacheService.bondingContractInquiry, false, true);
    } else if (q.versionIdForRenewal) {
      this.bondingContractInquiryService.renewalInquiryVersion(+q.versionIdForRenewal).subscribe(
        (v) => this.setInquiryVersion(v, false, true),
        (error) => this.handleServerError(error)
      );
    } else if (q.contractVersionId) {
      this.bondingContractInquiryService.initialVersionRenewal(q.contractVersionId).subscribe(
        (v) => this.setInquiryVersion(v, false, true),
        (error) => this.handleServerError(error)
      );
    } else {
      this.multiProduct = true;
      this.setInquiryVersion(this.initialVersion(), false, false);
      this.checkAllSelectorsSet();
    }
  }

  initialVersion() {
    return <BondingContractInquiryVersionDto>{inquiry: <BondingContractInquiryBaseDto>{}, contractCompanies: []};
  }

  loadVersion(versionId: number, refreshVersionList: boolean) {
    this.bondingContractInquiryService
      .getById<BondingContractInquiryVersionDto>(versionId)
      .subscribe((bv) => this.setInquiryVersion(bv, refreshVersionList, true));
  }

  private setInquiryVersion(inV: BondingContractInquiryVersionDto, refreshVersionList: boolean, showDetails: boolean) {
    console.log('setContract: ', inV);

    if (
      this.appService.kuke &&
      inV?.status?.id !== BondingContractInquiryStatus.COMPLETENESS_VERIFICATION &&
      inV?.status?.code !== undefined
    ) {
      this.readonly = true;
    } else {
      this.readonly = false;
    }

    this.inquiryVersion = inV;
    this.closeReason = <BondingContractInquiryCloseReasonDto>{};
    if (this.inquiryVersion.closeReason != null) {
      this.closeReason = this.inquiryVersion.closeReason;
    }
    this.handleButtons();
    if (refreshVersionList) {
      this.refreshVersionList();
    }
    this.showDetails = showDetails;
    this.initializePossibleMaintainers();

    if (this.kuke && !this.portal) {
      this.loadTemplates();
    }
  }

  initializePossibleMaintainers() {
    this.setPossibleMaintainers([]);
    if (this.inquiryVersion.inquiry.businessUnit && this.inquiryVersion.last) {
      this.userService.getBondingSupport(this.inquiryVersion.inquiry.businessUnit.id).subscribe((users) => {
        this.setPossibleMaintainers(users.map((u) => <any>{id: u.id, name: StringUtils.userName(u)}));
      });
    }
  }

  private setPossibleMaintainers(pm) {
    this.possibleMaintainers = pm;
    this.maintainerAutoAssignButton.disabled = !this.canAutoAssignMaintainer();
  }

  onSave() {
    console.log('save inquiry:', this.inquiryVersion);
    this.clearErrors();
    if (!this.formValidates()) {
      return;
    }
    this.inProgress = true;
    const originalId = this.inquiryVersion.id;
    const multipleCreation = this.inquiryVersion.versions && this.inquiryVersion.versions.length > 1;
    this.bondingContractInquiryService.save(this.inquiryVersion).subscribe(
      (bcv) => {
        this.setInquiryVersion(bcv, true, true);
        this.afterObjectSaved(originalId, () =>
          multipleCreation
            ? this.router.toBondingContractInquirySearch()
            : this.router.toBondingContractInquiryDetails(bcv.id)
        );
      },
      (error) => {
        this.checkClientInformationRequired(error);
        this.handleServerError(error);
      }
    );
  }

  initialClientInfo() {
    return <BondingContractInquiryClientInformationDto>{
      guarantyCurrency: this.systemCurrency,
      financialData: <BondingContractInquiryClientFinancialDataDto>{currency: this.systemCurrency},
      balance: <BondingContractInquiryClientBalanceDto>{
        currency: this.systemCurrency,
        duty: <BondingContractInquiryClientDutyDto>{},
        charge: <BondingContractInquiryClientDutyDto>{},
      },
      capitalGroup: <BondingContractInquiryCapitalGroupDto>{members: []},
      subsidiaries: [],
      claims: [],
      limits: [],
      grantedGuaranties: [],
      credits: [],
      ongoingContracts: [],
      completedContracts: [],
    };
  }

  checkClientInformationRequired(error: BackendError) {
    if (this.isSpecificError(error, ErrorCode.BONDING_CONTRACT_INQUIRY_CLIENT_INFO_REQUIRED)) {
      this.inquiryVersion.clientInformation = this.initialClientInfo();
      this.showErrors = true;
    }
  }

  onCancel(): void {
    this.clearErrors();
    if (this.inquiryVersion.id) {
      this.loadVersion(this.inquiryVersion.id, false);
    } else if (this.versionsTable.items && this.versionsTable.items.length > 0) {
      this.loadVersion(this.versionsTable.items[0].id, false);
    } else if (this.viewParams && this.viewParams.versionIdForRenewal) {
      this.router.toBondingContractInquiryDetails(+this.viewParams.versionIdForRenewal);
    } else {
      this.setInquiryVersion(this.initialVersion(), false, false);
    }
  }

  onCreateNewVersion() {
    this.serverErrors = undefined;
    this.bondingContractInquiryService.newVersion<BondingContractInquiryVersionDto>(this.inquiry.id).subscribe((v) => {
      this.setInquiryVersion(v, false, true);
      this.handleButtons();
    });
  }

  private refreshVersionList() {
    this.versionsDataProvider.searchCriteria.criteria.inquiry.id = this.inquiry.id;
    this.versionsTable.search();
  }

  onSelectVersion(bcv: BondingContractInquiryVersionSimpleDto) {
    this.clearErrors();
    this.loadVersion(bcv.id, false);
  }

  handleButtons() {
    this.saveButton.disabled = (this.inquiryVersion.id && !this.inquiryVersion.last) || this.readonly;
    this.newVersionButton.disabled = !this.inquiryVersion.id || !this.inquiryVersion.last;
    this.saveButton.hidden =
      (!this.inquiry.client && !this.mainClient) || (this.inquiryVersion.id && !this.inquiryVersion.updatable);
    this.cancelButton.hidden = this.saveButton.hidden;
    this.newVersionButton.hidden = !this.inquiryVersion.id || !this.inquiryVersion.inquiry.newVersionCreationPossible;
  }

  get inquiry() {
    return this.inquiryVersion && this.inquiryVersion.inquiry;
  }

  get client(): {company: CompanySimpleDto; raw: boolean} {
    return BusinessUtils.getThirdPartyCompany(this.inquiry && this.inquiry.client);
  }

  get mainClient(): BondingContractInquiryCompanyDto {
    return this.inquiryVersion.contractCompanies
      ? this.inquiryVersion.contractCompanies.find((c) => c.role.id === ContractCompanyRole.MAIN_CLIENT)
      : null;
  }

  onSelectClientCompanySimple(company: CompanySimpleDto) {
    if (!company) {
      return;
    }
    const mc = this.mainClient;
    if (this.mainClient != null) {
      NumberUtils.deleteItemFirstOccurrence(this.inquiryVersion.contractCompanies, mc);
    }
    const cc = <BondingContractInquiryCompanyDto>{company: company};
    cc.role = <DictionaryBaseDto>{id: ContractCompanyRole.MAIN_CLIENT, name: 'Main client'};
    this.inquiryVersion.contractCompanies.push(cc);
    this.bondingContractInquiryService.initialVersion(company.id).subscribe((v) => {
      this.setInquiryVersion(v, false, true);
      this.handleButtons();
    });
  }

  onSelectClientCompanySelectedEvent(event: CompanySelectedEvent) {
    this.inquiryVersion.inquiry.client = event.thirdParty;
    this.checkAllSelectorsSet();
  }

  checkAllSelectorsSet() {
    if (this.portal || this.inquiryVersion.inquiry.client) {
      const companyId = this.client?.company ? this.client.company.id : undefined;
      this.bondingContractInquiryService.initialVersion(companyId).subscribe((v) => {
        this.setInquiryVersion(v, false, true);
        this.handleButtons();
      });
    }
  }

  createNewClient() {
    this.router.toCompanyDetailsNew(
      View.BONDING_CONTRACT_INQUIRY_DETAILS.id,
      SettableObjectType.BONDING_CONTRACT_INQUIRY_CLIENT
    );
  }

  get showOffers(): boolean {
    return (
      this.inquiryVersion &&
      this.inquiryVersion.id &&
      [
        BondingContractInquiryStatus.LIMIT_GRANTED,
        BondingContractInquiryStatus.CANCELLED,
        BondingContractInquiryStatus.CONTRACT_CREATED,
      ].includes(this.inquiryVersion.businessStatus.id)
    );
  }

  onMaintainerAutoAssign() {
    this.maintainerAssign(this.loggedUserService.getLoggedUserData().id);
  }

  maintainerAssign(userId: number) {
    this.inquiryVersion.maintainer = <UserSimpleDto>{id: userId};
    this.onSave();
  }

  canAssignOtherMaintainers(): boolean {
    return (
      this.loggedUserService.hasLoggedUserRole(UserRole.GW_DIRECTOR) ||
      this.loggedUserService.hasLoggedUserRole(UserRole.DUW_DIRECTOR)
    );
  }

  private canAutoAssignMaintainer(): boolean {
    const user = this.loggedUserService.getLoggedUserData();
    return (
      (!this.inquiryVersion.maintainer || this.inquiryVersion.maintainer.id !== user.id) &&
      !this.canAssignOtherMaintainers() &&
      this.possibleMaintainers.find((pu) => pu.id === user.id)
    );
  }

  goToServiceDetails() {
    // TODO: set mainSalesRepUser
    // const salesPerson = this.inquiry.salesPerson;
    // const mainSalesRepUserId = salesPerson && salesPerson.id;
    this.router.toBondingContractInquiryTechnicalDetails(this.inquiryVersion.id, 10);
  }

  get renewalPossible() {
    return this.appService.kuke && this.inquiryVersion.id && this.inquiryVersion.renewalPossible;
  }

  toServiceContacts() {
    this.router.toServiceContacts({
      boTypeId: BusinessObjectType.BONDING_CONTRACT_INQUIRY,
      boId: this.inquiryVersion.inquiry.id,
      returnBoTypeId: BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION,
      returnBoId: this.inquiryVersion.id,
    });
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get credendo(): boolean {
    return this.appService.credendo;
  }

  isShowCloseConfirmation(): (transition: StateTransitionDto) => boolean {
    return (transition: StateTransitionDto) =>
      transition.newStatus.id === BondingContractInquiryStatus.CLOSED ||
      transition.newStatus.id === BondingContractInquiryStatus.REJECTED;
  }

  validCloseInput(): (transition: StateTransitionDto) => () => boolean {
    return (transition) => {
      return () => {
        if (transition.newStatus.id === BondingContractInquiryStatus.CLOSED) {
          const valid = !!this.closeReason && !!this.closeReason.type && !!this.closeReason.comment;
          this.showCloseErrors = !valid;
          if (valid) {
            this.inquiryVersion.closeReason = this.closeReason;
          }
          return valid;
        }
        if (transition.newStatus.id === BondingContractInquiryStatus.REJECTED) {
          const valid = !!this.rejectionReason?.id;
          this.showCloseErrors = !valid;
          if (valid) {
            this.inquiryVersion.rejectionReason = this.rejectionReason;
          }
          return valid;
        }
        return false;
      };
    };
  }

  get showStateTransitionConfirmation(): (transition: StateTransitionDto) => boolean {
    return (transition: StateTransitionDto) => transition.newStatus.id === BondingContractInquiryStatus.CANCELLED;
  }

  get rejectionReasonDictionaryProfile(): number | undefined {
    return this.loggedUserService.portal
      ? DictionaryProfile.PORTAL_BONDING_CONTRACT_INQUIRY_REJECTION_REASON
      : undefined;
  }

  get hiddenRejectionReasonIds(): number[] {
    return this.inquiryVersion?.inquiry?.businessUnit?.id === BusinessUnit.KUKE_GSP
      ? []
      : [BondingContractInquiryRejectionReason.CONTRACT_RISK_NOT_ACCEPTED];
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  private loadTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    dictionarySelectors.push(this.inquiryVersion.inquiry.productType);

    this.templateService
      .findByType(
        undefined,
        BusinessObjectType.BONDING_CONTRACT_INQUIRY,
        undefined,
        dictionarySelectors,
        undefined,
        undefined,
        undefined,
        true
      )
      .subscribe((result) => {
        this.templates = result;
      });
  }

  onPrintSelected(template: TemplateSimpleDto) {
    this.router.toDocumentDetailsNew(template.id, this.inquiry.id);
  }

  setSystemCurrency() {
    this.businessPropertiesService.getProperties().subscribe((p) => (this.systemCurrency = p.systemCurrency));
  }
}

export class BondingContractInquiryViewParams {
  // string when reading
  readFromCache?: string | boolean;
  versionIdForRenewal?: string | number;
  contractVersionId?: number;
}
