/* tslint:disable member-ordering */
/**
 * Created by jakubowski on 6.10.2017
 */
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, ContentChild, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {UserDto, UserSimpleDto} from '../../model/dtos';
import {UserRange, UserService} from '../../services/user.service';
import {UserNamePipe} from '../../pipes';

const USER_AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UserAutoCompleteComponent),
  multi: true,
};

@Component({
  selector: 'user-auto-complete',
  template: `
    <ng-container *ngIf="!!items">
      <input
        *ngIf="!presentationMode"
        [ngModel]="_selectedItem"
        (ngModelChange)="selectedItem = $event"
        class="bon-input"
        [disabled]="disabled"
        autocomplete="off"
        auto-complete
        [value-formatter]="valueFormatter"
        [list-formatter]="listFormatter"
        [match-formatted]="matchFormatted"
        [source]="items"
        [accept-user-input]="acceptUserInput"
        [min-chars]="3"
        [style.width]="width"
        [bonFocus]="focus"
      />
      <div *ngIf="presentationMode">
        <span [class.presentation]="true">{{ valueFormatter(_selectedItem) }}</span>
      </div>
    </ng-container>
  `,
  providers: [USER_AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR],
})
export class UserAutoCompleteComponent implements ControlValueAccessor, OnInit {
  @Input() range: UserRange = 'intranet';
  @Input() matchFormatted = true;
  @Input() acceptUserInput = false;

  @Input() nullLabel: string;
  @Input() disabled = false;
  @Input() label = 'name';
  @Input() focus: boolean | 'auto' | 'clear' = 'auto';

  @Input() width = 'calc(100% + 92px)';

  @Input() valueFormatter: (arg: any) => string = (u) => new UserNamePipe().transform(u);
  @Input() listFormatter: (arg: any) => string = (u) => new UserNamePipe().transform(u);

  @Input() set defaultValue(value: UserDto) {
    if (value) {
      this._selectedItem = value;
    }
  }

  /**
   * The items that will not be shown in a list. This should be be stored as set,
   * i.e.  hiddenIds[12] = true;
   */
  @Input() hiddenIds: Set<number>;
  @Input() presentationMode = false;

  @Output() changeItem = new EventEmitter<UserDto>();

  @ContentChild(TemplateRef, {static: true}) template: TemplateRef<any>;

  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  private _selectedKey: number;
  public _selectedItem: UserDto;
  // Item set from model. May be possibly incorrect.
  private selectedItemFromExternal: UserDto;

  public items: UserSimpleDto[];
  public errorMessage: string;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.loadUsers();
  }

  get selectedItem(): UserDto {
    console.log('get SelectedItem() ', this._selectedItem);
    return this._selectedItem;
  }

  set selectedItem(it: UserDto) {
    this._selectedItem = it;
    if (
      this.onChangeListeners &&
      (typeof this._selectedItem === 'object' || typeof this._selectedItem === 'undefined')
    ) {
      this.onChangeListeners(this._selectedItem);
      this.changeItem.emit(this._selectedItem);
    }
  }

  // From ControlValueAccessor interface
  writeValue(it: UserDto): void {
    if (it) {
      // this.selectedItemFromExternal = this.findSelectedItem(it.id);
      this._selectedItem = it;
      this._selectedKey = it.id;
    } else {
      this._selectedItem = undefined;
      this._selectedKey = undefined;
    }
    // this.updateSelection();
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  // private updateSelection() {
  //   if (this.selectedItemFromExternal) {
  //     this._selectedItem = this.findSelectedItem(this.selectedItemFromExternal.id);
  //     this._selectedKey = this.selectedItemFromExternal.id;
  //   } else {
  //     this._selectedItem = undefined;
  //     this._selectedKey = undefined;
  //   }
  // }

  // private findSelectedItem(k: number): UserDto {
  //   if (k && this.items) {
  //     for (const it of this.items) {
  //       if (String(it.id) === String(k)) {
  //         return <UserDto>it;
  //       }
  //     }
  //   }
  //   return undefined;
  // }

  private loadUsers() {
    this.userService.getUsers(this.range).subscribe((users) => (this.items = users));
  }
}
