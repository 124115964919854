import {map} from 'rxjs/operators';
import {ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgForm, UntypedFormBuilder} from '@angular/forms';
import {
  AppConfigService,
  ATableComponent,
  BackendError,
  BusinessUnitDto,
  CompanyService,
  CompanySimpleDto,
  ConfirmDialogComponent,
  ContractBaseDto,
  ContractBondDto,
  ContractCoInsurerCompanyDto,
  ContractCompanyDto,
  ContractDto,
  ContractService,
  ContractVersionCriteriaDto,
  ContractVersionDto,
  ContractVersionService,
  ContractVersionSimpleDto,
  CurrencyExchangeRateService,
  DictionaryBaseDto,
  DictionaryBaseService,
  DocumentService,
  GrowlService,
  LoggedUserService,
  PropertyService,
  RouterService,
  SalesRegionDto,
  SearchDataProvider,
  StringUtils,
  TemplateSimpleDto,
  UserDto,
} from '../../../bonding_shared';
import {BondTypeListComponent} from './components';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {
  AppProperty,
  BondingElementaryRight,
  BondInvoicingSchema,
  BondType,
  BusinessObjectType,
  CompanyType,
  ContractCompanyRole,
  ContractFees,
  ContractStatus,
  ContractType,
  DictionaryProfile,
  Frequency,
  Source,
  UserGroup,
} from '../../../bonding_shared/model/dictionary-ids';
import {DateRangeUtils} from '../../../bonding_shared/utils/date-range-utils';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {ContractVersionListComponent} from './components/shared/contract-version-list.component';
import {Button} from '../../../bonding_shared/components/details-view/button';
import {ContractSuspensionDialogComponent} from './components/contract-suspension-dialog.component';
import {ContractNoticeService} from './domain/contract-notice.domain';
import {ContractGuiService} from './services/contract-gui.service';
import {DictionaryUtils} from '../../../bonding_shared/utils/dictionary-utils';
import {SalesRegionService} from '../../../bonding_shared/services/sales-region.service';

@Component({
  selector: 'contract-details',
  templateUrl: './contract-details.component.pug',
  providers: [ContractNoticeService],
})
export class ContractDetailsComponent extends DetailsView implements OnInit {
  public readonly BusinessObjectType = BusinessObjectType;
  templates: TemplateSimpleDto[] = [];

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  @ViewChild(BondTypeListComponent)
  set bondListComponent(cmp: BondTypeListComponent) {
    console.log('Bond list compoennt', cmp);
    if (cmp) {
      this._bondListComponent = cmp;
    }
  }

  get bondListComponent() {
    return this._bondListComponent;
  }

  _bondListComponent: BondTypeListComponent;

  @ViewChild(ContractVersionListComponent)
  contractVersionListComponent: ContractVersionListComponent;

  @ViewChild(ConfirmDialogComponent, {static: true})
  confirmDialog: ConfirmDialogComponent;

  @ViewChild('contractCompanies', {static: true})
  contractCompaniesTable: ATableComponent<ContractCompanyDto>;

  @ViewChild('suspensionDialog', {static: true}) suspensionDialog: ContractSuspensionDialogComponent;

  public contractVersion: ContractVersionDto;

  self = this;

  isAnnexing = false;

  showCoInsurersSection = false;

  tempCV: ContractVersionDto;

  contractVersionDP: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto>;

  private mainContractCompany: ContractCompanyDto;

  periodUnitsHiddenIds = new Set<number>().add(38000002);

  private frequencyMap: {[key: string]: DictionaryBaseDto} = {};

  salesRegions: SalesRegionDto[];

  dictionaryProfile = DictionaryProfile;

  companyRoleClient: DictionaryBaseDto;
  companyRoleMainClient: DictionaryBaseDto;

  suspendButton = new Button('contract.details.button.suspend', this.openSuspensionDialog.bind(this), true);
  unsuspendButton = new Button('contract.details.button.unsuspend', this.onUnsuspendContract.bind(this), true);

  readonly BondingElementaryRight = BondingElementaryRight;
  readonly ContractStatus = ContractStatus;
  readonly CompanyType = CompanyType;
  readonly ContractType = ContractType;
  readonly Source = Source;
  readonly SYSTEM_CURRENCY_CODE = this.propertyService.properties[AppProperty.SYSTEM_CURRENCY] + '';
  readonly INVOICE_CONTRACT_SEND_ALL_INVOICES_AUTOMATICALLY =
    this.propertyService.properties[AppProperty.INVOICE_CONTRACT_SEND_ALL_INVOICES_AUTOMATICALLY];

  showMinimumDocumentary = false;

  constructor(
    private documentService: DocumentService,
    private companyService: CompanyService,
    private contractService: ContractService,
    public contractVersionService: ContractVersionService,
    public router: RouterService,
    public guiService: ContractGuiService,
    private loggedUserService: LoggedUserService,
    private route: ActivatedRoute,
    protected growlService: GrowlService,
    private cd: ChangeDetectorRef,
    private salesRegionService: SalesRegionService,
    private formBuilder: UntypedFormBuilder,
    private contractNoticeService: ContractNoticeService,
    public appService: AppConfigService,
    private currencyExchangeRateService: CurrencyExchangeRateService,
    private propertyService: PropertyService,
    private dictionaryBaseService: DictionaryBaseService,
    @Optional() @Inject('verifyFinancialDataConfirm') private verifyFinancialDataConfirm: boolean
  ) {
    super(growlService);
    this.hideButtons(false);
    this.deleteButton.hidden = true;
    this.selectorNameList = ['Client', 'CoInsurers'];
    this.initializeSelectorEmitters(true);
    this.contractVersionDP = BusinessUtils.createContractVersionDataProvider(contractVersionService);
    this.dictionaryBaseService
      .getDictionaryEntry(
        'ContractCompanyRole',
        ContractCompanyRole.MAIN_CLIENT,
        this.loggedUserService.getLoggedUserData().language.id
      )
      .subscribe((result) => {
        this.companyRoleMainClient = result;
      });
    this.dictionaryBaseService
      .getDictionaryEntry(
        'ContractCompanyRole',
        ContractCompanyRole.CLIENT,
        this.loggedUserService.getLoggedUserData().language.id
      )
      .subscribe((result) => {
        this.companyRoleClient = result;
      });
  }

  ngOnInit() {
    this.form = this.ngForm.form;
    this.commonInit<BondingContractViewParams>(this.route);
    this.dictionaryBaseService
      .getDictionaryBase('Frequency')
      .subscribe((frequencies) => frequencies.forEach((frequency) => (this.frequencyMap[frequency.id] = frequency)));

    if (this.kuke) {
      this.salesRegionService.getAll().subscribe((data) => {
        this.salesRegions = data;
      });
    }

    this.updateButtons();
  }

  initViewWithId(id: number, force = true) {
    console.log('initializeContractVersion id = ', id);
    if (this.contractVersion && id === this.contractVersion.id && !force) {
      return;
    }
    this.showErrors = false;
    this.contractVersionService
      .getContractVersion(id)
      .subscribe((contractVersion) => this.setContractVersion(contractVersion));
  }

  initViewWithCustomParams(q: BondingContractViewParams) {
    console.log('initializeContractVersion params = ', q);
    if (q.newVersion) {
      this.newVersion(+q.contractId);
    } else {
      this.initializeContractWithClient(q.clientId ? +q.clientId : undefined);
    }
  }

  initializeContractWithClient(clientId: number) {
    this.contractVersion = this.init();
    this.contractVersion.versionNumber = 1;
    this.contractVersion.versionType.id = 8000001;
    this.contractVersion.status.id = ContractStatus.CONTRACT_PROSPECT;
    this.contractVersion.businessStatus.id = ContractStatus.CONTRACT_PROSPECT;
    if (clientId && clientId > 0) {
      this.initializeCompany(clientId);
    }
    const user: UserDto = this.loggedUserService.getLoggedUserData();
    if (user && user.businessUnit) {
      this.contractVersion.contract.businessUnit = user.businessUnit;
    }
  }

  isActivated(cv: ContractVersionDto): boolean {
    return (
      cv &&
      cv.status &&
      cv.status.id >= ContractStatus.CONTRACT_ACTIVATED &&
      cv.status.id !== ContractStatus.CONTRACT_PROSPECT
    );
  }

  clearFees(cv: ContractVersionDto) {
    if (cv) {
      cv.minimalAnnualCommisionAmount = null;
      cv.managementFee = null;
      cv.annualFacilityFee = null;
      cv.facilityFee = null;
      cv.nonRecurrentManagementFee = null;
      cv.letterOfIntentFee = null;
    }
  }

  initializeCompany(clientId: number) {
    this.companyService.getCompanySimple(clientId).subscribe((company) => this.onSelectClient(company));
  }

  setDefaultBu() {
    if (!this.contractVersion.id || this.contractVersion.id === 0) {
      const user: UserDto = this.loggedUserService.getLoggedUserData();
      if (user && user.businessUnit) {
        this.contractVersion.contract.businessUnit = user.businessUnit;
      }
    }
  }

  setContractVersionDataProvider(contractId: number) {
    if (!this.contractVersionDP) {
      this.contractVersionDP = BusinessUtils.createContractVersionDataProvider(this.contractVersionService);
    }
    this.contractVersionDP.searchCriteria.criteria.contract.id = contractId;
  }

  setContractVersion(contractVersion: ContractVersionDto, isTemplate?: boolean) {
    console.log('Contract Version >>>>>>>>> ', contractVersion);
    if (isTemplate) {
      contractVersion.contractCompanies = this.contractVersion.contractCompanies;
    }

    this.contractVersion = contractVersion;

    console.log(this.contractVersion.validFrom, this.contractVersion.validTo);
    this.setContractVersionDataProvider(this.contractVersion.contract.id);

    if (isTemplate) {
      this.onSelectedMaxTotalLiabilityCurrency(this.contractVersion.maxTotalLiabilityCurrency);
      this.contractVersion.validTo = null;
    }

    if (contractVersion.coInsurers && contractVersion.coInsurers.length > 0) {
      this.showCoInsurersSection = true;
    }

    if (contractVersion.warnings && contractVersion.warnings.length > 0) {
      this.handleServerError(contractVersion.warnings);
    }

    console.log('Contract version initialized: bondsCount = ' + this.contractVersion.bondTypes.length);
    this.inProgress = false;
    this.updateButtons();
    this.loadTemplates();
  }

  // newAnnex()
  onCreateNewVersion() {
    this.newVersion(this.contractVersion.contract.id);
  }

  newVersion(contractId: number) {
    this.isAnnexing = true;
    this.updateButtons();
    this.contractVersionService
      .newVersion<ContractVersionDto>(contractId)
      .pipe(map((contractVersion) => this.init(contractVersion)))
      .subscribe({
        next: (contractVersion) => this.setContractVersion(contractVersion),
        error: (error) => (this.serverErrors = error),
      });
  }

  afterContractSaved(contract: ContractDto) {
    console.log('##### contract version saved id = ' + contract.id + 'contract = ', contract);
    this.showSavedMsg();
    this.setContractVersionDataProvider(this.contractVersion.contract.id);
    if (this.contractVersionListComponent) {
      this.contractVersionListComponent.dataProvider = this.contractVersionDP;
      this.contractVersionListComponent.search();
    }
    this.router.toContractDetails(this.contractVersion.id);
    this.updateButtons();
  }

  canDeleteAnnex(contractVersion: ContractVersionDto): boolean {
    const canDelete =
      contractVersion &&
      contractVersion.id > 0 &&
      contractVersion.last &&
      (contractVersion.validFrom > new Date() || contractVersion.status.id === 2000001);
    if (canDelete || !contractVersion || !contractVersion.id) {
      this.deleteButton.title = 'Delete';
    } else if (!contractVersion.last) {
      this.deleteButton.title = 'Only the last contract version can be deleted';
    } else {
      this.deleteButton.title = 'Contract version other than request can be deleted only if it is from the future';
    }
    return canDelete;
  }

  onDelete() {
    console.log('onDeleteAnnex selectedContractVersion.id =' + this.contractVersion.id);
    const number = this.contractVersion.contract.number
      ? this.contractVersion.contract.number
      : this.contractVersion.contract.requestNumber;
    const confirmationPromise: Promise<boolean> = this.confirmDialog.open(
      'Delete confirmation',
      'Are you sure you want to delete annex ' + number + '/' + this.contractVersion.versionNumber + ' ?'
    );
    confirmationPromise.then((result) => {
      this.deleteAnnex(result);
    });
  }

  deleteAnnex(shouldDelete: boolean) {
    this.inProgress = true;
    const id = this.contractVersion.id;
    if (id && shouldDelete) {
      this.contractVersionService.deleteContractVersion(id).subscribe({
        next: () => this.afterDeleteLastAnnex(id),
        error: (error) => {
          this.serverErrors = error;
          this.inProgress = false;
        },
      });
    }
  }

  afterDeleteLastAnnex(id: number) {
    console.log('afterDeleteLastAnnex: ', id);
    this.contractVersionListComponent.selectPreviousItem();
    this.contractVersionListComponent.search();
    this.inProgress = false;
    this.updateButtons();
  }

  showDeletedMsg() {
    this.growlService.notice('Annex deleted.');
    this.inProgress = false;
    this.updateButtons();
  }

  showNotDeletedMsg(error: BackendError) {
    this.serverErrors = error;
    this.inProgress = false;
    this.updateButtons();
  }

  onSave() {
    this.inProgress = true;
    if (!this.form.valid || !this.mainClient || !this.contractVersion.contract.businessUnit) {
      this.showErrors = true;
      this.inProgress = false;
      this.showFormError();
      console.log(this.form);
      StringUtils.logFormInvalidFieldsRecursive(this.form);
      return;
    }
    this.serverErrors = null;

    this.contractVersion.bondTypes = <ContractBondDto[]>this.bondListComponent.contractBondTypes;

    const saveFunc = () =>
      this.contractVersionService.save(this.contractVersion).subscribe({
        next: (contractVersion) => this.setContractVersion(contractVersion),
        error: (error) => {
          this.serverErrors = error;
          this.inProgress = false;
        },
        complete: () =>
          this.contractVersionService.getContract(this.contractVersion.contract.id).subscribe((contract) => {
            this.afterContractSaved(contract);
            this.isAnnexing = false;
          }),
      });

    if (this.isAnnexing && this.verifyFinancialDataConfirm) {
      this.verifyFinancialDataConfirmDialog().then((result) => {
        if (result) {
          saveFunc();
        } else {
          this.inProgress = false;
        }
      });
    } else {
      saveFunc();
    }
  }

  private verifyFinancialDataConfirmDialog(): Promise<boolean> {
    this.showMinimumDocumentary = true;
    return this.confirmDialog
      .open(
        'contract.details.verifyFinancialDataConfirmDialog.title',
        'contract.details.verifyFinancialDataConfirmDialog.message'
      )
      .then((r) => {
        this.showMinimumDocumentary = false;
        return r;
      });
  }

  showSavedMsg() {
    this.growlService.notice('Contract is saved.');
    this.inProgress = false;
  }

  onCancel() {
    super.onCancel();
    this.isAnnexing = false;
    if (this.contractVersion.id && this.contractVersion.id > 0) {
      this.contractVersionService
        .getContractVersion(this.contractVersion.id)
        .pipe(map((contractVersion) => this.init(contractVersion)))
        .subscribe((contractVersion) => this.setContractVersion(contractVersion));
    } else {
      this.initViewWithId(this.route.snapshot.params['id']);
    }
    this.updateButtons();
  }

  updateButtons() {
    let pendingRequest = false;
    if (this.contractVersionListComponent && this.contractVersionListComponent.contractTable.items) {
      for (const item of this.contractVersionListComponent.contractTable.items) {
        if (
          item.status.id === ContractStatus.CONTRACT_REQUEST ||
          item.status.id === ContractStatus.CONTRACT_REQUEST_ACCEPTED ||
          item.status.id === ContractStatus.CONTRACT_NOT_ACTIVATED
        ) {
          pendingRequest = true;
          break;
        }
      }
    }

    this.newVersionButton.disabled =
      this.credendo &&
      (!this.contractVersion ||
        this.isAnnexing ||
        pendingRequest ||
        this.contractVersion.suspended ||
        !(
          this.contractVersion.id > 0 &&
          this.contractVersion.lastActivated &&
          this.contractVersion.status.id === 2000005
        ));
    this.deleteButton.hidden = !this.canDeleteAnnex(this.contractVersion);

    this.suspendButton.hidden = !this.contractVersion || !this.contractVersion.issued || this.contractVersion.suspended;
    this.unsuspendButton.hidden = !this.contractVersion || !this.contractVersion.suspended;

    this.saveButton.hidden =
      this.kuke && !!this.contractVersion && !!this.contractVersion.id && (this.portal || this.readOnly);
    this.cancelButton.hidden = this.saveButton.hidden;

    this.saveButton.disabled = !this.contractVersion || this.contractVersion.suspended;
    this.cancelButton.disabled = this.saveButton.disabled;
  }

  get invoicingConfigDisabled() {
    return (
      this.contractVersion.status &&
      [ContractStatus.CONTRACT_ACTIVE, ContractStatus.CONTRACT_ACTIVATED, ContractStatus.VERSION_OBSOLETE].includes(
        this.contractVersion.status.id
      )
    );
  }

  get invoicingSchemaRequired(): boolean {
    return !!this.contractVersion && this.notProspect && !this.contractVersion.contract.manualInvoicing;
  }

  invoicingSchemaChanged(invoicingSchema: DictionaryBaseDto) {
    if (DictionaryUtils.equalsDictAndId(invoicingSchema, BondInvoicingSchema.FLAT_FEE)) {
      this.contractVersion.invoicingPeriod = undefined;
    } else if (
      DictionaryUtils.in(
        invoicingSchema,
        BondInvoicingSchema.FLAT_FEE_FIRST_YEAR_ONLY,
        BondInvoicingSchema.PERIODIC_UPFRONT
      )
    ) {
      this.contractVersion.invoicingPeriod = this.frequencyMap[Frequency.YEARLY];
    }
  }

  onSelectContractVersion(contractVersion: ContractVersionSimpleDto) {
    console.log('onSelectContractVersion :: validFrom: ', typeof contractVersion.validFrom, contractVersion.validFrom);
    this.contractVersionService
      .getContractVersion(contractVersion.id)
      .pipe(map((cv) => this.init(cv)))
      .subscribe((cv) => {
        this.setContractVersion(cv);
        this.router.toContractDetails(this.contractVersion.id);
      });
  }

  onContractMaxTotalLiabilityChange(value: number) {
    this.contractVersion.maxTotalLiabilityInSysCurr =
      this.contractVersion.maxTotalLiabilityCurrency.code === this.SYSTEM_CURRENCY_CODE
        ? this.contractVersion.maxTotalLiability
        : this.currencyExchangeRateService.calculate(value, this.contractVersion.maxTotalLiabilityCurrency.id);
  }

  onSelectedMaxTotalLiabilityCurrency(currency: DictionaryBaseDto) {
    this.contractVersion.maxTotalLiabilityInSysCurr =
      currency.code === this.SYSTEM_CURRENCY_CODE
        ? this.contractVersion.maxTotalLiability
        : this.currencyExchangeRateService.calculate(this.contractVersion.maxTotalLiability, +currency.id);
  }

  createContractCompany(c: CompanySimpleDto, main?: boolean): ContractCompanyDto {
    const cc = <ContractCompanyDto>{};
    cc.company = c;
    cc.role = <DictionaryBaseDto>{};
    if (main) {
      cc.role = this.companyRoleMainClient;
    } else {
      cc.role = this.companyRoleClient;
    }
    return cc;
  }

  onSelectClient(company: CompanySimpleDto) {
    if (company) {
      console.log('contractCompanySelect onSelected');

      // Check uniqueness
      for (const c of this.contractVersion.contractCompanies) {
        if (c.company.id === company.id) {
          this.growlService.warning('Company already added!');
          return;
        }
      }
      if (this.contractVersion.contractCompanies.length < 1) {
        const contractCompany = this.createContractCompany(company, true);
        this.contractVersion.contractCompanies.push(contractCompany);
        this.onSetMainContractCompany(contractCompany);
      } else {
        const contractCompany = this.createContractCompany(company, false);
        this.contractVersion.contractCompanies.push(contractCompany);
      }
    }
  }

  onCompanyContractRoleChanged(cc: ContractCompanyDto) {
    console.log('onCompanyContractRoleChanged', cc);
    if (cc.role.id !== ContractCompanyRole.MAIN_CLIENT || this.contractVersion.contractCompanies.length < 2) {
      return;
    }
    // if role is changed to mainClient, other mainClient is changed to client
    for (const c of this.contractVersion.contractCompanies) {
      if (c.role.id === ContractCompanyRole.MAIN_CLIENT && c.company.id !== cc.company.id) {
        cc.role = this.companyRoleClient;
      }
    }
  }

  get mainClient(): ContractCompanyDto {
    if (!this.contractVersion.contractCompanies || this.contractVersion.contractCompanies.length < 1) {
      return undefined;
    }
    for (const c of this.contractVersion.contractCompanies) {
      if (c.role.id === ContractCompanyRole.MAIN_CLIENT) {
        return c;
      }
    }
    return undefined;
  }

  businessUnitChanged() {
    if (!this.contractVersion.id) {
      this.loadInitialContractVersion();
    }
  }

  onSetMainContractCompany(mainContractCompany: ContractCompanyDto) {
    this.mainContractCompany = mainContractCompany;
    if (!this.contractVersion.id) {
      this.loadInitialContractVersion();
    }
  }

  private loadInitialContractVersion() {
    if (!this.mainContractCompany || !this.contractVersion) {
      console.log('loadInitialContractVersion: mainContractCompany or this.selectedContractVersion is null - skipping');
      return;
    }

    this.contractService
      .getInitialContractVersion(this.mainContractCompany.company.id, this.contractVersion.contract.businessUnit.id)
      .pipe(map((contractVersion) => this.init(contractVersion)))
      .subscribe(
        // contractVersion => this.setContractVersion(contractVersion),
        (contractVersion) => (this.tempCV = contractVersion),
        () => console.log('Error on contractService.getInitialContractVersionForCountryId'),
        () => {
          this.setContractVersion(this.tempCV, true);
        }
      );
  }

  get contractNumber(): string {
    return BusinessUtils.contractNumber(this.contractVersion);
  }

  isBoosterContract(): boolean {
    return this.contractVersion.contract.type && this.contractVersion.contract.type.id === ContractType.BOOSTER;
  }

  typeChanged(type: DictionaryBaseDto) {
    // BON-1745 clear bonds when contract type is changed
    this.contractVersion.bondTypes.length = 0;

    if (this.contractVersion) {
      this.contractVersion.facilityFeeUnit = ContractFees.FACILITY_FEE_UNIT[type.id];
    }
    if (type.id !== ContractType.BOOSTER) {
      this.clearFees(this.contractVersion);
    }
    this.contractVersion.bondIssuanceInPortal = [ContractType.BOOSTER, ContractType.BANK_BOOSTER].includes(type.id);

    if (this.contractVersion) {
      this.contractVersion.bondTypes = this.contractVersion.bondTypes.filter(function (el) {
        return (
          el.bondType.id !== BondType.ZOLL &&
          el.bondType.id !== BondType.ARBEITZEITKONTEN &&
          el.bondType.id !== BondType.ABFALLVERBRINGUNG
        );
      });
    }
  }

  validFromChanged(value: Date) {
    this.updateFirstInvoicingDateForKuke(value);
    this.calculateFacilityFee();
  }

  private updateFirstInvoicingDateForKuke(value: Date) {
    const scv = this.contractVersion;
    if (this.appService.kuke && scv && scv.validFrom) {
      scv.firstInvoicingDate = value;
    }
  }

  get kuke() {
    return this.appService.kuke;
  }

  get credendo() {
    return this.appService.credendo;
  }

  public calculateFacilityFee() {
    if (this.contractVersion) {
      this.contractVersion.facilityFee = DateRangeUtils.calculateTotalAmount(
        this.contractVersion.annualFacilityFee,
        this.contractVersion.validFrom,
        this.contractVersion.validTo
      );
    }
  }

  onNotificationOfTerminationChanged(checked: boolean) {
    if (!checked) {
      delete this.contractVersion.contractTermination.notificationOfTerminationDate;
    }
  }

  onNotificationOfTerminationChangeListeners() {
    return this.contractNoticeService.updateCancellationDate(this.contractVersion);
  }

  onNoticeReasonChange() {
    if (!this.isNotificationOfTerminationDateEditable()) {
      const cv = this.contractVersion;
      if (cv) {
        cv.cancellationDate = null;
        const ct = cv.contractTermination;
        if (ct) {
          ct.notificationOfTerminationDate = null;
        }
      }
    }
  }

  hiddenContractNoticeReasonIds(): Set<number> {
    return this.contractNoticeService.hiddenContractNoticeReasonIds(this.contractVersion);
  }

  public isNotificationOfTerminationDateEditable() {
    const c = this.contractVersion;
    if (!c) {
      return false;
    }
    const ct = c.contractTermination;
    if (!ct) {
      return false;
    }

    return (
      c.status &&
      this.isActivated(c) &&
      this.contractNoticeService.isNotificationOfTerminationDateEditable(ct.noticeReason)
    );
  }

  hiddenDetails() {
    return !this.contractVersion.contractCompanies || this.contractVersion.contractCompanies.length < 1;
  }

  addBrokerDisabled(): boolean {
    return !(
      this.contractVersion &&
      this.contractVersion.businessStatus &&
      (this.contractVersion.businessStatus.id === ContractStatus.CONTRACT_ACTIVATED ||
        this.contractVersion.businessStatus.id === ContractStatus.CONTRACT_ACTIVE)
    );
  }

  isBooster(): boolean {
    return this.contractVersion?.contract?.type.id === ContractType.BOOSTER;
  }

  isBoosterOrBankBooster(): boolean {
    return (
      this.contractVersion?.contract?.type &&
      [ContractType.BOOSTER, ContractType.BOOSTER].includes(this.contractVersion.contract.type.id)
    );
  }

  isType(types: number[]): boolean {
    return this.contractVersion?.contract && DictionaryUtils.inArr(this.contractVersion.contract.type, types);
  }

  init(cv?: ContractVersionDto): ContractVersionDto {
    if (!cv) {
      cv = <ContractVersionDto>{};
    }
    if (!cv.status) {
      cv.status = <DictionaryBaseDto>{};
    }
    if (!cv.businessStatus) {
      cv.businessStatus = <DictionaryBaseDto>{};
    }
    if (!cv.versionType) {
      cv.versionType = <DictionaryBaseDto>{};
    }
    if (!cv.invoiceCurrency) {
      cv.invoiceCurrency = <DictionaryBaseDto>{};
    }
    if (!cv.contract) {
      cv.contract = <ContractBaseDto>{};
      cv.contract.businessUnit = <BusinessUnitDto>{};
      cv.contract.businessUnit.country = <DictionaryBaseDto>{};
    }
    if (!cv.bondTypes) {
      cv.bondTypes = [];
    }
    if (!cv.contractCompanies) {
      cv.contractCompanies = [];
    }
    if (!cv.maxTotalLiabilityCurrency) {
      cv.maxTotalLiabilityCurrency = <DictionaryBaseDto>{};
    }
    return cv;
  }

  loanInExternalRequestStatus() {
    return !this.inExternalContractStatus() && this.contractVersion.quotation && this.contractVersion.quotation;
  }

  inExternalContractStatus(): boolean {
    return this.contractVersion && this.contractVersion.status.id >= 2000004;
  }

  get invoicedCompanies(): CompanySimpleDto[] {
    if (!this.contractVersion || !this.contractVersion.contractCompanies) {
      return [];
    }
    return this.contractVersion.contractCompanies.map((cc) => cc.company);
  }

  openSuspensionDialog() {
    this.suspensionDialog.openDialog();
  }

  onConfirmSuspension(reason: string) {
    this.contractVersion.suspended = true;
    this.contractVersion.suspensionReason = reason;
    this.onSave();
  }

  onUnsuspendContract() {
    this.clearErrors();
    this.inProgress = true;
    this.contractVersionService.unsuspend(this.contractVersion.id).subscribe(
      (contractVersion) => {
        this.setContractVersion(contractVersion);
        this.inProgress = false;
      },
      (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      }
    );
  }

  get readOnly() {
    return (
      this.contractVersion.suspended ||
      (this.kuke &&
        this.contractVersion.status &&
        [ContractStatus.CONTRACT_TO_BE_CANCELLED].includes(this.contractVersion.status.id)) ||
      (this.portal && !!this.contractVersion.id)
    );
  }

  get notProspect(): boolean {
    return (
      !!this.contractVersion &&
      !!this.contractVersion.status &&
      this.contractVersion.status.id !== ContractStatus.CONTRACT_PROSPECT
    );
  }

  addCoInsurer(company: CompanySimpleDto) {
    if (!this.contractVersion.coInsurers || this.contractVersion.coInsurers.length < 1) {
      this.contractVersion.coInsurers = [];
    }
    const insurer = <ContractCoInsurerCompanyDto>{company: company};
    this.contractVersion.coInsurers.push(insurer);
  }

  isSpecialUSer() {
    return this.loggedUserService
      .getLoggedUserData()
      .groups.find((g) => [UserGroup.EXCOM, UserGroup.SURCOM].includes(g.id));
  }

  isGSPProduct() {
    return (
      this.contractVersion.contract &&
      this.contractVersion.contract.type &&
      BusinessUtils.isGSPContract(this.contractVersion.contract.type.id)
    );
  }

  isGSPGeneralContract() {
    return (
      this.contractVersion.contract &&
      this.contractVersion.contract.type &&
      this.contractVersion.contract.type.id === ContractType.GSP_GENERAL_CONTRACT
    );
  }

  private loadTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    dictionarySelectors.push(this.contractVersion.contract.type);
    this.guiService.getContractTemplates(null, dictionarySelectors).subscribe((result) => {
      this.templates = result;
    });
  }

  onPrintSelected(template: TemplateSimpleDto) {
    this.router.toDocumentDetailsNew(template.id, this.contractVersion.contract.id);
  }

  getSectionNameForEndOfContract(): string {
    return this.kuke ? 'contract.details.endOfContract' : 'common.cancellation';
  }

  get isContrastRequestAccepted(): boolean {
    return (
      this.contractVersion &&
      this.contractVersion.id &&
      this.contractVersion.id > 0 &&
      this.contractVersion.status.id === ContractStatus.CONTRACT_REQUEST_ACCEPTED
    );
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  onGeneratedDocumentsPack() {
    this.router.toDocumentPackSearch(BusinessObjectType.CONTRACT, this.contractVersion.contract.id);
  }

  getCurrencyProfile() {
    return this.kuke ? DictionaryProfile.KUKE_BOND_CURRENCY : undefined;
  }

  get showConsortia() {
    return (
      this.contractVersion &&
      this.contractVersion.contract &&
      this.contractVersion.contract.type &&
      BusinessUtils.isBondConsortiaSectionVisible(this.contractVersion.contract.type.id)
    );
  }
}

export class BondingContractViewParams {
  // string when reading
  // readFromCache?: string | boolean;
  clientId?: string | number;
  newVersion?: string | boolean;
  contractId?: string | number;
}
