<details-view [title]="'cession.title'" objectName="cession.title" modelObjectName="cessionVersion"
              [fixedBlockHidden]="false" [parentComponent]="self" [versionListHidden]="!cessionVersion?.id"
              [deleteRightOrFunc]="PolicyElementaryRight.POLICY_CESSION_UPDATE">
      <div class="details-content">
        <form role="form" #ngForm="ngForm">
          <ng-container *ngIf="cessionVersion">
            <div class="bon-header"  style="padding-top:20px;" translate="common.details"></div>
            <div class="bon-card-group">
                <div class="bon-card-inner">
                    <dict-row labelKey="cession.details.type" [(ngModel)]="cessionVersion.cession.type" dictionary="CessionType" name="type"
                              (changeItem)="onSelectedType($event)" [showErrors]="showErrors" [disabled]="cessionFieldsDisabled"
                              required></dict-row>
                    <input-row *ngIf="showFullDetails" labelKey="cession.details.number" [(ngModel)]="cessionVersion.cession.number" name="number"
                               [showErrors]="showErrors" [disabled]="cessionFieldsDisabled"></input-row>
                    <dict-row *ngIf="cessionVersion?.cession?.type?.id" labelKey="common.status" [ngModel]="cessionVersion.businessStatus"
                              dictionary="CessionStatus" name="status" disabled="true"></dict-row>
                    <div *ngIf="cessionVersion?.cession?.type?.id" ngModelGroup="dates" #datesModel="ngModelGroup" validFromNotAfterValidTo>
                      <date-row labelKey="cession.details.validFrom" [(ngModel)]="cessionVersion.validFrom" [showErrors]="showErrors"
                                [disabled]="cessionFieldsDisabled" required name="validFrom"></date-row>
                      <span class="invalid inline"
                            *ngIf="datesModel && datesModel.control && datesModel.control.hasError('validFromAfterValidTo') && showErrors">Valid from can not be after Valid to!</span>
                      <date-row labelKey="cession.details.validTo"
                                [(ngModel)]="cessionVersion.validTo" name="validTo" [disabled]="cessionFieldsDisabled"></date-row>
                    </div>
                </div>
                <div class="bon-card-inner bon-label-190">
                    <ng-container *ngIf="showFullDetails">
                        <form-row labelKey="cession.details.assignor" *ngIf="assignors?.length > 0">
                            <item-combo class="bon-input-size"
                                        [disabled]="cessionFieldsDisabled"
                                        [items]="assignors" [(ngModel)]="cessionVersion.assignor" name="assignor"
                                        label="registrationName"></item-combo>
                        </form-row>
                        <search-row labelKey="cession.details.assignee" [(ngModel)]="cessionVersion.cession.assignee"
                                    displayedProperty="registrationName" [showErrors]="showErrors" #beneficiary="ngModel"
                                    name="beneficiary" [control]="beneficiary" required
                                    [openSelectorEmitter]="openSelectorEmitters[RelatedObject.BENEFICIARY]"
                                    [disabled]="cessionFieldsDisabled">
                        </search-row>
                        <input-row [label]="'BIC' | translate" [(ngModel)]="cessionVersion.bic" name="bic" [showErrors]="showErrors"
                                   [disabled]="cessionFieldsDisabled"></input-row>
                        <input-row [label]="'IBAN' | translate" [(ngModel)]="cessionVersion.iban" name="iban"
                                   [showErrors]="showErrors" [disabled]="cessionFieldsDisabled"></input-row>
                        <date-row *ngIf="!mehib" labelKey="cession.details.documentsDispatchDate" [(ngModel)]="cessionVersion.documentsDispatchDate"
                                  [showErrors]="showErrors" [disabled]="cessionFieldsDisabled"
                                  name="documentsDispatchDate"></date-row>
                        <date-row *ngIf="!mehib" labelKey="cession.details.documentsReceiptDate" [(ngModel)]="cessionVersion.documentsReceiptDate"
                                  [showErrors]="showErrors" [disabled]="cessionFieldsDisabled"
                                  name="documentsReceiptDate"></date-row>
                        <date-row labelKey="cession.details.conclusionDate" [(ngModel)]="cessionVersion.conclusionDate"
                                  [showErrors]="showErrors" [disabled]="cessionFieldsDisabled"
                                  name="conclusionDate"></date-row>
                    </ng-container>
                </div>
            </div>
            <bon-section labelKey="common.comment" *ngIf="cessionVersion?.cession?.type?.id">
              <div class="bon-card-group">
                    <textarea [(ngModel)]="cessionVersion.comment" name="comment" style="width: 100%" maxlength="2048"></textarea>
              </div>
            </bon-section>
            <bon-section labelKey="cession.section.buyers.title" *ngIf="showBuyers()">
                <div class="bon-card-group">
                    <div class="bon-card-inner">
                        <a-table [items]="cessionVersion.buyers" [addButton]="!cessionFieldsDisabled"
                                 [addButtonEventEmitterActive]="true" [addButtonEventEmitter]="openSelectorEmitters[RelatedObject.BUYER]"
                                 [deleteButton]="!cessionFieldsDisabled" [selection]="false">
                            <column property="id" labelKey="company.shared.companySelector.id"></column>
                            <column property="registrationName" labelKey="company.shared.companySelector.name"></column>
                            <column property="address" labelKey="company.shared.selector.address">
                                <ng-template let-item="item">
                                    <span>{{item.address | address: false}}</span>
                                </ng-template>
                            </column>
                            <column property="address" labelKey="common.country" sort="address.country.name">
                                <ng-template let-item="item">
                                    <span *ngIf="item.address">{{item.address.country?.name}}</span>
                                </ng-template>
                            </column>
                            <column property="nationalId" labelKey="company.shared.info.nationalId"></column>
                            <column property="vatNumber" labelKey="company.shared.info.taxId"></column>
                        </a-table>
                    </div>
                </div>
            </bon-section>
            <div class="bon-card-group">
                <company-selector-simple (selectItem)="onSelectAssignee($event)" labelKey="policy.technicalDetails.searchForAssignee"
                                         [open]="openSelectorEmitters[RelatedObject.BENEFICIARY]" [showDuplicates]="false"
                                         [criteria]="assigneeCriteria" [textCriteria]="assigneeTextCriteria"
                                         [searchModeSwitchable]="true" ></company-selector-simple>
            </div>
            <div class="bon-card-group">
                <company-selector-simple (selectItem)="onSelectBuyer($event)" labelKey="policy.technicalDetails.searchForBuyer"
                                         [open]="openSelectorEmitters[RelatedObject.BUYER]" [showDuplicates]="false"
                                         [criteria]="buyerCriteria" [textCriteria]="buyerTextCriteria"
                                         [limitBuyerMasterPolicyContractId]="cessionVersion.cession.masterPolicyContract.id"
                                         [searchModeSwitchable]="true" ></company-selector-simple>
            </div>
          </ng-container>
        </form>
      </div>

    <div class="additional-buttons">
        <lifecycle-dropdown-simple *ngIf="cessionVersion?.id" [service]="cessionVersionService"
            [selectedObject]="cessionVersion" [saveFunc]="onSave.bind(self)" [inProgress]="inProgress">
        </lifecycle-dropdown-simple>
        <item-dropdown *ngIf="cessionVersion?.id && !mehib" [items]="cessionTemplates" (menuSelect)="generateDocumentsCustom($event)" caption="common.button.newDocument" [dropup]="false"></item-dropdown>
        <item-dropdown *ngIf="cessionVersion?.id" [items]="cessionTemplates" (menuSelect)="generateDocumentsStandard($event)" caption="cession.details.generateDocuments.title" [dropup]="false"></item-dropdown>
        <button *ngIf="cessionVersion?.id && !cessionFieldsDisabled && showFullDetails && !mehib"
                class="bon-btn-info" btnIcon="fa-upload" (click)="toBuyersImporter()" type="button"
            translate="cession.button.importBuyers">
        </button>
    </div>
    <div class="information-header">
        <b class="font-larger a-link" [hidden]="!policyContractId" style="margin-right: 10px;"
           (click)="this.routerService.toPolicyContractTechnicalDetails(policyContractId)" translate>common.backToServiceDetails</b>
        <b class="font-larger a-link" *ngIf="previewLink()"
           (click)="routerService.toCessionVersionPreview(cessionVersion.id)" style="display: inline-block;">ID: {{cessionVersion.cession.id}} / {{cessionVersion.id}}</b>
    </div>
    <div class="versions-content" *ngIf="cessionVersion?.id">
        <policy-cession-version-list id="cessionVersionList" [selectedCessionVersion]="cessionVersion" (selectItem)="onSelectCessionVersion($event)"
        [canUnselect]="false" [showVersionNumberCol]="true" [selection]="true" [dataProvider]="cessionVersionDP"
        (refresh)="handleButtons()"></policy-cession-version-list>
    </div>
</details-view>
<confirm-dialog #deleteCessionConfirmDialog></confirm-dialog>

