<ng-container>
  <dict-row labelKey="differentPrice.type" [(ngModel)]="bond.differentPriceType" dictionary="BondDifferentPriceType"
            [ngModelOptions]="{standalone: true}" [codeRegexp]="differentPriceTypeRegExp"
            nullLabelKey="differentPrice.no" [disabled]="disabled">
  </dict-row>
  <form-row labelKey="differentPrice.price" *ngIf="isDifferentPriceType(BondDifferentPriceType.PERCENT_TYPES)" [control]="priceModel" [showErrors]="showErrors">
    <num-input class="bon-input-size" [(ngModel)]="bond.price" name="price" #priceModel="ngModel" numberOfDecimalPlaces="3" required  [disabled]="disabled"></num-input>
  </form-row>
  <form-row labelKey="differentPrice.premium" *ngIf="isDifferentPriceType(BondDifferentPriceType.PREMIUM_TYPES)" [control]="premiumModel" [showErrors]="showErrors">
    <num-input class="bon-input-size" [(ngModel)]="bond.premium" name="premium" #premiumModel="ngModel" numberOfDecimalPlaces="3" required  [disabled]="disabled"></num-input>
  </form-row>
</ng-container>
