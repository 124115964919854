import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {
  BackendError,
  CalcProblem,
  DictionaryBaseDto,
  DictionaryService,
  DocumentService,
  ErrorReason,
  GrowlService,
  IMultiSelectSettings,
  IMultiSelectTexts,
  LoggedUserService,
  OfferCalculationEnvelopeDto,
  OfferPBGCalculationDto,
  PBGCalcParams,
  PolicyInquiryCountryDto,
  RouterService,
  StringUtils,
} from '../../../../bonding_shared';
import {
  BusinessObjectType,
  InquiryProductType,
  PolicyElementaryRight,
  Template,
} from '../../../../bonding_shared/model/dictionary-ids';
import {InquiryPbgService} from '../../../../bonding_shared/services/inquiry-pbg.service';
import {InquiryCountries} from '../../domain/inquiry-countries';
import {BusinessUnit} from '../../../../bonding_shared/model/business-unit';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'calculator-pbg-details',
  templateUrl: 'calculator-pbg-details.component.html',
  styles: ['.bon-card-group {margin-top: 15px; margin-bottom: 15px}', 'table td {border-top: none}'],
})
export class CalculatorPbGDetailsComponent extends DetailsView implements OnInit {
  problems: CalcProblem[] = null;

  calculationPbg: OfferPBGCalculationDto = null;
  maxPaymentDates: {id: number}[];

  readonly PolicyElementaryRight = PolicyElementaryRight;
  readonly BusinessObjectType = BusinessObjectType;
  readonly portalMode: boolean;

  readonly multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
    selectionLimit: 1000,
    numSelectedOff: true,
    showCheckAll: true,
    showUncheckAll: true,
  };
  marketSelectTexts: IMultiSelectTexts = {defaultTitle: 'policyInquiry.details.countries.addMarket'};

  // @ViewChild('companyInfo') companyInfoComponent: CompanyInfoComponent;
  @ViewChild('otherCountriesSection', {read: ElementRef}) otherCountriesSection: ElementRef;

  countries: DictionaryBaseDto[] = null;

  otherCountries: any[] = null;

  constructor(
    private route: ActivatedRoute,
    public routerService: RouterService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    private inquiryPbGService: InquiryPbgService,
    private formBuilder: UntypedFormBuilder,
    private loggedUserService: LoggedUserService,
    private dictionaryService: DictionaryService,
    private documentService: DocumentService
  ) {
    super(growlService);
    this.saveButton.hidden = true;
    this.cancelButton.hidden = false;
    this.portalMode = this.loggedUserService.portal;

    this.initPbGForm();

    InquiryCountries.getProductsCountriesById(this.dictionaryService, InquiryProductType.KUKE_GSP_PBG).subscribe(
      (entries) => {
        this.countries = entries;
      }
    );

    this.saveButton.name = 'inquiry.calculator.pbg.saveButton';
  }

  private initPbGForm() {
    const notEmptyTableGroupValidator = (): ValidatorFn => {
      return (group: UntypedFormGroup): {[key: string]: boolean} => {
        if (!group.controls.table) {
          return null;
        }
        const valid = Object.keys((<UntypedFormGroup>group.controls.table).controls).length !== 0;

        return valid
          ? null
          : {
              notEmptyTableGroupValidator: true,
            };
      };
    };

    const formBuilder = this.formBuilder;
    const svGroup = formBuilder.group({});
    this.form = formBuilder.group({
      salesValues: svGroup,
    });

    svGroup.setValidators(notEmptyTableGroupValidator());
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params?: Params) {
    this.inProgress = true;
    this.inquiryPbGService.getCalculate().subscribe({
      next: (calc) => this.handleGetCalculation(calc),
      error: (error) => this.handleServerError(error),
      complete: () => (this.inProgress = false),
    });
  }

  private handleGetCalculation(offerCalculationEnv: OfferCalculationEnvelopeDto) {
    this.setPbgCalculation(offerCalculationEnv.calculationPbg);

    this.problems = offerCalculationEnv.problems;
    this.serverErrors = undefined;
    this.showErrors = false;
  }

  private setPbgCalculation(calculationPbg: OfferPBGCalculationDto) {
    this.calculationPbg = calculationPbg;

    this.initGuiMaxPaymentDate(calculationPbg.params);
    this.initGuiMaxPaymentDate(calculationPbg.results);
    this.maxPaymentDates = calculationPbg.maxPaymentDates.map((p) => ({id: p}));

    const hasNoRigh = false; // IKI-8547
    this.saveButton.hidden =
      hasNoRigh || !calculationPbg || !calculationPbg.results || calculationPbg.results.length === 0;
  }

  private initGuiMaxPaymentDate(calcs: PBGCalcParams[]) {
    if (!calcs) {
      return;
    }
    for (const param of calcs) {
      (param as PBGCalcParamsGUI).guiMaxPaymentDate = {id: param.maxPaymentDate};
    }
  }

  private rewriteGuiMaxPaymentDate(calcs: PBGCalcParams[]) {
    if (!calcs) {
      return;
    }
    for (const param of calcs) {
      param.maxPaymentDate = (param as PBGCalcParamsGUI).guiMaxPaymentDate.id;
    }
  }

  onSave(): void {
    if (this.inProgress) {
      console.warn('Parallel save clicks');
      return;
    }

    this.serverErrors = null;
    this.documentService.generatePdfPost(
      Template.POLICY_INQUIRY_CALCULATOR,
      this.calculationPbg,
      'Kalkulacja-PbG.pdf',
      (error) => {
        try {
          this.serverErrors = JSON.parse(error);
        } catch (e) {
          this.serverErrors = error;
        }
      }
    );
  }

  private prepareEnvelope(addNace: boolean): OfferCalculationEnvelopeDto {
    const envelope = <OfferCalculationEnvelopeDto>{};

    this.rewriteGuiMaxPaymentDate(this.calculationPbg.params);
    this.rewriteGuiMaxPaymentDate(this.calculationPbg.results);
    envelope.calculationPbg = this.calculationPbg;
    return envelope;
  }

  onCancel(route?: ActivatedRoute) {
    this.initializeView();
  }

  onCalculate() {
    this.showErrors = true;
    this.clearnPbgResults();
    if (!this.form.valid) {
      this.showFormError();
      console.log(this.form);
      StringUtils.logFormInvalidFieldsRecursive(this.form);
      return;
    }
    this.inProgress = true;
    this.serverErrors = null;

    this.inquiryPbGService.calculate(this.prepareEnvelope(true)).subscribe({
      next: (calcResult) => {
        this.handleGetCalculation(calcResult);
        this.growlService.notice('inquiry.calculator.succeeded');
      },
      error: (error) => this.handleServerError(error),
      complete: () => (this.inProgress = false),
    });
  }

  dataFetchedFromExternal() {
    return true;
  }

  handleServerError(error: BackendError | OfferCalculationEnvelopeDto, showGrowl = true) {
    this.inProgress = false;
    this.clearnPbgResults();

    if (error.hasOwnProperty('problems')) {
      const oc = <OfferCalculationEnvelopeDto>error;
      this.handleGetCalculation(oc);
      super.handleServerError(this.getBackendError(oc), showGrowl);
    } else {
      super.handleServerError(<BackendError>error, showGrowl);
    }
  }

  private clearnPbgResults() {
    if (this.calculationPbg && this.calculationPbg.results && this.calculationPbg.results.length > 0) {
      this.calculationPbg.results.length = 0;
    }
    this.saveButton.hidden = true;
  }

  private getBackendError(oc: OfferCalculationEnvelopeDto): BackendError {
    return oc.problems.map(
      (p) => <ErrorReason>{message: this.translateService.instant('inquiry.calculator.errors.' + p)}
    );
  }

  countriesAddItem(country: PolicyInquiryCountryDto) {
    for (const salesValue of this.calculationPbg.params) {
      if (!salesValue.selfRetention) {
        salesValue.selfRetention = this.calculationPbg.defaultSelfRetention;
      }
      if (!(salesValue as PBGCalcParamsGUI).guiMaxPaymentDate) {
        (salesValue as PBGCalcParamsGUI).guiMaxPaymentDate = {id: this.calculationPbg.maxPaymentDates[0]};
      }
    }
  }

  onNewInquiry() {
    if (this.portalMode) {
      this.routerService.toNewPolicyInquiryDetails(InquiryProductType.KUKE_GSP_PBG);
    } else {
      this.routerService.toInquiryDetailsNew(0, InquiryProductType.KUKE_GSP_PBG, BusinessUnit.KUKE_GSP);
    }
  }

  showOtherCountries() {
    InquiryCountries.getOtherProductsCountries(this.dictionaryService, InquiryProductType.KUKE_GSP_PBG).subscribe(
      (entries) => {
        this.otherCountries = entries.sort((a, b) => a[0].name.normalize().localeCompare(b[0].name.normalize()));
        setTimeout(() => {
          const ocs = this.otherCountriesSection;
          if (ocs && ocs.nativeElement) {
            ocs.nativeElement.scrollIntoView();
          }
        });
      }
    );
  }
}

interface PBGCalcParamsGUI extends PBGCalcParams {
  guiMaxPaymentDate: {id: number};
}
