<form #ngForm="ngForm" passwordConfirm>
  <div *ngIf="user" class="bon-card-main-group" style="padding-top: 15px;">
    <div class="bon-header" translate>user.data.generalHeader</div>
    <div class="bon-card-group" style="padding-top: 15px;">
      <div class="bon-card-inner">
        <form-row labelKey="user.data.businessUnit" >
          <business-unit-selector #businessUnit="ngModel" class="bon-input-size" [userDefaultBU]="false"
                                  [nullLabel]="" [(ngModel)]="user.businessUnit" name="businessUnit"
                                  (changeItem)="onBUChange($event)" required [disabled]="userNotEditable">
          </business-unit-selector>
          <error-message [control]="businessUnit" [show]="showErrors"></error-message>
        </form-row>
      </div>
      <div class="bon-card-inner">
        <dict-row *ngIf="user.userType?.id === 67000001" labelKey="user.data.defaultModule"
                  [(ngModel)]="user.defaultModule"
                  dictionary="ApplicationModule" name="applicationModule" nullLabel="" required
                  [showErrors]="showErrors" [presentationMode]="userNotEditable">

        </dict-row>
        <dict-row labelKey="user.data.language" [(ngModel)]="user.language"
                  [showErrors]="showErrors" required [codeRegexp]="langCodeRegexp" [presentationMode]="userNotEditable"
                  dictionary="Language" name="language" nullLabel=""></dict-row>
      </div>
      <div class="bon-card-inner"></div>
    </div>
  </div>
  <bon-section labelKey="common.section.details" *ngIf="user.userType">
    <div class="bon-card-group" style="padding-bottom: 25px;">
      <div class="bon-card-inner">
        <text-row labelKey="user.data.login" [value]="user.login"></text-row>
        <text-row labelKey="user.data.firstName" [value]="user.name"></text-row>
        <text-row labelKey="user.data.lastName" [value]="user.familyName"></text-row>
        <text-row labelKey="user.data.login" [value]="user.login"></text-row>
        <text-row labelKey="user.data.department" [value]="user.department"></text-row>
      </div>
      <div class="bon-card-inner">
        <text-row labelKey="user.data.email" [value]="user.email"></text-row>
        <text-row labelKey="user.data.phone" [value]="user.phone"></text-row>
        <text-row labelKey="user.data.office" [value]="user.office"></text-row>
      </div>
      <div class="bon-card-inner">
        <date-row labelKey="user.data.lastLoginDate" [(ngModel)]="user.lastLoginDate"
                  type="datetime" name="lastLoginDate" disabled="true" [presentationMode]="true"
                  ></date-row>
        <dict-row labelKey="user.data.status" [(ngModel)]="user.status" dictionary="UserStatus" name="userStatus"
                  disabled="true">
        </dict-row>
        <text-row labelKey="user.data.position" [value]="user.position"></text-row>
      </div>
    </div>
  </bon-section>
  <bon-section labelKey="user.data.comment" [expandable]="true" *ngIf="user.id">
    <div class="bon-card-group">
      <div class="bon-card-inner">
        <div class="bon-row">
          <textarea style="width: 97.5%;" [(ngModel)]="user.comment" name="comment" rows="6" [disabled]="userNotEditable"></textarea>
        </div>
      </div>
    </div>
  </bon-section>
  <div class="bon-card-main-group">
    <div class="bon-card-inner" style="width: 55%">
      <div class="bon-header" translate>user.data.profilesHeader</div>
      <div class="bon-card-group" >
        <div class="bon-card-inner">
          <div class="bon-row">
            <label style="padding-top: 5px;" class="bon-label" translate>user.data.assigned</label>
          </div>
          <a-table [items]="user.roles" [canUnselect]="false" [selection]="false">
            <column property="name" labelKey="user.data.name"></column>
          </a-table>
        </div>

      </div>
    </div>
    <div class="bon-card-inner" style="width: 45%">
      <ng-container >
        <div class="bon-header" translate>user.data.groupsHeader</div>
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <div class="bon-row">
              <label style="padding-top: 5px;" class="bon-label" translate>user.data.available</label>
            </div>
            <a-table [items]="groupsForProfileCategory" (selectItem)="addGroup($event)"
                     [canUnselect]="false" [selection]="false">
              <column labelKey="user.data.name" property="name"></column>
            </a-table>
          </div>
          <div class="bon-card-inner">
            <div class="bon-row">
              <label style="padding-top: 5px;" class="bon-label" translate>user.data.assigned</label>
            </div>
            <a-table [items]="user.groups" (selectItem)="removeGroup($event)" [canUnselect]="false" [selection]="false">
              <column labelKey="user.data.name" property="name"></column>
            </a-table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</form>
