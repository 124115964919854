import {Component, OnInit} from '@angular/core';
import {ControlContainer, NgForm, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {Section} from '../section';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {AppConfigService} from '../../../../../bonding_shared/services';
import {
  GlobalConditionsOfInsurance,
  POLICY_CONTRACT_ALL_LIVE_STATUSES_BEFORE_ISSUED,
  PolicyClauseType,
  PolicyContractType,
  PolicyContractVersionStatus,
} from '../../../../../bonding_shared/model/dictionary-ids';
import {validateFixedMinPremiums} from '../../../domain/fixed-min-premiums';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'kuke-premium-section',
  templateUrl: './kuke-premium-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class KukePremiumSectionComponent extends Section implements OnInit {
  self = this;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected translateService: TranslateService
  ) {
    super(policyContractViewService, appService);
  }

  ngOnInit(): void {
    if (this.isSectionVisible(this.viewSection.PREMIUM__KUKE_FIXED_MIN_PREMIUMS) && !this.policy.fixedMinPremiums) {
      this.policy.fixedMinPremiums = [];
    }
  }

  premiumFixedPresentationMode(): boolean {
    const generalReadonlyCondition = !this.euOrEpRequest && this.readonlyOrNotFirstVersion;
    if (!this.readonly) {
      const terminationRequest =
        this.policy.status &&
        (this.policy.status.id === PolicyContractVersionStatus.TERMINATION_REQUEST ||
          this.policy.status.id === PolicyContractVersionStatus.TERMINATION_REQUEST_ACCEPTED);
      return !terminationRequest && generalReadonlyCondition;
    }
    return generalReadonlyCondition;
  }

  premiumSubinsuredDisabled() {
    return !this.selectedPCVInOneOfStatuses(...POLICY_CONTRACT_ALL_LIVE_STATUSES_BEFORE_ISSUED);
  }

  private isGciAndHasClause(gci: GlobalConditionsOfInsurance, clauseTypesIds: Array<PolicyClauseType>): boolean {
    if (this.policy.globalConditionsOfInsurance && this.policy.globalConditionsOfInsurance.id === gci) {
      return this.policy.clauses
        .filter((clause) => clause.type && clause.type.id)
        .map((clause) => clause.type.id)
        .some((clauseTypeId) => clauseTypesIds.includes(clauseTypeId));
    }
    return false;
  }

  onCoverPlusChanged(checked: boolean) {
    if (!checked) {
      this.policy.coverPlusRate = undefined;
    }
  }

  onNnRateChanged(checked: boolean) {
    if (!checked) {
      this.policy.nnRateValue = undefined;
    }
  }

  isKukeKomEP() {
    return this.isType(PolicyContractType.KUKE_KOM_EP);
  }

  isKukeKomSP() {
    return this.isType(PolicyContractType.KUKE_KOM_SP);
  }

  isKukeGspPbg() {
    return this.isType(PolicyContractType.KUKE_GSP_PBG);
  }

  validateFixedMinPremiums(formGroup: UntypedFormGroup): ValidationErrors | null {
    if (!this.policy) {
      return null;
    }
    return validateFixedMinPremiums(formGroup);
  }
}
