import {Component, Input, NgZone, OnInit, Optional} from '@angular/core';
import {ContractBondExtendedDto, StringUtils} from '../../../../bonding_shared';
import {Chart, ChartDataset, ChartOptions, ChartType} from 'chart.js';
import {PortalRouterService} from '../../../../portal/services';
import {ActivatedRoute} from '@angular/router';
import {ChartUtils, Label} from '../chart-utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'bar-chart-contract-free-capacity',
  templateUrl: 'barchart-contract-free-capacity.component.pug',
})
export class BarChartContractFreeCapacityComponent implements OnInit {
  @Input() contractBondTypes: ContractBondExtendedDto[];
  contractVersionId: number;
  public barChartLabels: Label[] = [];
  public barChartData: ChartDataset[];
  public barChartType: ChartType = 'bar';
  public barChartOptions: ChartOptions = {
    responsive: true,
    layout: {
      padding: {
        top: 30,
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: function (value) {
          return StringUtils.formatNumber(value);
        },
      },
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = <number>context.dataset.data[context.dataIndex];
            return StringUtils.formatNumber(value);
          },
        },
      },
    },
    onHover: (event, activeElements) => {
      (<HTMLElement>event.native.target).style.cursor = activeElements[0] ? 'pointer' : 'default';
    },
    onClick: (event, activeElements) => {
      const idx = activeElements[0].index;
      const selectedType = this.contractBondTypes.find(
        (cbt: ContractBondExtendedDto) => cbt.bondType.name === this.barChartLabels[idx]
      );
      if (selectedType && this.portalRouterService) {
        this.ngZone.run(() =>
          this.portalRouterService.toClientBondDetailsNewWithBondType(this.contractVersionId, selectedType.bondType.id)
        );
      }
    },
  };

  constructor(
    private translateService: TranslateService,
    @Optional() private portalRouterService: PortalRouterService,
    private route: ActivatedRoute,
    private ngZone: NgZone
  ) {
    Chart.register(ChartDataLabels);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.contractVersionId = +params['contractVersionId'];
    });
    this.translateService.get('dashboard.Free capacity').subscribe((label) => {
      const freeCapacityLabel = label;
      const usedCapacityLabel = this.translateService.instant('dashboard.Used capacity');
      this.initData(freeCapacityLabel, usedCapacityLabel);
    });
  }

  initData(freeCapacityLabel: string, usedCapacityLabel: string) {
    const freeAmounts = [];
    const usedAmounts = [];
    for (const obj of this.contractBondTypes) {
      const t: ContractBondExtendedDto = obj;
      this.barChartLabels.push(t.bondType.name);
      freeAmounts.push(t.freeAmountSysCurr ? t.freeAmountSysCurr : 0);
      usedAmounts.push(t.usedAmountSysCurr);
    }
    this.barChartData = [
      {data: freeAmounts, stack: 'a', label: freeCapacityLabel, backgroundColor: ChartUtils.COLORS[2]},
      {data: usedAmounts, stack: 'a', label: usedCapacityLabel, backgroundColor: ChartUtils.COLORS[1]},
    ];
  }
}
