import {Component, OnInit} from '@angular/core';
import {RouterService} from '../../../bonding_shared/services/router-service';
import {
  ContractDto,
  ContractVersionCriteriaDto,
  ContractVersionDto,
  SalesRegionDto,
  UserDto,
  UserSimpleDto,
} from '../../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {ContractGuiService} from './services/contract-gui.service';
import {ComboItem} from '../../../bonding_shared/components/combos/string-combo.component';
import {LoggedUserService} from '../../../bonding_shared/services/logged-user.service';
import {BusinessUnitDto} from '../../../bonding_shared/model';
import {SearchView} from '../../../bonding_shared/components/search/search-view/search-view';
import {AppConfigService, ContractVersionService, FeatureService, UserService} from '../../../bonding_shared/services';
import {BondingElementaryRight, DictionaryProfile, Feature} from '../../../bonding_shared/model/dictionary-ids';
import {PortalRouterService} from '../../../portal/services';
import {UserResponsibleService} from '../../../bonding_shared/services/user-responsible.service';
import {SalesRegionService} from '../../../bonding_shared/services/sales-region.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'contract-version-search',
  templateUrl: './contract-version-search.component.pug',
})
export class ContractVersionSearchComponent extends SearchView implements OnInit {
  readonly BondingElementaryRight = BondingElementaryRight;

  dataProvider: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionDto>;
  versionPhases: ComboItem[] = [
    new ComboItem('ACTIVE', this.translateService.instant('contract.search.criteria.current')),
    new ComboItem('LAST', this.translateService.instant('contract.search.criteria.last')),
  ];
  public showShortNameIfExists: boolean; // show short company name instead of registration name when it exists on search view
  salesUsers: UserSimpleDto[] = null;
  managers: UserSimpleDto[] = null;
  readonly Feature = Feature;
  readonly DictionaryProfile = DictionaryProfile;
  salesRegions: SalesRegionDto[];

  constructor(
    public router: RouterService,
    public portalRouter: PortalRouterService,
    protected translateService: TranslateService,
    private service: ContractGuiService,
    public appService: AppConfigService,
    private loggedUserService: LoggedUserService,
    private featureService: FeatureService,
    private protalContractVersionService: ContractVersionService,
    private userResponsibleService: UserResponsibleService,
    private userService: UserService,
    private salesRegionService: SalesRegionService
  ) {
    super();
    if (loggedUserService.portal) {
      this.dataProvider = new SearchDataProvider<ContractVersionCriteriaDto, ContractVersionDto>(
        protalContractVersionService
      );
      this.dataProvider.searchCriteria.criteria = this.protalContractVersionService.getClientContractsCriteria(true);
    } else {
      this.dataProvider = service.searchDataProvider;
    }
    this.selectorNameList = ['Broker'];
    this.initializeSelectorEmitters(true);
    this.showShortNameIfExists = featureService.get(Feature.COMPANY_SHOW_SHORT_NAME_IF_EXISTS);
  }

  ngOnInit() {
    if (!this.dataProvider.searchCriteria.criteria) {
      this.initializeCriteria();
    }
    if (this.kuke) {
      this.loadSalesUsers();
      this.loadManagers();
      this.salesRegionService.getAll().subscribe((data) => {
        this.salesRegions = data;
      });
    }
  }

  initializeCriteria() {
    this.dataProvider.searchCriteria.criteria = <ContractVersionCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.contract = <ContractDto>{};
    this.dataProvider.textSearch = false;
    this.dataProvider.searchCriteria.criteria.activated = true;

    const user: UserDto = this.loggedUserService.getLoggedUserData();
    if (user && user.businessUnit) {
      this.dataProvider.searchCriteria.criteria.contract.businessUnit = <BusinessUnitDto>{id: user.businessUnit.id};
    }

    this.dataProvider.searchCriteria.criteria.userResponsible =
      this.userResponsibleService.getUserResponsibleSearchCriteria();
    if (this.kuke) {
      this.dataProvider.searchCriteria.criteria.versionPhase = 'ACTIVE';
    }
  }

  get kuke() {
    return this.appService.kuke;
  }

  toContractPreview(id: number) {
    if (this.loggedUserService.portal) {
      this.portalRouter.toClientContractPreview(id);
    } else {
      this.router.toContractPreview(id);
    }
  }

  protected loadSalesUsers() {
    this.userService.getSalesUsers().subscribe((users) => {
      this.salesUsers = users;
      console.log(this.salesUsers);
    });
  }

  protected loadManagers() {
    this.userService
      .getBondingSupport(this.dataProvider.searchCriteria.criteria.contract.businessUnit.id)
      .subscribe((res) => {
        this.managers = res;
      });
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }
}
