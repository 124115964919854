import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {
  AppConfigService,
  DictionaryBaseDto,
  DictionaryBaseService,
  DictionaryService,
  PolicyContractVersionDto,
} from '../../../../bonding_shared';
import {
  NoticeReason,
  PolicyClauseType,
  PolicyContractType,
  PolicyContractVersionStatus,
  PolicyTerminationType,
} from '../../../../bonding_shared/model/dictionary-ids';
import * as moment from 'moment';
import {ControlContainer, NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'kuke-termination-section',
  templateUrl: './kuke-termination-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class KukeTerminationSectionComponent extends Section implements OnInit {
  self = this;

  PolicyTerminationType = PolicyTerminationType;

  terminationSectionVisible: boolean;
  terminationTypeDisabled: boolean;

  terminationDateVisible: boolean;
  terminationDateRequired: boolean;
  terminationDateDisabled: boolean;

  terminationDocumentsDateRequired: boolean;

  notificationOfTerminationDateVisible: boolean;
  notificationOfTerminationDateRequired: boolean;

  noticeReasonRequired: boolean;

  terminationAgreement: boolean;
  buttonsEnabled: boolean;

  _titleLabelKey: string;
  _noticeReasonRegexp: RegExp;
  _terminationTypesRegexp: RegExp;

  validToWasChanged = false;
  tempTerminationDate: Date;

  minTerminationDate: Date;
  maxTerminationDate: Date;

  showNoticeReason = false;
  _acceptanceProcess = false;
  _rejectProcess = false;
  _acceptanceError = false;

  @Output() saveEvent = new EventEmitter<void>();

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected dictionaryService: DictionaryService,
    protected dictionaryBaseService: DictionaryBaseService,
    protected translateService: TranslateService
  ) {
    super(policyContractViewService, appService, undefined, dictionaryService);
  }

  ngOnInit(): void {
    if (this.policy) {
      this.updateShowNoticeReason(this.policy.terminationType);
    }
    this.recalculateTerminationDateBoundries();
  }

  @Input() set policy(policy: PolicyContractVersionDto) {
    this._policy = policy;

    this.recalculateTerminationDateBoundries();
    if (this.policy) {
      this.updateShowNoticeReason(this.policy.terminationType);
    }

    this.recalculateSectionVisibility();

    // termination TYPE
    this.recalculateTerminationTypeDisabled();
    this.recalculateTerminationTypesRegexp();

    // termination DATE
    this.recalculateTerminationDateDisabled();
    this.recalculateTerminationDateVisible();
    // this.recalculateTerminationDateRequired();

    // notice REASON
    this.recalculateNoticeReasonRequired();
    this.recalculateNoticeReasonRegExp();

    // notification of termination date
    this.recalculateNotificationOfTerminationDateVisible();
    this.recalculateNotificationOfTerminationDateRequired();

    this.recalculateTerminationDocumentsDateRequired();
    this.recalculateTerminationAgreement();

    this.recalculateButtonsEnabled();
    this.recalculateTitleLabelKey();
  }

  get policy() {
    return this._policy;
  }

  @Input() set showErrors(showErrors: boolean) {
    super.showErrors = showErrors;
    this.recalculateButtonsEnabled();
  }

  get showErrors(): boolean {
    return super.showErrors;
  }

  @Input() set acceptanceError(value: boolean) {
    this._acceptanceError = value;
    this.recalculateButtonsEnabled();
  }

  get acceptanceError(): boolean {
    return this._acceptanceError;
  }

  set acceptanceProcess(value: boolean) {
    this._acceptanceProcess = value;
    this.recalculateTerminationDocumentsDateRequired();
    this.recalculateTerminationDateRequired();
    this.recalculateNotificationOfTerminationDateRequired();
    this.recalculateNoticeReasonRequired();
  }

  get acceptanceProcess() {
    return this._acceptanceProcess;
  }

  set rejectProcess(value: boolean) {
    this._rejectProcess = value;
    this.recalculateTerminationDateRequired();
    this.recalculateNotificationOfTerminationDateRequired();
    this.recalculateNoticeReasonRequired();
  }

  get rejectProcess() {
    return this._rejectProcess;
  }

  public recalculateTerminationDateBoundries() {
    if (this.policy && !this.terminationDateDisabled && !this.readonly) {
      let offerValidTo: Date;
      if (this.policy.offer && this.policy.offer.policyValidTo) {
        offerValidTo = this.policy.offer.policyValidTo;
      }

      this.minTerminationDate = this.policy.accrualDate
        ? moment(this.policy.accrualDate).add(1, 'day').toDate()
        : this.policy.validFrom;

      this.maxTerminationDate = moment
        .max(moment(this.policy.validTo), moment(this.policy.policyContract.validTo), moment(offerValidTo))
        .toDate();

      if (moment(this.minTerminationDate).isAfter(this.policy.validTo)) {
        this.policy.terminationDate = this.minTerminationDate;
        this.policy.validTo = this.minTerminationDate;
      }
      if (moment(this.maxTerminationDate).isBefore(this.policy.validTo)) {
        this.policy.terminationDate = this.maxTerminationDate;
      }
    }
  }

  get terminationTypesRegexp(): RegExp {
    return this._terminationTypesRegexp;
  }

  recalculateTerminationTypesRegexp() {
    const afterPolicyStart = moment(this.policy.policyContract.validFrom).isSameOrBefore(new Date(), 'date');
    if (
      this.policy &&
      afterPolicyStart &&
      this.policy.baseVersionNumber === 1 &&
      this.policy.policyContract.policyYear === 1
    ) {
      // RESIGNATION available only in first year and first termination of first version and when policy was already started
      if (this.isProductEp()) {
        this._terminationTypesRegexp = /^(?!.*(CANCELLATION_PAYMENT|CANCELLATION_DOCUMENT|EXPIRE)).*$/;
      } else {
        this._terminationTypesRegexp = /^(?!.*(CANCELLATION_PAYMENT|CANCELLATION_DOCUMENT|EXPIRE|EARLY_REPAYMENT)).*$/;
      }
    }
    if (this.isProductEp()) {
      this._terminationTypesRegexp = /^(?!.*(CANCELLATION_PAYMENT|CANCELLATION_DOCUMENT|RESIGNATION|EXPIRE)).*$/;
    } else {
      this._terminationTypesRegexp =
        /^(?!.*(CANCELLATION_PAYMENT|CANCELLATION_DOCUMENT|RESIGNATION|EXPIRE|EARLY_REPAYMENT)).*$/;
    }
  }

  get noticeReasonRegexp(): RegExp {
    return this._noticeReasonRegexp;
  }

  private recalculateNoticeReasonRegExp() {
    if (!this.policyHas227Clause()) {
      this._noticeReasonRegexp = /^(?!.*(N21)).*$/;
    }
  }

  onNotificationOfTerminationDateChanged(notificationOfTerminationDate: Date) {
    const insured =
      this.policy.terminationType && this.policy.terminationType.id === PolicyTerminationType.TERMINATED_BY_INSURED;
    const insurer =
      this.policy.terminationType && this.policy.terminationType.id === PolicyTerminationType.INSURER_TERMINATION;
    if (insured) {
      if (this.policyHas227Clause() && this.isNoticeReason(NoticeReason.INSUFFICIENT_LIMIT_COVERAGE_CLAUSE_227)) {
        this.policy.terminationDate = notificationOfTerminationDate;
      } else if (this.isProductPbg()) {
        this.policy.terminationDate = moment(notificationOfTerminationDate).add(30, 'days').toDate();
      }
    }
    if (insurer && this.isProductPbg()) {
      this.policy.terminationDate = moment(notificationOfTerminationDate).add(30, 'days').toDate();
    }
  }

  onTerminationDateChanged(terminationDate: Date) {
    if (terminationDate) {
      this.policy.validTo = terminationDate;
    }
  }

  onTerminationTypeChanged(terminationType: DictionaryBaseDto) {
    this.clearTerminationFields();

    if (terminationType) {
      if (terminationType.id === PolicyTerminationType.INSURER_TERMINATION && this.isProductPacked()) {
        this.policy.terminationDate = this.policy.policyContract.validTo;
      } else if (
        terminationType.id === PolicyTerminationType.TERMINATED_BY_INSURED ||
        terminationType.id === PolicyTerminationType.INSURER_TERMINATION
      ) {
        if (this.isProductPbg()) {
          this.policy.notificationOfTerminationDate = new Date();
          this.policy.terminationDate = moment(new Date()).add(30, 'days').toDate();
        } else if (!this.isProductEp()) {
          this.policy.terminationDate = this.policy.policyContract.validTo;
        }
      }
    } else {
      this.policy.terminationDate = null;
    }
    if (this.isTerminationType(PolicyTerminationType.RESIGNATION)) {
      this.policy.terminationDate = this.policy.validFrom;
      this.policy.validTo = this.policy.validFrom;
      this.validToWasChanged = true;
    } else if (this.validToWasChanged) {
      this.policy.validTo = this.policy.policyContract.validTo;
      this.validToWasChanged = false;
    }

    if (terminationType && terminationType.id === PolicyTerminationType.TERMINATED_BY_INSURED) {
      this.policy.notificationOfTerminationDate = new Date();
    } else if (!this.isProductPbg()) {
      this.policy.notificationOfTerminationDate = null;
    }

    this.policy.withdrawalOnTime = this.isTerminationType(PolicyTerminationType.RESIGNATION) ? true : null;
    this.updateShowNoticeReason(terminationType);

    if (this.policy.terminationDate) {
      this.policy.validTo = this.policy.terminationDate;
    }

    this.recalculateTerminationDateVisible();
    this.recalculateTerminationDateRequired();
    this.recalculateTerminationDateDisabled();

    this.recalculateTerminationDocumentsDateRequired();
    this.recalculateTerminationAgreement();
    this.recalculateTitleLabelKey();

    this.recalculateNotificationOfTerminationDateVisible();
    this.recalculateNotificationOfTerminationDateRequired();
  }

  onNoticeReasonChanged(noticeReason: DictionaryBaseDto) {
    if (noticeReason && noticeReason.id === NoticeReason.INSUFFICIENT_LIMIT_COVERAGE_CLAUSE_227) {
      this.tempTerminationDate = this.policy.terminationDate;
      this.policy.terminationDate = this.policy.notificationOfTerminationDate;
    } else if (this.tempTerminationDate) {
      this.policy.terminationDate = this.tempTerminationDate;
      this.tempTerminationDate = null;
    }
    this.recalculateTerminationDateVisible();
  }

  private updateShowNoticeReason(terminationType: DictionaryBaseDto) {
    if (terminationType) {
      this.dictionaryService.getDictionaryEntry('PolicyTerminationType', terminationType.id).subscribe((tt) => {
        this.showNoticeReason =
          tt.relatedDictionaries &&
          tt.relatedDictionaries['NoticeReason'] &&
          tt.relatedDictionaries['NoticeReason'].length > 0;
      });
    } else {
      this.showNoticeReason = false;
    }
  }

  isNotificationOfTerminationDateVisible(): boolean {
    return this.notificationOfTerminationDateVisible;
  }

  private recalculateNotificationOfTerminationDateVisible() {
    this.notificationOfTerminationDateVisible = this.isTerminationType(
      PolicyTerminationType.INSURER_TERMINATION,
      PolicyTerminationType.TERMINATED_BY_INSURED,
      PolicyTerminationType.TERMINATION_AGREEMENT,
      PolicyTerminationType.TERMINATION_AGREEMENT_BEFORE_THE_END_PERIOD
    );
  }

  isTerminationDateRequired(): boolean {
    return this.terminationDateRequired;
  }

  private recalculateTerminationDateRequired() {
    if (this.selectedPCVInOneOfStatuses(PolicyContractVersionStatus.TERMINATION_REJECTED) || this.rejectProcess) {
      this.terminationDateRequired = false;
      return;
    }
    if (this.acceptanceProcess) {
      this.terminationDateRequired = true;
      return;
    }
    this.terminationDateRequired =
      !this.selectedPCVInOneOfStatuses(
        PolicyContractVersionStatus.TERMINATION_REJECTED,
        PolicyContractVersionStatus.TERMINATION_REQUEST
      ) &&
      (this.isTerminationType(
        PolicyTerminationType.RESIGNATION,
        PolicyTerminationType.CANCELLATION_PAYMENT,
        PolicyTerminationType.INSURER_TERMINATION
      ) ||
        this.isTerminationDateVisible());
  }

  private recalculateNotificationOfTerminationDateRequired() {
    if (
      this.selectedPCVInOneOfStatuses(PolicyContractVersionStatus.TERMINATION_REJECTED) ||
      this.isTerminationType(PolicyTerminationType.INSURER_TERMINATION) ||
      this.rejectProcess
    ) {
      this.notificationOfTerminationDateRequired = false;
      return;
    }
    if (this.acceptanceProcess) {
      this.notificationOfTerminationDateRequired = true;
      return;
    }
    this.notificationOfTerminationDateRequired =
      (this.selectedPCVInOneOfStatuses(PolicyContractVersionStatus.TERMINATION_REQUEST) &&
        this.isTerminationType(PolicyTerminationType.TERMINATED_BY_INSURED)) ||
      (this.selectedPCVInOneOfStatuses(PolicyContractVersionStatus.TERMINATION_REQUEST_ACCEPTED) &&
        this.isTerminationAgreement());
  }

  isNotificationOfTerminationDateRequired(): boolean {
    return this.notificationOfTerminationDateRequired;
  }

  private recalculateNoticeReasonRequired(): boolean {
    if (this.showNoticeReason) {
      if (this.selectedPCVInOneOfStatuses(PolicyContractVersionStatus.TERMINATION_REJECTED) || this.rejectProcess) {
        this.noticeReasonRequired = false;
        return;
      }
      if (this.acceptanceProcess) {
        this.noticeReasonRequired = true;
        return;
      }
      this.noticeReasonRequired =
        !this.selectedPCVInOneOfStatuses(PolicyContractVersionStatus.TERMINATION_REQUEST) ||
        (this.selectedPCVInOneOfStatuses(PolicyContractVersionStatus.TERMINATION_REQUEST) &&
          (this.isTerminationAgreement() ||
            this.isTerminationType(
              PolicyTerminationType.INSURER_TERMINATION,
              PolicyTerminationType.TERMINATED_BY_INSURED
            )));
      return;
    }
    this.noticeReasonRequired = false;
  }

  isNoticeReasonRequired(): boolean {
    return this.noticeReasonRequired;
  }

  isTerminationDateDisabled(): boolean {
    return this.terminationDateDisabled;
  }

  private recalculateTerminationDateDisabled() {
    const insuredAndNotEuEp =
      !this.isProductEp() &&
      (this.isTerminationType(PolicyTerminationType.TERMINATED_BY_INSURED) ||
        this.isTerminationType(PolicyTerminationType.INSURER_TERMINATION));
    const resignation = this.isTerminationType(PolicyTerminationType.RESIGNATION);
    this.terminationDateDisabled = insuredAndNotEuEp || resignation;
  }

  isTerminationTypeDisabled(): boolean {
    return this.terminationTypeDisabled;
  }

  private recalculateTerminationTypeDisabled() {
    this.terminationTypeDisabled = !this.selectedPCVInOneOfStatuses(PolicyContractVersionStatus.TERMINATION_REQUEST);
  }

  isTerminationDateVisible(): boolean {
    return this.terminationDateVisible;
  }

  private recalculateTerminationDateVisible() {
    const agreementCondition = this.isTerminationType(
      PolicyTerminationType.TERMINATION_AGREEMENT,
      PolicyTerminationType.TERMINATION_AGREEMENT_BEFORE_THE_END_PERIOD
    );

    const pbgOrClause227 =
      this.isProductPbg() ||
      (this.isNoticeReason(NoticeReason.INSUFFICIENT_LIMIT_COVERAGE_CLAUSE_227) && this.policyHas227Clause());
    const insuredCondition =
      this.isTerminationType(PolicyTerminationType.TERMINATED_BY_INSURED) &&
      (!pbgOrClause227 || (pbgOrClause227 && !!this.policy.notificationOfTerminationDate));

    const insurerCondition =
      this.isTerminationType(PolicyTerminationType.INSURER_TERMINATION) &&
      (this.isProductPacked() || this.isProductPbg());

    const earlyCondition =
      this.isTerminationType(PolicyTerminationType.EARLY_REPAYMENT_OF_INSURED_RECEIVABLES) && this.isProductEp();

    const differentNumberCondition = this.isTerminationType(
      PolicyTerminationType.CONTRACT_CONTINUED_UNDER_A_DIFFERENT_CONTRACT_NUMBER
    );

    const resignationOnTime = this.isTerminationType(PolicyTerminationType.RESIGNATION) && this.policy.withdrawalOnTime;
    const resignationOffTime =
      this.isTerminationType(PolicyTerminationType.RESIGNATION) && !this.policy.withdrawalOnTime;

    const canceledType = this.isTerminationType(
      PolicyTerminationType.CANCELLATION_PAYMENT,
      PolicyTerminationType.CANCELLATION__DOCUMENTS
    );
    const notTerminationRequest = this.selectedPCVInOneOfStatuses(
      PolicyContractVersionStatus.TERMINATION_ACTIVATED,
      PolicyContractVersionStatus.TERMINATION_REJECTED,
      PolicyContractVersionStatus.REQUEST_REJECTED
    );
    this.terminationDateVisible =
      agreementCondition ||
      insuredCondition ||
      insurerCondition ||
      earlyCondition ||
      differentNumberCondition ||
      resignationOnTime ||
      (notTerminationRequest && !canceledType && !resignationOffTime);
    this.recalculateTerminationDateRequired();
  }

  onChangeWithdrawalOnTime() {
    if (!this.policy.withdrawalOnTime) {
      this.policy.terminationDate = null;
      this.policy.validTo = this.policy.policyContract.validTo;
      this.validToWasChanged = false;
    } else {
      this.policy.terminationDate = this.policy.validFrom;
      this.policy.validTo = this.policy.validFrom;
      this.validToWasChanged = true;
    }
  }

  isTerminationType(...types: PolicyTerminationType[]): boolean {
    const tId = this.policy && this.policy.terminationType && this.policy.terminationType.id;
    return !!tId && types.indexOf(tId) !== -1;
  }

  private isNoticeReason(...noticeReasons: NoticeReason[]): boolean {
    const nId = this.policy && this.policy.noticeReason && this.policy.noticeReason.id;
    return !!nId && noticeReasons.indexOf(nId) !== -1;
  }

  private isProductPacked(): boolean {
    return this.isProductType(
      PolicyContractType.KUKE_KOM_OP,
      PolicyContractType.KUKE_KOM_SP,
      PolicyContractType.KUKE_KOM_FK,
      PolicyContractType.KUKE_KOM_FE
    );
  }

  private isProductEp(): boolean {
    return this.isProductType(PolicyContractType.KUKE_KOM_EP);
  }

  private isProductPbg(): boolean {
    return this.isProductType(PolicyContractType.KUKE_GSP_PBG);
  }

  private isProductType(...types: PolicyContractType[]): boolean {
    const typeId = this.policy && this.policy.contractType && this.policy.contractType.id;
    return !!typeId && types.indexOf(typeId) !== -1;
  }

  private isTerminationAgreement(): boolean {
    return this.terminationAgreement;
  }

  private recalculateTerminationAgreement() {
    this.terminationAgreement = this.isTerminationType(
      PolicyTerminationType.TERMINATION_AGREEMENT,
      PolicyTerminationType.TERMINATION_AGREEMENT_BEFORE_THE_END_PERIOD
    );
    this.recalculateNotificationOfTerminationDateRequired();
    this.recalculateNoticeReasonRequired();
    this.recalculateButtonsEnabled();
  }

  isDispatchDocumentsDate(): boolean {
    return this.isTerminationAgreement();
  }

  isCustomerReturnedDocumentsDate(): boolean {
    return this.isTerminationAgreement();
  }

  isTerminationDocumentsDateRequired(): boolean {
    return this.terminationDocumentsDateRequired;
  }

  private recalculateTerminationDocumentsDateRequired() {
    if (this.acceptanceProcess) {
      this.terminationDocumentsDateRequired = true;
    } else {
      this.terminationDocumentsDateRequired =
        this.isTerminationAgreement() &&
        this.selectedPCVInOneOfStatuses(PolicyContractVersionStatus.TERMINATION_REQUEST_ACCEPTED);
    }
  }

  acceptTermination() {
    this.acceptanceProcess = true;
    const requiredAndEmptyOrInvalid =
      this.isTerminationDateRequired() &&
      (!this.policy.terminationDate ||
        moment(this.policy.terminationDate).isBefore(this.minTerminationDate, 'date') ||
        !moment(this.policy.terminationDate).isSameOrBefore(this.maxTerminationDate, 'date'));
    if (!this.policyForm.valid || requiredAndEmptyOrInvalid) {
      this.acceptanceError = true;
    } else {
      this.acceptanceError = false;
      this.setStateAndSavePolicy(PolicyContractVersionStatus.TERMINATION_REQUEST_ACCEPTED);
    }
  }

  rejectTermination() {
    this.acceptanceProcess = false;
    this.rejectProcess = true;
    this.acceptanceError = false;
    this.setStateAndSavePolicy(PolicyContractVersionStatus.TERMINATION_REJECTED);
  }

  terminationDateInvalidMsg() {
    if ((this.showErrors || this.acceptanceError) && this.policy.terminationDate) {
      if (moment(this.policy.terminationDate).isBefore(this.minTerminationDate, 'date')) {
        return this.translateService.instant('policy.details.terminationSection.terminationDateBeforeMin', {
          minDate: this.minTerminationDate.toLocaleDateString(),
        });
      } else if (!moment(this.policy.terminationDate).isSameOrBefore(this.maxTerminationDate, 'date')) {
        return this.translateService.instant('policy.details.terminationSection.terminationDateAfterMax', {
          maxDate: this.maxTerminationDate.toLocaleDateString(),
        });
      }
    } else {
      return '';
    }
  }

  private setStateAndSavePolicy(state: PolicyContractVersionStatus) {
    this.dictionaryBaseService.getDictionaryEntry('PolicyContractVersionStatus', state).subscribe((s) => {
      this.policy.status = s;
      this.callSaveInParent();
    });
  }

  private callSaveInParent() {
    this.saveEvent.emit();
    this.rejectProcess = false;
  }

  private recalculateSectionVisibility() {
    if (this.policy && this.policy.status) {
      this.terminationSectionVisible =
        [
          PolicyContractVersionStatus.TERMINATION_REQUEST,
          PolicyContractVersionStatus.TERMINATION_REQUEST_ACCEPTED,
          PolicyContractVersionStatus.TERMINATION_REJECTED,
          PolicyContractVersionStatus.TERMINATION_ACTIVATED,
          PolicyContractVersionStatus.TERMINATION_RESIGNATION,
        ].includes(this.policy.status.id) ||
        (this.policy.status.id === PolicyContractVersionStatus.REQUEST_REJECTED &&
          this.policy.terminationType !== undefined) ||
        (this.policy.terminationType && // resignation + withdrawalOnTime set to false + status ACTIVATED
          this.policy.terminationType.id === PolicyTerminationType.RESIGNATION &&
          this.policy.withdrawalOnTime === false);
    } else {
      this.terminationSectionVisible = false;
    }
  }

  private recalculateButtonsEnabled() {
    const req = this.selectedPCVInOneOfStatuses(PolicyContractVersionStatus.TERMINATION_REQUEST);
    const acceptWithErrors =
      this.selectedPCVInOneOfStatuses(PolicyContractVersionStatus.TERMINATION_REQUEST_ACCEPTED) &&
      (this.showErrors || this.acceptanceError);
    this.buttonsEnabled = (req || acceptWithErrors) && this.isTerminationAgreement();
  }

  areButtonsEnabled() {
    return this.buttonsEnabled;
  }

  private clearTerminationFields() {
    this.policy.noticeReason = null;
    this.policy.notificationOfTerminationDate = null;
    this.policy.withdrawalOnTime = null;
    this.policy.terminationDate = null;
    this.policy.exitPenalty = null;
    this.policy.dispatchDocumentsDate = null;
    this.policy.customerReturnedDocumentsDate = null;
  }

  private policyHas227Clause() {
    return (
      this.policy &&
      this.isProductType(PolicyContractType.KUKE_KOM_OP, PolicyContractType.KUKE_KOM_SP) &&
      this.policy.clauses.filter(
        (cl) =>
          cl.type &&
          (cl.type.id === PolicyClauseType.OP_2016_01__227 ||
            cl.type.id === PolicyClauseType.OP_2018_12__227 ||
            cl.type.id === PolicyClauseType.SP_2017_06__227)
      ).length > 0
    );
  }

  titleLabelKey() {
    return this._titleLabelKey;
  }

  private recalculateTitleLabelKey() {
    if (
      this.policy.terminationType &&
      (this.policy.terminationType.id === PolicyTerminationType.TERMINATION_AGREEMENT_BEFORE_THE_END_PERIOD ||
        this.policy.terminationType.id === PolicyTerminationType.TERMINATION_AGREEMENT) &&
      this.policy.status.id === PolicyContractVersionStatus.TERMINATION_REJECTED
    ) {
      this._titleLabelKey = 'policy.details.terminationSection.titleReject';
    }
    this._titleLabelKey = 'policy.details.terminationSection.title';
  }
}
