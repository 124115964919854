<form #ngForm="ngForm" passwordConfirm>
  <div *ngIf="user" class="bon-card-main-group" style="padding-top: 15px;">
    <div class="bon-header" translate>user.data.generalHeader</div>
    <div class="bon-card-group" style="padding-top: 15px;">
      <div class="bon-card-inner">
        <dict-row labelKey="user.data.type" [(ngModel)]="user.userType"
                  [showErrors]="showErrors" required
                  dictionary="UserType" name="userType" (changeItem)="onUserTypeChange()"
                  [disabled]="userTypeConstant()"></dict-row>
        <form-row labelKey="user.data.businessUnit" *ngIf="!kukeExternal()">
          <business-unit-selector #businessUnit="ngModel" class="bon-input-size" [userDefaultBU]="false"
                                  [nullLabel]="" [(ngModel)]="user.businessUnit" name="businessUnit"
                                  (changeItem)="onBUChange($event)" required [disabled]="userNotEditable">
          </business-unit-selector>
          <error-message [control]="businessUnit.control" [show]="showErrors"></error-message>
        </form-row>
        <checkbox-row *ngIf="credendo && !mehib && user.userType" labelKey="user.data.employee"
                      [(ngModel)]="employeeCheckbox"
                      (changeState)="onEmployeeChange()"
                      name="employee" [disabled]="!isIntranetUser()"></checkbox-row>
      </div>
      <div class="bon-card-inner">
        <dict-row *ngIf="user.userType?.id === 67000001" labelKey="user.data.defaultModule"
                  [(ngModel)]="user.defaultModule"
                  dictionary="ApplicationModule" name="applicationModule" nullLabel="" required
                  [showErrors]="showErrors" [presentationMode]="userNotEditable">
        </dict-row>
        <dict-row labelKey="user.data.language" [(ngModel)]="user.language"
                  [showErrors]="showErrors" required [codeRegexp]="langCodeRegexp"
                  [presentationMode]="userNotEditable"
                  dictionary="Language" name="language" nullLabel="" [disabled]="kukeExternal()"></dict-row>
      </div>
      <div class="bon-card-inner"></div>
    </div>
  </div>
  <bon-section labelKey="common.section.details" *ngIf="user.userType">
    <div class="bon-card-group" style="padding-bottom: 25px;">
      <div class="bon-card-inner">
        <input-row *ngIf="!user.id && !kukeExternal() && !ecgExternal()" [required]="!user.id" labelKey="user.data.login"
                   [(ngModel)]="user.login"
                   name="login" loginFormat [showErrors]="showErrors" minlength="2" maxlength="30"></input-row>
        <text-row *ngIf="user.id" labelKey="user.data.login" [value]="user.login"></text-row>
        <dict-row labelKey="user.data.title" [(ngModel)]="user.title" name="title" [presentationMode]="userNotEditable"
                  [showErrors]="showErrors" dictionary="Title" nullLabel="" [required]="kukeExternal()"></dict-row>
        <input-row labelKey="user.data.firstName" [(ngModel)]="user.name" name="firstName"
                   minlength="2" maxlength="50"
                   [showErrors]="showErrors" firstNameFormat required
                   [presentationMode]="editionBlocked"></input-row>
        <input-row labelKey="user.data.lastName" [(ngModel)]="user.familyName" name="familyName"
                   minlength="2" maxlength="50"
                   [showErrors]="showErrors" lastNameFormat required
                   [presentationMode]="editionBlocked"></input-row>
        <input-row *ngIf="appService.kuke && isIntranetUser()" labelKey="user.data.department"
                   [(ngModel)]="user.department"
                   [presentationMode]="editionBlocked" name="department"></input-row>
        <dict-row labelKey="user.data.externalClientRole" *ngIf="kukeExternal()" required
                  [(ngModel)]="user.extranetClientRole" dictionary="ExtranetClientRole" name="extranetClientRole"
                  [disabled]="editionBlocked" [showErrors]="showErrors"></dict-row>
        <date-row *ngIf="user.status?.id === UserStatus.DELETED || user.status?.id === UserStatus.DELETED_IN_AD"
                  [presentationMode]="true"
                  labelKey="user.data.deletionDate" [(ngModel)]="user.deletionDate" name="deletionDate"></date-row>
        <text-row *ngIf="user.status?.id === UserStatus.DELETED || user.status?.id === UserStatus.DELETED_IN_AD"
                  [presentationMode]="true"
                  labelKey="user.data.deletedBy" [value]="user.deletedBy.fullName"></text-row>
      </div>
      <div class="bon-card-inner">
        <input-row labelKey="user.data.email" name="email" [(ngModel)]="user.email" [showErrors]="showErrors"
                   [presentationMode]="editionBlocked" required email></input-row>
        <input-row labelKey="user.data.phone" [(ngModel)]="user.phone" [showErrors]="showErrors" name="phone"
                   [presentationMode]="editionBlocked"></input-row>
        <div>
          <password-row *ngIf="showPassword()" labelKey="user.data.password" #newPassword="ngModel"
                        [(ngModel)]="user.password"
                        [control]="newPassword.control" name="newPassword" [required]="!user.id && isIntranetUser()"
                        [disabled]="userNotEditable"
                        [showErrors]="showErrors"></password-row>
          <password-row *ngIf="showPassword()" labelKey="user.data.confirmPassword" [(ngModel)]="confirmPassword"
                        #retypePassword="ngModel"
                        [control]="retypePassword.control" name="retypePassword" [required]="!user.id && isIntranetUser()"
                        [disabled]="userNotEditable"
                        [showErrors]="showErrors"></password-row>
          <span class="invalid inline" translate="changePassword.passwordsMismatch"
                *ngIf="showErrors && form.hasError('passwordsMismatch')"></span>
        </div>
        <input-row *ngIf="appService.kuke && isIntranetUser()" labelKey="user.data.office" [(ngModel)]="user.office"
                   [presentationMode]="editionBlocked"
                   name="office"></input-row>
        <ng-container *ngIf="user.userType?.id === 67000001">
          <checkbox-row *ngIf="user.userType?.id === 67000001" labelKey="user.data.absent" [(ngModel)]="userAbsent"
                        name="absent" (changeState)="onAbsenceChange($event)"
                        [presentationMode]="userNotEditable "></checkbox-row>
          <date-row [presentationMode]="userNotEditable" *ngIf="userAbsent" [(ngModel)]="user.absentFrom"
                    name="absentFrom"
                    labelKey="user.data.absentFrom"></date-row>
          <date-row [presentationMode]="userNotEditable" *ngIf="userAbsent" [(ngModel)]="user.absentTo" name="absentTo"
                    labelKey="user.data.absentTo"></date-row>
        </ng-container>
      </div>
      <div class="bon-card-inner">
        <date-row labelKey="user.data.registrationDate" [(ngModel)]="user.registrationDate"
                  type="date" name="registrationDate" disabled="true" [presentationMode]="true"
        ></date-row>
        <date-row labelKey="user.data.lastLoginDate" [(ngModel)]="user.lastLoginDate"
                  type="datetime" name="lastLoginDate" disabled="true" [presentationMode]="true"
        ></date-row>
        <dict-row labelKey="user.data.status" [(ngModel)]="user.status" dictionary="UserStatus" name="userStatus"
                  disabled="true">
        </dict-row>
        <input-row *ngIf="appService.kuke" labelKey="user.data.position" [(ngModel)]="user.position"
                   [presentationMode]="editionBlocked" name="position"></input-row>
      </div>
    </div>
  </bon-section>
  <bon-section labelKey="user.data.employment" *ngIf="isCredendoEmployee()">
    <div class="bon-card-group" style="padding-bottom: 25px;">
      <div class="bon-card-inner">
        <date-row labelKey="user.data.dateOfBirth" [(ngModel)]="user.employee.dateOfBirth" name="dateOfBirth"
                  [required]="true" [showErrors]="showErrors"></date-row>
        <date-row labelKey="user.data.employmentStart" [(ngModel)]="user.employee.employmentStart"
                  name="employmentStart"
                  [required]="true" [showErrors]="showErrors"></date-row>
        <date-row labelKey="user.data.employmentEnd" [(ngModel)]="user.employee.employmentEnd" name="employmentEnd"
        ></date-row>
        <dict-row labelKey="user.data.countryOfEmployment" [(ngModel)]="user.employee.countryOfEmployment"
                  dictionary="Country"
                  name="countryOfEmployment" [required]="true" [showErrors]="showErrors"
        ></dict-row>
      </div>
      <div class="bon-card-inner">
        <dict-row labelKey="user.data.businessLine" [(ngModel)]="user.employee.businessLine"
                  dictionary="BusinessLine" [required]="true" [showErrors]="showErrors"
                  name="businessLine"></dict-row>
        <input-row labelKey="user.data.position" [(ngModel)]="user.employee.position" name="position" [required]="true"
                   [showErrors]="showErrors"></input-row>
        <input-row labelKey="user.data.department" [(ngModel)]="user.employee.department" name="department"
                   [required]="true"
                   [showErrors]="showErrors"></input-row>

      </div>
      <div class="bon-card-inner">
        <div class="bon-row">
          <label class="bon-label">{{'user.data.directManager' | translate}}</label>
          <user-combo class="bon-input-size" [range]="hrUserRange"
                      [(ngModel)]="user.employee.directManager" name="directManager"></user-combo>
        </div>

        <div class="bon-row">
          <label class="bon-label">{{'user.data.countryManager' | translate}}</label>
          <user-combo class="bon-input-size" [range]="countryManagersUserRange"
                      [(ngModel)]="user.employee.countryManager" name="businessLineMangager"></user-combo>
        </div>
      </div>
    </div>
  </bon-section>
  <bon-section labelKey="holidaySettings.details.title" *ngIf="isCredendoEmployee()">
    <div class="bon-card-group" style="padding-bottom: 25px;">
      <div class="bon-card-inner">
        <a-table [items]="holidaysSettings" [editable]="false">
          <column property="id" labelKey="common.id"></column>
          <column property="type" labelKey="common.type" dictionary="HolidayType"></column>
          <column property="cyear" labelKey="holidaySettings.year"></column>
          <column property="numberOfDays" labelKey="holidaySettings.numberOfDays"></column>
          <column property="numberOfDaysUsed" labelKey="holidaySettings.numberOfDaysUsed"></column>
          <column property="numberOfDaysToUse" labelKey="holidaySettings.numberOfDaysToUse"></column>
        </a-table>
      </div>
    </div>
  </bon-section>
  <bon-section labelKey="user.data.comment" [expandable]="true" *ngIf="user.userType">
    <div class="bon-card-group">
      <div class="bon-card-inner">
        <div class="bon-row">
          <textarea style="width: 97.5%;" [(ngModel)]="user.comment" name="comment" rows="6"
                    [disabled]="userNotEditable"></textarea>
        </div>
      </div>
    </div>
  </bon-section>
  <div class="bon-card-main-group" *ngIf="user.userType">
    <div class="bon-card-inner" style="width: 55%">
      <div class="bon-header" translate>user.data.profilesHeader</div>
      <div class="bon-card-group">
        <span class="invalid inline"
              *ngIf="(!user.roles || user.roles.length == 0) && showErrors" translate>user.data.atLeastOneProfileRequired</span>
      </div>
      <div class="bon-card-group" *ngIf="showProfiles()">
        <div class="bon-card-inner" *ngIf="userProfilesEditable()">
          <div class="bon-row">
            <label style="padding-top: 5px;" class="bon-label" translate>user.data.available</label>
            <div style="float: right; display: inline; padding-right: 5px;">
              <dict-combo class="bon-input-size" dictionary="ProfileCategory" [(ngModel)]="selectedProfileCategory"
                          [hiddenIds]="emptyProfileCategoryIds" [disabled]="userNotEditable"
                          (changeItem)="onProfileCategoryChange()" name="profileCategory"></dict-combo>
            </div>
          </div>
          <a-table [items]="availableRolesForType" (selectItem)="addRole($event)"
                   [canUnselect]="false" [selection]="false">
            <column property="name" labelKey="user.data.name"></column>
          </a-table>
        </div>
        <div class="bon-card-inner">
          <div class="bon-row">
            <label style="padding-top: 5px;" class="bon-label" translate>user.data.assigned</label>
          </div>
          <a-table [items]="user.roles" (selectItem)="removeRole($event)" [canUnselect]="false" [selection]="false">
            <column property="parentName" labelKey="user.data.category"></column>
            <column property="name" labelKey="user.data.name"></column>
          </a-table>
        </div>
      </div>
      <div class="bon-card-group" *ngIf="(kukeExternal() || ecgExternal()) && user">
        <div class="bon-card-inner" style="width:33%" *ngFor="let pc of extranetProfileCategories">
          <div class="bon-header">
            {{pc.name}}
          </div>
          <ng-container *ngFor="let r of pc.relatedDictionaries['UserRole']">
            <div class="bon-row">
              <label for="{{r.code}}" style="width: 250px;">{{r.name}}</label>
              <input type="checkbox" id="{{r.code}}" [checked]="hasRole(r)" (change)="onContextChange(r)"
                     [disabled]="hasPortalAdminChkecked(r)"/>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="bon-card-inner" style="width: 45%">
      <ng-container *ngIf="isIntranetUser()">
        <div class="bon-header" translate>user.data.groupsHeader</div>
        <div class="bon-card-group">
          <div class="bon-card-inner" *ngIf="userGroupsEditable()">
            <div class="bon-row">
              <label style="padding-top: 5px;" class="bon-label" translate>user.data.available</label>
            </div>
            <a-table [items]="groupsForProfileCategory" (selectItem)="addGroup($event)"
                     [canUnselect]="false" [selection]="false">
              <column labelKey="user.data.name" property="name"></column>
            </a-table>
          </div>
          <div class="bon-card-inner">
            <div class="bon-row">
              <label style="padding-top: 5px;" class="bon-label" translate>user.data.assigned</label>
            </div>
            <a-table [items]="user.groups" (selectItem)="removeGroup($event)" [canUnselect]="false" [selection]="false">
              <column labelKey="user.data.name" property="name"></column>
            </a-table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="user.userType?.id === 67000002" class="bon-card-main-group">
    <company-section labelKey="user.data.company" [(ngModel)]="user.company" [clearable]="false" name="company"
                     [openSelectorEmitter]="openSelectorEmitters['Company']" [showLink]="true"
                     [invalid]="showErrors && !user.company"
                     [control]="company.control" required
                     #company="ngModel"></company-section>
    <company-section labelKey="user.data.creatorCompany" [(ngModel)]="user.creatorCompany" [clearable]="false"
                     name="creatorCompany"
                     [openSelectorEmitter]="openSelectorEmitters['CreatorCompany']" [showLink]="true"
                     *ngIf="kukeExternal()"
                     [invalid]="showErrors && !user.creatorCompany"
                     [control]="creatorCompany.control" required
                     #creatorCompany="ngModel"></company-section>
    <div class="bon-card-inner">
    </div>
  </div>
  <div class="bon-card-group">
    <div class="bon-card-inner">
      <company-selector-simple (selectItem)="user.company = $event" labelKey="user.data.company"
                               [open]="openSelectorEmitters['Company']"
                               [showDuplicates]="false" [searchModeSwitchable]="true">
      </company-selector-simple>
      <company-selector-simple (selectItem)="user.creatorCompany = $event" labelKey="user.data.creatorCompany"
                               [open]="openSelectorEmitters['CreatorCompany']" *ngIf="kukeExternal()"
                               [showDuplicates]="false" [searchModeSwitchable]="true">
      </company-selector-simple>
    </div>
  </div>
</form>
