import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {BusinessLogCriteriaDto, BusinessObjectDto, SearchCriteria, TrainingCriteriaDto} from '../model';
import {BinaryDownloaderService} from './binary-downloader.service';

@Injectable()
export class BusinessLogService extends AbstractService {
  protected url = this.urlPrefix + 'businessLog';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getLoginAlert(): Observable<string> {
    return this.get<string>(this.url + '/failedLoginAlert/get');
  }

  clearLoginAlert() {
    return this.get<string>(this.url + '/failedLoginAlert/clear');
  }

  export(
    criteria: BusinessLogCriteriaDto,
    outputFileName: string,
    errorCallback: (errMsg: string) => void,
    completeCallback: (f: File) => void
  ) {
    console.log('export ', criteria);
    this.binaryDownloader.download(
      'POST',
      this.url + '/export/',
      'application/json',
      'application/octet-stream',
      outputFileName,
      criteria,
      errorCallback,
      completeCallback
    );
  }
}
