<!--suppress HtmlUnknownAttribute, TypeScriptUnresolvedVariable, AngularInvalidExpressionResultType -->
<details-view [title]="'inquiry.calculator.pbg.title' | translate" objectName='Inquiry' [parentComponent]="self"
              [versionListHidden]="true" [fixedBlockHidden]="false">
  <div class="details-content">
    <ng-container *ngIf="calculationPbg === null; then thenBlock else elseBlock">
    </ng-container>
    <ng-template #elseBlock>
      <bon-section labelKey="inquiry.calculator.pbg.title">
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <ng-container
              [ngTemplateOutlet]="saleValuesTable"
              [ngTemplateOutletContext]="{inputTable:true, items: calculationPbg.params}">
            </ng-container>
            <span class="invalid inline" *ngIf="form.hasError('notEmptyTableGroupValidator', ['salesValues']) && showErrors">
              {{'errorMessage.required' | translate}}
            </span>
          </div>
        </div>
        <div class="bon-card-group">
          <div class="bon-card-inner" style="width: 25%;">
            <div class="bon-row">
              <ss-multiselect-dropdown *ngIf="countries" [(ngModel)]="calculationPbg.params" name="countries"
                     [options]="countries" [settings]="multiSelectSettings" objectName="country"
                     [texts]="marketSelectTexts"
                     (addItem)="countriesAddItem($any($event))">
              </ss-multiselect-dropdown>

            </div>
          </div>
        </div>
        <div class="bon-card-group" *ngIf="!otherCountries">
          <div class="bon-card-inner" style="width: 25%;">
            <div class="bon-row">
              <span class="bon-input">
                <span>
                  <a class="a-link"
                     (click)="showOtherCountries()" translate>
                    inquiry.calculator.pbg.otherCountries.button
                  </a>
                </span>
              </span>
            </div>
          </div>
        </div>

      </bon-section>

      <bon-section *ngIf="calculationPbg && calculationPbg.results && calculationPbg.results.length > 0" >
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <ng-container
              [ngTemplateOutlet]="saleValuesTable"
              [ngTemplateOutletContext]="{inputTable:false, items: calculationPbg.results}">
            </ng-container>
          </div>
        </div>
      </bon-section>

      <bon-section #otherCountriesSection *ngIf="otherCountries" labelKey="inquiry.calculator.pbg.otherCountries.title" expandable="true">
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <div class="bon-row" *ngFor="let oc of otherCountries">
              <label class="bon-label" style="width: 15%;">{{oc[0].name}}</label>
              <span class="" *ngFor="let prodTypes of oc[1]; let last=last">
                <span *ngIf="!last">
                 {{prodTypes.code + ","}}
                </span>
                <span *ngIf="last">
                  {{prodTypes.code}}
                </span>
              </span>
            </div>
          </div>
        </div>
      </bon-section>

    </ng-template>
    <ng-template #thenBlock>
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <i class="fa fa-spinner fa-spin fa-2x"></i>
        </div>
      </div>
    </ng-template>
  </div>
  <div  class="additional-buttons">
    <ng-container *ngIf="calculationPbg?.params?.length > 0">
      <button  (click)="onCalculate()" *hasRight="PolicyElementaryRight.POLICY_INQUIRY_VIEW"
               class="bon-btn-warning" translate="inquiry.calculator.pbg.recalculate" type="button"></button>
    </ng-container>

    <button *ngIf="!portalMode" (click)="onNewInquiry()" class="bon-btn-warning"
            translate="inquiry.search.new" type="button"></button>
  </div>
</details-view>

<ng-template #saleValuesTable let-inputTable='inputTable' let-items='items'>
  <a-table [tableName]="'salesValues' + inputTable" [formModel]="inputTable ? form.controls.salesValues : null" [items]="items"
           [deleteButton]="inputTable" [addButton]="false" [editable]="inputTable" [sortable]="false" [showAllErrors]="inputTable">
    <column property="country"  dictionary="Country" [editable]="false" cellWidth="15%"></column>
    <column property="selfRetention" type="number" title="inquiry.calculator.pbg.selfRetention"
            [editable]="false" cellWidth="15%"></column>
    <column property="salesSum" type="number" title="inquiry.calculator.pbg.salesSum" [required]="true"></column>
    <column labelKey="inquiry.calculator.pbg.maxPaymentDate" cellWidth="30%">
      <ng-template let-item="item" let-edition="edition">
        <item-combo [items]="maxPaymentDates" [(ngModel)]="item.guiMaxPaymentDate"
                    name="maxPaymentDate" [presentationMode]="!edition">
          <ng-template let-item="item">Do {{item.id}} dni</ng-template>
        </item-combo>
      </ng-template>
    </column>
    <column *ngIf="!inputTable" property="price" title="inquiry.calculator.pbg.price">
      <ng-template let-item="item" >
        {{item.price | number: '1.3-3'}}
      </ng-template>
    </column>
    <column *ngIf="!inputTable" property="premium" title="common.premium">
      <ng-template let-item="item" >
        {{item.premium | number: '1.2-2'}}
      </ng-template>
    </column>
  </a-table>
</ng-template>
