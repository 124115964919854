/**
 * Created by siminski on 13.07.2016.
 */
import {Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {AsyncValidatorFn, ValidatorFn, Validators} from '@angular/forms';
import {StringUtils} from '../../utils/string-utils';
import {CustomValidators} from '../../validators/index';
import {DictionaryBaseService, FormatService} from '../../services/index';
import {TableValidators} from './table.validators';
import {DictionaryBaseDto} from '../../model/dtos';
import {Observable} from 'rxjs';
import {isNumeric} from '../../utils/number-utils';

type ColumnType =
  | 'text'
  | 'autocomplete'
  | 'number'
  | 'email'
  | 'dictionary'
  | 'checkbox'
  | 'currency'
  | 'radio'
  | 'date'
  | 'datetime'
  | 'combo'
  | 'stringCombo'
  | 'booleanCombo'
  | 'userCombo'
  | 'userAutocomplete'
  | 'archetype'
  | 'archetypeCheckbox';

type MinMaxType = number | string | Array<number | string> | ((item) => number);

@Component({
  selector: 'column',
  template: ``,
})
export class ColumnComponent<T> implements OnInit {
  _property: string;

  /**
   * Header of the column
   */
  @Input() title: string;

  /**
   * Name of a dictionary. Mandatory for edition. In case of read only you can set type='dictionary' instead.
   */
  @Input() dictionary: string;
  @Input() parentDictionary: string;
  @Input() parentDictionaryEntryId: number;
  @Input() dictionaryProfileId: number;
  @Input() dictionaryUserRoleId: number;
  @Input() tooltip: string;
  @Input() ellipsis = false;
  @Input() codeRegexp: RegExp;
  @Input() replaceUnderscoresWithSpaces = false;
  @Input() showTemplate = true;

  /**
   * Type of a column.
   * If you set field dictionary, you do not need to set type.
   * @type {string}
   */
  @Input() type: ColumnType = 'text';

  /**
   * Format for number columns
   * Default is 1.2-2
   * For integers 1.0-0
   */
  @Input() numberFormat: string;

  /**
   * Number of decimal places for num-input
   * Default is 2
   */
  @Input() numberOfDecimalPlaces = 2;

  /**
   * Limit input precision, value is limited not only the display value
   */
  @Input() numberPrecision = 100;

  /**
   * Set type of num-input to either 'decimal' or 'integer' type
   */
  @Input() numberType: 'decimal' | 'integer' = 'decimal';

  /**
   * <value in model> = <displayed value> * numberBaseMultiplier
   */
  @Input() numberBaseMultiplier = 1;

  /**
   * Whether column should accept negative number values or not
   */
  @Input() allowNegativeNumbers: boolean | ((item: T) => boolean) = false;

  /**
   * Indicate if this field is link. You should handle onLinkClick in this case.
   * @type {boolean}
   */
  @Input() link: boolean | ((item: T) => boolean) = false;

  /**
   * Indicate if the sum for this column should be shown in footer
   * Should be used only for columns with type number or stringCombo
   * @type {boolean}
   */
  @Input() sumInFooter = false;

  /**
   * use float sum in footer, mandatory if you have floats in your column
   */
  @Input() sumFloat = false;

  /**
   * Sum property name
   * Should be used only for columns with type number and for sum calculated on backend
   * @type {string}
   */
  @Input() sumPropertyInFooter: string;

  /**
   * Value shown in footer e.g. currency code
   * @type {string}
   */
  @Input() footerValue: string;

  @Input() groupRadios = false;
  /**
   * Name of sort property.
   * Used in case of server sort (pagination = true)
   * This value will be sent to server
   */
  @Input() sort: string;
  @Input() class: any;
  @Input() style: any;
  @Input() dictLabel = 'name';
  @Input() archetypeLabel = 'name';
  @Input() infinity: boolean;
  @Input() nullLabel = '-';
  @Input() cellWidth: any;
  /**
   * up or down
   */
  @Input() initialSort: string;

  // Validations

  @Input() required: boolean | ((item: T) => boolean);
  @Input() minlength: number;
  @Input() maxlength: number;
  @Input() pattern: string;
  @Input() min: MinMaxType;
  @Input() max: MinMaxType;
  @Input() autoMinMax = true;
  @Input() geMultiply: Array<string>;
  @Input() ltMultiply: Array<string>;
  @Input() minDate: Date | ((item: T) => Date) | string;
  @Input() maxDate: Date | ((item: T) => Date) | string;
  @Input() notPast: boolean;
  @Input() notFuture: boolean;
  @Input() step: number | string = 'any';
  @Input() customAsyncValidator: AsyncValidatorFn;
  @Input() customValidatorSupplier: (item: T) => ValidatorFn;
  @Input() customSortValueFunc: (item: T) => number;
  @Input() updateOn: 'blur' | 'change' | 'submit' = 'change';

  /**
   * If set, it is not possible to add duplicate value for this column.
   */
  @Input() unique: boolean;

  /**
   * If table is editable, all column are by default also editable. You can disable it for specific column
   * using this property
   */
  @Input() editable: boolean | ((item: T) => boolean) = true;

  /**
   * The items that will not be shown in a list. This should be be stored as set.
   * Use for dictionary columns.
   */
  @Input() dictHiddenIds: Set<number>;

  @Input() comboItems: {id: number}[];
  @Input() stringComboItems: {value: string; label: string}[]; // ComboItem
  @Input() comboLabel: string;
  @Input() toStringFunc: (item: T) => string;

  @Input() boolComboNullLabel: String;
  @Input() boolComboFalseLabel: String;
  @Input() boolComboTrueLabel: String;
  @Input() boolComboAllowUndefined;
  @Input() userComboRange;
  @Input() userComboNullLabel: String;
  @Input() focus: boolean | 'auto' | 'clear';

  @ContentChild(TemplateRef, {static: true}) template: TemplateRef<any>;

  @Output() cellClick = new EventEmitter<T>();
  @Output() linkClick = new EventEmitter<T>();
  @Output() cellChange = new EventEmitter<CellChangeEvent<T>>();
  @Output() dictionaryReady = new EventEmitter();

  /**
   * Used for unique dictionary fields to check if there is more items to add
   * @type {number}
   */
  dictionaryCount = 0;
  dictionaryEntries: DictionaryBaseDto[];
  hiddenIds: Set<number> = new Set<number>();
  _labelKey: string;
  _buId: number;
  initialDictionarySearchCalled = false;

  /**
   * ***************  Column mode ****************
   */
  @Input() selectable = false;
  selected = false;
  /**
   * used when there are multiple header rows in table (currently implemented only for columnMode)
   */
  @Input() titles: string[] = [''];

  /**
   * ***************  End column mode ****************
   */

  private errorMessage: string;

  get property() {
    return this._property;
  }

  @Input() set property(property: string) {
    this._property = property;
    this.setTitle();
  }

  @Input() set labelKey(k: string) {
    this._labelKey = k;
    this.setTitle();
  }

  setTitle() {
    if (this._labelKey) {
      this.title = this._labelKey;
    } else if (this._property && !this.title) {
      this.title = StringUtils.camelCaseToText(this._property);
    }
  }

  /**
   * Used for dictionary columns to filter entries by BU
   */
  @Input('buId')
  set buId(value: number) {
    this._buId = value;
    if (this.initialDictionarySearchCalled) {
      this.loadDictionaryEntries();
    }
  }

  get buId(): number {
    return this._buId;
  }

  constructor(
    private el: ElementRef,
    private dictBaseService: DictionaryBaseService,
    private formatService: FormatService
  ) {}

  ngOnInit() {
    if (this.dictionary) {
      this.loadDictionaryEntries();
      this.initialDictionarySearchCalled = true;
    }
    if (this.titles.length < 2) {
      this.titles[0] = this.title;
    }
  }
  get content() {
    return this.el.nativeElement.innerHTML;
  }

  isEditable(item: T) {
    return this.type !== 'archetype' && (this.editable instanceof Function ? this.editable(item) : this.editable);
  }

  isLink(item: T) {
    return this.link instanceof Function ? this.link(item) : this.link;
  }

  doesAllowNegativeNumbers(item: T): boolean {
    return this.allowNegativeNumbers instanceof Function ? this.allowNegativeNumbers(item) : this.allowNegativeNumbers;
  }

  getMinDateValue(item: T) {
    return this.minDate instanceof Function ? this.minDate(item) : this.minDate;
  }

  getMaxDateValue(item: T) {
    return this.maxDate instanceof Function ? this.maxDate(item) : this.maxDate;
  }

  getAsyncValidators(): AsyncValidatorFn {
    return this.customAsyncValidator;
  }

  private multiplyFieldsInItem(item: T, fields: Array<string>): () => number {
    return () => {
      let value = 1;
      let initialized = false;
      let undefVal = false;
      for (const m of fields) {
        if (isNumeric(m)) {
          value = value * +m;
          initialized = true;
        } else if (isNumeric((<any>item)[<string>m])) {
          value = value * (<any>item)[<string>m];
          initialized = true;
        } else {
          undefVal = true;
          break;
        }
      }
      return !undefVal && initialized ? value : undefined;
    };
  }

  getValidators(table: any, item: T): ValidatorFn {
    const array: ValidatorFn[] = [];
    if (this.isRequired(item)) {
      if (this.isDictionary()) {
        array.push(CustomValidators.chooseRequired);
      } else {
        array.push(Validators.required);
      }
    }
    if (this.minlength) {
      array.push(Validators.minLength(this.minlength));
    }
    if (this.maxlength) {
      array.push(Validators.maxLength(this.maxlength));
    }
    if (this.pattern) {
      array.push(Validators.pattern(this.pattern));
    }
    if (this.isType('email')) {
      array.push(Validators.email);
    }
    if (this.min || this.min === 0) {
      array.push(CustomValidators.minValue(this.getMin(item), this.numberBaseMultiplier));
    }
    if (this.max || this.max === 0) {
      array.push(CustomValidators.maxValue(this.getMax(item), this.numberBaseMultiplier));
    }
    if (this.geMultiply && this.geMultiply.length > 1) {
      array.push(
        CustomValidators.greaterOrEqualThanValueFunc(
          this.multiplyFieldsInItem(item, this.geMultiply),
          this.numberBaseMultiplier
        )
      );
    }
    if (this.ltMultiply && this.ltMultiply.length > 1) {
      array.push(
        CustomValidators.lessOrEqualThanValueFunc(
          this.multiplyFieldsInItem(item, this.ltMultiply),
          this.numberBaseMultiplier
        )
      );
    }
    if (this.isUnique() && !this.isDictionary()) {
      array.push(TableValidators.unique(table, this, item, this.formatService));
    }
    if (this.customValidatorSupplier && this.customValidatorSupplier(item)) {
      array.push(this.customValidatorSupplier(item));
    }
    return Validators.compose(array);
  }

  getMax(item: T): number {
    return this.getMinMax(this.max, item, Math.min);
  }

  getMin(item: T): number {
    return this.getMinMax(this.min, item, Math.max);
  }

  private getMinMax(minMax: MinMaxType, item: T, arrayToOne: Math['min'] | Math['max']): number {
    if (isNumeric(minMax)) {
      return +minMax;
    } else if (Array.isArray(minMax)) {
      const arr: Array<number> = [];
      for (const m of minMax) {
        if (isNumeric(m)) {
          arr.push(+m);
        } else if (<any>m instanceof Function) {
          return (<any>m)(item);
        } else if (isNumeric((<any>item)[<string>m])) {
          arr.push((<any>item)[<string>m]);
        }
      }
      if (arr.length > 0) {
        return arrayToOne(...arr);
      }
    } else if (minMax instanceof Function) {
      return minMax(item);
    } else {
      return (<any>item)[minMax];
    }
  }

  getMinDate(value: Date | ((item: T) => Date) | string, item: T) {
    if (!value) {
      return undefined;
    }
    if (value instanceof Function) {
      return this.getMinDateValue(item);
    }
    return value instanceof Date ? value : item[value];
  }

  getMaxDate(value: Date | ((item: T) => Date) | string, item: T) {
    if (!value) {
      return undefined;
    }
    if (value instanceof Function) {
      return this.getMaxDateValue(item);
    }
    return value instanceof Date ? value : item[value];
  }

  isType(type: any) {
    if (String(this.type) === String('radio') && String(type) === 'checkbox') {
      return true;
    }
    return String(this.type) === String(type);
  }

  isDictionary(): boolean {
    if ((this.dictionary && !this.isType('autocomplete')) || this.isType('dictionary')) {
      return true;
    } else {
      return false;
    }
  }

  isText(): boolean {
    if (!this.dictionary && this.property) {
      if (
        !this.type ||
        String(this.type) === 'text' ||
        String(this.type) === 'number' ||
        String(this.type) === 'email' ||
        String(this.type) === 'dateYear'
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isBoolean(): boolean {
    if (!this.dictionary && this.property) {
      if (!this.type || String(this.type) === 'checkbox' || String(this.type) === 'radio') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isNumber() {
    return this.isType('number');
  }

  isDate() {
    return (this.isType('date') || this.isType('datetime')) && this.property;
  }

  isAutoComplete() {
    return this.isType('autocomplete');
  }

  isCustom() {
    return (!this.property && this.template) || String(this.type) === 'custom';
  }

  cellClicked(item: T) {
    this.cellClick.emit(item);
  }

  cellChanged(item: T, value: any) {
    const event = new CellChangeEvent<T>(item, value);
    this.cellChange.emit(event);
  }

  isUnique(): boolean {
    return this.isDeclared(this.unique);
  }

  isRequired(item?: T): boolean {
    if (this.required instanceof Function) {
      return item && this.required(item);
    } else {
      return this.isDeclared(this.required);
    }
  }

  /**
   * In case of properties that does not have values (like required) we have empty string in this property.
   * In this case if(property) is false. In such case we must use this method checked if property is defined.
   */
  private isDeclared(property: any): boolean {
    return property !== undefined && property !== false;
  }

  private loadDictionaryEntries() {
    if (this.dictionary) {
      this.getDictionaryBaseFiltered().subscribe({
        next: (entries) => {
          this.dictionaryEntries = entries;
          this.dictionaryCount = entries.length;
        },
        error: (error) => (this.errorMessage = error),
        complete: () => this.dictionaryReady.emit(),
      });
    }
  }

  private getDictionaryBaseFiltered(): Observable<DictionaryBaseDto[]> {
    if (this.dictionaryUserRoleId) {
      return this.dictBaseService.getDictionaryBaseFilteredByUserRole(
        this.dictionary,
        this._buId,
        this.parentDictionaryEntryId,
        this.dictionaryUserRoleId
      );
    }
    return this.dictBaseService.getDictionaryBaseFiltered(
      this.dictionary,
      this._buId,
      this.parentDictionaryEntryId,
      this.dictionaryProfileId
    );
  }

  doReplaceUnderscoresWithSpaces(v: string): string {
    return v.replace(/_/g, ' ');
  }
}

export class CellChangeEvent<T> {
  item: T;
  value: any;

  constructor(item: T, value: any) {
    this.item = item;
    this.value = value;
  }
}
