<ng-container>
  <!-- ********************--><!-- * Section: Details *--><!-- ********************-->
  <div class="bon-card-inner-group" [hidden]="!showDetails">
    <div class="bon-header">{{'common.details' | translate }}</div>
  </div>
  <div class="bon-card-group" [hidden]="!showDetails">
    <div class="bon-card-inner bon-label-190" style="width: 50%">
      <dict-row *ngIf="!portal && bondVersion.id" labelKey="bond.common.status" [ngModel]="bondVersion.businessStatus" dictionary="BondStatus" [disabled]="true"
                name="businessStatus" [presentationMode]="true"></dict-row>
      <div class="bon-row" *ngIf="!portal && (bondVersion.approvalUser || approvalUserEditable)">
        <label class="bon-label" translate="bondingContractOffer.approvalUser"></label>
        <item-combo *ngIf="approvalUserEditable" class="bon-input-size" [items]="approvalUsers | async" content="header" [(ngModel)]="bondVersion.approvalUser"
                    name="approvalUser" [presentationMode]="disabled">
            <ng-template let-item="item">{{item | username}}</ng-template>
        </item-combo>
        <span *ngIf="!approvalUserEditable"> {{bondVersion.approvalUser | username}} </span>
      </div>
      <dict-row labelKey="common.language" [(ngModel)]="bondVersion.language" dictionary="Language" name="language" (changeItem)="onLanguageChanged($event)" [disabled]="disabled"></dict-row>
      <dict-row *ngIf="isGSP && !isCurrencyMatchingRegex()" labelKey="bond.common.bankAccount" [(ngModel)]="bondVersion.bankAccount" [codeRegexp]="bondBankAccountCurrencyCodeRegExp"
                dictionary="Currency" name="bankAccount" itemLabel="code">
      </dict-row>
      <dict-row labelKey="common.currency" [(ngModel)]="bondVersion.currency" dictionary="Currency" [itemLabel]="'code'"
                name="currency" (changeItem)="onCurrencyChanged($event)" [disabled]="disabled" [profileId] = "bondCurrencyDictProfile"></dict-row>
      <num-row labelKey="Bond value" [ngModel]="bondVersion.value" name="bondValue" [disabled]="true"></num-row>
      <num-row *ngIf="!bondVersion.output.showMultiValues" [labelKey]="'common.' + (bondVersion.output.showMultiValues ? 'originalValue' : 'value')" [(ngModel)]="bondVersion.values[0].value" [required]="true"
               name="originalValue" [disabled]="true" [showErrors]="showErrors && !bondVersion.output.showMultiValues" ></num-row>
      <div class="bon-card-inner-group">
      <bond-value-list *ngIf="bondVersion.output.showMultiValues" [title]="bondVersion.output.showSupplementaryValues ? 'bond.performanceValues' : 'bond.values'"
                       [values]="bondVersion.values" [showErrors]="showErrors" [showSystemCurrency]="false" listName="bondMultiValues"
                       [showValidTo]="true"  (validityPeriodChanged)="updateBondVersionValidity()" [editable]="!disabled"></bond-value-list>
      <bond-value-list *ngIf="bondVersion.output.showSupplementaryValues" title="bond.warrantyValues" [values]="bondVersion.supplementaryValues"
                       [showErrors]="showErrors" [showSystemCurrency]="false" [firstValueDeletable]="true"
                       listName="bondSupplementaryValues" [showValidTo]="true" (validityPeriodChanged)="updateBondVersionValidity()"
                       [editable]="!disabled"></bond-value-list>
      </div>
      <search-row *ngIf="!portal" labelKey="bond.details.currentClient" [(ngModel)]="bondVersion.currentClient"
                  displayedProperty="registrationName" [presentationMode]="disabled"
                  [openSelectorEmitter]="listEmitters.openSelectorEmitters[CompanyListEmitter.CURRENT_CLIENT]" [addressInTitle]="true"
                  name="currentClient" #currentClientModel="ngModel" [control]="currentClientModel.control" [showErrors]="showErrors">
      </search-row>
    </div>
    <div class="bon-card-inner bon-label-230">
      <date-row labelKey="bond.common.issueDate" [ngModel]="this.bondIssueDate" [presentationMode]="true" name="issueDate"></date-row>
      <date-row labelKey="bond.common.bondInquiryCreationDate" [(ngModel)]="bondVersion.bondInquiryCreationDate" name="bondInquiryCreationDate" [disabled]="disabled"></date-row>
      <date-row labelKey="bond.common.versionValidFrom" [(ngModel)]="bondVersion.validFrom" [disabled]="readOnly"
                name="validFrom" [required]="true" [showErrors]="showErrors"></date-row>
      <date-row labelKey="bond.common.versionValidTo" [(ngModel)]="bondVersion.validTo" [disabled]="readOnly" name="validTo"></date-row>
      <date-row labelKey="bond.common.validFrom" [(ngModel)]="bondVersion.bond.validFrom" name="bondValidFrom"
                [disabled]="true"></date-row>
      <date-row labelKey="bond.common.validTo" [(ngModel)]="bondVersion.bond.validTo" name="bondValidTo"
                [disabled]="true"></date-row>
      <date-row *ngIf="isPromesa" labelKey="bond.common.promiseValidFrom" [(ngModel)]="bondVersion.promiseValidFrom" name="promesseValidFrom"  [disabled]="disabled"></date-row>
      <date-row *ngIf="isPromesa" labelKey="bond.common.promiseValidTo" [(ngModel)]="bondVersion.promiseValidTo" name="promesseValidTo" [required]="true" [showErrors]="showErrors"  [disabled]="disabled"></date-row>

      <textarea-row labelKey="bond.common.comment" [(ngModel)]="bondVersion.comment" rows="2" [showErrors]="showErrors" name="comment"
                    [required]="bondVersion.status?.id === BondStatus.BOND"
                    [minlength]="bondVersion.status?.id === BondStatus.BOND ? 20 : 0" [disabled]="disabled"></textarea-row>
      <dict-row *ngIf="!portal" [presentationMode]="!bondVersion.output.possiblyReInsurable" labelKey="bond.details.underwritingType" [(ngModel)]="bondVersion.underwritingType" nullLabel=" "
                dictionary="UnderwritingType" name="underwritingType" [showErrors]="showErrors" [disabled]="disabled"></dict-row>
      <checkbox-row *ngIf="isGSP" labelKey="bond.common.sent" [(ngModel)]="bondVersion.sent" name="bondSent" [disabled]="disabled"></checkbox-row>
      <dict-row *ngIf="bondVersion.rejectionReason" labelKey="bond.common.rejectionReason" [ngModel]="bondVersion.rejectionReason" dictionary="BondRejectionReason"
                name="rejectionReasonView" [presentationMode]="true"></dict-row>
    </div>
  </div>
  <bonding-consortia-section *ngIf="showDetails" [showErrors]="showErrors" [productTypeId]="contractTypeId"
                             [consortiumBeneficiaryCompanies]="bondVersion.consortiumBeneficiaryCompanies"
                             [consortiumClientCompanies]="bondVersion.consortiumClientCompanies", [readOnly]="readOnly"></bonding-consortia-section>
  <div class="bon-card-inner-group">
    <div class="bon-card-group">
      <company-selector-simple (selectItem)="bondVersion.currentClient = $event" labelKey="bond.details.currentClient" [open]="listEmitters.openSelectorEmitters[CompanyListEmitter.CURRENT_CLIENT]"
      [searchModeSwitchable]="true"></company-selector-simple>
    </div>
  </div>
  <!-- **************************--><!-- * Section: Custom fields *--><!-- **************************-->
  <bon-section labelKey="bond.common.customFields" [hidden]="!showDetails"
               *ngIf="bondSubtypes[bondVersion.subtype?.id]?.relatedDictionaries['BondCustomFieldType']?.length &gt; 0">
    <div class="bon-card-group">
      <div class="bon-card-inner">
        <bond-custom-fields #bondCustomFieldsComponent [bond]="bondVersion" [bondForm]="ngForm.form" [showErrors]="showErrors"></bond-custom-fields>
      </div>
    </div>
  </bon-section><!-- ****************************--><!-- * Section: Subject of bond *-->
  <!-- ****************************-->
  <bonding-decisions [showErrors]="showErrors" [object]="bondVersion"></bonding-decisions>
  <ng-container *ngFor="let externalContract of bondVersion.externalContracts">
    <bonding-external-contract [showErrors]="showErrors" [contract]="externalContract" [bondVersion]="bondVersion" [readOnly]="readOnly"
                               [productTypeId]="contractTypeId" [bondType]="bondType"></bonding-external-contract>
  </ng-container>

  <bon-section labelKey="bond.common.premiumAndFees" [hidden]="!showDetails" *ngIf="bondVersion.id && !portal">
    <div class="bon-card-group">
      <div class="bon-card-inner bon-label-190">
        <form-row labelKey="bond.common.bondPayment">
          <ng-container *ngIf="bondPaymentCheckbox">
            <num-input class="bon-input-size" [(ngModel)]="bondVersion.bondPayment" style="margin-right: 8px;" name="bondPayment" [disabled]="disabled" required #bondPayment="ngModel"></num-input>
            <checkbox-entity name="bondPaymentCheckbox" [(ngModel)]="bondPaymentCheckbox" [disabled]="disabled"></checkbox-entity>
            <error-message [control]="bondPayment" [show]="showErrors"></error-message>
          </ng-container>
          <checkbox-entity *ngIf="!bondPaymentCheckbox" name="bondPaymentCheckbox" [(ngModel)]="bondPaymentCheckbox" [disabled]="disabled"></checkbox-entity>
        </form-row>
        <form-row labelKey="bond.common.anexPayment">
          <ng-container *ngIf="annexPaymentCheckbox">
            <num-input class="bon-input-size" [(ngModel)]="bondVersion.anexPayment" style="margin-right: 8px;" name="anexPayment" [disabled]="disabled" required #anexPayment="ngModel"></num-input>
            <checkbox-entity name="anexPaymentCheckbox" [(ngModel)]="annexPaymentCheckbox" [disabled]="disabled"></checkbox-entity>
            <error-message [control]="anexPayment" [show]="showErrors"></error-message>
          </ng-container>
          <checkbox-entity *ngIf="!annexPaymentCheckbox" name="anexPaymentCheckbox" [(ngModel)]="annexPaymentCheckbox" [disabled]="disabled"></checkbox-entity>
        </form-row>
        <form-row labelKey="bond.common.otherPayment">
          <ng-container *ngIf="otherPaymentCheckbox">
            <num-input class="bon-input-size" [(ngModel)]="bondVersion.otherPayment" style="margin-right: 8px;" name="otherPayment" [disabled]="disabled" required #otherPayment="ngModel"></num-input>
            <checkbox-entity name="otherPaymentCheckbox" [(ngModel)]="otherPaymentCheckbox" [disabled]="disabled"></checkbox-entity>
            <error-message [control]="otherPayment" [show]="showErrors"></error-message>
          </ng-container>
          <checkbox-entity *ngIf="!otherPaymentCheckbox" name="otherPaymentCheckbox" [(ngModel)]="otherPaymentCheckbox" [disabled]="disabled"></checkbox-entity>
        </form-row>
      </div>
      <div class="bon-card-inner bon-label-150">
        <checkbox-row labelKey="bond.common.premiumInstallment" [(ngModel)]="bondVersion.premiumInstallment"
                      name="premiumInstallment" (changeState)="onPremiumInstallmentCheckboxChange()" [disabled]="disabled">
        </checkbox-row>
        <ng-container *ngIf="bondVersion.premiumInstallment">
          <a-table [items]="bondVersion.bondInstallmentPayments" [sortable]="false" [editable]="!bondVersion.activated"
                   [addButton]="!bondVersion.activated" [deleteButton]="!bondVersion.activated" [selection]="false">
            <column property="paymentdate" labelKey="common.date" type="date" required="true"></column>
            <column property="value" labelKey="common.value" type="number" required="true" minValue="0"></column>
          </a-table>
        </ng-container>
        <different-price [bond]="bondVersion" [contractVersion]="contractVersion"  [showErrors]="showErrors" [disabled]="disabled"></different-price>
      </div>
      <div *ngIf="!portal" labelKey="bond.common.calculatedPremium" class="bon-card-inner bon-label-150">
        <form-row labelKey="bond.common.invoicedCompany" [showErrors]="showErrors">
          <item-combo class="bon-input-size" nullLabel=" " [items]="invoicedCompanies" [(ngModel)]="bondVersion.invoicedCompany" name="invoicedCompanies" [disabled]="disabled">
            <ng-template let-item="item">{{item.registrationName}}</ng-template>
          </item-combo>
        </form-row>

        <checkbox-row labelKey="bond.common.noBrokerage" [(ngModel)]="bondVersion.noBrokerage" name="noBrokerage" [disabled]="disabled"></checkbox-row>
      </div>
    </div>
  </bon-section>
  <bon-section labelKey="bond.common.contractExecutionAddress" [hidden]="!showDetails"
               *ngIf="bondVersion?.contractType?.code === 'BSTR'">
    <div class="bon-card-group">
      <div class="bon-card-inner">
        <address-table [form]="ngForm.form" controlName="executionAddress" [oneTypeMode]="true"
                       [addresses]="[bondVersion.executionAddress]" [showErrors]="showErrors" [editable]="!disabled"></address-table>
      </div>
    </div>
  </bon-section><!-- *************************************--><!-- * Section: Cancellation and release *-->

  <bon-section labelKey="contract.details.collaterals.title" [hidden]="!showDetails || hideCollaterals">
    <bonding-contract-collaterals [collaterals]="bondVersion.collaterals" [form]="ngForm.form" [showErrors]="showErrors"
                                  [businessObjectTypeId]="BusinessObjectType.BOND_VERSION" [tabView]="false" [dropup]="true" [readOnly]="disabled"></bonding-contract-collaterals>
  </bon-section>



  <!-- *************************************-->
  <bon-section labelKey="bond.common.cancellationAndRelease" [hidden]="!showDetails" *ngIf="!portal && bondVersion.id && bondVersion.activated">
    <div class="bon-card-group">
      <div class="bon-card-inner bon-label-190">
        <date-row *ngIf="bondVersion.releaseDate || releaseDateEditable" labelKey="bond.common.releaseDate" [(ngModel)]="bondVersion.releaseDate" name="releaseDate"
                  [showErrors]="showErrors" [disabled]="!releaseDateEditable" (changeItem)="calculatePremiumReturn()"></date-row>
        <date-row *ngIf="bondVersion.cancellationDate || cancellationDateEditable" labelKey="bond.common.cancellationDate" [(ngModel)]="bondVersion.cancellationDate"
                  name="cancellationDate" [disabled]="!cancellationDateEditable"></date-row>
      </div>
      <div class="bon-card-inner bon-label-300" *ngIf="bondVersion.releaseDate || releaseDateEditable">
        <checkbox-row  labelKey="bond.common.premiumReturn" [(ngModel)]="bondVersion.premiumReturn"
                      name="premiumReturn" [disabled]="!releaseDateEditable" (changeState)="calculatePremiumReturn()">
        </checkbox-row>
        <num-row labelKey="bond.common.premiumReturnAmount" *ngIf="bondVersion.premiumReturn" [(ngModel)]="bondVersion.premiumReturnAmount"
                 name="premiumReturnAmount" required></num-row>
      </div>
      <div class="bon-card-inner bon-label-190"></div>
    </div>
  </bon-section>
</ng-container>
