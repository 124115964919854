import {NgModule} from '@angular/core';
import {ClientInvoiceGuiService} from './services/client-invoice-gui';
import {ClientInvoiceSearchComponent} from './client-invoice-search.component';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClientInvoicePreviewComponent} from './client-invoice-preview.component';
import {InvoiceItemModule} from '../../bonding/invoice-item/invoice-item.module';
import {CompanySharedModule} from '../../bonding/company/components/shared/company-shared.module';
import {DocumentModule} from '../../bonding/document/document.module';
import {ClientInvoiceListComponent} from './components/client-invoice-list.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    InvoiceItemModule,
    CompanySharedModule,
    DocumentModule,
  ],
  declarations: [ClientInvoiceSearchComponent, ClientInvoicePreviewComponent, ClientInvoiceListComponent],
  exports: [ClientInvoiceListComponent],
  providers: [ClientInvoiceGuiService],
})
export class ClientInvoiceModule {}
