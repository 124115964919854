import {Component, ViewChild} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {
  BondVersionSimpleDto,
  BusinessObjectDto,
  DictionaryBaseDto,
  InvoiceCriteriaDto,
  InvoiceDto,
  InvoiceSimpleDto,
} from '../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {InvoiceGuiService} from './services/invoice-gui.service';
import {DocumentService} from '../../bonding_shared/services/document.service';
import {ATableComponent} from '../../bonding_shared/components/aku-table/a-table.component';
import {
  BusinessObjectType,
  Feature,
  InvoiceCategory,
  InvoiceStatus,
  ProductLine,
} from '../../bonding_shared/model/dictionary-ids';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchViewComponent} from '../../bonding_shared/components/search/search-view/search-view.component';
import {
  AppConfigService,
  DictionaryBaseService,
  FeatureService,
  GrowlService,
  InvoiceCoreService,
  LoggedUserService,
  PolicyContractService,
  SettingsService,
} from '../../bonding_shared/services';
import {AbstractInvoiceComponent} from '../../bonding_shared/components/shared/abstract-invoice.component';
import {InvoiceService} from '../../bonding_shared/services/invoice.service';
import {TranslateService} from '@ngx-translate/core';
import {ComboItem} from '../../bonding_shared/components/combos';
import {BondGuiService} from '../bond/services';
import {InvoiceMassService} from '../../bonding_shared/services/invoice-mass.service';
import {ConfirmDialogComponent, StringUtils} from '../../bonding_shared';

@Component({
  selector: 'invoice-search',
  templateUrl: 'invoice-search.component.html',
})
export class InvoiceSearchComponent extends AbstractInvoiceComponent {
  readonly InvoiceCategory = InvoiceCategory;
  protected hiddenStatuses = new Set([InvoiceStatus.TO_BE_PAID]);

  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<InvoiceCriteriaDto, InvoiceSimpleDto>;
  @ViewChild(ATableComponent, {static: true}) invoiceTable: ATableComponent<InvoiceDto>;
  @ViewChild('confirmDialog') confirmDialog: ConfirmDialogComponent;
  dataProvider: SearchDataProvider<InvoiceCriteriaDto, InvoiceDto>;
  productLine: DictionaryBaseDto;
  productLineHiddenIds: number[];
  businessObjectTypeId: number;

  policyYearComboItems: ComboItem[] = [];
  selectedPolicyYearComboItem: ComboItem;
  captionKey: string;
  newObjectLabelKey: string;
  policyContractId: number;
  masterPolicyId: number;

  bondId: number;
  bondVersions: BondVersionSimpleDto[] = [];
  productLineDisabled = false;
  public showShortNameIfExists: boolean; // show short company name instead of registration name when it exists on search view

  constructor(
    public router: RouterService,
    protected route: ActivatedRoute,
    public guiService: InvoiceGuiService,
    private invoiceService: InvoiceService,
    private invoiceMassService: InvoiceMassService,
    private policyContractService: PolicyContractService,
    private bondGuiService: BondGuiService,
    private documentService: DocumentService,
    protected dictionaryBaseService: DictionaryBaseService,
    protected translateService: TranslateService,
    protected invoiceCoreService: InvoiceCoreService,
    private settingsService: SettingsService,
    private growlService: GrowlService,
    protected loggedUserService: LoggedUserService,
    private appService: AppConfigService,
    private featureService: FeatureService
  ) {
    super(dictionaryBaseService, route, translateService, invoiceCoreService, loggedUserService);
    this.showShortNameIfExists = featureService.get(Feature.COMPANY_SHOW_SHORT_NAME_IF_EXISTS);
  }

  getSearchView(): SearchViewComponent<any, any> {
    return this.searchView;
  }

  protected initializeView(params: Params) {
    const categoryStr: keyof typeof InvoiceCategory = params['category'];
    this.businessObjectTypeId = params['businessObjectTypeId'];
    const businessObjectId: number = params['businessObjectId'];
    const categoryId = InvoiceCategory[categoryStr];
    this.dataProvider = this.guiService.getDataProvider(categoryId, this.businessObjectTypeId, businessObjectId);
    this.initProductLine(categoryId);
    if (this.categoryId !== categoryId) {
      if (this.categoryId) {
        // We search again when category is changed. We must avoid double search (SearchViewComponent executes search after view init).
        this.searchView.newProviderSearch(this.dataProvider);
      }
      this.categoryId = categoryId;
    }

    this.captionKey = this.getCategoryTranslationKey('invoice.search.caption');
    this.newObjectLabelKey = this.getCategoryTranslationKey('invoice.search.newObject');

    this.initPolicyContract(this.businessObjectTypeId, businessObjectId);
    this.initBond(this.businessObjectTypeId, businessObjectId);

    if (!this.businessObjectTypeId) {
      this.policyContractId = undefined;
      this.masterPolicyId = undefined;
      this.selectedPolicyYearComboItem = undefined;
      this.policyYearComboItems = [];
    }
  }

  initPolicyContract(businessObjectTypeId: number, businessObjectId: number) {
    if (String(businessObjectTypeId) === String(BusinessObjectType.POLICY) && businessObjectId) {
      this.policyContractId = businessObjectId;
      this.dataProvider.searchCriteria.criteria.contractLinkTypes = undefined;
      this.policyContractService.getPeers(businessObjectId).subscribe((result) => {
        // tslint:disable-next-line:max-line-length
        const preselected = result.filter((e) => String(e.id) === String(this.policyContractId))[0]; // will not work with === instead of ==
        this.masterPolicyId = preselected.masterPolicyId;
        result.forEach((e) => this.policyYearComboItems.push(new ComboItem(e.id + '', e.policyYearFormatted)));
        this.selectedPolicyYearComboItem = new ComboItem(preselected.id + '', preselected.policyYearFormatted);
      });
    }
  }

  initBond(businessObjectTypeId: number, businessObjectId: number) {
    if (String(businessObjectTypeId) === String(BusinessObjectType.BOND) && businessObjectId) {
      this.bondId = businessObjectId;
      this.dataProvider.searchCriteria.criteria.contractLinkTypes = undefined;
      this.onSelectBondVersion(undefined);
      this.bondGuiService.getBondVersions(this.bondId).subscribe((versions) => (this.bondVersions = versions.result));
    }
  }

  initProductLine(categoryId: number) {
    if (categoryId === InvoiceCategory.INVOICE.valueOf() || categoryId === InvoiceCategory.INTEREST.valueOf()) {
      this.productLineHiddenIds = [ProductLine.BROKER];
    }

    if (categoryId === InvoiceCategory.BROKER.valueOf()) {
      this.productLine = new (class implements DictionaryBaseDto {
        active: true;
        code: 'BRO';
        description: string;
        extendedDescription: string;
        dictName: 'ProductLine';
        id: number = ProductLine.BROKER;
        name: 'Broker';
        parentId: number;
        parentName: string;
        sortOrder: number;
      })();
      this.dataProvider.searchCriteria.criteria.contractLinkTypes = [BusinessObjectType.BROKER_CONTRACT];
      this.productLineDisabled = true;
    } else if (this.settingsService.bondingViews) {
      this.productLine = new (class implements DictionaryBaseDto {
        active: true;
        code: 'BON';
        description: string;
        extendedDescription: string;
        dictName: 'ProductLine';
        id: number = ProductLine.BONDING;
        name: 'Bonding';
        parentId: number;
        parentName: string;
        sortOrder: number;
      })();
      this.dataProvider.searchCriteria.criteria.contractLinkTypes = [BusinessObjectType.CONTRACT];
    } else if (this.settingsService.policyViews) {
      this.productLine = new (class implements DictionaryBaseDto {
        active: true;
        code: 'INS';
        description: string;
        extendedDescription: string;
        dictName: 'ProductLine';
        id: number = ProductLine.INSURANCE;
        name: 'Insurance';
        parentId: number;
        parentName: string;
        sortOrder: number;
      })();
      this.dataProvider.searchCriteria.criteria.contractLinkTypes = [
        BusinessObjectType.POLICY,
        BusinessObjectType.POLICY_INQUIRY,
      ];
    }
  }

  onProductLineChange(p: DictionaryBaseDto) {
    if (p) {
      let typeId;
      if (p.id === ProductLine.BONDING) {
        typeId = [BusinessObjectType.CONTRACT];
      } else if (p.id === ProductLine.INSURANCE) {
        typeId = [BusinessObjectType.POLICY, BusinessObjectType.POLICY_INQUIRY];
      } else if (p.id === ProductLine.BROKER) {
        typeId = [BusinessObjectType.BROKER_CONTRACT];
      }
      this.dataProvider.searchCriteria.criteria.contractLinkTypes = typeId;
    } else {
      this.dataProvider.searchCriteria.criteria.contractLinkTypes = undefined;
    }
  }

  isBonding(): boolean {
    return this.settingsService.bondingViews;
  }

  newExecuted(event: any) {
    this.router.toInvoiceDetails(this.categoryId, 0);
  }

  downloadZip() {
    const boList: BusinessObjectDto[] = [];
    for (const item of this.invoiceTable.items) {
      const bo = <BusinessObjectDto>{};
      bo.relatedToId = item.id;
      bo.relatedTo = <DictionaryBaseDto>{};
      bo.relatedTo.id = BusinessObjectType.INVOICE;
      boList.push(bo);
    }

    this.documentService.downloadBoZip(boList, 'invoices.zip', (errMsg) => {
      this.searchView.resultList.errorMessage = JSON.parse(errMsg);
    });
  }

  get writeAllOffVisible() {
    return this.categoryId === InvoiceCategory.INTEREST;
  }

  openWriteAllOffConfirmDialog() {
    this.confirmDialog.openAndExecuteOnConfirm(
      'invoice.search.writeAllOffConfirmationTitle',
      'invoice.search.writeAllOffConfirmationMessage',
      this.writeAllOff.bind(this)
    );
  }

  writeAllOff() {
    this.searchView.serverErrors = undefined;
    this.searchView.dataProvider.inProgress = true;
    this.invoiceMassService.writeAllOff(this.dataProvider.searchCriteria).subscribe(
      (result) => {
        this.growlService.notice('invoice.search.writeAllOffSuccess', '', {count: result});
        this.searchView.dataProvider.inProgress = false;
        this.searchView.search();
      },
      (error) => {
        this.searchView.serverErrors = error;
        this.searchView.dataProvider.inProgress = false;
      }
    );
  }

  exportClicked() {
    this.exportInProgress = true;
    this.invoiceService.export(
      this.dataProvider.searchCriteria.criteria,
      'Invoices.xlsx',
      (errorMsg) => this.downloadErrorCallback(errorMsg),
      () => this.exportCompleteCallback()
    );
  }

  getInvoiceCategoryLabel(): string {
    return this.guiService.getInvoiceCategoryLabel(this.invoiceCategoryMap[this.categoryId], false);
  }

  onSelectContractByPolicyYear(e: any) {
    console.log('onSelectContractByPolicyYear policy contract id = ' + e);
    if (!e) {
      this.dataProvider.searchCriteria.criteria.contractLink.policyContract.masterPolicyId = this.masterPolicyId;
    }
  }

  onSelectBondVersion(bondVersionId: number) {
    this.dataProvider.searchCriteria.criteria.includeBoVersions = true;
    if (bondVersionId) {
      this.dataProvider.searchCriteria.criteria.businessObject.relatedTo = <DictionaryBaseDto>{
        id: BusinessObjectType.BOND_VERSION,
      };
    } else {
      this.dataProvider.searchCriteria.criteria.businessObject.relatedTo = <DictionaryBaseDto>{
        id: BusinessObjectType.BOND,
      };
      this.dataProvider.searchCriteria.criteria.businessObject.relatedToId = this.bondId;
    }
  }

  public getSubtypeOrType(invoice: InvoiceSimpleDto): string {
    let txt = invoice.subtype ? invoice.subtype.name : invoice.subtypes;
    if (StringUtils.isEmpty(txt) || txt === '-') {
      txt = invoice.types;
    }
    return txt;
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }

  initialSort(): string {
    return this.kuke ? null : 'down';
  }
}

export interface PolicyYear {
  year: number;
  label: string;
}
