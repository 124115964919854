import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SearchViewComponent} from '../../../bonding_shared/components/search';
import {
  BusinessLogCriteriaDto,
  BusinessLogDto,
  ErrorReason,
  LocalDateTimeRange,
  UserBaseDto,
} from '../../../bonding_shared/model';
import {BusinessLogService, RouterService, SearchDataProvider} from '../../../bonding_shared/services';
import {BusinessLogGuiService} from '../services/business-log-gui.service';
import {DateUtils} from '../../../bonding_shared/utils/date-utils';

@Component({
  selector: 'business-log',
  templateUrl: './business-log.component.pug',
})
export class BusinessLogComponent implements OnInit {
  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<
    BusinessLogCriteriaDto,
    BusinessLogDto
  >;
  dataProvider: SearchDataProvider<BusinessLogCriteriaDto, BusinessLogDto>;

  constructor(
    public router: RouterService,
    private service: BusinessLogGuiService,
    private businessLogService: BusinessLogService
  ) {
    this.dataProvider = service.searchDataProvider;
  }

  ngOnInit() {
    if (!this.dataProvider.searchCriteria.criteria) {
      this.initializeCriteria();
    }
  }

  initializeCriteria() {
    this.dataProvider.searchCriteria.criteria = <BusinessLogCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.user = <UserBaseDto>{};
    const today = DateUtils.today();
    this.dataProvider.searchCriteria.criteria.eventDateRange = <LocalDateTimeRange>{
      dateFrom: today,
      dateTo: today,
    };
    this.dataProvider.textSearch = false;
  }

  public export() {
    this.dataProvider.inProgress = true;
    this.businessLogService.export(
      this.dataProvider.searchCriteria.criteria,
      'business-log.xlsx',
      (errorMsg) => this.downloadErrorCallback(errorMsg),
      () => {
        this.searchView.serverErrors = null;
        this.dataProvider.inProgress = false;
        this.exportCompleteCallback();
      }
    );
  }

  private downloadErrorCallback(err: string): void {
    const error = <ErrorReason[]>JSON.parse(err);
    console.log('Training export error', error);
    this.searchView.serverErrors = error;
    this.dataProvider.inProgress = false;
  }

  private exportCompleteCallback(): void {
    console.log('exportCompleteCallback');
  }
}
