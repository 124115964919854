import {TemplateSearchComponent} from './template-search.component';
import {DocumentSearchComponent} from './document-search.component';
import {DocumentDetailsComponent} from './document-details.component';
import {TemplateDetailsComponent} from './template-details.component';
import {TemplateDetailsGuard} from './_guards';
import {DocumentsPackComponent} from './documents-pack.component';

export const DocumentRoutes = [
  {
    path: 'template-search',
    component: TemplateSearchComponent,
  },
  {
    path: 'document-search',
    component: DocumentSearchComponent,
  },
  {
    path: 'template-details/:id',
    component: TemplateDetailsComponent,
    canActivate: [TemplateDetailsGuard],
  },
  {
    path: 'document-details/:id',
    component: DocumentDetailsComponent,
  },
  {
    path: 'document-details/:id/:additionalValuesBoTypeId/:additionalValuesBoId',
    component: DocumentDetailsComponent,
  },
  {
    path: 'document-details/:id/:parentBusinessObjectTypeId/:parentBusinessObjectId/:businessObjectId/:contactBusinessObjectTypeId/:contactBusinessObjectId',
    component: DocumentDetailsComponent,
  },
  {
    path: 'document-details-new/:templateId/:businessObjectId',
    component: DocumentDetailsComponent,
  },
  {
    path: 'document-details-new/:templateId/:businessObjectId/:copyFor',
    component: DocumentDetailsComponent,
  },
  {
    path: 'document-details-new/:targetCompanyId/:templateId/:parentBusinessObjectTypeId/:parentBusinessObjectId/:businessObjectId/:contactBusinessObjectTypeId/:contactBusinessObjectId',
    component: DocumentDetailsComponent,
  },
  {
    path: 'document-details-new-with-additional/:templateId/:businessObjectId/:additionalValuesBoTypeId/:additionalValuesBoId',
    component: DocumentDetailsComponent,
  },
  {
    path: 'document-details-new-operations/:operationId/:templateId/:businessObjectId/:docTypeId',
    component: DocumentDetailsComponent,
  },
  {
    path: 'documents-pack/:boTypeId/:boId',
    component: DocumentsPackComponent,
  },
];
