import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {AppConfigService} from '../../../../../bonding_shared/services';
import {ControlContainer, NgForm} from '@angular/forms';
import {PolicyContractVersionStatus, PolicyTerminationType} from '../../../../../bonding_shared/model/dictionary-ids';
import * as moment from 'moment';

@Component({
  selector: 'kuke-dates-section',
  templateUrl: './kuke-dates-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class KukeDatesSectionComponent extends Section {
  @Input() documentReceivedAndSignatureDateRequired: boolean;
  @Output() dataChangedEvent = new EventEmitter<void>();

  constructor(protected policyContractViewService: PolicyContractViewService, public appService: AppConfigService) {
    super(policyContractViewService, appService);
  }

  get readonly() {
    return super.readonly || this.termination();
  }

  onValidFromChanged(validFrom: Date) {
    if (
      this.policy.terminationType &&
      this.policy.terminationType.id === PolicyTerminationType.RESIGNATION &&
      this.policy.withdrawalOnTime
    ) {
      this.policy.terminationDate = validFrom;
      this.policy.validTo = validFrom;
    }

    if (!this.readonly) {
      if (this.policy.policyContract.last && this.initialPolicyVersion()) {
        const maxValidTo = this.calculateMaxValidTo();
        if (maxValidTo) {
          this.policy.validTo = maxValidTo.toDate();
        }
      }
    }
    if (this.dataChangedEvent) {
      this.dataChangedEvent.emit();
    }
  }

  private termination() {
    return this.selectedPCVInOneOfStatuses(
      PolicyContractVersionStatus.TERMINATION_REQUEST,
      PolicyContractVersionStatus.TERMINATION_REQUEST_ACCEPTED,
      PolicyContractVersionStatus.TERMINATION_REJECTED,
      PolicyContractVersionStatus.TERMINATION_ACTIVATED
    );
  }

  private terminationEditable() {
    return false; // IKI-9865
  }

  // info: prolongation statuses excluded due to unchangeable character of prolongation,
  // date have to be right after VT of previous PC
  pcvValidFromPresentationMode() {
    return !this.isLiveRequestOrTerminationRequest() || this.policyContractViewService.readonly;
  }

  pcvValidToPresentationMode() {
    // IKI-5090
    const terminationEditable = this.terminationEditable();
    return !(!this.policyContractViewService.readonly && this.policy.policyContract.last && terminationEditable);
  }

  paperValidFromPresentationMode() {
    return (
      (this.policy.status &&
        !this.selectedPCVInOneOfStatuses(
          PolicyContractVersionStatus.REQUEST,
          PolicyContractVersionStatus.REQUEST_ACCEPTED,
          PolicyContractVersionStatus.TERMINATION_REQUEST,
          PolicyContractVersionStatus.TERMINATION_REQUEST_ACCEPTED,
          PolicyContractVersionStatus.PROLONGATION_REQUEST,
          PolicyContractVersionStatus.PROLONGATION_REQUEST_EXTRA_RIGHTS,
          PolicyContractVersionStatus.PROLONGATION_REQUEST_ACCEPTED,
          PolicyContractVersionStatus.ISSUED
        )) ||
      this.policyContractViewService.readonly
    );
  }

  documentsSendTypeRequired() {
    return this.statusAtLeastREQUEST_ACCEPTED() && this.getNoTerminationTypeOrNotCancelledType();
  }

  documentsReceiveDateRequired() {
    return (
      this.statusAtLeastISSUED() &&
      this.getNoTerminationTypeOrNotCancelledType() &&
      this.documentReceivedAndSignatureDateRequired
    );
  }

  signatureDateRequired() {
    return (
      this.statusAtLeastISSUED() &&
      this.getNoTerminationTypeOrNotCancelledType() &&
      this.documentReceivedAndSignatureDateRequired
    );
  }

  // case cancellation (not paid rate or not returned documents)
  getNoTerminationTypeOrNotCancelledType() {
    if (this.policy.terminationType) {
      return !(
        this.policy.terminationType.id === PolicyTerminationType.CANCELLATION_PAYMENT ||
        this.policy.terminationType.id === PolicyTerminationType.CANCELLATION__DOCUMENTS
      );
    }
    return true;
  }

  pcvValidToMinDate() {
    if (!this.readonly) {
      const now = new Date();
      return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    } else {
      return undefined;
    }
  }

  pcvValidToMaxDate() {
    if (!this.readonly) {
      if (this.policy.policyContract.last) {
        const maxValidTo = this.calculateMaxValidTo();
        if (maxValidTo) {
          return this.calculateMaxValidTo().toDate();
        } else {
          return undefined;
        }
      } else {
        return this.policy.policyContract.validTo;
      }
    } else {
      return undefined;
    }
  }

  private calculateMaxValidTo() {
    if (this.policy.offer && this.policy.offer.insurancePeriod) {
      return moment(this.policy.validFrom).add(this.policy.offer.insurancePeriod, 'month').subtract(1, 'day');
    } else {
      return undefined;
    }
  }

  private initialPolicyVersion() {
    return (
      this.selectedPCVInOneOfStatuses(
        PolicyContractVersionStatus.REQUEST,
        PolicyContractVersionStatus.REQUEST_ACCEPTED
      ) && this.firstVersionOfFirstYear()
    );
  }

  private firstVersionOfFirstYear() {
    return this.policy.policyContract.policyYear === 1 && this.policy.versionNumber === 1;
  }
}
