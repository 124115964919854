import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
@Directive({
  selector: '[bonFocus]',
})
export class BonFocusDirective implements OnInit {
  private _bonFocus: boolean | 'auto' | 'clear';

  @Input() set bonFocus(bonFocus: boolean | 'auto' | 'clear') {
    this._bonFocus = bonFocus;
    switch (bonFocus) {
      case true:
        setTimeout(
          function () {
            this.elementRef.nativeElement.focus();
          }.bind(this),
          0
        );
        break;
      case false:
        setTimeout(
          function () {
            this.elementRef.nativeElement.blur();
          }.bind(this),
          0
        );
        break;
    }
  }

  constructor(public renderer: Renderer2, public elementRef: ElementRef) {}

  ngOnInit(): void {
    console.log('focus: ', this._bonFocus);
    if (this._bonFocus === true || this._bonFocus === 'auto' || this._bonFocus === 'clear') {
      setTimeout(
        function () {
          this.elementRef.nativeElement.focus();
          if (this._bonFocus === 'clear') {
            this.elementRef.nativeElement.value = '';
          }
        }.bind(this),
        0
      );
    }
  }

  focus() {
    // TODO ANGULAR delete after confiramtion that solution works
    // Fix according to migration guide
    // this.renderer.invokeElementMethod(this.elementRef.nativeElement, 'focus', []);
    (this.elementRef.nativeElement as any)['focus'].apply(this.elementRef.nativeElement, []);
  }

  blur() {
    // TODO ANGULAR delete after confiramtion that solution works
    // Fix according to migration guide
    // this.renderer.invokeElementMethod(this.elementRef.nativeElement, 'blur', []);
    (this.elementRef.nativeElement as any)['blur'].apply(this.elementRef.nativeElement, []);
  }
}
