<ng-container>
  <!-- ********************--><!-- * Section: Details *--><!-- ********************-->
  <div class="bon-card-inner-group" [hidden]="!showDetails">
    <div class="bon-header">Details</div>
  </div>
  <div class="bon-card-group" [hidden]="!showDetails">
    <div class="bon-card-inner bon-label-190">
      <dict-row label="Version type" [(ngModel)]="bond.versionType" dictionary="BondVersionType" name="versionType"  [disabled]="true"></dict-row>
      <dict-row *ngIf="bond?.businessStatus" label="Status" [ngModel]="bond.businessStatus" dictionary="BondStatus" [disabled]="true" name="status"></dict-row>
      <dict-row label="Currency" [(ngModel)]="bond.currency" dictionary="Currency" [itemLabel]="'code'" name="currency" (changeItem)="onCurrencyChanged($event)"></dict-row>

      <num-row [labelKey]="'common.' + (bond.output.showMultiValues ? 'originalValue' : 'value')" [(ngModel)]="bond.values[0].value"
               (changeValue)="updateSingleValueInSysCurr($event)" name="originalValue" [disabled]="!isSingleValueEditable(false)"
               [showErrors]="showErrors && !bond.output.showMultiValues" [required]="true"></num-row>
      <num-row [(ngModel)]="bond.values[0].valueInSysCurr" name="valueInSysCurr" [disabled]="true" [presentationMode]="true"
               [label]="(bond.output.showMultiValues ? 'common.originalValueWithCurr' : 'common.valueWithCurr') | translate : {currencyCode: SYSTEM_CURRENCY_CODE}"></num-row>
      <num-row *ngIf="!appService.kuke && isCoInsurance" [labelKey]="'bond.common.coinsurance.totalValue'" [(ngModel)]="bond.coInsuranceTotalValue" name="coInsuranceTotalValue"
               [showErrors]="showErrors" (changeValue)="updateTotalValueInSysCurr($event)"></num-row>
      <num-row *ngIf="!appService.kuke && isCoInsurance" [labelKey]="'bond.common.coinsurance.totalValueEUR'" [(ngModel)]="bond.coInsuranceTotalValueEUR" name="coInsuranceTotalValueEUR"
               [showErrors]="showErrors" [disabled]="!bond.currency || isSysCurr(bond.currency)"></num-row>
      <dict-row label="Language" [(ngModel)]="bond.language" dictionary="Language" name="language" (changeItem)="onLanguageChanged($event)"></dict-row>
      <dict-row label="Law country" [(ngModel)]="bond.lawCountry" dictionary="Country" name="lawCountry"></dict-row>
      <dict-row label="Court country" [(ngModel)]="bond.courtCountry" dictionary="Country" name="courtCountry" (changeItem)="onCourtCountryChange($event)"></dict-row>
      <form-row labelKey="bond.common.cityOfJurisdiction" *ngIf="isCityFromDict(bond.courtCountry)">
        <geo-dict-auto-complete [(ngModel)]="bond.cityOfJurisdiction" name="cityOfJurisdiction"
                                [geoDict]="'city'" [parentDictionary]="'country'"
                                [parentDictionaryEntryId]="this.bond.courtCountry.id" valueFormatter="name"
                                listFormatter="name" [matchFormatted]="true"
                                style="display: inline-block; width: calc(100% - 220px);"
                                [focus]="false"></geo-dict-auto-complete>
      </form-row>
      <input-row labelKey="bond.common.cityOfJurisdiction" name="cityOfJurisdictionText"
                 *ngIf="bond.courtCountry && !isCityFromDict(bond.courtCountry)"
                 [(ngModel)]="bond.cityOfJurisdictionText" [showErrors]="showErrors"></input-row>
      <form-row label="Signer business unit">
        <business-unit-selector class="bon-input-size" [(ngModel)]="bond.businessUnitSigner" name="businessUnitSigner"></business-unit-selector>
      </form-row>
      <div class="bon-card-inner-group">
        <bond-value-list #bondMultiValuesTable *ngIf="bond.output.showMultiValues" title="bond.values" [showReason]="true" [bondCurrency]="bond.currency"
                         [values]="bond.values" [showErrors]="showErrors" [showSystemCurrency]="true" listName="bondMultiValues" [editable]="!bond.output.showPayments"></bond-value-list>
        <bond-value-list #bondSupplementaryValuesTable *ngIf="bond.output.showSupplementaryValues" title="bond.warrantyValues" [values]="bond.supplementaryValues" [showErrors]="showErrors"
                         [bondCurrency]="bond.currency"
                         [showSystemCurrency]="true" [firstValueDeletable]="true" [showReason]="true" listName="bondSupplementaryValues"></bond-value-list>
        <bond-payment-list #bondPaymentsTable *ngIf="bond.output.showPayments" [values]="bond.payments" listName="bondPayments"
                         [bondCurrency]="bond.currency" [showErrors]="showErrors" (addUpdateDelete)="recalculateBondValidTo()"></bond-payment-list>
      </div>
      <!-- it-bid-bond(#itBidBondComponent, [bond] = "bond")-->
      <!-- it-performance-bond(#itPerformanceBondComponent, *ngIf="bond && tempContractVersion && tempContractVersion.id", [bond] = "bond", [contractVersionId] = "tempContractVersion.id")-->
    </div>
    <div class="bon-card-inner bon-label-150">
      <date-row label="Issue date" [(ngModel)]="bond.issueDate" [disabled]="true" name="issueDate"></date-row>
      <date-row *ngIf="acceptedConditionsDateVisible()" label="Date bond accepted/paid" [(ngModel)]="bond.acceptedConditionsDate" name="acceptedConditionsDate" [showErrors]="showErrors"
                [required]="acceptedConditionsDateRequired()"></date-row>
      <date-row label="*Annex valid from" [(ngModel)]="bond.validFrom" name="validFrom" required  [showErrors]="showErrors"
                (changeItem)="onValidFromChanged($event)"></date-row>
      <date-row label="Annex valid to" [(ngModel)]="bond.validTo" [disabled]="!bond.id || bond.last" name="validTo"></date-row>
      <date-row label="Valid to" [(ngModel)]="bond.bondValidTo" name="bondValidTo" (changeItem)='onValidToChanged()' [showErrors]="showErrors"
                [disabled]="isValidToDisabled()"></date-row>
      <checkbox-row label="Valid to approximated" [(ngModel)]="bond.estimatedValidTo" name="estimatedValidTo" (change)="refreshAutoprolongation()"></checkbox-row>
      <checkbox-row label="Manual invoicing" [(ngModel)]="bond.bond.manualInvoicing" name="manualInvoicing"></checkbox-row>
      <dict-row label="Delivery type" [(ngModel)]="bond.deliveryType" dictionary="DeliveryType" nullLabel="-"
                name="deliveryType"></dict-row>
      <amount-row labelKey="bond.common.deliveryFee" [parent]="bond" amountPropertyName="deliveryFee" [currencyId]="bond.invoiceCurrency?.id" type="decimal"></amount-row>
      <amount-row labelKey="bond.common.individualTextFee" [parent]="bond" amountPropertyName="individualTextFee" [currencyId]="bond.invoiceCurrency?.id" type="decimal"></amount-row>
      <form-row labelKey="bond.grossNotaryAuthentication" *ngIf="bond?.contractBusinessUnit?.country?.code=='IT'">
        <num-input class="bon-input-size" [(ngModel)]="bond.grossNotaryAuthentication" name="grossNotaryAuthentication"></num-input>
      </form-row>
    </div>
    <div class="bon-card-inner bon-label-190">
      <checkbox-row label="Beneficiary confirmation" [longLabel]="true" [(ngModel)]="bond.confirmedByBeneficiary" name="confirmedByBeneficiary"></checkbox-row>
      <input-row label="{{'Beneficiary' | translate}} PEC" [(ngModel)]="bond.beneficiaryRaw.pec" name="beneficiaryCountry"
                 *ngIf="isItalianCountry(bond.beneficiaryRaw?.registrationAddress?.country)"></input-row>
      <date-row label="Confirmation date" [(ngModel)]="bond.beneficiaryConfirmationDate" name="beneficiaryConfirmationDate"></date-row>
      <textarea-row label="Comment" [(ngModel)]="bond.comment" rows="2" [required]="bond.status && bond.status.id === BondStatus.BOND"
                    [showErrors]="showErrors" name="comment" [minlength]="bond.status &&  bond.status.id === BondStatus.BOND ? 20 : 0"></textarea-row>
      <textarea-row labelKey="bond.common.clientComment" [(ngModel)]="bond.clientComment" rows="2" [presentationMode]="true"
                    name="clientComment"></textarea-row>
      <dict-row labelKey="bond.common.reinsuranceMode" [(ngModel)]="bond.reinsuranceMode" dictionary="ReinsuranceMode" required name="reinsuranceMode"  [hiddenIds]="hiddenReinsuranceModeIds" [showErrors]="showErrors"></dict-row>
      <dict-row *ngIf="allowStandardTreaty()" label="Renewable? (UW)" [(ngModel)]="bond.underwritingType" dictionary="UnderwritingType" name="underwritingType"
                [showErrors]="showErrors" [required]="credendo"></dict-row>
      <a-table *ngIf="allowFacultativeTreaty()" [items]="bond.facultativeTreaties" [editable]="true" [addButton]="true" [deleteButton]="true" [selection]="false" [formModel]="ngForm.form">
        <column labelKey="treaty.section.treaty" property="treaty" type="combo" [comboItems]="treaties" comboLabel="number" [required]="true"></column>
        <column labelKey="treaty.section.proportion" property="proportion" type="number" [required]="true"></column>
      </a-table>
      <different-price  [bond]="bond" [contractVersion]="contractVersion" [showErrors]="showErrors"></different-price>
      <checkbox-row labelKey="bond.common.noBrokerage" [(ngModel)]="bond.noBrokerage" name="noBrokerage"></checkbox-row>
      <checkbox-row labelKey="bond.common.esgBond" [(ngModel)]="bond.esgBond" name="esgBond"></checkbox-row>
    </div>
  </div><!-- **************************--><!-- * Section: Custom fields *--><!-- **************************-->
  <bon-section labelKey="bond.common.customFields" [hidden]="!showDetails" *ngIf="this.bond?.subtype && bondSubtypeHasCustomFields()">
    <div class="bon-card-group">
      <div class="bon-card-inner">
        <bond-custom-fields #bondCustomFieldsComponent [originalSubtype]="originalSubtypeCustomTemplate" [bond]="bond"
                            [bondForm]="ngForm.form" [showErrors]="showErrors"
                            (damageClauseChange)="recalculateBondValidTo()"
                            (timberPaymentDaysChange)="recalculateBondValidTo()"></bond-custom-fields>
      </div>
    </div>
  </bon-section><!-- ****************************--><!-- * Section: Subject of bond *-->
  <!-- ****************************-->
  <bon-section title="Autoprolongation" [hidden]="!showDetails">
    <div class="bon-card-group">
      <div class="bon-card-inner bon-label-190">
          <checkbox-row label="Autoprolongate" [disabled]="!canProlong()" [(ngModel)]="bond.autoprolongation.autoprolongate" name="autoprolongate"></checkbox-row>
      </div>
      <div class="bon-card-inner bon-label-150">
        <num-row label="Prolongate for" *ngIf="bond.autoprolongation.autoprolongate" [(ngModel)]="bond.autoprolongation.periodValue" name="autoprolongatePeriodValue" type="integer"></num-row>
        <dict-row label="Unit" *ngIf="bond.autoprolongation.autoprolongate" [(ngModel)]="bond.autoprolongation.periodUnit" dictionary="PeriodUnit" required
                  name="autoprolongationPeriodUnit" [showErrors]="showErrors"></dict-row>
        <num-row label="Days in advance" *ngIf="bond.autoprolongation.autoprolongate" [(ngModel)]="bond.autoprolongation.daysInAdvance" name="autoprolongateDaysInAdvance" type="integer"></num-row>
        <checkbox-row label="Skip request" *ngIf="bond.autoprolongation.autoprolongate" [(ngModel)]="bond.autoprolongation.skipRequest" name="autoprolongateSkipRequest"></checkbox-row>
      </div>
      <div class="bon-card-inner bon-label-190"></div>
    </div>
  </bon-section>

  <bon-section title="Performance data" [hidden]="!showDetails" *ngIf="isItalianPerformanceBond()">
    <div class="bon-card-group">
      <div class="bon-card-inner bon-label-190">
        <form-row label="Discount percentage">
          <num-input class="bon-input-size" [(ngModel)]="bond.discountPercentage" name="discountPercentage" type="decimal"></num-input>
        </form-row>
      </div>
      <div class="bon-card-inner bon-label-150"></div>
      <div class="bon-card-inner bon-label-190"></div>
    </div>
  </bon-section>

  <bon-section title="Subject of bond" [hidden]="!showDetails">
    <div class="bon-card-group">
      <div class="bon-card-inner bon-label-190">
        <date-row labelKey="bond.common.contractDate" [(ngModel)]="bond.contractDate" [disabled]="false" name="contractDate"></date-row>
        <input-row labelKey="bond.common.contractReferenceNumber" [(ngModel)]="bond.contractReferenceNumber" name="contractReferenceNumber"></input-row>
        <form-row labelKey="bond.common.contractAmount">
          <num-input class="bon-input-size" [(ngModel)]="bond.contractAmount" name="contractAmount"></num-input>
        </form-row>
      </div>
      <div class="bon-card-inner bon-label-150">
        <textarea-row label="Contractual obligations" [(ngModel)]="bond.contractualObligations" name="contractualObligations" rows="2"></textarea-row>
      </div>
      <div class="bon-card-inner bon-label-190"></div>
    </div>
  </bon-section>
  <bon-section title="{{ getExecutionAddressLabelKey() | translate }}" [hidden]="!showDetails" *ngIf="!appService.kuke">
    <div class="bon-card-group" ngModelGroup="executionAddress">
      <div class="bon-card-inner">
        <address-table #executionAddr [form]="ngForm.form" controlName="executionAddress" [oneTypeMode]="true" [editable]="true" [commonFieldsRequired]="false"
                       [addresses]="[bond.executionAddress]" [showErrors]="showErrors"></address-table>
      </div>
    </div>
  </bon-section><!-- *************************************--><!-- * Section: Cancellation and release *-->
  <!-- *************************************-->
  <bon-section title="Cancellation and release" [hidden]="!showDetails" *ngIf="bond.id">
    <div class="bon-card-group">
      <div class="bon-card-inner bon-label-190">
        <date-row label="Release date" [(ngModel)]="bond.releaseDate" name="releaseDate" [showErrors]="showErrors"></date-row>
        <date-row label="Cancellation date" [(ngModel)]="bond.cancellationDate" name="cancellationDate" [disabled]="isBooster()"></date-row>
      </div>
      <div class="bon-card-inner bon-label-300">
        <textarea-row label="Expiry cause and cancellation notice" [(ngModel)]="bond.expiryCauseAndCancellationNotice" name="expiryCauseAndCancellationNotice"  rows="2"></textarea-row>
        <checkbox-row labelKey="bond.common.premiumReturn" *ngIf="contractVersion && DictionaryUtils.inArr(contractVersion.invoicingSchema, BondInvoicingSchema.FLAT_FEES)"
                      [(ngModel)]="bond.premiumReturn" name="premiumReturn"></checkbox-row>
      </div>
      <div class="bon-card-inner bon-label-190"></div>
    </div>
  </bon-section>
  <div class="card-simple" [expandableSection]="false" [hidden]="!showDetails || !bond.id"
       style="padding-left: 10px;padding-right: 10px;">
    <div class="card-header"><span class="card-title">Documents</span></div>
    <div class="card-block">
      <document-list #documentLists [relatedToId]="bond.id" relatedToType="bond_version" [showCertificate]="true"></document-list>
    </div>
  </div>
</ng-container>
