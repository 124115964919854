/**
 * Created by siminski on 08.08.2016.
 */
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {
  AppConfigService,
  BusinessObjectService,
  ContactPersonService,
  DocumentService,
  RouterService,
} from '../../bonding_shared/services/index';
import {
  BusinessObjectDto,
  ContactPersonDto,
  ContactPersonGroupDto,
  DictionaryBaseDto,
  DocumentDto,
  DocumentEmailDto,
  EmailAddressDto,
} from '../../bonding_shared/model/dtos';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {DocumentGuiService} from './services/document-gui.service';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {BackendError} from '../../bonding_shared/model';
import {BusinessObjectType, KukeRepoOperation, Template} from '../../bonding_shared/model/dictionary-ids';
import {Button} from '../../bonding_shared/components/details-view/button';

@Component({
  selector: 'document-details',
  templateUrl: 'document-details.component.html',
})
export class DocumentDetailsComponent extends DetailsView implements OnInit {
  document: DocumentDto;
  serverErrors: BackendError;

  dev: boolean;

  canSave: boolean;
  docTypeId: number;
  operationId: number;

  sendEmailButton: Button;

  emailRecipientGroups: MetaContactPersonGroup[] = [];
  additionalEmailAddresses: {email: String}[] = [];

  targetCompanyId: number;
  additionalValuesBoTypeId: number;
  additionalValuesBoId: number;

  parentBusinessObjectTypeId: number;
  parentBusinessObjectId: number;
  parentBusinessObject: BusinessObjectDto;
  templateId: number;
  businessObjectId: number;
  contactBusinessObjectTypeId: number;
  contactBusinessObjectId: number;
  copyFor: number;

  constructor(
    private route: ActivatedRoute,
    private documentService: DocumentService,
    private businessObjectService: BusinessObjectService,
    private cd: ChangeDetectorRef,
    protected growlService: GrowlService,
    public guiService: DocumentGuiService,
    private contactPersonService: ContactPersonService,
    public appService: AppConfigService,
    public router: RouterService
  ) {
    super(growlService);
    this.sendEmailButton = new Button('document.details.sendEmail', this.onSendEmail.bind(this), false, true);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.saveButton.disabled = !this.canSave;
    this.dev = appService.envCode === 'DEV';
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const id = +params['id'];
    this.targetCompanyId = +params['targetCompanyId'];
    this.additionalValuesBoTypeId = +params['additionalValuesBoTypeId'];
    this.additionalValuesBoId = +params['additionalValuesBoId'];
    this.parentBusinessObjectTypeId = +params['parentBusinessObjectTypeId'];
    this.parentBusinessObjectId = +params['parentBusinessObjectId'];
    this.contactBusinessObjectTypeId = +params['contactBusinessObjectTypeId'];
    this.contactBusinessObjectId = +params['contactBusinessObjectId'];
    this.copyFor = +params['copyFor'];
    if (id) {
      this.readDocument(id);
    } else {
      this.operationId = +params['operationId'];
      this.templateId = +params['templateId'];
      this.businessObjectId = +params['businessObjectId'];
      this.docTypeId = +params['docTypeId'];
      this.initializeData(this.parentBusinessObjectTypeId, this.parentBusinessObjectId);
    }
  }

  initializeData(parentBusinessObjectTypeId?: number, parentBusinessObjectId?: number) {
    if (parentBusinessObjectId && parentBusinessObjectTypeId) {
      this.businessObjectService.getDto(parentBusinessObjectTypeId, parentBusinessObjectId).subscribe((bo) => {
        this.parentBusinessObject = bo;
      });
    }
    this.generateDocument(
      this.templateId,
      this.businessObjectId,
      this.additionalValuesBoTypeId,
      this.additionalValuesBoId,
      this.copyFor
    );
  }

  businessObject(): BusinessObjectDto {
    return this.parentBusinessObject || this.document?.businessObject;
  }

  initializeEmailRecipients(businessObject: BusinessObjectDto) {
    this.contactPersonService
      .getContactPersonGroupsForDocuments(businessObject.relatedToId, businessObject.relatedTo.id)
      .subscribe((groups) => {
        this.emailRecipientGroups = [];
        for (const group of groups) {
          group.persons = group.persons.filter((p) => p.email && p.email.trim().length > 0);
          if (group.persons.length > 0) {
            this.emailRecipientGroups.push(<MetaContactPersonGroup>{all: group, selected: []});
          }
        }
        this.updateSendEmailButton();
      });
  }

  getEmailFromContactPerson(boId: number, boTypeId: number) {
    if (this.kuke) {
      // add emails to document from service contacts
      this.contactPersonService.getContactPersonGroupsForDocuments(boId, boTypeId).subscribe((groups) => {
        this.emailRecipientGroups = [];
        for (const group of groups) {
          group.persons = group.persons.filter((p) => p.email && p.email.trim().length > 0);
          if (group.persons.length > 0) {
            this.emailRecipientGroups.push(<MetaContactPersonGroup>{all: group, selected: []});
          }
        }
        this.updateSendEmailButton();
      });
    }
  }

  getEmailAddresses(): string[] {
    const result = [];
    for (const metaGroup of this.emailRecipientGroups) {
      for (const contact of metaGroup.selected) {
        result.push(contact.person.email);
      }
    }
    for (const address of this.additionalEmailAddresses) {
      result.push(address.email);
    }
    return result;
  }

  updateSendEmailButton() {
    this.sendEmailButton.disabled = this.getEmailAddresses().length === 0;
  }

  onSave() {
    this.inProgress = true;
    this.documentService.save(this.document).subscribe(
      (document) => {
        this.document = document;
        this.growlService.notice('document.details.documentSave');
        this.inProgress = false;
      },
      (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      }
    );
  }

  onCancel(): void {
    super.onCancel(this.route);
  }

  onSendEmail() {
    this.inProgress = true;
    this.clearErrors();
    const documentEmail = <DocumentEmailDto>{};
    documentEmail.document = this.document;
    documentEmail.emailAddress = <EmailAddressDto>{};
    documentEmail.emailAddress.addressesTo = this.getEmailAddresses();
    if (documentEmail.document && this.targetCompanyId) {
      documentEmail.document.targetBusinessObject = <BusinessObjectDto>{
        relatedToId: this.targetCompanyId,
        relatedTo: <DictionaryBaseDto>{id: BusinessObjectType.COMPANY},
      };
    }
    this.documentService.sendEmail(documentEmail).subscribe(
      (document) => {
        this.document = document;
        this.growlService.notice('document.documentSending.generic');
        this.inProgress = false;
      },
      (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      }
    );
  }

  generateFilename(): string {
    return this.document.template.name + '.' + this.document.businessObject.relatedToId + '.pdf';
  }

  generatePdf() {
    this.documentService.generatePdf(
      this.document.template.id,
      this.document.businessObject.relatedToId,
      this.generateFilename(),
      false,
      null,
      this.additionalValuesBoTypeId,
      this.additionalValuesBoId
    );
  }

  downloadPdf(documentId: number) {
    return this.documentService.downloadPdf(documentId, this.generateFilename());
  }

  downloadPdfWithoutSending() {
    this.inProgress = true;
    this.documentService.saveWithoutSending(this.document).subscribe({
      next: (document) => {
        this.document = document;
        this.downloadPdf(document.id);
        this.inProgress = false;
      },
      error: (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      },
    });
  }

  downloadPdfDisabled() {
    return this.kuke ? !this.canSave : !this.canSave || !this.document?.id;
  }

  generateProposalPdf() {
    this.documentService.proposals(this.document).subscribe({
      next: (document) => {
        this.growlService.notice('document.documentSending.success');
      },
      error: (error) => {
        this.growlService.error('document.documentSending.failed');
        this.serverErrors = error;
      },
    });
  }

  generateSigningPdf() {
    this.documentService.signing(
      this.document.template.id,
      this.document.businessObject.relatedToId,
      this.docTypeId,
      this.generateFilename(),
      (error) => (this.serverErrors = error)
    );
  }

  generateWordingHtml() {
    this.documentService
      .wordings(
        this.document.template.id,
        this.document.businessObject.relatedToId,
        this.docTypeId,
        this.document.template.name + '.' + this.document.businessObject.relatedToId + '.html',
        (error) => (this.serverErrors = error)
      )
      .subscribe({
        next: (_) => {
          this.growlService.notice('document.documentSending.success');
        },
        error: (error) => {
          this.growlService.error('document.documentSending.failed');
          this.serverErrors = error;
        },
      });
  }

  onEditorModeChange(mode: string) {
    this.canSave = mode === 'wysiwyg';
    this.saveButton.disabled = !this.canSave;
    this.cd.detectChanges();
  }

  private generateDocument(
    templateId: number,
    businessObjectId: number,
    additionalValuesBoTypeId: number,
    additionalValuesBoId: number,
    copyFor?: number
  ) {
    this.documentService
      .generate(templateId, businessObjectId, additionalValuesBoTypeId, additionalValuesBoId, copyFor)
      .subscribe({
        next: (document) => {
          this.document = document;
          // this.initializeEmailRecipients(this.document.businessObject);
          if (this.contactBusinessObjectTypeId && this.contactBusinessObjectId) {
            this.initializeEmailRecipients(<BusinessObjectDto>{
              relatedToId: this.contactBusinessObjectId,
              relatedTo: {id: this.contactBusinessObjectTypeId},
            });
          } else {
            this.initializeEmailRecipients(this.businessObject());
          }
        },
        error: (error) => (this.serverErrors = error),
      });
  }

  private readDocument(id: number) {
    this.documentService.getDocument(id).subscribe({
      next: (doc) => {
        if (this.parentBusinessObjectId && this.parentBusinessObjectTypeId) {
          this.businessObjectService
            .getDto(this.parentBusinessObjectTypeId, this.parentBusinessObjectId)
            .subscribe((bo) => {
              this.parentBusinessObject = bo;
              this.setDocument(doc);
            });
        } else {
          this.setDocument(doc);
        }
      },
      error: (error) => (this.serverErrors = error),
    });
  }

  private setDocument(doc: DocumentDto) {
    this.document = doc;
    if (this.contactBusinessObjectId && this.contactBusinessObjectTypeId) {
      this.initializeEmailRecipients(<BusinessObjectDto>{
        relatedToId: this.contactBusinessObjectId,
        relatedTo: {id: this.contactBusinessObjectTypeId},
      });
    } else {
      this.initializeEmailRecipients(this.document.businessObject);
    }
  }

  isProposals(): boolean {
    return this.operationId === KukeRepoOperation.PROPOSALS;
  }

  isWordings(): boolean {
    return this.kuke && this.operationId === KukeRepoOperation.WORDINGS;
  }

  isSigning(): boolean {
    return this.operationId === KukeRepoOperation.SIGNIGS;
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get credendo(): boolean {
    return this.appService.credendo;
  }

  showGenerateProposalPdf(): boolean {
    return [
      Template.KPUE_APPLICATION_QUARANTES,
      Template.APPLICATION_FOR_KRU,
      Template.APPLICATION_TO_THE_MANAGMENT_BOARD,
      Template.APPLICATION_TO_THE_MANAGMENT_BOARD_COLECTION,
      Template.APPLICATION_TO_THE_MANAGMENT_BOARD_CLAIM,
      Template.APPLICATION_TO_THE_KRU_CLAIM,
      Template.APPLICATION_TO_THE_KRU_COLECTION,
    ].includes(this.document?.template?.id);
  }

  isMarkAsSent() {
    return this.credendo && !!this.document && !this.document.lastSentDate;
  }

  markAsSent() {
    this.inProgress = true;
    this.documentService.markAsSent(this.document.id).subscribe(
      (document) => {
        this.document = document;
        this.growlService.notice('document.details.documentSave');
        this.inProgress = false;
        this.router.toDocumentDetailsData(
          this.document?.id,
          this.parentBusinessObjectTypeId,
          this.parentBusinessObjectId,
          this.businessObjectId,
          this.contactBusinessObjectTypeId,
          this.contactBusinessObjectId
        );
      },
      (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      }
    );
  }
}

class MetaContactPerson {
  person: ContactPersonDto;
}

class MetaContactPersonGroup {
  all: ContactPersonGroupDto;
  selected: MetaContactPerson[];
}
