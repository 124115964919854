import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {RouterService} from '../../bonding_shared/services/router-service';
import {ActivatedRoute, Params} from '@angular/router';
import {LimitGuiService} from './shared/services/limit-gui.service';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  CompanyBaseDto,
  DictionaryBaseDto,
  LimitRequestCriteriaDto,
  LimitRequestDto,
} from '../../bonding_shared/model/dtos';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {
  BusinessObjectType,
  Country,
  GeneratedDocumentType,
  LimitDecisionStatus,
} from '../../bonding_shared/model/dictionary-ids';
import {CompanyService, SearchDataProvider, TemplateService} from '../../bonding_shared';
import {LimitListComponent} from './shared/components/limit-list.component';

@Component({
  selector: 'limit-mailing-list',
  templateUrl: './limit-mailing-list.component.pug',
})
export class LimitMailingListComponent extends DetailsView implements OnInit {
  @ViewChild(LimitListComponent) limitList: LimitListComponent;

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    limitGuiService: LimitGuiService,
    private companyService: CompanyService,
    private router: RouterService,
    protected growlService: GrowlService,
    private templateService: TemplateService
  ) {
    super(growlService);
    this.dataProvider = limitGuiService.createDataProvider();
    this.cancelButton.hidden = false;
  }

  company: CompanyBaseDto;
  dataProvider: SearchDataProvider<LimitRequestCriteriaDto, LimitRequestDto>;

  selectedLimitRequest: LimitRequestDto;

  ngOnInit() {
    this.form = this.ngForm.form;
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const companyId = +params['companyId'];
    this.companyService.getCompanyBaseDto(companyId).subscribe((result) => {
      this.company = result;
      this.refreshList();
    });
  }

  refreshList() {
    this.dataProvider.searchCriteria.criteria.buyerCompanyId = this.company.id;
    this.dataProvider.searchCriteria.criteria.contactAgreement = true;
    if (this.limitList && this.limitList.limitTable) {
      this.limitList.limitTable.search();
    }
  }

  onLimitRequestSelected(event: LimitRequestDto) {
    this.selectedLimitRequest = event;
  }

  toDocumentView() {
    const legalForm = this.selectedLimitRequest.limit.buyer.company.legalForm;
    const address = this.selectedLimitRequest.limit.buyer.company.address;
    const country = address !== null && address ? address.country : undefined;

    if ((legalForm && legalForm.id) || (this.company && country && country.id !== Country.PL)) {
      const parentBusinessObjectTypeId = BusinessObjectType.COMPANY;
      const parentBusinessObjectId = this.selectedLimitRequest.limit.buyer.company.id;
      const businessObjectId = this.selectedLimitRequest.limit.insured.id;
      const contactBusinessObjectTypeId = BusinessObjectType.COMPANY;
      const contactBusinessObjectId = parentBusinessObjectId;
      const countryId = this.selectedLimitRequest.limit.buyer.company.address.country.id;
      this.templateService
        .findByType(
          GeneratedDocumentType.DF_MONITORING,
          BusinessObjectType.COMPANY,
          undefined,
          [legalForm],
          null,
          null,
          null,
          null,
          null,
          countryId
        )
        .subscribe({
          next: (templates) => {
            switch (templates.length) {
              case 0:
                this.growlService.error('document.templateNotFound');
                break;
              case 1:
                // todo: refactor after angular migration - pass contactBusinessObject: BusinessObjectDto (supported from version 7.2)
                this.router.toDocumentDetailsNewWithContacts(
                  parentBusinessObjectId,
                  templates[0].id,
                  parentBusinessObjectTypeId,
                  parentBusinessObjectId,
                  businessObjectId,
                  contactBusinessObjectTypeId,
                  contactBusinessObjectId
                );
                break;
              default:
                this.growlService.error('document.templateNotUnique');
            }
          },
          error: (error) => this.handleServerError(error),
        });
    } else {
      this.growlService.error('limitMailingList.companyLegalFormMissing');
    }
  }

  onCancel() {
    this.limitList.limitTable.selectedItem = undefined;
  }
}
