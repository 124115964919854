import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm, Validators} from '@angular/forms';
import {
  AmountDto,
  AppConfigService,
  CellChangeEvent,
  ComboItem,
  CompanyDto,
  CompanyRatingDto,
  CompanyRatingService,
  CompanyRatingVersionDto,
  CompanyResponsiblePersonDto,
  CompanyService,
  DictionaryBaseDto,
  DictionaryBaseService,
  GrowlService,
  LoggedUserService,
  ManualRatingCalculationResultDto,
  ManualRatingPartDto,
  RouterService,
  StringUtils,
  TemplateService,
  TemplateSimpleDto,
  UserIdDto,
  UserService,
  UserWithProfileDto,
} from '../../bonding_shared';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  BusinessObjectType,
  CompanyElementaryRight,
  CompanyResponsiblePersonRole,
  CompanyType,
  Currency,
  DocumentType,
  KukeRepoOperation,
  ProfileCategory,
  Rating,
  RatingCategory,
  RatingDepartment,
  RatingLimitation,
  RatingSource,
  RatingStatus,
  RatingType,
  RepoDocumentElementaryRight,
  ScreeningCategory,
  ScreeningFinding,
  ScreeningResult,
  UserRole,
} from '../../bonding_shared/model/dictionary-ids';
import * as _ from 'lodash';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'company-rating',
  templateUrl: './company-rating.component.pug',
})
export class CompanyRatingComponent extends DetailsView implements OnInit {
  @ViewChild('ngForm', {static: true}) public ngForm: NgForm;
  readonly RatingStatus = RatingStatus;
  public ratingVersion: CompanyRatingVersionDto;
  public ratingVersions: CompanyRatingVersionDto[];
  public company: CompanyDto;
  public ratingType: DictionaryBaseDto;
  public ratingCategoryId: number;
  public companyId: number;
  public ratingStatuses: DictionaryBaseDto[] = [];
  public acceptors: UserIdDto[] = [];
  public weightItems: ComboItem[];
  public pointItems: ComboItem[];
  public templates: TemplateSimpleDto[] = [];
  public ratingReadOnlyMode = false;
  public checkedCompetency = true;
  private readonly newVersionButtonLabelKey = 'company.rating.newRating';
  readonly WAIT_FOR_APPROVAL = [
    RatingStatus.WAITING_FOR_APPROVAL,
    RatingStatus.WAITING_FOR_1ST_APPROVAL,
    RatingStatus.WAITING_FOR_2ND_APPROVAL,
    RatingStatus.WAITING_FOR_3RD_APPROVAL,
  ];
  readonly ACCEPTABLE_TPES = [RatingType.ST_INSURANCE, RatingType.MLT_INSURANCE, RatingType.CC_CATEGORY];

  constructor(
    private route: ActivatedRoute,
    private ratingService: CompanyRatingService,
    public appService: AppConfigService,
    private dictionaryBaseService: DictionaryBaseService,
    private loggedUserService: LoggedUserService,
    private userService: UserService,
    private companyService: CompanyService,
    public router: RouterService,
    private templateService: TemplateService,
    protected translateService: TranslateService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.newVersionButton.name = this.newVersionButtonLabelKey;
    this.weightItems = CompanyRatingComponent.createComboItems(0, 1, 0.05, 2);
    this.pointItems = CompanyRatingComponent.createComboItems(1, 10, 1, 0);
  }

  readonly BusinessObjectType = BusinessObjectType;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;

  private static createComboItems(
    startValue: number,
    maxValue: number,
    step: number,
    fractionDigits: number
  ): ComboItem[] {
    return _(_.range(startValue, maxValue + step, step))
      .map((nextValue) => nextValue.toFixed(fractionDigits))
      .map((nextValue) => new ComboItem(nextValue.toString(), nextValue.toString()))
      .value();
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }

  public get mehib(): boolean {
    return this.appService.mehib;
  }

  public get ecg(): boolean {
    return this.appService.ecg;
  }

  public get credendo(): boolean {
    return this.appService.credendo;
  }

  public get insuranceSTMLTRating(): boolean {
    return this.ratingType?.id === RatingType.ST_INSURANCE || this.ratingType?.id === RatingType.MLT_INSURANCE;
  }

  public get insuranceSTRatingInKuke(): boolean {
    return this.kuke && this.ratingType?.id === RatingType.ST_INSURANCE;
  }

  public get insuranceMLTRating(): boolean {
    return this.ratingType && this.ratingType.id === RatingType.MLT_INSURANCE;
  }

  public get bankRating(): boolean {
    return this.ratingType?.id === RatingType.BANK;
  }

  public get gRCRating(): boolean {
    return this.ratingType && this.ratingType.id === RatingType.GRC;
  }

  public get kycRating(): boolean {
    return this.ratingType && this.ratingType.id === RatingType.KYC;
  }

  public get environmentalRating(): boolean {
    return this.ratingType && this.ratingType.id === RatingType.ENVIRONMENTAL;
  }

  public get sandpRating(): boolean {
    return this.ratingType?.id === RatingType.S_AND_P;
  }

  public get fitchRating(): boolean {
    return this.ratingType?.id === RatingType.FITCH;
  }

  public get moodysRating(): boolean {
    return this.ratingType?.id === RatingType.MOODY_S;
  }

  public get ccCategoryRating(): boolean {
    return this.ratingType?.id === RatingType.CC_CATEGORY;
  }

  public get showSingleLimit(): boolean {
    return this.ecg || (this.kuke && this.insuranceSTMLTRating);
  }

  public get bondingEXPRating(): boolean {
    return this.ratingType && this.ratingType.id === RatingType.GSP;
  }

  public get bondingRating(): boolean {
    return this.ratingType && this.ratingType.id === RatingType.BONDING;
  }

  public get ratingInactive(): boolean {
    return this.ratingVersion?.status && this.ratingVersion.status.id === RatingStatus.INACTIVE;
  }

  public get ratingActive(): boolean {
    return this.ratingVersion?.status && this.ratingVersion.status.id === RatingStatus.ACTIVE;
  }

  public get ratingInAcceptance(): boolean {
    return (
      this.ratingVersion?.status?.id === RatingStatus.IN_ACCEPTANCE ||
      (this.appService.mehib && this.WAIT_FOR_APPROVAL.includes(this.ratingVersion?.status?.id))
    );
  }

  public get ratingHasId(): boolean {
    return this.ratingVersion && this.ratingVersion.id && this.ratingVersion.id > 0;
  }

  public get inCreateMode(): boolean {
    return !this.ratingHasId;
  }

  public get companyIsGroupHead(): boolean {
    return this.company && this.company.groupHead;
  }

  public get farmerCompany(): boolean {
    return this.company?.companyType && this.company.companyType.id === CompanyType.FARM;
  }

  public get responsiblePerson(): string {
    if (this.company?.responsiblePersons) {
      return _(this.company.responsiblePersons)
        .filter((responsiblePerson: CompanyResponsiblePersonDto) => {
          if (
            this.ratingVersion?.ratingType?.id === RatingType.BONDING ||
            this.ratingVersion?.ratingType?.id === RatingType.GSP
          ) {
            return responsiblePerson.role.id === CompanyResponsiblePersonRole.BONDING_MANAGER;
          } else {
            return (
              responsiblePerson.role.id ===
              (this.kuke ? CompanyResponsiblePersonRole.POLICY_MANAGER : CompanyResponsiblePersonRole.UNDERWRITER)
            );
          }
        })
        .map((responsiblePerson: CompanyResponsiblePersonDto) => responsiblePerson.user.fullName)
        .head();
    }
    return null;
  }

  public get documentTypes(): RatingDocument[] {
    if (this.ratingType && this.ratingType.id) {
      return _(RATING_DOCUMENT_TYPES)
        .filter((ratingDocument: RatingDocument) =>
          _.some(ratingDocument.forRatingTypes, (ratingType: RatingType) => ratingType === this.ratingType.id)
        )
        .value();
    }
    return null;
  }

  public ngOnInit(): void {
    this.form = this.ngForm.form;
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  public initializeView(params: Params): void {
    this.objectNotFound = false;
    this.serverErrors = undefined;
    this.companyId = +params['id'];
    this.ratingCategoryId = +params['ratingCategoryId'];
    this.initRatingType(+params['ratingType']);
  }

  private initData() {
    this.ratingService.getRatingStatuses().subscribe({
      next: (statuses) => (this.ratingStatuses = statuses),
      error: (error) => this.handleServerError(error),
    });

    this.getCompanyData(this.companyId);
    this.route.queryParams.subscribe((queryParam) => {
      if (queryParam['calculate'] === 'true') {
        this.calculateRating(this.companyId);
      } else {
        this.getRating(this.companyId, true);
      }
    });

    if (this.kuke) {
      this.dictionaryBaseService.getDictionaryEntry('Currency', Currency.PLN).subscribe((c) => {
        if (this.ratingVersion) {
          this.ratingVersion.currency = c;
        }
      });
      this.userService.getUsersForProfileCategory(ProfileCategory.KUKE_LIMITS, null, false).subscribe({
        next: (acceptors: UserWithProfileDto[]) => (this.acceptors = acceptors),
        error: (error) => this.handleServerError(error),
      });
      if (!this.ratingHasId) {
        this.ratingReadOnlyMode = true;
      }
    }

    if (this.ecg) {
      this.userService.getUsersForProfileCategory(ProfileCategory.ECG_LIMITS, null, false).subscribe({
        next: (acceptors: UserWithProfileDto[]) => (this.acceptors = acceptors),
        error: (error) => this.handleServerError(error),
      });
    }

    this.loadTemplates();

    console.log('6 RATING TYPE ID = ' + this.ratingType?.id);
  }

  private getCompanyData(companyId: number) {
    this.companyService.getCompany(companyId).subscribe({
      next: (company: CompanyDto) => (this.company = company),
      error: (error) => this.handleServerError(error),
    });
  }

  initRatingType(ratingTypeId: number): void {
    if (this.ratingType?.id) {
      this.dictionaryBaseService
        .getDictionaryEntry('RatingType', this.ratingType.id, undefined, 'RatingScale')
        .subscribe((ratingType) => {
          this.ratingType = ratingType;
          this.initData();
        });
    } else {
      console.log('111 Category id = ', this.ratingCategoryId);
      this.ratingService.getRatingType(this.ratingCategoryId).subscribe((t) => {
        this.ratingType = t;
        // for add rating type and show responsible person for this type
        this.showInitialVersionForKuke();
        this.initData();
      });
    }
  }

  public onSave(): void {
    this.serverErrors = undefined;

    if (!this.ratingVersion.rating) {
      this.recalculateManualRating(true);
    }

    if (!this.ngForm.form.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFields(this.ngForm.form);
      return;
    }
    this.inProgress = true;
    this.ratingVersion.ratingType = this.ratingType;
    this.ratingVersion.source = <DictionaryBaseDto>{id: RatingSource.UW};
    if (this.ratingHasId && (this.ratingInactive || this.ratingVersion.acceptor)) {
      this.update();
    } else {
      this.create();
    }
  }

  public onCreateNewVersion(): void {
    this.newVersionButton.disabled = true;
    if (!this.kuke || this.ratingHasId) {
      this.ratingService
        .newRatingVersion(this.ratingVersion.parent.company.id, this.ratingType.id)
        .subscribe((rating) => {
          this.setRatingVersion(rating);
          this.handleButtons();
        });
    }

    this.showInitialVersionForKuke();
    this.ratingReadOnlyMode = false;
  }

  showInitialVersionForKuke() {
    if (this.kuke && !this.ratingHasId) {
      this.ratingService
        .initialRatingVersion(this.companyId, this.ratingCategoryId, this.ratingType.id)
        .subscribe((r) => {
          this.ratingVersion = r;
          this.handleButtons();
        });
    }
  }

  public onCancel(): void {
    super.onCancel();
    this.getRating(this.ratingVersion.parent.company.id, false);
    if (!this.ratingHasId) {
      this.ratingReadOnlyMode = true;
    }
    this.newVersionButton.disabled = false;
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  public recalculateRatingPart(ratingPartChangeEvent: CellChangeEvent<ManualRatingPartDto>): void {
    const ratingPart = ratingPartChangeEvent.item;
    ratingPart.value = ratingPart.points * ratingPart.weight;
    if (
      _(this.ratingVersion.ratingParts)
        .map((manualRatingPart: ManualRatingPartDto) => Number(manualRatingPart.weight))
        .sum() > 1
    ) {
      this.addFrontendWarning('company.rating.ratingPartsWeightSumShouldNotExceedOneMessageKey');
    } else {
      this.clearErrors();
    }
  }

  public onNewDocumentSelect(template: TemplateSimpleDto): void {
    this.router.toDocumentDetailsNewOperations(
      KukeRepoOperation.PROPOSALS,
      template.id,
      this.company.id,
      template.type.id
    );
  }

  public calculateRating(companyId: number): void {
    if (this.kuke) {
      this.calculateRatingForType(companyId, RatingType.ST_INSURANCE);
    } else {
      this.calculateRatingForType(companyId, this.ratingType.id);
    }
  }

  public ratingChanged(rating: DictionaryBaseDto): void {
    if (rating) {
      if (rating.id === Rating.KUKE_UNQUALIFIED) {
        this.ratingVersion.liability = <AmountDto>{value: 0, valueInRefCurrency: 0};
      }
      if (rating.id === Rating.X) {
        this.ratingVersion.liability = <AmountDto>{value: 0, valueInRefCurrency: 0};
        this.ratingVersion.singleBondLimit = <AmountDto>{value: 0, valueInRefCurrency: 0};
        this.ratingVersion.bondingFacilityLimit = <AmountDto>{value: 0, valueInRefCurrency: 0};
        this.ratingVersion.singleLimit = <AmountDto>{value: 0, valueInRefCurrency: 0};
        this.ratingVersion.validTo = undefined;
        this.ratingVersion.nextReviewDate = undefined;
      }
    }
  }

  isUnqualified(): boolean {
    return this.ratingVersion?.rating?.id === Rating.KUKE_UNQUALIFIED;
  }

  public onSelectVersion(selectedCompanyRatingVersion: CompanyRatingVersionDto): void {
    this.ratingVersion = selectedCompanyRatingVersion;
    this.handleButtons();
  }

  public onLiabilityChange(): void {
    this.form.get('liability').updateValueAndValidity();
    this.form.get('singleLimit').updateValueAndValidity();
  }

  public onRatingStatusChange(status: DictionaryBaseDto): void {
    this.ratingVersion.status = status;
    this.update();
  }

  public editionDisabled(): boolean {
    const v =
      (this.appService.credendo && this.ratingCategoryId === RatingCategory.AUTO) ||
      (this.kuke && this.ratingReadOnlyMode) ||
      !this.hasEditRight() ||
      !this.hasCreateRight() ||
      (this.ratingHasId && this.ratingVersion.status && !this.ratingInactive) ||
      !this.ratingVersion.last;
    return v;
  }

  acceptorDisabled(): boolean {
    const value =
      (this.mehib && !!this.ratingVersion.acceptedBy) ||
      ((this.appService.credendo || this.kuke || this.ecg) &&
        (this.editionDisabled() || (!this.ratingInAcceptance && this.ratingVersion.status && !this.canSetAcceptor())));
    return value;
  }

  acceptorSelect(user: UserIdDto) {
    this.saveButton.disabled = false;
  }

  public documentsButtonDisabled(): boolean {
    return this.ratingVersions.length === 1 && !this.ratingVersion.id;
  }

  public hasUWMasterOrUWDirectorRole(): boolean {
    return (
      this.loggedUserService.hasLoggedUserRole(UserRole.UW_MASTER) ||
      this.loggedUserService.hasLoggedUserRole(UserRole.UW_DIRECTOR)
    );
  }

  public disablePndLntEdition(): boolean {
    if (
      this.appService.kuke &&
      this.ratingVersion?.id &&
      this.ratingVersion?.rating &&
      this.ratingVersion?.source?.id === RatingSource.MANUAL
    ) {
      return !(
        this.ratingVersion.rating.id === Rating.KUKE_8 ||
        this.ratingVersion.rating.id === Rating.KUKE_NC ||
        this.ratingVersion.rating.id === Rating.KUKE_NR ||
        ((this.loggedUserService.hasLoggedUserRole(UserRole.UW_MASTER) ||
          this.loggedUserService.hasLoggedUserRole(UserRole.DUW_DIRECTOR)) &&
          (this.ratingVersion.rating.id === Rating.KUKE_9 || this.ratingVersion.rating.id === Rating.KUKE_10))
      );
    }
    return false;
  }

  public acceptingBodyEditionDisabled(): boolean {
    if (this.kuke) {
      return (!this.ratingInAcceptance && this.ratingEditionDisabled()) || !this.hasUWMasterOrUWDirectorRole();
    } else {
      return this.ratingEditionDisabled() || !!this.ratingVersion.acceptedBy;
    }
  }

  public financialInformationDisabled(): boolean {
    return this.editionDisabled() && !!this.ratingVersion.acceptedBy;
  }

  public acceptingBodyRequired(): boolean {
    return (
      this.kuke &&
      (this.ratingType?.id === RatingType.ST_INSURANCE ||
        this.ratingType?.id === RatingType.MLT_INSURANCE ||
        this.ratingType?.id === RatingType.CC_CATEGORY)
    );
  }

  public grcPartsDisabled(): boolean {
    return (
      this.editionDisabled() ||
      (this.ratingVersion?.grcRatingPart?.screeningResult &&
        this.ratingVersion.grcRatingPart.screeningResult.id === ScreeningResult.NO_MATCH)
    );
  }

  public showStatusButton(): boolean {
    return (this.kuke || this.mehib || this.ecg) && this.ratingHasId && this.ratingInAcceptance;
  }

  public canAcceptRating(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_RATING_ACCEPTANCE);
  }

  public hasCompetency(): boolean {
    return this.ratingVersion.hasCompetency;
  }

  showSingleBondLimit(): boolean {
    return (
      (this.appService.credendo && !(this.ratingType?.id === RatingType.ST_INSURANCE)) ||
      (this.mehib &&
        !this.bankRating &&
        !this.insuranceSTMLTRating &&
        !(this.sandpRating || this.fitchRating || this.moodysRating || this.environmentalRating))
    );
  }

  public showLiability(): boolean {
    return this.kuke || this.ecg || (this.mehib && (this.insuranceSTMLTRating || this.bankRating));
  }

  public liabilityDisabled(): boolean {
    return this.editionDisabled() || this.isUnqualified() || this.disablePndLntEdition();
  }

  public showBondingFacilityLimit(): boolean {
    return (
      (this.appService.credendo && !this.insuranceSTMLTRating) ||
      (this.mehib &&
        !(
          this.sandpRating ||
          this.fitchRating ||
          this.moodysRating ||
          this.environmentalRating ||
          this.insuranceSTMLTRating ||
          this.bankRating
        ))
    );
  }

  public showGroupLiability(): boolean {
    return (
      (this.kuke || this.ecg) &&
      [RatingType.BONDING, RatingType.GSP, RatingType.ST_INSURANCE].includes(this.ratingType.id) &&
      this.companyIsGroupHead
    );
  }

  public ratingEditionDisabled(): boolean {
    return this.editionDisabled() || (this.kuke && this.insuranceMLTRating);
  }

  public showManualCalculation(): boolean {
    return (
      (this.kuke || this.ecg) &&
      (this.ratingType?.id === RatingType.ST_INSURANCE || this.bondingRating) &&
      this.ratingPartsExists()
    );
  }

  public showCalculateRating(): boolean {
    return ((this.showSingleLimit && this.ratingActive) || this.ecg) && !this.farmerCompany;
  }

  public canCalculateRating(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_RATING_CALCULATE);
  }

  public canSendProposal(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_RATING_MANAGEMENT_PROPOSAL_KRU_KPUE);
  }

  public canSetAcceptor(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_RATING_ACCEPTOR);
  }

  public ratingCorrectionChanged(): void {
    if (!this.ratingVersion.manualRatingModifier.ratingCorrection) {
      this.ratingVersion.manualRatingModifier.ratingCorrectionChange = null;
      this.ratingVersion.manualRatingModifier.ratingCorrectionComment = null;
    }
    this.setAndUpdateValidator(
      'ratingCorrectionComment',
      Validators.compose([Validators.required, Validators.minLength(3)])
    );
  }

  public ratingLimitationChanged(): void {
    const ratingLimitation = this.ratingVersion.manualRatingModifier.ratingLimitation;
    if (!ratingLimitation) {
      this.ratingVersion.manualRatingModifier.ratingLimitationComment = null;
    } else if (ratingLimitation.id === RatingLimitation.KUKE_NR) {
      this.ratingVersion.rating = <DictionaryBaseDto>{id: Rating.KUKE_NR};
      this.ratingVersion.liability.value = 0;
      this.ratingVersion.singleLimit.value = 0;
    } else if (ratingLimitation.id === RatingLimitation.KUKE_NC) {
      this.ratingVersion.rating = <DictionaryBaseDto>{id: Rating.KUKE_NC};
      this.ratingVersion.liability.value = 0;
      this.ratingVersion.singleLimit.value = 0;
    }
    this.setAndUpdateValidator(
      'ratingLimitationComment',
      Validators.compose([Validators.required, Validators.minLength(3)])
    );
  }

  public recalculateManualRating(onSave = false): void {
    this.ratingService.calculateManualRating(this.ratingVersion).subscribe({
      next: (ratingCalculation: ManualRatingCalculationResultDto) =>
        this.onNewManualRationRecalculation(ratingCalculation, onSave),
      error: (error) => this.handleServerError(error),
    });
  }

  public ratingVersionsExists(): boolean {
    return this.ratingVersions && this.ratingVersions.length >= 1 && !!this.ratingVersions[0].id;
  }

  private onNewManualRationRecalculation(ratingCalculation: ManualRatingCalculationResultDto, onSave: boolean): void {
    this.ratingVersion.rating = ratingCalculation.rating;
    this.ratingVersion.ratingAssesed = ratingCalculation.ratingAssesed;
    this.ratingVersion.ratingCorrected = ratingCalculation.ratingCorrected;
    if (
      !onSave &&
      DictionaryUtils.in(
        this.ratingVersion.rating,
        Rating.KUKE_NC,
        Rating.KUKE_NR,
        Rating.KUKE_8,
        Rating.KUKE_9,
        Rating.KUKE_10
      )
    ) {
      this.ratingVersion.liability.value = 0;
      this.ratingVersion.singleLimit.value = 0;
    }
  }

  private ratingPartsExists(): boolean {
    return this.ratingVersion && this.ratingVersion.ratingParts && this.ratingVersion.ratingParts.length > 0;
  }

  // TODO: check if there is configuration of document types for offer in DB - templates must be defined
  private loadTemplates(): void {
    if (this.ratingType && this.ratingType.id) {
      const dictionarySelectors: DictionaryBaseDto[] = [];
      dictionarySelectors.push(this.ratingType);
      this.loadTemplatesByType(DocumentType.WZ, BusinessObjectType.COMPANY, dictionarySelectors);
      this.loadTemplatesByType(DocumentType.WZR, BusinessObjectType.COMPANY, dictionarySelectors);
      this.loadTemplatesByType(DocumentType.WZKP, BusinessObjectType.COMPANY, dictionarySelectors);
      this.loadTemplatesByType(DocumentType.APP_RISK_ASSESSMENT, BusinessObjectType.COMPANY, dictionarySelectors);
    }
  }

  private loadTemplatesByType(
    type: DocumentType,
    boType: BusinessObjectType,
    dictionarySelectors: DictionaryBaseDto[]
  ): void {
    this.templateService.findByType(type, boType, null, dictionarySelectors).subscribe((result) => {
      if (result !== undefined && result !== null) {
        result.forEach((r) => this.templates.push(r));
        // information about templates are return singly (not in one call). Sort the result so that they are always in the same order
        this.templates.sort((a, b) => a.id - b.id);
      }
    });
  }

  private hasCreateRight(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_RATING_CREATE);
  }

  private hasEditRight(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_RATING_EDIT);
  }

  private calculateRatingForType(companyId: number, ratingTypeId: number): void {
    this.ratingService.calculate(companyId, ratingTypeId).subscribe(
      () => this.afterCompanyRatingSaved(companyId),
      (error) => this.handleServerError(error)
    );
  }

  private afterCompanyRatingSaved(companyId: number): void {
    this.afterObjectSaved();
    this.getRating(companyId, true);
    // for first rating we add ResponsiblePerson for kuke
    if (this.kuke) {
      this.getCompanyData(companyId);
    }
  }

  private getRating(companyId: number, initial: boolean): void {
    this.ratingService.getCompanyRating(companyId, this.ratingCategoryId, this.ratingType?.id).subscribe({
      next: (rating) => {
        this.setRating(rating, initial);
      },
      error: (error) => this.handleServerError(error),
    });
  }

  private setRating(rating: CompanyRatingDto, initial: boolean): void {
    if (initial) {
      this.ratingVersion = rating.versions.reduce(
        (max, v) => (v.versionNumber > max.versionNumber ? v : max),
        rating.versions[0]
      );
    }
    if (this.inCreateMode) {
      this.dictionaryBaseService
        .getDictionaryEntry('RatingStatus', this.kuke ? RatingStatus.INACTIVE : RatingStatus.ACTIVE)
        .subscribe((status: DictionaryBaseDto) => {
          if (!this.ratingVersion.status) {
            this.ratingVersion.status = status;
          }
        });
    }
    this.ratingVersions = rating.versions;
    this.handleButtons();
  }

  private setRatingVersion(rating: CompanyRatingVersionDto): void {
    this.ratingVersion = rating;
  }

  protected handleButtons(): void {
    this.saveButton.disabled =
      this.editionDisabled() ||
      (this.kuke && this.ratingVersion.status != null && !this.ratingInAcceptance) ||
      !this.hasEditRight();
    this.newVersionButton.disabled =
      (this.appService.credendo && this.ratingCategoryId === RatingCategory.AUTO) ||
      !this.hasCreateRight() ||
      (this.appService.kuke && (!this.hasEditRight() || !this.editionDisabled()));
    this.cancelButton.disabled = this.editionDisabled() || !this.hasEditRight() || !this.ratingVersion.last;
    this.hideButtons(false);
  }

  private create(): void {
    this.ratingService.createRatingVersion(this.ratingVersion).subscribe({
      next: (version: CompanyRatingVersionDto) => {
        this.afterCompanyRatingSaved(version.parent.company.id);
        if (version.warnings) {
          this.handleServerError(version.warnings, true);
        }
      },
      error: (error) => {
        this.checkedCompetency = this.ratingVersion.hasCompetency;
        this.handleServerError(error);
      },
    });
  }

  private update(): void {
    this.ratingService.updateRatingVersion(this.ratingVersion).subscribe({
      next: (v) => this.afterCompanyRatingSaved(this.ratingVersion.parent.company.id),
      error: (error) => {
        this.handleServerError(error);
        this.getRating(this.ratingVersion.parent.company.id, false);
      },
    });
  }

  screeningResultChanged(screeningResult: DictionaryBaseDto) {
    if (screeningResult.id === ScreeningResult.NO_MATCH) {
      this.ratingVersion.grcRatingPart.screeningFindings = [<DictionaryBaseDto>{id: ScreeningFinding.NONE}];
      this.ratingVersion.grcRatingPart.screeningFindingDetails = [];
      this.ratingVersion.grcRatingPart.screeningCategories = [<DictionaryBaseDto>{id: ScreeningCategory.NONE}];
    } else {
      this.ratingVersion.grcRatingPart.screeningFindings = [];
      this.ratingVersion.grcRatingPart.screeningFindingDetails = [];
      this.ratingVersion.grcRatingPart.screeningCategories = [];
    }
  }

  firstAcceptorDisabled(): boolean {
    return !this.ratingInAcceptance;
  }

  secondAcceptorDisabled(): boolean {
    return !this.ratingInAcceptance || !this.ratingVersion.acceptedBy || !!this.ratingVersion.secondAcceptedBy;
  }

  thirdAcceptorDisabled(): boolean {
    return !this.ratingInAcceptance || !this.ratingVersion.secondAcceptedBy || !!this.ratingVersion.thirdAcceptedBy;
  }

  showAdditionalAcceptor(): boolean {
    return (
      this.mehib &&
      this.ratingVersion.ratingDepartment &&
      this.ratingVersion.ratingDepartment.id === RatingDepartment.LEVEL_1
    );
  }

  showMehibAdditionalAcceptedBy(): boolean {
    return (
      this.mehib &&
      this.ratingVersion.id &&
      this.ratingVersion.ratingDepartment &&
      this.ratingVersion.ratingDepartment.id === RatingDepartment.LEVEL_1
    );
  }

  ratingCalculationType(): string {
    if (this.ratingVersion.fullRamaEvaluation) {
      return this.translateService.instant('company.rating.extendedCalcType');
    }
    return this.translateService.instant('company.rating.qualityCalcType');
  }

  ratingDepartmentAccessible(): boolean {
    return this.kuke || this.ecg || this.credendo;
  }

  ratingValueRequired(): boolean {
    return (!this.insuranceMLTRating && !this.kuke) || (this.kuke && this.bondingEXPRating);
  }

  properLabelKey(): string {
    return this.credendo ? 'company.rating.ratingAgency' : 'company.rating.acceptingBody';
  }

  showDiscrtionaryRating(): boolean {
    return this.credendo && this.ratingType && this.ratingType.id === RatingType.CREDIT_INSURANCE;
  }

  showRating(): boolean {
    return !this.showDiscrtionaryRating();
  }
}

class RatingDocument {
  constructor(public name: string, public forRatingTypes: RatingType[]) {}
}

const RATING_DOCUMENT_TYPES: RatingDocument[] = [
  new RatingDocument('company.rating.KRUInquiry', [
    RatingType.ST_INSURANCE,
    RatingType.MLT_INSURANCE,
    RatingType.BONDING,
    RatingType.GSP,
  ]),
  new RatingDocument('company.rating.managementInquiry', [
    RatingType.ST_INSURANCE,
    RatingType.MLT_INSURANCE,
    RatingType.BONDING,
  ]),
  new RatingDocument('company.rating.KPUECreditInquiry', [RatingType.ST_INSURANCE, RatingType.MLT_INSURANCE]),
  new RatingDocument('company.rating.KPUEBondingInquiry', [RatingType.GSP]),
  new RatingDocument('company.rating.applicantRiskAssessment', [RatingType.GSP]),
];
