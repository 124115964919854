import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  CountryGroupsFeeDto,
  DictionaryBaseDto,
  DictionaryDto,
  FeeThresholdDto,
  PolicyContractFeesDto,
} from '../../../../bonding_shared/model';
import {AppConfigService, DictionaryService, PolicyContractVersionService} from '../../../../bonding_shared/services';
import {IMultiSelectSettings} from '../../../../bonding_shared/components/multiselect-dropdown';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Section} from '../sections/section';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {DictionaryProfile, LumpSum, PolicyContractType} from '../../../../bonding_shared/model/dictionary-ids';
import {DictionaryUtils} from '../../../../bonding_shared/utils/dictionary-utils';

@Component({
  selector: 'policy-limit-fees-section',
  templateUrl: './policy-limit-fees-section.component.html',
})
export class PolicyLimitFeesSectionComponent extends Section implements OnChanges {
  self = this;
  private _fees: PolicyContractFeesDto;
  private countryInvoicingTypes: {[key: number]: DictionaryDto} = {};
  private countryGroupsFeeCache: {[key: number]: CountryGroupsFeeDto} = {};
  private readonly policyFeesThresholdsControl: UntypedFormGroup;

  readonly lumpSum = LumpSum;
  readonly dictionaryProfile = DictionaryProfile;

  multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
  };

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    protected pcvService: PolicyContractVersionService,
    protected dictionaryService: DictionaryService,
    private fb: UntypedFormBuilder,
    public appService: AppConfigService
  ) {
    super(policyContractViewService, appService, pcvService, dictionaryService);
    this.policyFeesThresholdsControl = this.fb.group({});
    this.dictionaryService.getDictionary('CountryInvocingType').subscribe((entries) => {
      for (const entry of entries) {
        this.countryInvoicingTypes[entry.id] = entry;
      }
    });
  }

  get monitoringPeriodicityCodeRegexp() {
    return /^(?:ONE).*$/;
    // const insuranceYearInMonth = this.insuranceYearInMonth();
    // if (isNaN(insuranceYearInMonth) || insuranceYearInMonth > 12) {
    //   return '';
    // } else {
    //   return '^(?!ONE).*$';
    // }
  }

  // Requirement form task IKI-1816 and
  // conversation on slack with Michał Krasoń "kuke now want only option 'once per insurance period'" for monitoring periodicity

  // insuranceYearInMonth() {
  //   const p = this.policy;
  //   if (!p) {
  //     return NaN;
  //   }
  //   return moment(p.validTo).diff(moment(p.validFrom), 'months', true);
  // }

  @Input() set fees(f: PolicyContractFeesDto) {
    this._fees = f;
    if (!f) {
      return;
    }
    this.countryGroupsFeeCache = {};
    this.formUpdate(this._fees.lumpSum);
    this.updateCountryGroups();
  }

  get fees() {
    return this._fees;
  }

  get productTypeDict() {
    if (this.inquiry) {
      return 'InquiryProductType';
    }
    return 'PolicyContractType';
  }

  get productTypeId() {
    if (this.inquiry) {
      return this.inquiry.productType.id;
    }
    if (this.policy) {
      return this.policy.contractType.id;
    }
  }

  limitFeeEnabled() {
    return DictionaryUtils.equalsDictAndId(this._fees.lumpSum, LumpSum.NO);
  }

  updateModel() {
    // Cleanup unused data. We do it at the end to avoid loosing data during edition when user updates combo
    if (DictionaryUtils.equalsDictAndId(this._fees.lumpSum, LumpSum.NO)) {
      delete this._fees.feeThresholds;
      if (this._fees.noInvoiceForRefusals) {
        for (const fee of this.fees.fees) {
          fee.refusal = undefined;
        }
      }
    } else {
      delete this._fees.fees;
      delete this._fees.countryFees;
    }
  }

  addButtonClickLimitsThreshold() {
    if (!this._fees.feeThresholds) {
      this._fees.feeThresholds = [];
    }
    this._fees.feeThresholds.push(<FeeThresholdDto>{
      id: undefined,
      amount: 1,
      noOfLimits: 0,
    });
  }

  validateThresholds(formGroup: UntypedFormGroup): ValidationErrors | null {
    let prevLimits: number = null;
    for (const keyRow in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(keyRow)) {
        const controlRow: UntypedFormGroup = <UntypedFormGroup>formGroup.controls[keyRow];
        const controlLimit: UntypedFormControl = <UntypedFormControl>controlRow.controls[1];
        if (controlLimit) {
          if (prevLimits != null && controlLimit.value - prevLimits < 10) {
            return {
              validateThresholds10: {
                valid: false,
              },
            };
          }
          prevLimits = controlLimit.value;
          if (keyRow === '0' && controlLimit.value !== 0) {
            return {
              validateThresholds0: {
                valid: false,
              },
            };
          }
        }
      }
    }
    return null;
  }

  onLumpSumKeyChanged(ls: DictionaryBaseDto) {
    this._fees.countryInvocingType = undefined;
    this.updateCountryGroups();
    this.formUpdate(ls);
  }

  onInvoicingOptionsChanged(cit: DictionaryBaseDto) {
    this._fees.countryInvocingType = cit;
    if (this.isSectionVisible(this.viewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES)) {
      this.updateCountryGroups();
      this.formUpdateAreaLimits(this._fees.lumpSum);
    }
  }

  onNoInvoiceForRefusalsChange() {
    // hack, a-table doesn't refresh validators fast enough
    this.policyForm.removeControl('areaLimitsNO');
  }

  private updateCountryGroups() {
    if (!this._fees.countryInvocingType) {
      this._fees.fees = [];
      return;
    }
    const countryInvoicingType = this.countryInvoicingTypes[this._fees.countryInvocingType.id];
    const countryGroups: DictionaryBaseDto[] = countryInvoicingType.relatedDictionaries['CountryGroup'];

    if (!this._fees.fees) {
      this._fees.fees = [];
    }

    // Prevents to lose values when user changes country group profile
    for (const fee of this._fees.fees) {
      this.countryGroupsFeeCache[fee.countryGroup.id] = fee;
    }

    this.fees.fees.length = 0;
    for (const countryGroup of countryGroups) {
      let fee = this.countryGroupsFeeCache[countryGroup.id];
      if (!fee) {
        fee = <CountryGroupsFeeDto>{};
        if (this.mehib) {
          fee.monitoring = 15_000;
          fee.limit = 15_000;
          fee.refusal = 15_000;
        }
        fee.countryGroup = countryGroup;
      }
      this.fees.fees.push(fee);
    }
  }

  private formUpdate(ls: DictionaryBaseDto) {
    if (!ls) {
      return;
    }
    if (!this.policyForm || !this.fees || (!this.policy && !this.inquiry)) {
      return;
    }
    if (!this.policyForm.contains('countryInvoicingType')) {
      this.policyForm.addControl('countryInvoicingType', new UntypedFormControl());
    }
    this.policyForm.get('countryInvoicingType').setValue(this.fees.countryInvocingType);
    if (this.isRequired(this.viewSection.LIMIT_FEES__KUKE_COUNTRY_INVOICING_TYPE_REQUIRED)) {
      this.policyForm.get('countryInvoicingType').validator = DictionaryUtils.equalsDictAndId(ls, LumpSum.NO)
        ? Validators.required
        : null;
      this.policyForm.get('countryInvoicingType').updateValueAndValidity();
    }

    this.formUpdateAreaLimits(ls);
  }

  private formUpdateAreaLimits(ls: DictionaryBaseDto) {
    this.policyForm.removeControl('areaLimitsNO');
    this.policyForm.removeControl('policyFeesExceptionsCountriesNO');

    if (DictionaryUtils.equalsDictAndId(ls, LumpSum.NO)) {
      this.policyForm.addControl('areaLimitsNO', this.fb.group({}));
      this.policyForm.addControl('policyFeesExceptionsCountriesNO', this.fb.group({}));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.policyForm || changes.fees || changes.policy || changes.inquiry) && this._fees) {
      this.formUpdate(this._fees.lumpSum);
    }
  }

  get mehib(): boolean {
    return this.appService.mehib;
  }

  get ecg(): boolean {
    return this.appService.ecg;
  }

  get readonly() {
    if (this.mehib && this.inquiry) {
      return this.sectionDisabled;
    }
    return super.readonly;
  }
}
