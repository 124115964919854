<details-view [parentComponent]="self" [fixedBlockHidden]="false" [versionListHidden]="true" [objectName]="'Training'"
              [title]="'training.details.title'">
  <div class="details-content">
    <form #ngForm="ngForm">
      <div *ngIf="training" class="bon-card-main-group" style="padding-top: 15px;">
        <div class="bon-header" translate>training.data.generalHeader</div>
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <dict-row labelKey="common.type" [(ngModel)]="training.type"
                      [showErrors]="showErrors" required
                      dictionary="TrainingType" name="trainingType"></dict-row>

          </div>
          <div class="bon-card-inner"></div>
          <div class="bon-card-inner"></div>
        </div>
      </div>
      <bon-section labelKey="common.section.details">

        <div class="bon-card-group" *ngIf="training?.type">
          <div class="bon-card-inner">
            <input-row labelKey="training.data.topic" [(ngModel)]="training.topic" name="topic"
                       [showErrors]="showErrors" required></input-row>
            <date-row labelKey="common.dateFrom" [(ngModel)]="training.dateFrom"
                      name="dateFrom"
                      [required]="true" [showErrors]="showErrors"></date-row>
            <date-row labelKey="common.dateTo" [(ngModel)]="training.dateTo"
                      name="dateTo"
                      [required]="true" [showErrors]="showErrors"></date-row>
            <input-row [hidden]="isIntranet()" labelKey="training.data.organizer" [(ngModel)]="training.organizer"
                       name="organizer"></input-row>
            <input-row [hidden]="isExternal()" labelKey="training.data.trainer" [(ngModel)]="training.trainer"
                       name="trainer"></input-row>
          </div>
          <div class="bon-card-inner">
            <num-row labelKey="training.data.numberOfHours" [(ngModel)]="training.numberOfHours"
                       name="numberOfHours"></num-row>
            <num-row labelKey="training.data.price" [(ngModel)]="training.price"
                     name="price"></num-row>
            <dict-row labelKey="training.data.currency" [(ngModel)]="training.currency"
                      dictionary="Currency" name="currency" itemLabel="code"></dict-row>

          </div>
          <div class="bon-card-inner">
            <checkbox-row labelKey="training.data.idd"
                          [(ngModel)]="training.idd"
                          name="idd" [nullable]="false"></checkbox-row>
            <checkbox-row labelKey="training.data.certificate"
                          [(ngModel)]="training.certificate"
                          name="certificate" [nullable]="false"></checkbox-row>
            <checkbox-row labelKey="training.data.relevantTechnology"
                          [(ngModel)]="training.relevantTechnology"
                          name="relevantTechnology" [nullable]="false"></checkbox-row>
          </div>
        </div>

      </bon-section>
      <bon-section labelKey="common.description" [expandable]="true" *ngIf="training?.type">
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <div class="bon-row">
              <textarea style="width: 97.5%;" [(ngModel)]="training.description" name="description" rows="6"></textarea>
            </div>
          </div>
        </div>

      </bon-section>

      <bon-section labelKey="training.data.participants" *ngIf="training?.type && loggedUserHasHRRole">
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <div class="bon-row">
              <a-list type="userAutocomplete"
                      labelKey="training.data.participant"
                      [formModel]="ngForm.form"
                      [items]="training.participants"
                      [showAllErrors]="showErrors"
                      [deleteButton]="showDeleteParticipantButton()"
                      [addButton]="true"
                      [editable]="true"
                      [userRange]="employeeUserRange">
              </a-list>
            </div>
          </div>
        </div>
      </bon-section>
    </form>
  </div>
</details-view>
