import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {
  BrokerContractIdDto,
  BrokerContractVersionDto,
  BusinessObjectDto,
  CompanySimpleDto,
  ContractVersionDto,
  DictionaryBaseDto,
  ErrorReason,
  InvoiceIdDto,
  InvoiceItemDto,
  InvoiceItemIdDto,
  InvoiceItemTaxDto,
  PolicyContractIdDto,
  PolicyContractVersionDto,
  PolicyInquiryIdDto,
  PolicyInquiryVersionDto,
  ProvinceDto,
  SearchCriteria,
  TaxIfc,
  TaxRateDto,
  TreatyBaseDto,
  TreatyVersionCriteriaDto,
} from '../../../bonding_shared/model/dtos';
import {RouterService} from '../../../bonding_shared/services/router-service';
import {InvoiceCoreService} from '../../../bonding_shared/services/invoice-core.service';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {BusinessObjectService} from '../../../bonding_shared/services/business-object.service';
import {GrowlService} from '../../../bonding_shared/services/growl/growl.service';
import {ContractIdDto, ContractLinkDto, TreatyVersionSimpleDto} from '../../../bonding_shared/model';
import {
  AppProperty,
  BusinessObjectType,
  ElementaryRight,
  InvoiceCategory,
} from '../../../bonding_shared/model/dictionary-ids';
import {InvoiceItemService} from '../../../bonding_shared/services/invoice-item.service';
import {TreatyVersionService} from '../../../bonding_shared/services/treaty-version.service';
import {InvoiceItemGuiService} from '../services/invoice-item-gui.service';
import {BusinessObjectUtils} from '../../../bonding_shared/utils/business-object-utils';
import {DtoClass} from '../../../bonding_shared/model/classes';
import {ATableComponent, CellChangeEvent} from '../../../bonding_shared/components/aku-table';
import {Observable, of as observableOf} from 'rxjs';
import {share, tap} from 'rxjs/operators';
import {
  AddressAutocompleteService,
  DictionaryService,
  LoggedUserService,
  PropertyService,
} from '../../../bonding_shared/services';
import {BusinessObjectSelectorComponent} from '../../../bonding_shared/components/business-object-selector/business-object-selector.component';
import {InvoiceUtils} from '../../../bonding_shared/utils/invoice-utils';
import {ObjectUtils} from '../../../bonding_shared/utils/object-utils';

@Component({
  selector: 'invoice-item-data',
  templateUrl: 'invoice-item-data.component.html',
  providers: [AddressAutocompleteService],
})
export class InvoiceItemDataComponent extends DetailsView implements OnInit {
  @Input() categoryId: InvoiceCategory;
  @Input() header: string;
  @ViewChild(BusinessObjectSelectorComponent, {static: true}) businessObjectSelector: BusinessObjectSelectorComponent;
  _itemTaxTable: ATableComponent<InvoiceItemTaxDto>;

  @Output() errorOccured = new EventEmitter<ErrorReason[]>();

  showErrors = false;
  selectorNameList: string[] = [];

  treaties: TreatyBaseDto[] = [];
  protected appProperties: {[index: string]: any};
  readonly AppProperty = AppProperty;

  invoiceItemTaxes: InvoiceItemTaxDto[];
  businessObjectTypes: {[index: string]: DictionaryBaseDto} = {};

  manualCostCenter = false;

  @ViewChild('itemTaxTable')
  set itemTaxTable(itemTaxTable: ATableComponent<InvoiceItemTaxDto>) {
    this._itemTaxTable = itemTaxTable;
  }

  get itemTaxTable(): ATableComponent<InvoiceItemTaxDto> {
    return this._itemTaxTable;
  }

  // map[taxTypeId][taxCategoryId][countryId] -> Vat rate value
  private taxRates: {[index: string]: {[index: string]: {[index: string]: TaxRateDto[]}}};
  private taxRatesObservable: Observable<{[index: string]: {[index: string]: {[index: string]: TaxRateDto[]}}}>;
  private provinces: ProvinceDto[];

  private _invoiceItem: InvoiceItemDto = <InvoiceItemDto>{};

  @ViewChild(NgForm)
  set parentForm(form: NgForm) {
    if (form) {
      this.form = form.form;
      this.itemTaxTable.formModel = this.form;
      this.cd.detectChanges();
    }
  }

  constructor(
    public router: RouterService,
    private invoiceCoreService: InvoiceCoreService,
    private invoiceItemService: InvoiceItemService,
    private invoiceItemGuiService: InvoiceItemGuiService,
    private businessObjectService: BusinessObjectService,
    private dictionaryService: DictionaryService,
    private geoDictService: AddressAutocompleteService,
    protected growlService: GrowlService,
    private treatyService: TreatyVersionService,
    protected propertyService: PropertyService,
    private loggedUserService: LoggedUserService,
    private cd: ChangeDetectorRef
  ) {
    super(growlService);
    this.selectorNameList = ['Client'];
    this.initializeSelectorEmitters(true);
  }

  get invoiceItem(): InvoiceItemDto {
    return this._invoiceItem;
  }

  @Input() set invoiceItem(item: InvoiceItemDto) {
    this._invoiceItem = item;
    if (this._invoiceItem) {
      if (this._invoiceItem.taxes) {
        this.invoiceItemTaxes = Object.values(this._invoiceItem.taxes);
      }
      if (this._invoiceItem.costCenter) {
        this.manualCostCenter = true;
      } else {
        this.manualCostCenter = false;
      }
      this.updateProvinces();
    }
  }

  ngOnInit() {
    const treatyCriteria: SearchCriteria<TreatyVersionCriteriaDto> = <SearchCriteria<TreatyVersionCriteriaDto>>{};
    treatyCriteria.criteria = <TreatyVersionCriteriaDto>{};
    treatyCriteria.criteria.lastActivated = true;
    this.treatyService.searchByCriteria(treatyCriteria).subscribe({
      next: (treatiesResult) => {
        this.treaties = treatiesResult.result.map((tv) => (<TreatyVersionSimpleDto>tv).treaty);
      },
      error: (error) => {
        this.serverErrors = error;
      },
    });

    this.loadTaxRates().subscribe();

    this.dictionaryService
      .getDictionary('BusinessObjectType')
      .subscribe((res) => res.forEach((d) => (this.businessObjectTypes[d.id] = d)));

    this.loadAppProperties();
  }

  invoiceItemTypeChanged(type: DictionaryBaseDto) {
    this.cd.detectChanges();
  }

  receivedBusinessObject(businessObject: BusinessObjectDto) {
    this.invoiceItem.businessObject = businessObject;
  }

  public editionBlocked(allowServiceEdition = true) {
    return (
      this._invoiceItem &&
      this._invoiceItem.invoicePosition &&
      this._invoiceItem.invoicePosition.invoice.number &&
      !(allowServiceEdition && this.loggedUserService.hasRight(ElementaryRight.INVOICE_SERVICE_EDITION))
    );
  }

  get invoiceItemStatusRegexp() {
    return ObjectUtils.getId(this._invoiceItem)
      ? InvoiceUtils.getInvoiceItemStatusRegexp(this.categoryId)
      : InvoiceUtils.getNewInvoiceItemStatusRegexp();
  }

  private loadTaxRates(): Observable<{[index: string]: {[index: string]: {[index: string]: TaxRateDto[]}}}> {
    if (this.taxRatesObservable) {
      // Load in progress...
      return this.taxRatesObservable;
    }
    if (!this.taxRates) {
      this.taxRatesObservable = this.invoiceCoreService.getAllTaxRates().pipe(
        tap((result) => {
          this.taxRates = result;
          this.taxRatesObservable = undefined;
          console.log('loadTaxTypes done', this.taxRates);
        }),
        share()
      );

      return this.taxRatesObservable;
    }
    return observableOf(this.taxRates);
  }

  updateProvinces() {
    this.provinces = [];
    if (!this.invoiceItem || !this.invoiceItem.taxCountry) {
      return;
    }
    this.geoDictService.getPostCodeGeoDict(this.invoiceItem.taxCountry.id).subscribe({
      next: (postCodes) => {
        this.provinces = postCodes
          .filter((postCode) => postCode.province && postCode.province.taxRates.length > 0)
          .map((postCode) => postCode.province);
      },
      error: (error) => {
        this.serverErrors = error;
      },
    });
  }

  updateTaxRate() {
    if (!this.invoiceItem || !this.invoiceItem.taxes) {
      return;
    }

    const countryId = this.getCountryId();
    if (!countryId) {
      console.log('No country to calculate taxes!');
      return;
    }

    const countryTaxRates = this.taxRates[countryId];
    for (const itemTax of this.invoiceItemTaxes) {
      const categoryTaxRate = countryTaxRates ? countryTaxRates[itemTax.taxCategory.id] : undefined;
      const taxRates = categoryTaxRate ? categoryTaxRate[itemTax.taxType.id] : undefined;
      const taxRate = taxRates
        ? taxRates.find((tr) => ObjectUtils.equalsId(tr.province, this.invoiceItem.province))
        : undefined;
      if (taxRate) {
        itemTax.taxRateValue = taxRate.rate;
        itemTax.taxNotApplicable = taxRate.taxNotApplicable;
      } else {
        itemTax.taxRateValue = 0;
        itemTax.taxNotApplicable = true;
      }
    }
  }

  onCompanySelect(company: CompanySimpleDto) {
    this.invoiceItem.client = company;
  }

  onBusinessObjectSelect(businessObject: BusinessObjectDto) {
    this.invoiceItem.businessObject = businessObject;
    this.invoiceItem.businessObjectNumber = BusinessObjectUtils.getNumber(businessObject);
    if (businessObject && businessObject.relatedTo && businessObject.relatedToId) {
      this.updateContractLink(businessObject);
      this.updateTreaties();
    } else {
      this.invoiceItem.contractNumber = '';
      delete this.invoiceItem.contractLink;
    }
  }

  taxCountryChanged() {
    this.invoiceItem.province = undefined;
    this.updateProvinces();
    this.updateTaxRate();
  }

  reset() {
    this.closeAllSelectors();
  }

  toInvoice(invoice: InvoiceIdDto) {
    if (!invoice) {
      return;
    }
    this.router.toInvoiceDetails(invoice.category.id, invoice.id);
  }

  toInvoiceItem(item: InvoiceItemIdDto) {
    if (!item) {
      return;
    }
    this.router.toInvoiceItemDetails(item.category.id, item.id);
  }

  getInvoiceItemCategoryLabel(invoiceCategory: DictionaryBaseDto): string {
    return this.invoiceItemGuiService.getInvoiceItemCategoryLabel(invoiceCategory, false);
  }

  manualCostCenterChanged(value: boolean) {
    if (!this.manualCostCenter) {
      this._invoiceItem.costCenter = undefined;
    }
  }

  formatInvoiceNumber(invoice: InvoiceIdDto): string {
    return invoice.number ? invoice.number : 'Draft';
  }

  formatTax(tax: TaxIfc): string {
    return this.invoiceCoreService.formatTax(tax);
  }

  taxTypeChanged(event: CellChangeEvent<InvoiceItemDto>) {
    this.updateTaxRate();
  }

  addTax(tax: InvoiceItemTaxDto) {}

  onSave(): void {
    super.onSave();
    this.invoiceItem.taxes = {};

    for (const itemTax of this.invoiceItemTaxes) {
      this.invoiceItem.taxes[itemTax.taxCategory.id] = itemTax;
    }
  }

  private updateContractLink(businessObject: BusinessObjectDto) {
    this.businessObjectService
      .getRelatedContractVersion(businessObject.relatedTo.id, businessObject.relatedToId)
      .subscribe({
        next: (legalVersion) => {
          if (legalVersion) {
            if (legalVersion.clazz === DtoClass.ContractVersionDto) {
              const contractVersion = <ContractVersionDto>legalVersion;
              this.invoiceItem.contractNumber = contractVersion.contract.number;
              this.invoiceItem.businessUnit = contractVersion.contract.businessUnit;
              this.invoiceItem.contractLink = <ContractLinkDto>{
                linkType: this.businessObjectTypes[BusinessObjectType.CONTRACT],
                contract: <ContractIdDto>{
                  id: contractVersion.contract.id,
                  number: contractVersion.contract.number,
                },
              };
            } else if (legalVersion.clazz === DtoClass.PolicyContractVersionDto) {
              const policyContractVersion = <PolicyContractVersionDto>legalVersion;
              this.invoiceItem.contractNumber = policyContractVersion.policyContract.number;
              this.invoiceItem.businessUnit = policyContractVersion.businessUnit;
              this.invoiceItem.contractLink = <ContractLinkDto>{
                linkType: this.businessObjectTypes[BusinessObjectType.POLICY],
                policyContract: <PolicyContractIdDto>{
                  id: policyContractVersion.policyContract.id,
                  number: policyContractVersion.policyContract.number,
                },
              };
            } else if (legalVersion.clazz === DtoClass.BrokerContractVersionDto) {
              const brokerContractVersion = <BrokerContractVersionDto>legalVersion;
              this.invoiceItem.contractNumber = brokerContractVersion.brokerContract.number;
              this.invoiceItem.businessUnit = brokerContractVersion.brokerContract.businessUnit;
              this.invoiceItem.contractLink = <ContractLinkDto>{
                linkType: this.businessObjectTypes[BusinessObjectType.BROKER_CONTRACT],
                brokerContract: <BrokerContractIdDto>{
                  id: brokerContractVersion.brokerContract.id,
                  number: brokerContractVersion.brokerContract.number,
                },
              };
            } else if (legalVersion.clazz === DtoClass.PolicyInquiryVersionDto) {
              const policyInquiryVersion = <PolicyInquiryVersionDto>legalVersion;
              this.invoiceItem.contractNumber = policyInquiryVersion.policyInquiry.number;
              this.invoiceItem.businessUnit = policyInquiryVersion.businessUnit;
              this.invoiceItem.contractLink = <ContractLinkDto>{
                linkType: this.businessObjectTypes[BusinessObjectType.POLICY_INQUIRY],
                policyInquiry: <PolicyInquiryIdDto>{
                  id: policyInquiryVersion.policyInquiry.id,
                  number: policyInquiryVersion.policyInquiry.number,
                },
              };
            }
          }
        },
        error: (error) => {
          this.errorOccured.emit(error);
          this.serverErrors = error;
        },
      });
  }

  private getCountryId(): number {
    if (!this.invoiceItem) {
      return undefined;
    }
    if (this.invoiceItem.taxCountry && this.invoiceItem.taxCountry.id) {
      return this.invoiceItem.taxCountry.id;
    }

    if (this.invoiceItem.client && this.invoiceItem.client.address && this.invoiceItem.client.address.country) {
      return this.invoiceItem.client.address.country.id;
    }
    return undefined;
  }

  private loadAppProperties() {
    this.appProperties = this.propertyService.properties;
  }

  public updateTreaties() {
    if (!this.invoiceItem || !this.invoiceItem.businessObject || !this.invoiceItem.contractLink) {
      return;
    }

    let id: number;
    if (this.invoiceItem.contractLink.contract) {
      id = this.invoiceItem.contractLink.contract.id;
    } else if (this.invoiceItem.contractLink.policyContract) {
      id = this.invoiceItem.contractLink.policyContract.id;
    } else {
      return;
    }

    this.invoiceItemService
      .calculateTreaties(
        this.invoiceItem.contractLink.linkType.id,
        id,
        this.invoiceItem.businessObject.relatedTo.id,
        this.invoiceItem.businessObject.relatedToId,
        this.invoiceItem.dateFrom,
        this.invoiceItem.dateFrom,
        this.invoiceItem.dateTo,
        this.invoiceItem.type.id
      )
      .subscribe({
        next: (treaties) => {
          this.invoiceItem.treaties = treaties;
        },
        error: (error) => {
          this.errorOccured.emit(error);
          this.serverErrors = error;
        },
      });
  }
}
