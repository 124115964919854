import {CrPolicyProduct} from '../cr/cr-policy-product';
import {Directive, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {PolicyContractVersionDto} from '../../../../../bonding_shared/model';
import {
  POLICY_CONTRACT_REQUEST_OR_TERMINATION_REQUEST_STATUSES,
  PolicyContractVersionStatus,
} from '../../../../../bonding_shared/model/dictionary-ids';
import {KukeTerminationSectionComponent} from '../../sections/kuke-termination-section.component';

@Directive()
export class KukePolicyProduct extends CrPolicyProduct {
  @ViewChild('kukeTerminationSectionComponent', {static: true})
  kukeTerminationSectionComponent: KukeTerminationSectionComponent;

  _policyInRegistration: boolean;
  _notFirstVersionBeforeActivation: boolean;

  @Input() documentReceivedAndSignatureDateRequired: boolean;
  @Output() saveEvent = new EventEmitter<void>();

  @Input() set policy(policy: PolicyContractVersionDto) {
    super.policy = policy;
    this._policyInRegistration = POLICY_CONTRACT_REQUEST_OR_TERMINATION_REQUEST_STATUSES.includes(policy.status.id);

    this._notFirstVersionBeforeActivation = !(
      policy.versionNumber === 1 &&
      (policy.status.id === PolicyContractVersionStatus.REQUEST ||
        policy.status.id === PolicyContractVersionStatus.REQUEST_ACCEPTED ||
        policy.status.id === PolicyContractVersionStatus.ISSUED ||
        policy.status.id === PolicyContractVersionStatus.PROLONGATION_REQUEST ||
        policy.status.id === PolicyContractVersionStatus.PROLONGATION_REQUEST_ACCEPTED ||
        policy.status.id === PolicyContractVersionStatus.PROLONGATION_REQUEST_EXTRA_RIGHTS)
    );
  }

  get policy(): PolicyContractVersionDto {
    return super.policy;
  }

  get policyInRegistration(): boolean {
    return this._policyInRegistration;
  }

  get notFirstVersionBeforeActivation(): boolean {
    return this._notFirstVersionBeforeActivation;
  }

  onDataChangedEvent() {
    this.kukeTerminationSectionComponent.recalculateTerminationDateBoundries();
  }
}
